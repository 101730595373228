import React from 'react';
import { Row, Col, Descriptions } from 'antd';
import { FormattedMessage } from 'react-intl';
import ImportButton from './ImportButtonDisbursements';
import { satangToBath } from '../../../../helpers/format/satangToBath';

const SummaryDisbursements: React.FC<any> = (props: any) => {
  const { summary = undefined, disbursements, transferredDate } = props;

  if (disbursements === undefined) window.location.href = '#/import/disbursements';

  return (
    <div>
      <div
        style={{
          backgroundColor: '#ffffff',
          marginBottom: '20px',
        }}
      >
        <Descriptions
          title="disbursements"
          column={{ lg: 4, md: 3, sm: 2, xs: 1 }}
          size="small"
          bordered
        >
          <Descriptions.Item label={<FormattedMessage id="import.disbursements.summary.all" />}>
            {summary?.all ? summary.all : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.disbursements.summary.normal" />}>
            {summary?.normal ? summary.normal : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.disbursements.summary.duplicate" />}
          >
            {summary?.duplicate ? summary.duplicate : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.disbursements.summary.notfound" />}
          >
            {summary?.notfound ? summary.notfound : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.disbursements.summary.fail" />}>
            {summary?.fail ? summary?.fail : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.disbursements.summary.amountSubmit" />}
          >
            {summary?.amountSubmit ? satangToBath(summary.amountSubmit) : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.baht" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.disbursements.summary.amountReject" />}
          >
            {summary?.amountReject ? satangToBath(summary.amountReject) : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.baht" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.disbursements.summary.amountFail" />}
          >
            {summary?.amountFail ? satangToBath(summary?.amountFail) : 0}
            {'  '}
            <FormattedMessage id="import.disbursements.summary.baht" />
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <ImportButton disbursements={disbursements} transferredDate={transferredDate} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SummaryDisbursements;
