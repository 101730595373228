import React, { FC } from 'react';
import ContentContainer from '../../../components/LayoutComponents/ContentContainer';
import ProductForm from '../../../components/CbsComponents/ProductForm/ProductForm';

const NewProduct: FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.products',
      href: '/products',
    },
    {
      title: 'add',
      href: `/products/new`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="product-new.helmet-title"
      pageTitle="product-new.page-title"
      breadcrumbs={breadcrumbs}
    >
      <ProductForm isNew />
    </ContentContainer>
  );
};

export default NewProduct;
