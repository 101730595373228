import React from 'react';
import KeyDataTabs from './KeyDataTabs/KeyDataTabs';

interface Props {
  projectId: string;
  application: any;
  reloadApplication: () => void;
}

const KeyData: React.FC<Props> = ({ projectId, application, reloadApplication }) => {
  return (
    <KeyDataTabs
      projectId={projectId}
      application={application}
      reloadApplication={reloadApplication}
    />
  );
};

export default KeyData;
