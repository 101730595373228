import React, { FC, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Form, Modal, Row, Col, notification, InputNumber, Input, Checkbox, message } from 'antd';
import { Store } from 'antd/lib/form/interface';
import InputBaht from '../../common/InputBaht';
import InputPercent from '../../common/InputPercent';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import componentValues from '../../../../services/utilities/ComponentValue';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { ErrorInfo } from '../../../../types/form-types';
import { satangToBath } from '../../../../helpers/format/satangToBath';

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  lastTransaction: any;
  loan: any;
}

const EditLoanModal: FC<Props> = ({
  projectId,
  visible,
  onCancel,
  cancelText,
  okText,
  loan,
  lastTransaction,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { getFieldValue } = form;

  const isGracePeriod = () => {
    const useGracePeriod = getFieldValue('useGracePeriod');
    const isGracePeriod = useGracePeriod;
    return isGracePeriod;
  };
  const onFinish = async (value: Store) => {
    try {
      const diffField: { [key: string]: string } = {};
      Object.keys(value).forEach(key => {
        if (value[key] !== loan[key]) {
          diffField[key] = value[key];
        }
      });
      const { creditLimit } = diffField;
      const { maximumCreditLimit } = loan;
      if (
        maximumCreditLimit &&
        creditLimit &&
        Number(creditLimit) > Number(maximumCreditLimit) &&
        Number(maximumCreditLimit) > 0
      ) {
        message.error(`Maximum CreditLimit: ${satangToBath(Number(maximumCreditLimit))}`);
      } else {
        await postJson(`${API_URL}/projects/${projectId}/request-approvals`, {
          type: 'editloan',
          values: {
            loanId: loan.id,
            ...diffField,
          },
        });
        notification.success({ message: intl.formatMessage({ id: 'editloan.success-message' }) });
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  function disabledDate(current: any) {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment(lastTransaction.transDate)
          .add(-1, 'day')
          .endOf('day')
    );
  }

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} initialValues={loan} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="editloan" />
          </h2>
        </Row>
        <hr />
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="creditLimit" />}
              name="creditLimit"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="intRate" />}
              name="intRate"
              rules={[{ required: true }]}
            >
              <InputPercent />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="safeMargin" />}
              name="safeMargin"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="minPayRate" />}
              name="minPayRate"
              rules={[{ required: true }]}
            >
              <InputPercent />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="minPayAmount" />}
              name="minPayAmount"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="lateFee" />}
              name="lateFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="stampFee" />}
              name="stampFee"
              rules={[{ required: true }]}
            >
              <InputPercent />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="bureauFee" />}
              name="bureauFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="bureauMissFee" />}
              name="bureauMissFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="preterminateFee" />}
              name="preterminateFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="preterminatePeriod" />}
              name="preterminatePeriod"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="transferFee" />}
              name="transferFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="interBankTransferFee" />}
              name="interBankTransferFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="useGracePeriod" valuePropName="checked">
              <Checkbox>
                <FormattedMessage id="useGracePeriod" />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item shouldUpdate>
              {() => {
                return (
                  isGracePeriod() && (
                    <Fragment>
                      <Row gutter={componentValues.gutterValue}>
                        <Col
                          sm={{ span: componentValues.fullColSpan }}
                          md={{ span: componentValues.halfColSpan }}
                        >
                          <Form.Item
                            label={<FormattedMessage id="gracePeriod" />}
                            name="gracePeriod"
                          >
                            <Input type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Fragment>
                  )
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="effectiveDate" />}
              name="effectiveDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="YYYY-MM-DD" disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditLoanModal;
