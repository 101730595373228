import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL } from '../../../helpers/api/api';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Layout } from 'antd';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import SearchableTable from '../../../components/CbsComponents/SearchableTable/SearchableTable';
// import { Projects } from '../../../types/projects-types';
import { getSortOrderFromQueryString } from '../../../helpers/querystring';

interface Props {
  queryString: string;
  pathname: string;
  currentProjectId: string;
}
const ListProjects: React.FC<Props & WrappedComponentProps> = ({
  queryString,
  pathname,
  currentProjectId,
  intl,
}) => {
  const columns = [
    {
      title: <FormattedMessage id="projects.id" />,
      dataIndex: 'id',
      width: 100,
      fixed: 'left',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any) => (a.code < b.code ? -1 : 1),
      sortOrder: getSortOrderFromQueryString({ dataIndex: 'id', queryString }),
      render: (id: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`projects/${id}`}>
            {id}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="projects.name" />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id="organisation.name" />,
      dataIndex: 'companyName',
    },
    {
      title: <FormattedMessage id="organisationName" />,
      dataIndex: 'organisationName',
    },
    {
      title: <FormattedMessage id="status" />,
      dataIndex: 'status',
    },
  ];
  const breadcrumbs = [
    {
      title: 'menu.projects',
      href: '/projects',
    },
  ];

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.projects' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.projects" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SearchableTable
          columns={columns}
          fetchUrl={`${API_URL}/projects/${currentProjectId}/projects`}
          addUrl="projects/new"
          addPermissions="projects.*.create"
          pathname={pathname}
          search={queryString}
          rowKey="code"
          enableDownload
        />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps)(injectIntl(ListProjects));
