import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import SmsEstatementForm from '../../components/CbsComponents/Sms/SmsEstatementForm';

const SmsEstatement: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'sms-estatement.page-title',
      href: `/sms/statement`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="sms-estatement.page-title"
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsEstatementForm />
    </ContentContainer>
  );
};

export default SmsEstatement;
