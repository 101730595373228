import store from 'store';
import { ReduxAction, ReduxFailPayload } from '../../types/redux-types';
import { NotificationConfig } from '../../types/form-types';

// Action types
export const LOGIN = 'user/LOGIN';
const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
const LOGIN_FAIL = 'user/LOGIN_FAIL';

export const SCHEDULE_RENEW_TOKEN = 'user/SCHEDULE_RENEW_TOKEN';
export const RENEW_TOKEN = 'user/RENEW_TOKEN';
const RENEW_TOKEN_SUCESS = 'user/RENEW_TOKEN_SUCCESS';
const RENEW_TOKEN_FAIL = 'user/RENEW_TOKEN_FAIL';

export const VERIFY = 'user/VERIFY';
const VERIFY_SUCCESS = 'user/VERIFY_SUCCESS';
const VERIFY_FAIL = 'user/VERIFY_FAIL';

export const REGISTER = 'user/REGISTER';
const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
const REGISTER_FAIL = 'user/REGISTER_FAIL';

export const LOGOUT = 'user/LOGOUT';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'user/LOGOUT_FAIL';
export const SET_CURRENT_PROJECT = 'user/SET_CURRENT_PROJECT';

export const ONBOARDING = 'user/ONBOARDING';
const ONBOARDING_SUCCESS = 'user/ONBOARDING_SUCCESS';
const ONBOARDING_FAIL = 'user/ONBOARDING_FAIL';

export const ACCEPT_INVITE = 'user/ACCEPT_INVITE';
const ACCEPT_INVITE_SUCCESS = 'user/ACCEPT_INVITE_SUCCESS';
const ACCEPT_INVITE_FAIL = 'user/ACCEPT_INVITE_FAIL';

// Action creators
export function login({
  email,
  password,
  successNotification,
  redirect,
}: {
  email: string;
  password: string;
  successNotification: NotificationConfig;
  redirect?: string;
}): ReduxAction {
  return {
    type: LOGIN,
    payload: { email, password, successNotification, redirect },
  };
}

export interface UserInfo {
  email: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  projects: ProjectPermissionState[];
  accessToken: string;
  authorized: boolean;
}
export function loginSuccess(userInfo: UserInfo): ReduxAction {
  return {
    type: LOGIN_SUCCESS,
    payload: userInfo,
  };
}
export function loginFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: LOGIN_FAIL,
    payload: {
      code,
      message,
    },
  };
}
export function verify({
  token,
  successNotification,
}: {
  token: string;
  successNotification: NotificationConfig;
}): ReduxAction {
  return {
    type: VERIFY,
    payload: { token, successNotification },
  };
}
export function verifySuccess(userInfo: UserInfo): ReduxAction {
  return {
    type: VERIFY_SUCCESS,
    payload: userInfo,
  };
}
export function verifyFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: VERIFY_FAIL,
    payload: {
      code,
      message,
    },
  };
}
export function register({
  email,
  password,
  successNotification,
}: {
  email: string;
  password: string;
  successNotification: NotificationConfig;
}): ReduxAction {
  return {
    type: REGISTER,
    payload: {
      email,
      password,
      successNotification,
    },
  };
}
export function registerSuccess(): ReduxAction {
  return {
    type: REGISTER_SUCCESS,
  };
}
export function registerFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: REGISTER_FAIL,
    payload: {
      code,
      message,
    },
  };
}
export function renewToken(): ReduxAction {
  return {
    type: RENEW_TOKEN,
  };
}
export function scheduleRenewToken(): ReduxAction {
  return {
    type: SCHEDULE_RENEW_TOKEN,
  };
}
export function renewTokenSuccess(userInfo: any): ReduxAction {
  return {
    type: RENEW_TOKEN_SUCESS,
    payload: userInfo,
  };
}
export function renewTokenFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: RENEW_TOKEN_FAIL,
    payload: {
      code,
      message,
    },
  };
}
export function logout(): ReduxAction {
  return {
    type: LOGOUT,
  };
}
export function logoutSuccess(): ReduxAction {
  return {
    type: LOGOUT_SUCCESS,
  };
}
export function logoutFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: LOGOUT_FAIL,
    payload: {
      code,
      message,
    },
  };
}
export function setCurrentProject({
  projectId,
  projectName,
}: {
  projectId: string;
  projectName: string;
}): ReduxAction {
  return {
    type: SET_CURRENT_PROJECT,
    payload: { projectId, projectName },
  };
}

export function onboarding({
  otp,
  ref,
  successNotification,
}: {
  otp: string;
  ref: string;
  successNotification: NotificationConfig;
}): ReduxAction {
  return {
    type: ONBOARDING,
    payload: { otp, ref, successNotification },
  };
}

export function onboardingSuccess(userInfo: UserInfo): ReduxAction {
  return {
    type: ONBOARDING_SUCCESS,
    payload: userInfo,
  };
}

export function onboardingFail({ code, message }: ReduxFailPayload) {
  return {
    type: ONBOARDING_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function acceptInvite({ accessToken }: { accessToken: string }): ReduxAction {
  return {
    type: ACCEPT_INVITE,
    payload: { accessToken },
  };
}

export function acceptInviteSuccess(userInfo: any): ReduxAction {
  return {
    type: ACCEPT_INVITE_SUCCESS,
    payload: userInfo,
  };
}

export function acceptInviteFail({ code, message }: ReduxFailPayload): ReduxAction {
  return {
    type: ACCEPT_INVITE_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export interface ProjectPermissionState {
  project: string;
  id: string; // projectId
  name: string;
  organisationId: string;
  organisationName: string;
  roleIds: string[];
  permissions: string[];
}
export interface UserState {
  email: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  projects: ProjectPermissionState[];
  authorized: boolean;
  currentProjectId: string;
  currentProjectName: string;
  accessToken?: string;
  loading: boolean;
  error: object;
}
const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  mobileNo: '',
  projects: [],
  authorized: store.get('app.user.authorized') || false,
  currentProjectId: store.get('app.user.currentProjectId') || '',
  currentProjectName: store.get('app.user.currentProjectName'),
  loading: false,
  error: {},
};

export default function userReducer(
  state: UserState = initialState,
  action: ReduxAction,
): UserState {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
    case RENEW_TOKEN:
    case REGISTER:
    case VERIFY:
    case ONBOARDING:
    case ACCEPT_INVITE:
    case LOGOUT:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
    case RENEW_TOKEN_SUCESS:
    case ACCEPT_INVITE_SUCCESS:
    case ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        ...payload,
      };
    case VERIFY_SUCCESS:
    case REGISTER_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case LOGIN_FAIL:
    case LOGOUT_FAIL:
    case VERIFY_FAIL:
    case REGISTER_FAIL:
    case RENEW_TOKEN_FAIL:
    case ONBOARDING_FAIL:
    case ACCEPT_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProjectId: payload.projectId,
        currentProjectName: payload.projectName,
      };
    default:
      return state;
  }
}
