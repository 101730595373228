import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Button, Input, Row, Col, notification, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import componentValues from '../../../services/utilities/ComponentValue';
import { UserState } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';
import SmsStatementTable from './SmsStatementTable';
import DateOnlyPicker from '../common/DateOnlyPicker';
import ProductSelect from '../common/ProductSelect';

const { TextArea } = Input;

const SmsEstatementForm: FC<PropsFromRedux> = ({ projectId }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [rows, setSelectedRows] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [disableSend, setDisableSend] = useState<any>(true);
  const { setFieldsValue } = form;
  const rowSelection: any = {
    onChange: (_: any, selectedRowKeys: any) => {
      const newSelectedRowKeys = selectedRowKeys.map((row: any) => {
        const { loanId, mobileNo, message } = row;
        return { loanId, mobileNo, message };
      });
      setSelectedRows(newSelectedRowKeys);
      if (selectedRowKeys.length > 0) {
        setDisableSend(false);
      } else {
        setDisableSend(true);
      }
    },
  };
  const onFinish = async (value: Store) => {
    try {
      const body = value;
      body.sendMessages = rows;
      const { status } = body;
      body.status = status || 'normal';
      const result = await postJson(`${API_URL}/projects/${projectId}/sms/estatement`, body);
      if (body.sendSMS === 'n') {
        setData(result.data);
        setDisableSend(true);
        setSelectedRows([]);
        notification.success({
          message: intl.formatMessage({ id: 'sms-statement.search-success' }),
        });
      }
      if (body.sendSMS === 'y') {
        notification.success({
          message: intl.formatMessage({ id: 'sms-statement.success-message' }),
        });
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SearchButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button type="primary" onClick={() => setFieldsValue({ sendSMS: 'n' })} htmlType="submit">
      <FormattedMessage id="search" />
    </Button>,
  );
  const SendButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button
      style={{ marginLeft: '8px' }}
      type="primary"
      htmlType="submit"
      onClick={() => setFieldsValue({ sendSMS: 'y' })}
      disabled={disableSend}
    >
      <FormattedMessage id="notify-approval.search-button" />
    </Button>,
  );
  const { Option } = Select;
  return (
    <div className="card">
      <div className="card-body">
        <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                style={{ display: 'inline-block' }}
                label={<FormattedMessage id="statementDate" />}
                name="statementDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={{ span: 6 }} md={{ span: 3 }}>
              <Form.Item
                style={{ display: 'inline-block' }}
                label={<FormattedMessage id="sendEStatement" />}
                name="sendEstatement"
                rules={[{ required: true }]}
              >
                <Select style={{ width: '100%' }}>
                  <Option value="true">
                    <FormattedMessage id="common.yes" />
                  </Option>
                  <Option value="false">
                    <FormattedMessage id="common.no" />
                  </Option>
                  <Option value="all">
                    <FormattedMessage id="dashboards.all" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={{ span: 6 }} md={{ span: 3 }}>
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}
                label={<FormattedMessage id="status" />}
                name="status"
              >
                <Select defaultValue="normal" style={{ width: '100%' }}>
                  <Option value="normal">
                    <FormattedMessage id="ลูกหนี้ปกติ" />
                  </Option>
                  <Option value="writeoff">
                    <FormattedMessage id="ลูกหนี้ตัดหนี้สูญ" />
                  </Option>
                  <Option value="all">
                    <FormattedMessage id="ทั้งหมด" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="product" />} name="productId">
                <ProductSelect />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="sms.format" />}
                name="message"
                rules={[{ required: true }]}
                initialValue="เรียนคุณ {firstName} {lastName} สินเชื่อ{productName} {loanId} มียอดขั้นต่ำ {amountPastDue} บาท ชำระภายใน {dueDate} ดูใบแจ้งยอด {link} ใช้วันเดือนปีเกิดเป็นรหัสผ่าน เช่น เกิด 1 มีนาคม 2535 ใช้ 01032535"
              >
                <TextArea autoSize style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'left' }}>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SearchButton />
              </Form.Item>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SendButton />
              </Form.Item>
            </Col>
          </Row>
          <SmsStatementTable dataSource={data} pagination={undefined} rowSelection={rowSelection} />
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({
  projectId: user.currentProjectId,
});
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SmsEstatementForm);
