import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function iso8601ToLocalDate(dateStr: any) {
  return moment(dateStr).format('YYYY-MM-DD');
}

export function iso8601ToLocalDateWithTime(dateStr: any) {
  return moment(dateStr).format('YYYY-MM-DD HH:mm');
}

export function datediff(d1: string | Date, d2: string | Date) {
  // Use startOf to compare only date diff ignore time component
  // Use utcOffset to fix timezone to 7 so we only compare in Bangkok timezone no matter where actual server is located
  // For instance, duration between 2018-06-05T23:59:59+0000 and 2018-06-06T00:00:00+0000 can be 1 if compared in +0 GMT timezone
  // However, it will be 0 if compared in +7 GMT timezone because startOf date of both dates will become 2018-06-06
  const d1date = moment(d1)
    .utcOffset('+07:00')
    .startOf('day');
  const d2date = moment(d2)
    .utcOffset('+07:00')
    .startOf('day');
  return d1date.diff(d2date, 'days');
}

export function toYYYYMM(date: any) {
  return moment(date).format('YYYY-MM');
}

export function startOfDate(date: any) {
  return moment(date)
    .startOf('day')
    .toISOString();
}

export function endOfDate(date: any) {
  return moment(date)
    .endOf('day')
    .toISOString();
}
