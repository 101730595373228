import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';

interface Props {
  onChange?: (value: string | number) => void;
  placeHolder?: string;
  'data-__meta'?: {
    initialValue: string;
  };
  format: string;
  style?: object;
  disabled?: boolean;
  value?: any;
  disabledDate?: any;
}

class DateOnlyPicker extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.keepDateOnly = this.keepDateOnly.bind(this);
    const { 'data-__meta': meta, onChange } = this.props;
    const initialValue = meta ? meta.initialValue || '' : ''; // initialValue fron backend is in satang unit
    if (onChange) {
      if (initialValue !== '') {
        onChange(initialValue);
      }
    }
  }

  keepDateOnly(date: Moment | null, dateString: string) {
    const { onChange, format } = this.props;
    if (onChange) {
      onChange(moment(dateString, format).format('YYYY-MM-DD'));
    }
  }

  render() {
    const { placeHolder, style, disabled, value, format, disabledDate } = this.props;
    const formattedValue =
      value && value !== 'Invalid date' ? moment(value, 'YYYY-MM-DD').format(format) : undefined;
    return (
      <DatePicker
        placeholder={placeHolder}
        onChange={this.keepDateOnly}
        style={style}
        format={format}
        value={value && value !== 'Invalid date' ? moment(formattedValue, format) : undefined}
        disabled={disabled}
        disabledDate={disabledDate}
      />
    );
  }
}

export default DateOnlyPicker;
