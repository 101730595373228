export interface Bank {
  shortName: string;
  code: string;
}

/**
 * Get banks.
 * @returns {Bank[]} Banks - All available banks.
 */
export function getBankName(): Bank[] {
  const Banks: Bank[] = [
    { shortName: 'BBL', code: '002' },
    { shortName: 'KBANK', code: '004' },
    { shortName: 'RBS', code: '005' },
    { shortName: 'KTB', code: '006' },
    { shortName: 'JPMC', code: '008' },
    { shortName: 'UFJ', code: '010' },
    { shortName: 'TTB', code: '011' },
    { shortName: 'TMB', code: '011' },
    { shortName: 'SCB', code: '014' },
    { shortName: 'CITI', code: '017' },
    { shortName: 'SMBC', code: '018' },
    { shortName: 'SCBT', code: '020' },
    { shortName: 'CIMBT', code: '022' },
    { shortName: 'UOBT', code: '024' },
    { shortName: 'BAY', code: '025' },
    { shortName: 'MEGA ICBC', code: '026' },
    { shortName: 'AMERICA', code: '027' },
    { shortName: 'GSB', code: '030' },
    { shortName: 'HSBC', code: '031' },
    { shortName: 'DB', code: '032' },
    { shortName: 'GHB', code: '033' },
    { shortName: 'BAAC', code: '034' },
    { shortName: 'MHCB', code: '039' },
    { shortName: 'BNPP', code: '045' },
    { shortName: 'TBANK', code: '065' },
    { shortName: 'ISBT', code: '066' },
    { shortName: 'TISCO', code: '067' },
    { shortName: 'KK', code: '069' },
    { shortName: 'ICBC THAI', code: '070' },
    { shortName: 'TCRB', code: '071' },
    { shortName: 'LH BANK', code: '073' },
  ];
  return Banks;
}
