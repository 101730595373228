import React from 'react';
import { Form, Radio, Input, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import componentValues from '../../../../../../../../../services/utilities/ComponentValue';
import InputBaht from '../../../../../../../common/InputBaht';
import styles from '../../../../../../application.module.scss';
import { getValidator } from '../../../../../../../../../helpers/validator';

interface Prop {}
const SpouseInfo: React.FC<Prop> = () => {
  const intl = useIntl();
  return (
    <div id="spouseInfo" className={styles.header}>
      <strong className={styles.title}>
        <FormattedMessage id="spouseInfo" />
      </strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item name={['spouse', 'title']}>
            <Radio.Group>
              <Radio value="นาย">นาย</Radio>
              <Radio value="นาง">นาง</Radio>
              <Radio value="นางสาว">นางสาว</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="firstName" />} name={['spouse', 'firstName']}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id="lastName" />} name={['spouse', 'lastName']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="citizenId" />} name={['spouse', 'citizenId']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="company" />} name={['spouse', 'company']}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="occupation" />} name={['spouse', 'occupation']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="position" />} name={['spouse', 'position']}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item
            label={<FormattedMessage id="mobileNo" />}
            name={['spouse', 'mobileNo']}
            rules={[{ validator: getValidator(intl, 'telNo') }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
          <Form.Item label={<FormattedMessage id="salary" />} name={['spouse', 'salary']}>
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default SpouseInfo;
