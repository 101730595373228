import React, { useState, useEffect } from 'react';
import SummaryPayments from './SummaryPayments';
import PaymentsTable from './PaymentsTable';
import { store } from '../../../redux/store';

const ImportPaymentsConfirm: React.FC<any> = (props: any) => {
  const [payments, setPayments] = useState<any>([]);
  const [summary, setSummary] = useState<any>({});
  const [pagination, setPagination] = useState<any>([]);

  const payment = store.getState().import;

  useEffect(() => {
    setPayments(payment?.data?.payments);
    setSummary(payment?.data?.summary);
    setPagination(payment.meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryPayments payments={payments} summary={summary} />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <PaymentsTable dataSource={payments} pagination={pagination} />
      </div>
    </div>
  );
};

export default ImportPaymentsConfirm;
