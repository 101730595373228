import React, { FC } from 'react';
import { Button, Modal, Table } from 'antd';

interface Props {
  loan: any;
  visible: boolean;
  onCancel: any;
  addressTable: any;
  editAddress: any;
  dataAddress: any;
}

const ConfirmModal: FC<Props> = ({
  loan,
  visible,
  onCancel,
  addressTable,
  editAddress,
  dataAddress,
}) => {
  const columns = [
    {
      title: 'Loan Id',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '30%',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      width: '30%',
    },
    {
      title: 'Permanent',
      dataIndex: 'permanent',
      key: 'permanent',
      width: '30%',
    },
  ];

  const submitOne = () => {
    const { id } = loan;
    editAddress([id], dataAddress);
  };

  const submitAll = () => {
    const { loanIds } = addressTable;
    editAddress(loanIds, dataAddress);
  };

  return (
    <Modal
      title="Address"
      width={1000}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} danger ghost>
          ยกเลิก
        </Button>,
        <Button key="submit" onClick={submitOne} type="primary" ghost>
          สัญญาเดียว
        </Button>,
        <Button key="allsubmit" onClick={submitAll} type="primary">
          ทุกสัญญา
        </Button>,
      ]}
    >
      <Table dataSource={addressTable?.address} columns={columns} pagination={false} />
    </Modal>
  );
};

export default ConfirmModal;
