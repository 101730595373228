import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Input, AutoComplete, Select, Button } from 'antd';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import componentValues from '../../../../services/utilities/ComponentValue';
import { API_URL, getJson, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import InputBaht from '../../common/InputBaht';
import ConfirmNoteModal from './ConfirmNoteModal';

interface Props {
  loan: any;
  projectId: string;
  getDataSource: any;
  setDataNote?: any;
  setIsModalOpen?: any;
}

const { TextArea } = Input;
const { Option } = Select;

const AddNote: React.FC<Props> = ({ loan, projectId, getDataSource }) => {
  const { citizenId, firstName, lastName, mobileNo } = loan;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataNote, setDataNote] = useState<any>();
  const [addressTable, setAddressTable] = useState<any>([]);

  const [form] = Form.useForm();
  const intl = useIntl();

  const getLoansByCitizenId = async (projectId: string, citizenId: string) => {
    try {
      const getLoans = await getJson(
        `${API_URL}/projects/${projectId}/loans/${citizenId}/address-table`,
      );
      setAddressTable(getLoans.data);
    } catch (error) {
      showError(error);
    }
  };

  const onFinish = async (values: any) => {
    const note = {
      ...values,
      citizenId,
      firstName,
      lastName,
      mobileNo,
    };
    getLoansByCitizenId(projectId, citizenId);
    setDataNote(note);
    setIsModalOpen(true);
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const dataSource = [
    'ปัญหาการให้บริการเสนอขาย', // medium
    'ติดตามทวงถามหนี้', // medium
    'ไม่ได้รับใบแจ้งยอด', // high
    'ผลการพิจารณา เกิน 3 วันทำการ', // high
    'สอบถามยอดเงินโอนและวันที่โอนเงิน', // low
    'ขอเพิ่มวงเงิน/เบิกถอนวงเงิน', // low
    'สอบถามประเภทสินเชื่อ/เอกสารประกอบการสมัคร', // low
    'สอบถามช่องทางการชำระเงิน/วิธีการชำระเงิน', // low
    'สอบถามวันที่ชำระเงินงวดแรก/ยอดผ่อนชำระ', // low
    'สอบถามสถานะใบสมัคร/ช่องทางการส่งใบสมัคร', // low
    'แจ้งเปลี่ยนแปลงที่อยู่และข้อมูลส่วนตัว', // low
    'ร้องเรียนเรื่องทุจริต', // high
    'สอบถามยอดการชำระ/ใบแจ้งยอด',
    'เบิกถอนวงเงิน/เพิ่มวงเงิน',
    'ปิดบัญชีถาวร/ชั่วคราว',
    'เปลี่ยนเบอร์มือถือ/เบอร์ที่ทำงาน',
    'เปลี่ยนแปลงที่อยู่/บุคคลอ้างอิง',
    'เปลี่ยนชื่อ-นามสกุล',
    'เปลี่ยนบัญชีธนาคารที่ใช้โอนเงิน',
    'สอบถามการสมัครสินเชื่อ',
    'สอบถามช่องการชำระเงิน/วิธีการชำระเงิน',
    'สอบถามประเภทสินเชื่อ/ช่องทางการส่งใบสมัคร',
    'สอบถามผลการพิจารณา/สถานะใบสมัคร',
    'อื่นๆ', // unknow
  ];
  const channelDataSource = [
    'Line',
    'Facebook',
    'Mobile Phone',
    'SMS',
    'OC - โทรออก',
    'LC - รับสาย',
  ];
  // const priorityDataSource = ['low', 'medium', 'high'];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const editNote = async (values: any) => {
    try {
      await postJson(`${API_URL}/projects/${projectId}/notes/loanIds`, values);
      getDataSource();
      form.resetFields();
    } catch (error) {
      showError(error);
    }
    handleCancel();
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="category" label={<FormattedMessage id="note.category" />}>
              <AutoComplete dataSource={dataSource} placeholder="ปัญหา" />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="channel" label={<FormattedMessage id="note.channel" />}>
              <AutoComplete dataSource={channelDataSource} placeholder="ช่องทางการติดต่อ" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              name="message"
              label={<FormattedMessage id="note.message" />}
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Message" style={{ width: '800px' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            return (
              getFieldValue('category') === 'ติดตามทวงถามหนี้' && (
                <>
                  <Row gutter={componentValues.gutterValue}>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      <Form.Item
                        name="followupDate"
                        label={<FormattedMessage id="note.followupDate" />}
                      >
                        <DateOnlyPicker format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      <Form.Item
                        name="followupAmount"
                        label={<FormattedMessage id="note.followupAmount" />}
                      >
                        <InputBaht />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={componentValues.gutterValue}>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      <Form.Item name="repayDate" label={<FormattedMessage id="note.repayDate" />}>
                        <DateOnlyPicker format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      <Form.Item
                        name="repayAmount"
                        label={<FormattedMessage id="note.repayAmount" />}
                      >
                        <InputBaht />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={componentValues.gutterValue}>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      <Form.Item
                        name="collectionStatus"
                        label={<FormattedMessage id="note.collectionStatus" />}
                      >
                        <Select>
                          <Option value="paid">ชำระแล้ว</Option>
                          <Option value="promise to pay">นัดชำระ</Option>
                          <Option value="broken promise">ผิดนัดชำระ</Option>
                          <Option value="contact by phone">เจอตัวได้คุย (ทางโทรศัพท์)</Option>
                          <Option value="contact by line">เจอตัวได้คุย (ทางไลน์)</Option>
                          <Option value="no answer">โทรติดแต่ไม่ได้คุย</Option>
                          <Option value="line not read">อ่านไลน์แต่ไม่ตอบ</Option>
                          <Option value="no contact">ติดต่อไม่ได้</Option>
                          <Option value="no direct contact">
                            ติดต่อไม่ได้(แต่ติดต่อบุคคลอ้างอิง / ที่ทำงานได้)
                          </Option>
                          <Option value="pass away">กลุ่มลูกค้าเสียชีวิต</Option>
                          <Option value="TL14">คัด ทร14</Option>
                          <Option value="other">อื่นๆ</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            );
          }}
        </Form.Item>

        <Row>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Row>
      </Form>
      <ConfirmNoteModal
        loan={loan}
        visible={isModalOpen}
        addressTable={addressTable}
        onCancel={handleCancel}
        editNote={editNote}
        dataNote={dataNote}
      />
    </div>
  );
};

export default AddNote;
