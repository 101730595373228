import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tag, Popconfirm } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';
import { Application } from '../../../../../types/application-types';
import { UserState } from '../../../../../redux/user/user-duck';
import { satangToBath } from '../../../../../helpers/format/satangToBath';
import { API_URL, deleteJson, showError } from '../../../../../helpers/api/api';
import { iso8601ToLocalDate } from '../../../../../helpers/date/date';
import renderIfHasPermissisons from '../../../../../layouts/renderIfHasPermissisons';

interface Props {
  dataSource: Application[];
  loading: boolean;
  onChange: (pagination: TablePaginationConfig) => void;
  paginations?: TablePaginationConfig;
  reloadData: () => void;
}

export function getTag(status: string) {
  let color = '';
  switch (status) {
    case 'keying_data':
      color = 'purple';
      break;
    case 'verifying':
      color = 'blue';
      break;
    case 'wait_verify':
      color = 'cyan';
      break;
    case 'analyzing':
      color = 'orange';
      break;
    case 'wait_guarantor':
      color = 'gold';
      break;
    case 'approving':
      color = 'geekblue';
      break;
    case 'loan_opened':
      color = 'green';
      break;
    case 'reject':
      color = 'red';
      break;
    default:
      color = 'magenta';
      break;
  }
  return (
    <span>
      <Tag style={{ textAlign: 'center' }} color={color}>
        {status}
      </Tag>
    </span>
  );
}

export function getFullName(value: Application) {
  return (
    <span>
      {value.title} {value.firstName} {value.lastName}
    </span>
  );
}

const DefaultTable: FC<Props & PropsFromRedux> = ({
  projectId,
  dataSource,
  loading,
  onChange,
  paginations,
  reloadData,
}) => {
  const intl = useIntl();
  const DeleteButton: FC<any> = ({ applicationId }) => {
    return (
      <span>
        <Popconfirm
          title={<FormattedMessage id="common.are-you-sure" />}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          cancelText={<FormattedMessage id="no" />}
          okText={<FormattedMessage id="yes" />}
          okButtonProps={{ danger: true }}
          onConfirm={async () => {
            try {
              await deleteJson(`${API_URL}/projects/${projectId}/applications/${applicationId}`);
              reloadData();
            } catch (error) {
              showError(error);
            }
          }}
          onCancel={e => e && e.stopPropagation()}
        >
          <a href="#" onClick={e => e.stopPropagation()}>
            <i className="icmn-bin" />
          </a>
        </Popconfirm>
      </span>
    );
  };

  const columns: ColumnType<Application>[] = [
    {
      title: intl.formatMessage({ id: 'application.id' }),
      fixed: 'left',
      dataIndex: 'id',
      width: '96px',
      render: (id: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`applications/${id}`}>
            {id}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'full-name' }),
      dataIndex: 'name',
      width: '192px',
      // eslint-disable-next-line
      render: (name: string, row: Application) => {
        return getFullName(row);
      },
    },
    { title: intl.formatMessage({ id: 'citizenId' }), width: '136px', dataIndex: 'citizenId' },
    { title: intl.formatMessage({ id: 'product' }), dataIndex: 'productName' },
    {
      title: intl.formatMessage({ id: 'requestAmount' }),
      align: 'right',
      width: '120px',
      dataIndex: 'requestAmount',
      render: (amount: number) => {
        return satangToBath(amount);
      },
    },
    {
      title: intl.formatMessage({ id: 'createDate' }),
      width: '96px',
      dataIndex: 'createDate',
      render: (createDate: string) => {
        return iso8601ToLocalDate(createDate);
      },
    },
    {
      title: intl.formatMessage({ id: 'lastModifyDate' }),
      width: '96px',
      dataIndex: 'lastModifyDate',
      render: (lastModifyDate: string) => {
        return iso8601ToLocalDate(lastModifyDate);
      },
    },
    {
      title: intl.formatMessage({ id: 'lastModifyBy' }),
      width: '152px',
      dataIndex: 'lastModifyBy',
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      width: '120px',
      dataIndex: 'status',
      fixed: 'right',
      render: (status: string) => {
        return getTag(status);
      },
    },
    {
      width: '50px',
      fixed: 'right',
      render: (value: any) => {
        const DeleteIfHasPermission = renderIfHasPermissisons(
          'application.*.delete',
          <DeleteButton applicationId={value.id} />,
        );
        return <DeleteIfHasPermission />;
      },
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      loading={loading}
      columns={columns}
      onChange={onChange}
      pagination={paginations}
      scroll={{ x: 'max-content' }}
    />
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DefaultTable);
