import React from 'react';
import { Table, Tag, Descriptions } from 'antd';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { DataSource } from '../RequestApprovalsTabs';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../../helpers/date/date';

interface TableProp {
  dataSource: DataSource[];
  loading: boolean;
}

const columnsAll = [
  {
    title: <FormattedMessage id="withdraw.column.id" />,
    dataIndex: 'id',
    sorter: (a: any, b: any) => a.id.localeCompare(b.id),
  },
  {
    title: <FormattedMessage id="requestApproval.tab.appId" />,
    dataIndex: 'appId',
  },
  { title: <FormattedMessage id="import.payments.loanId" />, dataIndex: 'loanId' },
  {
    title: <FormattedMessage id="withdraw.column.name" />,
    render: (data: any) => {
      return `${data.firstName} ${data.lastName}`;
    },
  },
  { title: <FormattedMessage id="citizenId" />, dataIndex: 'citizenId' },
  { title: <FormattedMessage id="requestApproval.tab.type" />, dataIndex: 'type' },
  {
    title: <FormattedMessage id="withdraw.column.amount" />,
    render: (record: any) => {
      return satangToBath(record.context.writeoffAmount || 0);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.effectiveDate" />,
    render: (record: any) => {
      return iso8601ToLocalDate(record.context.effectiveDate);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.status" />,
    render: (record: any) => (
      <Tag style={{ textAlign: 'center' }} color="success">
        {record.status}
      </Tag>
    ),
  },
];

const restructureType: any = {
  debtRestructuring: 'Debt Restructuring',
  sam: 'SAM',
  settlementAgreement: 'Settlement Agreement',
  defaultJudgement: 'Default Judgement',
};

function checkEdit(context: any) {
  const {
    creditLimit,
    intRate,
    safeMargin,
    minPayRate,
    minPayAmount,
    lateFee,
    stampFee,
    bureauFee,
    bureauMissFee,
    preterminateFee,
    preterminatePeriod,
    transferFee,
    interBankTransferFee,
    writeoffAmount,
    writeoffDate,
    cfPrincipal,
    cfInterest,
    cfFee,
    filingDate,
    blackCaseNo,
    restructureDate,
    paymentType,
    redCaseNo,
    installTerm,
    type,
    gracePeriod,
    useGracePeriod,
  } = context;
  const mapLoan = [
    {
      name: 'Credit Limit',
      value: creditLimit || creditLimit === 0 ? satangToBath(creditLimit) : undefined,
    },
    {
      name: 'Interest Rate',
      value: intRate || intRate === 0 ? `${Number(intRate * 100).toFixed(0)} %` : undefined,
    },
    {
      name: 'Safe Margin',
      value: safeMargin || safeMargin === 0 ? satangToBath(safeMargin) : undefined,
    },
    {
      name: 'Minimum Payment Rate',
      value: minPayRate || minPayRate === 0 ? `${minPayRate * 100} %` : undefined,
    },
    {
      name: 'Minimum Payment Amount',
      value: minPayAmount || minPayAmount === 0 ? satangToBath(minPayAmount) : undefined,
    },
    { name: 'Late Fee', value: lateFee || lateFee === 0 ? satangToBath(lateFee) : undefined },
    {
      name: 'Stamp Fee',
      value: stampFee || stampFee === 0 ? `${stampFee * 100} %` : undefined,
    },
    {
      name: 'Bureau Hit Fee',
      value: bureauFee || bureauFee === 0 ? satangToBath(bureauFee) : undefined,
    },
    {
      name: 'Bureau Miss Fee',
      value: bureauMissFee || bureauMissFee === 0 ? satangToBath(bureauMissFee) : undefined,
    },
    {
      name: 'Preterminate Fee',
      value: preterminateFee || preterminateFee === 0 ? satangToBath(preterminateFee) : undefined,
    },
    {
      name: 'Preterminate Period',
      value: preterminatePeriod || preterminatePeriod === 0 ? preterminatePeriod : undefined,
    },
    {
      name: 'Transfer Fee',
      value: transferFee || transferFee === 0 ? satangToBath(transferFee) : undefined,
    },
    {
      name: 'Inter bank transfer Fee',
      value:
        interBankTransferFee || interBankTransferFee === 0
          ? satangToBath(interBankTransferFee)
          : undefined,
    },
    {
      name: 'Writeoff Amount',
      value: writeoffAmount || writeoffAmount === 0 ? satangToBath(writeoffAmount) : undefined,
    },
    { name: 'Writeoff Date', value: writeoffDate },
    {
      name: 'cfPrincipal',
      value: cfPrincipal || cfPrincipal === 0 ? satangToBath(cfPrincipal) : undefined,
    },
    {
      name: 'cfInterest',
      value: cfInterest || cfInterest === 0 ? satangToBath(cfInterest) : undefined,
    },
    { name: 'cfFee', value: cfFee || cfFee === 0 ? satangToBath(cfFee) : undefined },
    {
      name: 'filingDate',
      value: filingDate ? iso8601ToLocalDate(filingDate) : undefined,
    },
    {
      name: 'blackCaseNo',
      value: blackCaseNo ? `${blackCaseNo}` : undefined,
    },
    {
      name: 'restructureDate',
      value: restructureDate ? iso8601ToLocalDate(restructureDate) : undefined,
    },
    {
      name: 'paymentType',
      value: paymentType ? `${paymentType}` : undefined,
    },
    {
      name: 'redCaseNo',
      value: redCaseNo ? `${redCaseNo}` : undefined,
    },
    {
      name: 'installTerm',
      value: installTerm ? `${installTerm}` : undefined,
    },
    {
      name: 'type',
      value: type ? `${restructureType[type]}` : undefined,
    },
    {
      name: 'gracePeriod',
      value: gracePeriod ? `${gracePeriod}` : undefined,
    },
    {
      name: 'useGracePeriod',
      value: useGracePeriod === true || useGracePeriod === false ? `${useGracePeriod}` : undefined,
    },
  ];
  const mapValue: any = [];
  mapLoan.forEach((loan: any) => {
    if (loan.value)
      mapValue.push(<Descriptions.Item label={loan.name}>{loan.value}</Descriptions.Item>);
  });
  return (
    <Descriptions
      title="Loan"
      column={{ lg: 4, md: 4, sm: 4, xs: 3 }}
      size="small"
      // bordered
    >
      {mapValue}
    </Descriptions>
  );
}

const TableApproved: React.FC<TableProp> = ({ dataSource, loading }) => {
  return (
    <div>
      <Table
        rowKey="id"
        expandedRowRender={(record: any) =>
          record.type === 'editloan' ||
          record.type === 'writeoff' ||
          record.type === 'filing' ||
          record.type === 'restructure' ? (
            <div>{checkEdit(record.context)}</div>
          ) : (
            undefined
          )
        }
        dataSource={dataSource}
        loading={loading}
        columns={columnsAll}
      />
    </div>
  );
};

export default connect(null, { push })(TableApproved);
