import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import { getJson, API_URL } from '../../../helpers/api/api';
import SaleForm from '../../../components/CbsComponents/SaleForm/SaleForm';
import { store } from '../../../redux/store';

const NewSale: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [teamLeads, setTeamLeads] = useState<string[]>();
  const breadcrumbs = [
    {
      title: 'menu.sales',
      href: '/sales',
    },
    {
      title: 'add',
      href: `/sales/new`,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const projectId = store.getState().user.currentProjectId;
      const list = await getJson(`${API_URL}/projects/${projectId}/sales`);
      setTeamLeads(list.data);
    };
    fetchData();
  }, []);

  if (!teamLeads) return null;

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.addSale' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.sales" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SaleForm teamLeads={teamLeads} />
      </div>
    </Layout.Content>
  );
};

export default injectIntl(NewSale);
