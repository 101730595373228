import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Radio, Input, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import DateOnlyPicker from '../../../common/DateOnlyPicker';
import styles from '../../../Application/application.module.scss';
import componentValues from '../../../../../services/utilities/ComponentValue';
import { getValidator } from '../../../../../helpers/validator';

interface Props {
  form: FormInstance;
}

const PersonalInfo: React.FC<Props> = ({ form }) => {
  const { setFieldsValue } = form;
  const intl = useIntl();
  return (
    <div id="personalInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="personalInfo" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="customId" />} name="customId">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="title" />}
            name="title"
            rules={[{ required: true }]}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={e => {
                const { value } = e.target;
                switch (value) {
                  case 'นาย':
                    setFieldsValue({ titleEn: 'Mr' });
                    break;
                  case 'นาง':
                    setFieldsValue({ titleEn: 'Mrs' });
                    break;
                  case 'นางสาว':
                    setFieldsValue({ titleEn: 'Miss' });
                    break;
                }
              }}
            >
              <Radio.Button value="นาย">นาย</Radio.Button>
              <Radio.Button value="นาง">นาง</Radio.Button>
              <Radio.Button value="นางสาว">นางสาว</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="titleEn" />}
            name="titleEn"
            rules={[{ required: true }]}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={e => {
                const { value } = e.target;
                switch (value) {
                  case 'Mr':
                    setFieldsValue({ title: 'นาย' });
                    break;
                  case 'Mrs':
                    setFieldsValue({ title: 'นาง' });
                    break;
                  case 'Miss':
                    setFieldsValue({ title: 'นางสาว' });
                    break;
                }
              }}
            >
              <Radio.Button value="Mr">Mr</Radio.Button>
              <Radio.Button value="Mrs">Mrs</Radio.Button>
              <Radio.Button value="Miss">Miss</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstName" />}
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastNameTh" />}
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstNameEn" />}
            name="firstNameEn"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastNameEn" />}
            name="lastNameEn"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="citizenId" />}
            name="citizenId"
            rules={[{ required: true }, { validator: getValidator(intl, 'citizenId') }]}
          >
            <Input prefix={<i className="icmn-credit-card" />} />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="birthDate" />}
            name="birthDate"
            rules={[{ required: true }]}
          >
            <DateOnlyPicker format="DD-MM-YYYY" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PersonalInfo;
