import React, { Fragment } from 'react';
import { Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import PersonalInfo from './Panel/PersonalInfo';
import LoanInfo from './Panel/LoanInfo';
import AccountInfo from './Panel/AccountInfo';
import SpouseInfo from './Panel/SpouseInfo';

interface Props {
  form: FormInstance;
  application: any;
}

const General: React.FC<Props> = ({ form, application }) => {
  const { getFieldValue } = form;
  const isMarried = () => {
    const maritalStatus = getFieldValue('maritalStatus') || application.maritalStatus;
    const isMarried = maritalStatus === 'married' || maritalStatus === 'notregister';
    return isMarried;
  };

  return (
    <div>
      <PersonalInfo form={form} />
      <Divider dashed />
      {isMarried() && (
        <Fragment>
          <SpouseInfo />
          <Divider dashed />
        </Fragment>
      )}
      <LoanInfo application={application} />
      <Divider dashed />
      <AccountInfo application={application} />
    </div>
  );
};

export default General;
