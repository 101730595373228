import React from 'react';
import { useParams } from 'react-router-dom';
import SmsNotifyApprovalForm from '../../components/CbsComponents/Sms/SmsNotifyApprovalForm';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';

const SmsNotifyApproval: React.FC = () => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: 'notify-approval.page-title',
      href: '/sms/notify/approval',
    },
  ];

  return (
    <ContentContainer
      helmetTitle="notify-approval.page-title"
      helmetTitleParams={{ id }}
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsNotifyApprovalForm />
    </ContentContainer>
  );
};

export default SmsNotifyApproval;
