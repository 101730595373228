import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Button, Row, Col } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { onFinishFailedHandler } from '../../../helpers/form';
import { ErrorInfo } from '../../../types/form-types';
import { getValidator } from '../../../helpers/validator';
import styles from './onboarding.module.scss';

interface Props {
  next: () => void;
  prev: () => void;
  values: any;
  updateValues: (values: Store) => void;
  requestOtp: (values: Store) => Promise<void>;
}

const AdminInfo: FC<Props> = ({ next, prev, values, updateValues, requestOtp }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { getFieldsValue } = form;
  const onFinish = async (values: any) => {
    setLoading(true);
    await requestOtp(values);
    setLoading(false);
    next();
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'onboarding.failed-message', errorInfo);
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={values}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <Row gutter={50}>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="firstName" />}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="lastName" />}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="mobileNo" />}
              name="mobileNo"
              rules={[{ required: true }, { validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" placeholder="08XXXXXXXX" />
            </Form.Item>
            <Form.Item />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={3} lg={3} style={{ marginRight: 10 }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
                loading={loading}
                disabled={loading}
              >
                <FormattedMessage id="next" />
              </Button>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={3} lg={3}>
            <Form.Item>
              <Button
                className={styles.button}
                loading={loading}
                disabled={loading}
                onClick={() => {
                  const values = getFieldsValue();
                  updateValues(values);
                  prev();
                }}
              >
                <FormattedMessage id="previous" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdminInfo;
