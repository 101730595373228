import React, { useState } from 'react';
import { Table, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../../helpers/format/satangToBath';

const { Search } = Input;

const DisbursementsTable: React.FC<any> = (props: any) => {
  const { dataSource, loading, transferredDate } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (value: any) => {
    setSearchQuery(value);
  };

  const filteredData = dataSource.filter((item: any) => {
    if (searchQuery.length === 0) return true;
    return (
      item.firstName.toString().includes(searchQuery) ||
      item.lastName.toString().includes(searchQuery)
    );
  });

  const statusSet = new Set<string>();
  dataSource.forEach((item: any) => statusSet.add(item.status));
  const uniqueStatuses = Array.from(statusSet);

  const column = [
    {
      title: <FormattedMessage id="disbursements.name" />,
      render: (resolve: any) => {
        const { firstNameEn, lastNameEn } = resolve;
        return `${firstNameEn} ${lastNameEn}`;
      },
      width: '20%',
    },
    {
      title: <FormattedMessage id="disbursements.accountNo" />,
      dataIndex: 'accountNo',
      width: '15%',
    },
    {
      title: <FormattedMessage id="disbursements.amount" />,
      dataIndex: 'amount',
      render: (resolve: any) => {
        return satangToBath(resolve);
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="disbursements.transferredDate" />,
      render: (resolve: any) => {
        const mapTransferredDate = resolve.transferredDate || transferredDate;
        return `${mapTransferredDate}`;
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="disbursements.status" />,
      dataIndex: 'status',
      width: '15%',
      filters: uniqueStatuses.map(item => {
        return { text: item, value: item };
      }),
      onFilter: (value: any, record: any) => record.status.includes(value),
    },
    {
      title: <FormattedMessage id="disbursements.rejectDesc" />,
      dataIndex: 'rejectDesc',
      width: '20%',
    },
  ];

  return (
    <div>
      <Search
        placeholder="Search name"
        onSearch={handleSearch}
        style={{ marginBottom: 20, width: 400 }}
      />
      <Table loading={loading} columns={column} dataSource={filteredData} />
    </div>
  );
};

export default DisbursementsTable;
