import React from 'react';
import { Table, Popconfirm, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { API_URL, deleteJson, showError } from '../../../../../../helpers/api/api';

const TableNote: React.FC<any> = (props: any) => {
  const { dataSource, loading, projectId, getDataSource } = props;

  async function deleteNote(id: string) {
    const status = 'delete';
    try {
      await deleteJson(`${API_URL}/projects/${projectId}/notes`, {
        id,
        status,
      });
      getDataSource();
    } catch (err) {
      message.warning(`${err}`);
    }
  }

  const columns: any = [
    {
      title: <FormattedMessage id="note.name" />,
      key: 'name',
      render: (record: any) => {
        const { firstName, lastName } = record;
        return `${firstName} ${lastName}`;
      },
    },
    {
      title: <FormattedMessage id="note.mobileNo" />,
      key: 'mobile',
      render: (record: any) => {
        return record.mobileNo;
      },
    },
    {
      title: <FormattedMessage id="note.category" />,
      key: 'category',
      render: (record: any) => {
        return record.category;
      },
    },
    {
      title: <FormattedMessage id="note.channel" />,
      key: 'channel',
      render: (record: any) => {
        return record.channel;
      },
    },
    {
      title: <FormattedMessage id="note.priority" />,
      key: 'priority',
      render: (record: any) => {
        return record.priority;
      },
    },
    {
      title: <FormattedMessage id="note.message" />,
      key: 'message',
      render: (record: any) => {
        return record.message;
      },
    },
    {
      fixed: 'right',
      render: (record: any, row: any, index: any) => {
        return (
          <span>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteNote(record.id)}>
              <i className="icmn-bin" />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={columns} />
    </div>
  );
};

export default TableNote;
