import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { postJson, API_URL, showError } from '../../../helpers/api/api';
import styles from './forgot.module.scss';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';

const ForgotForm: FC<PropsFromRedux> = ({ push }) => {
  const intl = useIntl();
  const onFinish = async (value: Store) => {
    try {
      await postJson(`${API_URL}/users/forgot-password`, value);
      notification.success({
        message: intl.formatMessage({ id: 'forgot.success-message' }),
      });
      push('/');
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  return (
    <div className={styles.container}>
      <Helmet title={intl.formatMessage({ id: 'forgot.helmet-title' })} />
      <Row>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Row style={{ justifyContent: 'center' }}>
            <img
              style={{ marginTop: '350px' }}
              src="resources/images/logo-inverse.svg"
              alt="noburo logo"
            />
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className={styles.form}>
            <h4 className="text-uppercase">
              <strong>
                <FormattedMessage id="forgot.page-title" />
              </strong>
            </h4>
            <br />
            <FormattedMessage id="forgot.instruction" />

            <br />
            <br />
            <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                label={<FormattedMessage id="email" />}
                name="email"
                rules={[{ required: true }, { type: 'email' }]}
              >
                <Input />
              </Form.Item>
              <div className="mb-2">
                <Link to="/users/login" className="utils__link--blue utils__link--underlined">
                  <FormattedMessage id="forgot.back-to-login" />
                </Link>
              </div>
              <div className="form-actions">
                <Button type="primary" htmlType="submit">
                  <FormattedMessage id="resetPassword" />
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const connector = connect(null, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ForgotForm);
// interface Props extends FormComponentProps {
//   push: Push;
// }

// const ForgotForm: React.FC<Props> = ({ form, push }) => {
//   const { validateFields, resetFields, getFieldDecorator } = form;

//   function handleSubmit(e: any) {
//     e.preventDefault();
//     validateFields(async (err, values) => {
//       if (err) {
//         return;
//       }
//       const forgot = {
//         email: values.email,
//       };
//       resetFields();
//       postJson(`${API_URL}/users/forgot-password`, forgot);
//       notification.success({
//         message: 'Send reset password request',
//         description:
//           'You have send reset password request! Please check your mailbox for instructions.',
//       });
//     });
//   }
//   return (
//     <div className={styles.container}>
//       <Helmet title="Forgot" />
//       <Row>
//         <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }} />
//         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
//           <div className={styles.form}>
//             <h4 className="text-uppercase">
//               <strong>Forgot Password?</strong>
//             </h4>
//             <br />
//             Forgotten your password? Please enter your email address below. You will receive a link
//             to set a new password via email.
//             <br />
//             <br />
//             <Form layout="vertical" hideRequiredMark>
//               <Form.Item label="Email">
//                 {getFieldDecorator('email', {
//                   rules: [
//                     { type: 'email', message: 'The input is not valid E-mail!' },
//                     { required: true, message: 'Please input your e-mail address' },
//                   ],
//                 })(<Input />)}
//               </Form.Item>
//               <div className="mb-2">
//                 <Link to="/users/login" className="utils__link--blue utils__link--underlined">
//                   Back to login
//                 </Link>
//               </div>
//               <div className="form-actions">
//                 <Link to="/">
//                   <Button
//                     type="primary"
//                     className="width-150 mr-4"
//                     htmlType="submit"
//                     loading={false}
//                     onClick={handleSubmit}
//                   >
//                     Send
//                   </Button>
//                 </Link>
//               </div>
//             </Form>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// const ForgotPassForm = Form.create<Props>()(ForgotForm);
// export default connect(null, { push })(ForgotPassForm);
