import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import { getJson, API_URL } from '../../../../../../helpers/api/api';
import AddNote from './AddNote';
import TableNote from './TableNote';

interface Props {
  application: any;
}

const Note: React.FC<Props> = ({ application }) => {
  const { id, projectId } = application;
  const [notes, setNotes] = useState<any>([]);

  async function getDataSource() {
    const url = `${API_URL}/projects/${projectId}/notes?appId=${id}`;
    const { data } = await getJson(url);
    setNotes(data);
  }

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <div>
      <AddNote application={application} getDataSource={getDataSource} />
      <Divider dashed />
      <TableNote dataSource={notes} projectId={projectId} getDataSource={getDataSource} />
    </div>
  );
};

export default Note;
