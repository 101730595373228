import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Row, Col, Button } from 'antd';
import ContactPerson from '../../Application/ApplicationForm/DataTabs/KeyData/KeyDataTabs/ContactPerson';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface Props {
  loan: any;
}

const Contact: React.FC<Props> = ({ loan }) => {
  const intl = useIntl();
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SaveButton = renderIfHasPermissisons(
    'loans.*.update',
    <Button type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );
  return (
    <div className="card-body">
      <Form
        name="contactPerson"
        initialValues={loan}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <ContactPerson />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
