import React from 'react';
import { Table } from 'antd';
import { iso8601ToLocalDate } from '../../../helpers/date/date';

const SmsLoanCloseTable: React.FC<any> = (props: any) => {
  const { dataSource, loading, rowSelection } = props;
  const column = [
    {
      title: 'Id',
      dataIndex: 'id',
    },

    {
      title: 'Date',
      dataIndex: 'closeDate',
      render: (date: string) => {
        return iso8601ToLocalDate(date);
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
  ];
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        loading={loading}
        columns={column}
        pagination={{ pageSize: 5000000 }}
      />
    </div>
  );
};

export default SmsLoanCloseTable;
