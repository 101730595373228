import React, { useState } from 'react';
import { Steps, notification } from 'antd';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { showError, postJson, API_URL } from '../../../helpers/api/api';
import UserContent from '../../../components/CbsComponents/UserForm/UserContent';
import ConfirmContent from '../../../components/CbsComponents/UserForm/ConfirmContent';
import styles from '../../../components/CbsComponents/UserForm/user-form.module.scss';
import { push, Push } from 'connected-react-router';

const { Step } = Steps;

export type UserContentType = {
  firstName: string;
  lastName: string;
  password: string;
  mobileNo: string;
};

interface NewUserProps {
  intl: any;
  token: string;
  push: Push;
}

const NewUser: React.FC<NewUserProps> = props => {
  const { intl, token, push } = props;
  const step: string = intl.formatMessage({ id: 'common.step' });
  const [current, setCurrent] = useState<number>(0);
  const [otpData, setOtpData] = useState();
  const [userContentValues, setUserContentValues] = useState<UserContentType>({
    firstName: '',
    lastName: '',
    password: '',
    mobileNo: '',
  });

  const next: () => void = () => {
    const currentTemp = current + 1;
    setCurrent(currentTemp);
  };

  const prev: () => void = () => {
    const currentTemp = current - 1;
    setCurrent(currentTemp);
  };

  const done: () => void = () => {
    notification.success({
      message: intl.formatMessage({ id: 'onboarding.success-message' }),
    });
    push('/');
  };

  async function requestOtp(): Promise<boolean> {
    try {
      const otpData = await postJson(`${API_URL}/users/onboarding/otps`, {
        ...userContentValues,
      });
      setOtpData(otpData);
    } catch (error) {
      showError(error);
      return false;
    }
    return true;
  }

  async function verifyOtp(otp: string, ref: string) {
    await postJson(`${API_URL}/users/accept-invite/otps/verify`, { otp, ref });
    return true;
  }

  const steps = [
    {
      title: `${step} 1`,
      description: <FormattedMessage id="accept-invite.user-info" />,
      content: (
        <UserContent
          next={next}
          userContentValues={userContentValues}
          setUserContentValues={setUserContentValues}
          setOtpData={setOtpData}
        />
      ),
    },
    {
      title: `${step} 2`,
      description: <FormattedMessage id="accept-invite.confirm" />,
      content: (
        <ConfirmContent
          token={token}
          prev={prev}
          userContentValues={userContentValues}
          done={done}
          otpData={otpData}
          verifyOtp={verifyOtp}
          requestOtp={requestOtp}
        />
      ),
    },
  ];

  return (
    <section className="card">
      <div className="card-body">
        <Steps current={current} className={styles.steps}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} description={item.description} />
          ))}
        </Steps>
        <div style={{ marginTop: '16px' }} className="steps-content">
          {steps[current].content}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ intl }: { intl: any }) => ({ intl });

export default connect(mapStateToProps, { push })(injectIntl(NewUser));
