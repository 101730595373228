import { IntlShape } from 'react-intl';
import { notification } from 'antd';
import { ErrorInfo } from '../types/form-types';

// eslint-disable-next-line import/prefer-default-export
export function onFinishFailedHandler(intl: IntlShape, messageId: string, errorInfo: ErrorInfo) {
  const description = `${intl.formatMessage({
    id: 'common.please-check',
  })} ${errorInfo.errorFields.map(error => error.name.join('.')).join(', ')}`;
  notification.error({ message: intl.formatMessage({ id: messageId }), description });
}
