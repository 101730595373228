import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import styles from './style.module.scss';

@withRouter
class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <Layout>
        <Layout.Content>
          <div className={styles.content}>{children}</div>
        </Layout.Content>
      </Layout>
    );
  }
}
LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
