import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Button, Row, Col, Spin, Input } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useIntl, FormattedMessage } from 'react-intl';
import { UserContentType } from '../../../pages/user/user-new/NewUser';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import { acceptInvite } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';

interface Props {
  token: string;
  prev: () => void;
  done: () => void;
  userContentValues: UserContentType;
  otpData: any;
  verifyOtp: any;
  requestOtp: () => Promise<boolean>;
}

const ContentForm: FC<Props & PropsFromRedux> = ({
  token,
  prev,
  done,
  userContentValues,
  otpData,
  verifyOtp,
  requestOtp,
  acceptInvite,
}) => {
  const intl = useIntl();
  const onFinish = async (value: Store) => {
    try {
      const isCorrect = await verifyOtp(value.otp, otpData.data.ref);
      if (isCorrect) {
        const data = await postJson(
          `${API_URL}/users/accept-invite?token=${token}`,
          userContentValues,
        );
        const { accessToken } = data.data;
        acceptInvite({ accessToken });
        done();
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const body = (
    <div>
      <Row gutter={50}>
        <Col span={12}>
          <strong>{<FormattedMessage id="firstName" />}: </strong>
          {userContentValues.firstName}
          <br />
          <strong>{<FormattedMessage id="lastName" />}: </strong>
          {userContentValues.lastName}
        </Col>
        <Col span={12}>
          <strong>{<FormattedMessage id="mobileNo" />}: </strong>
          {userContentValues.mobileNo}
        </Col>
      </Row>
      <br />
      <div style={{ marginRight: 10 }}>
        <strong>Ref No: </strong>
        {otpData.data.ref}
      </div>
      <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <Col>
            <Form.Item label="Otp No:" name="otp" rules={[{ required: true }]} noStyle>
              <Input type="number" />
            </Form.Item>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => requestOtp()}>
              <FormattedMessage id="sendAgain" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button style={{ marginRight: '8px' }} type="primary" htmlType="submit">
              <FormattedMessage id="confirm" />
            </Button>
            <Button onClick={() => prev()}>
              <FormattedMessage id="previous" />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  return <div>{otpData.loading ? <Spin tip="Loading...">{body}</Spin> : <div>{body}</div>}</div>;
};

const connector = connect(null, { acceptInvite });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContentForm);

// type formEvent = React.FormEvent<EventTarget>;

// interface ContentProps {
//   token: string;
//   form: any;
//   prev: () => void;
//   done: () => void;
//   userContentValues: UserContentType;
//   otpData: any;
//   verifyOtp: any;
//   requestOtp: () => Promise<boolean>;
//   acceptInvite?: any;
// }

// const ContentForm: React.FC<ContentProps> = props => {
//   const {
//     token,
//     form,
//     prev,
//     done,
//     userContentValues,
//     otpData,
//     verifyOtp,
//     requestOtp,
//     acceptInvite,
//   } = props;

//   const handleSubmit = (e: formEvent) => {
//     e.preventDefault();
//     form.validateFields(async (err: any, values: any) => {
//       if (!err) {
//         const isCorrect = await verifyOtp(values.otp, otpData.data.ref);
//         if (isCorrect) {
//           const postBody = {
//             firstName: userContentValues.firstName,
//             lastName: userContentValues.lastName,
//             password: userContentValues.password,
//             mobileNo: userContentValues.mobileNo,
//           };
//           const data = await postJson(`${API_URL}/users/accept-invite?token=${token}`, postBody);
//           const { accessToken } = data.data;
//           acceptInvite({ accessToken });
//           done();
//         }
//       }
//     });
//   };

//   function checkOtp(rule: any, value: string, callback: any) {
//     if (value.length !== 6) {
//       callback('Invalid otp length');
//     } else {
//       callback();
//     }
//   }

//   const body = (
//     <div>
//       <br />
//       <h5>
//         <strong>{<FormattedMessage id="inviteUser.userInformation" />}:</strong>
//       </h5>
//       <Row gutter={50}>
//         <Col span={12}>
//           <strong>{<FormattedMessage id="common.name" />}: </strong>
//           {userContentValues.firstName}
//           <br />
//           <strong>{<FormattedMessage id="common.lastName" />}: </strong>
//           {userContentValues.lastName}
//         </Col>
//         <Col span={12}>
//           <strong>{<FormattedMessage id="common.mobileNo" />}: </strong>
//           {userContentValues.mobileNo}
//         </Col>
//       </Row>
//       <br />
//       <div style={{ marginRight: 10 }}>
//         <strong>Ref No: </strong>
//         {otpData.data.ref}
//       </div>
//       <Form onSubmit={handleSubmit} className="thirdContent-form">
//         <Row>
//           <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
//             <Row>
//               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
//                 <Form.Item
//                   label="OTP No:"
//                   labelCol={{
//                     span: 5,
//                   }}
//                   wrapperCol={{
//                     span: 16,
//                   }}
//                 >
//                   {form.getFieldDecorator('otp', {
//                     rules: [
//                       { required: true, message: 'Please input your otp' },
//                       {
//                         validator: checkOtp,
//                       },
//                     ],
//                   })(<Input type="number" />)}
//                 </Form.Item>
//               </Col>

//               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 5 }}>
//                 <Form.Item
//                   wrapperCol={{
//                     span: 24,
//                   }}
//                 >
//                   <Button onClick={() => requestOtp()} className={styles.button}>
//                     <FormattedMessage id="onboarding.sendAgain" />
//                   </Button>
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//         <br />

//         <Row>
//           <Col
//             xs={{ span: 24 }}
//             sm={{ span: 24 }}
//             md={{ span: 3 }}
//             lg={{ span: 3 }}
//             style={{ marginRight: 10 }}
//           >
//             <Form.Item>
//               <Button type="primary" htmlType="submit" className={styles.button}>
//                 <FormattedMessage id="common.done" />
//               </Button>
//             </Form.Item>
//           </Col>
//           <Col
//             xs={{ span: 24 }}
//             sm={{ span: 24 }}
//             md={{ span: 3 }}
//             lg={{ span: 3 }}
//             style={{ marginRight: 10 }}
//           >
//             <Form.Item>
//               <Button className={styles.button} onClick={() => prev()}>
//                 <FormattedMessage id="common.previous" />
//               </Button>
//             </Form.Item>
//           </Col>
//         </Row>
//       </Form>
//     </div>
//   );

//   return <div>{otpData.loading ? <Spin tip="Loading...">{body}</Spin> : <div>{body}</div>}</div>;
// };

// const ConfirmContent = Form.create<ContentProps>({ name: 'confirmContent' })(ContentForm);

// export default connect(null, { acceptInvite })(ConfirmContent);
