import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, notification } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { postJson, patchJson, API_URL } from '../../../helpers/api/api';
import componentValues from '../../../services/utilities/ComponentValue';
import styles from './role.module.scss';
import { store } from '../../../redux/store';

export interface Role {
  name: string;
  id: string;
  description: string;
  scopes: string[];
}

interface Props extends FormComponentProps {
  roleData?: Role;
  scopesData: string[];
  push: Push;
}

const { TextArea } = Input;
const { Option } = Select;

const RoleForm: React.FC<Props> = ({ roleData, scopesData, form, push }) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = form;
  const scopes = [];
  const urlPath = window.location.href;

  useEffect(() => {
    const data = roleData;
    if (data !== undefined) {
      setFieldsValue({
        name: data.name,
        id: data.id,
        description: data.description,
        scopes: data.scopes,
      });
    }
  }, [roleData, setFieldsValue]);

  for (let i: number = 0; i < scopesData.length; i += 1) {
    scopes.push(
      <Option key={scopesData[i]} value={scopesData[i]}>
        {scopesData[i]}
      </Option>,
    );
  }

  function handleSubmit(): void {
    validateFields(async (err, values) => {
      if (err) {
        notification.error({
          message: 'Failed to add role',
          description: JSON.stringify(err),
        });
        return;
      }
      const roles: Role = {
        name: values.name,
        id: values.id,
        description: values.description,
        scopes: values.scopes,
      };
      try {
        const projectId = store.getState().user.currentProjectId;
        await postJson(`${API_URL}/projects/${projectId}/roles`, roles);
        resetFields();
        notification.success({
          message: 'Added Role',
          description: 'You have successfully added role!',
        });
        push('/roles');
      } catch (error) {
        notification.error({
          message: 'Failed to add role',
          description: error.message,
        });
      }
    });
  }

  function handleSave(): void {
    validateFields(async (err, values) => {
      if (err) {
        notification.error({
          message: 'Failed to edit role',
          description: JSON.stringify(err),
        });
        return;
      }
      const roles: Role = {
        name: values.name,
        id: values.id,
        description: values.description,
        scopes: values.scopes,
      };
      try {
        const projectId = store.getState().user.currentProjectId;
        await patchJson(`${API_URL}/projects/${projectId}/roles/${roles.id}`, roles);
        resetFields();
        notification.success({
          message: 'Edited Role',
          description: 'You have successfully edited role!',
        });
        push('/roles');
      } catch (error) {
        notification.error({
          message: 'Failed to edit role',
          description: error.message,
        });
      }
    });
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="utils__title">
          <strong>
            <FormattedMessage id="form.header.roles" />
          </strong>
        </div>
      </div>
      <div className="card-body">
        <Form layout="vertical">
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="roles.name" />}>
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: <FormattedMessage id="roles.name.inputMessage" /> },
                  ],
                })(<Input maxLength={255} />)}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="common.id" />}>
                {getFieldDecorator('id', {
                  initialValue: ' ',
                })(<Input disabled />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col>
              <Form.Item label={<FormattedMessage id="common.description" />}>
                {getFieldDecorator(
                  'description',
                  {},
                )(<TextArea autoSize style={{ resize: 'none' }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="roles.scopes" />}>
                {getFieldDecorator('scopes', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="roles.scopes.inputMessage" />,
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    placeholder={<FormattedMessage id="roles.scopes.placeholder" />}
                  >
                    {scopes}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item>
                <Row gutter={componentValues.gutterButtonValue}>
                  <Col
                    xs={{ span: componentValues.halfColSpan }}
                    sm={{ span: componentValues.halfColSpan }}
                    md={{ span: componentValues.mdButtonSpan }}
                  >
                    {urlPath.endsWith('new') || urlPath.endsWith('new/') ? (
                      <Button
                        className={styles.buttonStyle}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                      >
                        <FormattedMessage id="form.add" />
                      </Button>
                    ) : (
                      <Button
                        className={styles.buttonStyle}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSave}
                      >
                        <FormattedMessage id="form.save" />
                      </Button>
                    )}
                  </Col>
                  <Col
                    xs={{ span: componentValues.halfColSpan }}
                    sm={{ span: componentValues.halfColSpan }}
                    md={{ span: componentValues.mdButtonSpan }}
                  >
                    <Button className={styles.buttonStyle} htmlType="button">
                      <Link to="/roles">
                        <FormattedMessage id="form.cancel" />
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const AntdForm = Form.create<Props>()(RoleForm);

export default connect(null, { push })(AntdForm);
