import React, { FC, Fragment, useState } from 'react';
import { Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import CloseLoanModal from './CloseLoanModal';

interface Props {
  projectId: string;
  loanId: string;
  reloadData: () => void;
  permissionCheck: any;
  transactions: any[];
  loan: any;
}

const CloseLoanButton: FC<Props> = ({
  projectId,
  loanId,
  reloadData,
  permissionCheck,
  transactions,
  loan,
}) => {
  const [closeLoanModalVisible, setCloseLoanModalVisible] = useState<any>(false);
  const intl = useIntl();
  const lastTransaction = transactions ? transactions[0] : {};

  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        danger
        ghost
        onClick={() => setCloseLoanModalVisible(true)}
      >
        {permissionCheck === 'true' ? (
          <FormattedMessage id="closeloan" />
        ) : (
          <FormattedMessage id="calculateCloseLoan" />
        )}
      </Button>

      <CloseLoanModal
        onCancel={() => setCloseLoanModalVisible(false)}
        visible={closeLoanModalVisible}
        okText={intl.formatMessage({ id: 'closeloan' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        reloadData={reloadData}
        projectId={projectId}
        loanId={loanId}
        permissionCheck={permissionCheck}
        lastTransaction={lastTransaction}
        loan={loan}
      />
    </Fragment>
  );
};

export default CloseLoanButton;
