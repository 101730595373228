import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductForm from '../../../components/CbsComponents/ProductForm/ProductForm';
import { API_URL } from '../../../helpers/api/api';
import { useDataById } from '../../../helpers/form/fetch-hook';
import ContentContainer from '../../../components/LayoutComponents/ContentContainer';

const EditProduct: React.FC<PropsFromRedux> = ({ projectId }) => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: 'menu.products',
      href: '/products',
    },
    {
      title: id,
      href: `/products/${id}`,
    },
  ];

  const [product, reloadProduct] = useDataById(`${API_URL}/projects/${projectId}/products/${id}`);

  return (
    <ContentContainer
      helmetTitle="product-edit.helmet-title"
      helmetTitleParams={{ id }}
      pageTitle="product-edit.page-title"
      breadcrumbs={breadcrumbs}
    >
      <ProductForm data={product.data} isNew={false} reloadData={reloadProduct} />
    </ContentContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditProduct);
