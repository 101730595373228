/**
 * Create a regular expression that will capture permission strings that match required permissions
 * eg.
 * case 1: 'a.b.c' => '(a|\*)\.(b|\*)\.(c|\*)'
 * case 2: 'a.*.c' => '(a|\*)\.([\w\d]+|\*)\.(c|\*)'
 * case 3: ['a.b.c', 'a.d.c] => '(a|\*)\.(b|\*)\.(c|\*)|(a|\*)\.(d|\*)\.(c|\*)'
 * @param {string | string[]} permissions - List of required permissions
 * @return {string} - Regular expressesion string that can be passed as a parameter to create RegExp object.
 *    The created RegExp will return true if tested text match one of required permissions
 */
export function requiredPermissionRegEx(permissions: string | string[]): string {
  // If permissions is a string return array with that string
  const permissionList = typeof permissions === 'string' ? [permissions] : permissions;

  // case 1: 'a.b.c' => '(a|\*)\.(b|\*)\.(c|\*)'
  // case 2: 'a.*.c' => '(a|\*)\.([\w\d]+|\*)\.(c|\*)'
  // case 3: ['a.b.c', 'a.d.c] => '(a|\*)\.(b|\*)\.(c|\*)|(a|\*)\.(d|\*)\.(c|\*)'
  const regex = permissionList
    .map(permission => {
      const [group, scope, action] = permission
        .split('.')
        .map(selector => (selector === '*' ? '[\\w\\d]+' : selector));
      return `(${group}|\\*)\\.(${scope}|\\*)\\.(${action}|\\*)`;
    })
    .join('|');

  return regex;
}

/**
 * Check whehter grantedPermissions has enough priviledge regarding requiredPermisions
 * @param requiredPermissions
 * @param grantedPermissions
 */
export function hasPermission(
  requiredPermissions: string | string[],
  grantedPermissions: string[],
) {
  const regexString = requiredPermissionRegEx(requiredPermissions);
  const regex = new RegExp(`^(${regexString})$`, 'i');
  if (grantedPermissions.some(grantedPermission => regex.test(grantedPermission))) {
    return true;
  }
  return false;
}
