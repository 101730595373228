import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import NewUser from '../user-new/NewUser';
import { connect } from 'react-redux';
import { patchJson, getJson, API_URL } from '../../../helpers/api/api';
import { Redirect } from 'react-router';
import { acceptInvite, logout } from '../../../redux/user/user-duck';

// interface DispatchFromProps {
//   acceptInvite: () => void;
//   logout: () => void;
// }

// interface StateFromProps {
//   search: any;
// }

const AcceptInvite: React.FC = (path: any) => {
  const { search, acceptInvite, logout } = path;
  const { token } = queryString.parse(search);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    const checkCode = async () => {
      const obj = await getJson(`${API_URL}/users/accept-invite/${token}/accessToken`);
      if (obj.data.length !== 0) {
        const data = await patchJson(`${API_URL}/users/accept-invite?token=${token}`);
        const { accessToken } = data.data;
        acceptInvite({ accessToken });
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        logout();
      }
    };

    if (token !== undefined) checkCode();
  });

  return <div>{isAuthorized ? <Redirect to="/" /> : <NewUser token={`${token}`} />}</div>;
};

const mapStateToProps = (state: any) => ({
  search: state.router.location.search,
});

// export default connect<StateFromProps, DispatchFromProps>(mapStateToProps, {
//   acceptInvite,
//   logout,
// })(AcceptInvite);

export default connect(mapStateToProps, { acceptInvite, logout })(AcceptInvite);
