import React from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';
import { logout } from '../../../../redux/user/user-duck';
import styles from './profile-menu.module.scss';

function ProfileMenu(props) {
  const { user, logout } = props;
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="email" />:{' '}
          </strong>
          {user.email}
          <br />
          <strong>
            <FormattedMessage id="mobileNo" />:{' '}
          </strong>
          {user.mobileNo || '-'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="javascript: void(0);">
          <i className={`${styles.menuIcon} icmn-lock`} />
          <FormattedMessage id="change-password" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="javascript: void(0);" onClick={logout}>
          <i className={`${styles.menuIcon} icmn-exit`} />
          <FormattedMessage id="logout" />
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
}

export default connect(({ user }) => ({ user }), { logout })(ProfileMenu);
