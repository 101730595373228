import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ImportDisbursementsFrom from '../../components/CbsComponents/Imports/Disbursements/ImportDisbursementsFrom';

const ImportDisbursements: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importDisbursements',
      href: `/import/disbursements`,
    },
  ];

  return (
    <ContentContainer
      helmetTitle="menu.importDisbursements"
      pageTitle="menu.imports"
      breadcrumbs={breadcrumbs}
    >
      <ImportDisbursementsFrom />
    </ContentContainer>
  );
};

export default ImportDisbursements;
