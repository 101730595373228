import React, { useState, useEffect } from 'react';
import { store } from '../../../redux/store';
import CreditreportsTable from './CreditreportsTable';
import SummaryCreditreports from './SummaryCreditreports';

const ImportCreditreportsConfirm: React.FC<any> = (props: any) => {
  const [creditreportImport, setCreditreport] = useState<any>([]);

  const { creditreports } = store.getState().import;

  useEffect(() => {
    setCreditreport(creditreports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryCreditreports creditreports={creditreports} />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <CreditreportsTable dataSource={creditreportImport?.creditreports} />
      </div>
    </div>
  );
};

export default ImportCreditreportsConfirm;
