import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Row, Col, DatePicker } from 'antd';
import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { FormattedMessage } from 'react-intl';

interface Prop extends FormComponentProps {
  visible: boolean;
  onCancel: any;
  onCreate: any;
}
interface State {
  yearVisible: boolean;
}
const TransferWithdraw = Form.create<Prop>({ name: 'form_in_modal' })(
  class extends React.Component<Prop, State> {
    constructor(props: Prop) {
      super(props);
      this.state = { yearVisible: false };
    }

    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal visible={visible} okText="ยืนยัน" width="700px" onCancel={onCancel} onOk={onCreate}>
          <Row>
            <h2>
              <FormattedMessage id="withdraws-list.transfer" />
            </h2>
          </Row>
          <hr />
          <Row>
            <Col span={24}>
              <Form.Item
                label={<FormattedMessage id="transferDate" />}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
              >
                {getFieldDecorator('transferredDate')(<DatePicker format="DD-MM-YYYY" />)}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      );
    }
  },
);

export default TransferWithdraw;
