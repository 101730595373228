import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { postJson, API_URL } from '../../../../helpers/api/api';

const ImportButton: React.FC<any> = (props: any) => {
  const { projectId, writeoffs } = props;
  const [state, setState] = useState({ loading: false });

  async function handleSave() {
    setState({ loading: true });
    try {
      const { push } = props;
      await postJson(`${API_URL}/projects/${projectId}/imports/write-offs`, {
        writeoffs,
      });
      message.success(`file uploaded successfully.`);
      setState({ loading: false });
      push('/import/writeoffs');
    } catch (err) {
      setState({ loading: false });
      message.warning(`${err}`);
    }
  }
  return (
    <Button type="primary" onClick={handleSave} style={{ width: '120px' }} loading={state.loading}>
      <FormattedMessage id="import.applications.confirmImport" />
    </Button>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: state.user.currentProjectId,
});

export default connect(mapStateToProps, { push })(ImportButton);
