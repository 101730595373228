import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL, deleteJson } from '../../../helpers/api/api';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Popconfirm } from 'antd';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import SearchableTable from '../../../components/CbsComponents/SearchableTable/SearchableTable';
import { Sale } from '../../../types/sale-types';
import { getSortOrderFromQueryString } from '../../../helpers/querystring';

interface Props {
  queryString: string;
  pathname: string;
  currentProjectId: string;
}
const ListSale: React.FC<Props & WrappedComponentProps> = ({
  queryString,
  pathname,
  currentProjectId,
  intl,
}) => {
  const columns = [
    {
      title: <FormattedMessage id="sales.id" />,
      dataIndex: 'id',
      width: 100,
      fixed: 'left',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Sale, b: Sale) => (a.code < b.code ? -1 : 1),
      sortOrder: getSortOrderFromQueryString({ dataIndex: 'id', queryString }),
      render: (id: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`sales/${id}`}>
            {id}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="sales.code" />,
      dataIndex: 'code',
    },
    {
      title: <FormattedMessage id="common.email" />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id="common.name" />,
      dataIndex: 'firstName',
    },
    {
      title: <FormattedMessage id="common.lastName" />,
      dataIndex: 'lastName',
    },
    {
      title: <FormattedMessage id="common.mobileNo" />,
      dataIndex: 'mobileNo',
    },
    {
      title: <FormattedMessage id="account.accountNo" />,
      dataIndex: 'accountNo',
    },
    {
      title: <FormattedMessage id="sales.teamLead" />,
      dataIndex: 'teamLead',
    },
    {
      title: <FormattedMessage id="common.action" />,
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (value: any) => {
        return (
          <span>
            <Popconfirm
              title={<FormattedMessage id="question.areYouSure" />}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              cancelText={<FormattedMessage id="common.no" />}
              okText={<FormattedMessage id="common.yes" />}
              onConfirm={async (e: any) => {
                const { id } = value;
                await deleteJson(`${API_URL}/projects/${currentProjectId}/sales/${id}`);
                window.location.reload();
              }}
              onCancel={e => e && e.stopPropagation()}
            >
              <a href="#" onClick={e => e.stopPropagation()}>
                <i className="icmn-bin" />
              </a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  const breadcrumbs = [
    {
      title: 'menu.sales',
      href: '/sales',
    },
  ];

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.sales' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.sales" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SearchableTable
          columns={columns}
          fetchUrl={`${API_URL}/projects/${currentProjectId}/sales`}
          addUrl="sales/new"
          addPermissions="sales.*.create"
          pathname={pathname}
          search={queryString}
          rowKey="code"
          enableDownload
        />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps)(injectIntl(ListSale));
