import store from 'store';

// Action types
export const SET_STATE = 'settings/SET_STATE';
export const CHANGE_SETTING = 'settings/CHANGE_SETTING';

// Action creators
/**
 * Creator function for CHANGE_SETTING action. After dispatch this action,
 * the setting will be persisted to store and SET_STATE action is dispatched
 * eg. if you want to change isLightTheme to false, call changeSetting({ setting: 'isLightTheme', value: false })
 * @param {object} payload
 * @param {string} payload.setting - Name of setting the value to be applied to.
 * @param {any} payload.value - The value that will be applied
 */
export function changeSetting({ setting, value }) {
  return {
    type: CHANGE_SETTING,
    payload: { setting, value },
  };
}
/**
 * Creator function for SET_STATE action. Normally you don't dispatch this function directly
 * it will be dispatched by settings-saga when you dispatch CHANGE_SETTING action
 * eg. call setState({ isLightTheme: false }) if you want to set state.isLightTheme to false.
 * @param {object} payload - A key/value pair of state to be set
 */
export function setState(payload) {
  return {
    type: SET_STATE,
    payload,
  };
}

/**
 * Load setting from saved value
 * @param {object} defaultSettings - Default setting value if the setting was not saved to store before.
 */
function loadSettings(defaultSettings) {
  const settings = {};
  Object.keys(defaultSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : defaultSettings[key];
  });
  return settings;
}

const initialState = {
  ...loadSettings({
    isMobileView: false,
    isMobileMenuOpen: false,
    isLightTheme: true,
    isSettingsOpen: false,
    isMenuTop: false,
    isMenuCollapsed: false,
    isBorderless: true,
    isSquaredBorders: false,
    isFixedWidth: false,
    isMenuShadow: false,
    locale: 'th-TH',

    /* for listTable components */
    pageSize: 10,
  }),
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
