import React from 'react';
import { Form, Divider, Row, Col, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PersonalInfo from './Panel/PersonalInfo';
import LoanInfo from './Panel/LoanInfo';
import AccountInfo from './Panel/AccountInfo';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface Props {
  loan: any;
}

const General: React.FC<Props> = ({ loan }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SaveButton = renderIfHasPermissisons(
    'loans.*.update',
    <Button type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );
  return (
    <div className="card-body">
      <Form name="general" initialValues={loan} layout="vertical" onFinishFailed={onFinishFailed}>
        <PersonalInfo form={form} />
        <Divider dashed />
        <LoanInfo />
        <Divider dashed />
        <AccountInfo />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default General;
