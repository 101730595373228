import fetchMock from 'fetch-mock/src/client';
import {
  productsList,
  usersList,
  roleInfo,
  saleInfo,
} from '../../components/CbsComponents/List/mock';
import jwt from 'jsonwebtoken';

(function mock() {
  // Mock login
  const userInfo = {
    email: 'arnupharp.v@ittp.co.th',
    projects: [
      // { type: 'organisation', id: '512387', name: 'ittp.co.th' },
      // { type: 'project', id: 'default-145768', name: 'default' },
      // { type: 'project', id: 'moneybuddy-342134', name: 'moneybuddy' },
    ],
    role: {
      '512387': ['dataentry'],
      default_145768: ['approver', 'accountlead'],
      'moneybudy-342134': ['approver', 'accountlead'],
    },
    scope: {
      '512387': ['cbs.application.create'],
      default_145768: ['cbs.application.approve', 'cbs.loan.disburse'],
      'moneybudy-342134': ['cbs.application.approve', 'cbs.loan.payment.create'],
    },
    avatar: '',
    mobileNo: '0866651662',
    verified: true,
    organisationId: ['1234', '2345'],
  };
  const secret = 'testsecret';
  const accessToken = jwt.sign(userInfo, secret, { expiresIn: 60 * 5 });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/users/login`, {
    status: 200,
    headers: {
      'Set-Cookie': 'refreshToken=asdfasdffasdwerwq; HttpOnly; Secure',
    },
    body: {
      accessToken,
    },
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/users/renew-token`, () => {
    return {
      status: 200,
      body: {
        accessToken: jwt.sign(userInfo, secret, { expiresIn: 60 * 5 }),
      },
    };
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/users/logout`, 200);

  // Mock register
  const registerInfo = {
    email: 'test@test.com',
    password: '123',
    projects: [],
  };
  fetchMock.post(`${process.env.REACT_APP_API_URL}/users/register`, () => {
    return {
      status: 200,
      body: {
        accessToken: jwt.sign(registerInfo, secret, { expiresIn: 60 * 5 }),
      },
    };
  });

  // Mock eligible roles
  const allRole = ['Admin', 'Reader', 'Writer', 'New'];
  fetchMock.get(`${process.env.REACT_APP_API_URL}/allRole`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        allRole,
      },
    };
  });

  // Mock users
  fetchMock.get(`${process.env.REACT_APP_API_URL}/users/abc@gk.com`, () => {
    return {
      code: 200,
      data: {
        email: 'abc@gk.com',
        password: 'pwd',
        name: 'somk',
        lastName: 'fomd',
        mobileNo: '0932993034',
        role: ['Reader', 'Writer'],
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/users/cdlsrc@gk.com`, () => {
    return {
      code: 200,
      data: {
        email: 'cdlsrc@gk.com',
        password: 'pddd',
        name: 'dlckvf',
        lastName: 'efvfvdv',
        mobileNo: '0929099094',
        role: ['Writer'],
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/users/kdlkmvk@gk.com`, () => {
    return {
      code: 200,
      data: {
        email: 'kdlkmvk@gk.com',
        password: 'dfvkpmdof',
        name: 'vapooqerf',
        lastName: 'qorpofm',
        mobileNo: '0945678901',
        role: ['Admin', 'Writer'],
      },
    };
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/users`, () => {
    usersList.push({
      email: 'abcddddd@gkdd.com',
      password: 'password',
      name: 'This is me',
      lastName: 'meeeeeee',
      mobileNo: '0939485949',
      role: ['Admin', 'Writer'],
    });
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/users/abc@gk.com`, () => {
    usersList[0] = {
      email: 'abc@gk.com',
      password: 'udpassword',
      name: 'somk',
      lastName: 'newLastName',
      mobileNo: '0932993034',
      role: ['Reader', 'Writer'],
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/users/cdlsrc@gk.com`, () => {
    usersList[1] = {
      email: 'cdlsrc@gk.com',
      password: 'pddd',
      name: 'newName',
      lastName: 'newLastname',
      mobileNo: '0929099094',
      role: ['Reader'],
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/users/kdlkmvk@gk.com`, () => {
    usersList[2] = {
      email: 'kdlkmvk@gk.com',
      password: 'dfvkpmdof',
      name: 'nameeeeee',
      lastName: 'qorpofmkkkfdvkd',
      mobileNo: '0945678901',
      role: ['Admin', 'Writer'],
    };
  });

  // Mock products
  fetchMock.get(`${process.env.REACT_APP_API_URL}/products/pdname-278122`, () => {
    return {
      code: 200,
      data: {
        name: 'ldfm',
        id: 'pdname-278122',
        description: '',
        loanType: 'ploan',
        paymentType: 'installment',
        intType: 'flat',
        intRate: 0.24,
        minPayRate: 0.39,
        minPayAmount: 12300,
        transferFee: 24050,
        lateFee: 2950,
        preterminateFee: 14300,
        safeMargin: 230,
        sendEStatement: true,
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/products/ddt-278102`, () => {
    return {
      code: 200,
      data: {
        name: 'ddt',
        id: 'ddt-278102',
        description: 'Mock Desc.',
        loanType: 'nano',
        paymentType: 'installment',
        intType: 'effective',
        intRate: 0.12,
        minPayRate: 0.19,
        minPayAmount: 11100,
        transferFee: 20000,
        lateFee: 29000,
        preterminateFee: 10000,
        safeMargin: 2000,
        sendEStatement: true,
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/products/ttok-278142`, () => {
    return {
      code: 200,
      data: {
        name: 'dvmkdlv',
        id: 'ttok-278142',
        description: 'Mock Desc llfrg',
        loanType: 'staff',
        paymentType: 'revolving',
        intType: 'effective',
        intRate: 0.29,
        minPayRate: 0.2,
        minPayAmount: 102029,
        transferFee: 20300,
        lateFee: 403944,
        preterminateFee: 29409,
        safeMargin: 409049,
        sendEStatement: true,
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/products/wekle-238102`, () => {
    return {
      code: 200,
      data: {
        name: 'dkglvmdkg',
        id: 'wekle-238102',
        description: 'Mock Desc.',
        loanType: 'ploan',
        paymentType: 'revolving',
        intType: 'flat',
        intRate: 0.89,
        minPayRate: 0.23,
        minPayAmount: 19023,
        transferFee: 24100,
        lateFee: 294430,
        preterminateFee: 11930,
        safeMargin: 3004,
        sendEStatement: true,
      },
    };
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/products`, () => {
    productsList.push({
      name: 'pd name',
      id: 'pdname-234567',
      description: 'This is Mock Desc.',
      loanType: 'staff',
      paymentType: 'revolving',
      intType: 'flat',
      intRate: 0.43,
      minPayRate: 0.92,
      minPayAmount: 1510,
      transferFee: 2000,
      lateFee: 4000,
      preterminateFee: 9000,
      safeMargin: 8000,
      sendEStatement: false,
    });
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/products/pdname-278122`, () => {
    productsList[0] = {
      name: 'ldfm',
      id: 'pdname-278122',
      description: '',
      loanType: 'ploan',
      paymentType: 'revolving',
      intType: 'flat',
      intRate: 0.24,
      minPayRate: 0.39,
      minPayAmount: 12276,
      transferFee: 24050,
      lateFee: 2950,
      preterminateFee: 14300,
      safeMargin: 230,
      sendEStatement: true,
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/products/ddt-278102`, () => {
    // code: 200,
    // errorMessage: 'Update failed',
    // body: {
    productsList[1] = {
      name: 'ddt',
      id: 'ddt-278102',
      description: 'New description',
      loanType: 'ploan',
      paymentType: 'installment',
      intType: 'flat',
      intRate: 0.12,
      minPayRate: 0.19,
      minPayAmount: 11100,
      transferFee: 25340,
      lateFee: 22300,
      preterminateFee: 19470,
      safeMargin: 2090,
      sendEStatement: true,
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/products/ttok-278142`, () => {
    productsList[2] = {
      name: 'new nameee',
      id: 'ttok-278142',
      description: 'Mock Desc llfrg',
      loanType: 'staff',
      paymentType: 'revolving',
      intType: 'effective',
      intRate: 0.29,
      minPayRate: 0.2,
      minPayAmount: 102029,
      transferFee: 20939,
      lateFee: 4039,
      preterminateFee: 29409,
      safeMargin: 4090,
      sendEStatement: false,
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/products/wekle-238102`, () => {
    productsList[3] = {
      name: 'dkekw edit name',
      id: 'wekle-238102',
      description: 'THis is desc.',
      loanType: 'ploan',
      paymentType: 'revolving',
      intType: 'flat',
      intRate: 0.89,
      minPayRate: 0.23,
      minPayAmount: 19023,
      transferFee: 24100,
      lateFee: 294430,
      preterminateFee: 11930,
      safeMargin: 3004,
      sendEStatement: true,
    };
  });

  // Mock eligible teamleads
  const teamLeads = [
    { code: 'TL001-Somchai Tester' },
    { code: 'TL002-Bruni Whatever' },
    { code: 'TL003-Cherprang Aree' },
    { code: 'TL004-Preecha World' },
    { code: 'TL005-Sadio Mane' },
    { code: 'TL006-John Henry' },
    { code: 'TL007-Mohamed Salah' },
    { code: 'TL008-Alisson Becker' },
    { code: 'TL009-Klopp Normalone' },
    { code: 'TL010-Tony Stark' },
    { code: 'TL011-Peter Parker' },
    { code: 'TL0012-Peni Parker' },
  ];
  fetchMock.get(`${process.env.REACT_APP_API_URL}/teamleads`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        teamLeads,
      },
    };
  });

  // Mock elegible permission
  const permissions = ['Read', 'Write', 'Loan'];
  fetchMock.get(`${process.env.REACT_APP_API_URL}/permissions`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        permissions,
      },
    };
  });

  // Mock roles
  fetchMock.get(`${process.env.REACT_APP_API_URL}/roles/admin-123456`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        name: 'Admin',
        id: 'admin-123456',
        description: 'Can do everythings',
        permissions: ['Read', 'Write', 'Loan'],
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/roles/reader-234567`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        name: 'Reader',
        id: 'reader-234567',
        description: 'Read only',
        permissions: ['Read'],
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/roles/writer-111111`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        name: 'Writer',
        id: 'writer-111111',
        description: 'Write only',
        permissions: ['Write'],
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/roles/manager-123123`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        id: 'manager-123123',
        name: 'Manager',
        description: '',
        permissions: ['Writer', 'Read', 'Loan'],
      },
    };
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/roles`, () => {
    roleInfo.push({
      name: 'Manager',
      id: 'manager-123123',
      description: '',
      permissions: ['Writer', 'Read', 'Loan'],
    });
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/roles/admin-123456`, () => {
    roleInfo[0] = {
      name: 'SuperAdmin',
      id: 'admin-123456',
      description: '',
      permissions: ['Reader', 'Write', 'Loan'],
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/roles/reader-234567`, () => {
    roleInfo[1] = {
      name: 'Reader',
      id: 'reder-234567',
      description: 'Read only',
      permissions: ['Reader', 'Loan'],
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/roles/writer-111111`, () => {
    roleInfo[2] = {
      name: 'Loan',
      id: 'writer-234567',
      description: 'Loan only',
      permissions: ['Loan'],
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/roles/manager-123123`, () => {
    roleInfo[3] = {
      id: 'manager-123123',
      name: 'Manager',
      description: 'Edit description',
      permissions: ['Writer', 'Read', 'Loan'],
    };
  });

  // Mock sales
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL001-Somchai Tester`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        code: 'TL001-Somchai Tester',
        email: 'somchai@test.com',
        name: 'Somchai',
        lastName: 'Tester',
        mobileNo: '0897654968',
        bankAccount: '1111111111',
        teamLead: 'TL001-Somchai Tester',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL002-Bruno Whatever`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        code: 'TL002-Bruno Whatever',
        email: 'bruno@test.com',
        name: 'Bruno',
        lastName: 'Whatever',
        mobileNo: '0987888764',
        bankAccount: '1234512345',
        teamLead: 'TL001-Somchai Tester',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL003-Cherprang Aree`, () => {
    return {
      code: 200,
      errorMessage: '',
      data: {
        code: 'TL003-Cherprang Aree',
        email: 'cherprang@test.com',
        name: 'Cherprang',
        lastName: 'Aree',
        mobileNo: '0998713656',
        bankAccount: '9879087465',
        teamLead: 'TL006-John Henry',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL004-Preecha World`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL004-Preecha World',
        email: 'preecha@test.com',
        name: 'Preecha',
        lastName: 'World',
        mobileNo: '0875692345',
        bankAccount: '1975428567',
        teamLead: 'TL006-John Henry',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL005-Sadio Mane`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL005-Sadio Mane',
        email: 'sadio@test.com',
        name: 'Sadio',
        lastName: 'Mane',
        mobileNo: '0923451195',
        bankAccount: '0009826458',
        teamLead: 'TL005-Sadio Mane',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL006-John Henry`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL006-John Henry',
        email: 'john@test.com',
        name: 'John',
        lastName: 'Henry',
        mobileNo: '0988897561',
        bankAccount: '1112480096',
        teamLead: 'TL006-John Henry',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL007-Mohamed Salah`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL007-Mohamed Salah',
        email: 'mohamed@test.com',
        name: 'Mohamed',
        lastName: 'Salah',
        mobileNo: '0877592614',
        bankAccount: '1129576009',
        teamLead: 'TL001-Somchai Tester',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL008-Alisson Becker`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL008-Alisson Becker',
        email: 'alisson@test.com',
        name: 'Alisson',
        lastName: 'Becker',
        mobileNo: '0898975600',
        bankAccount: '4567444201',
        teamLead: 'TL008-Alisson Becker',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL009-Klopp Normalone`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL009-Klopp Normalone',
        email: 'klopp@test.com',
        name: 'Klopp',
        lastName: 'Normalone',
        mobileNo: '0900741234',
        bankAccount: '1234765113',
        teamLead: 'TL008-Alisson Becker',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL010-Tony Stark`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL010-Tony Stark',
        email: 'tony@test.com',
        name: 'Tony',
        lastName: 'Stark',
        mobileNo: '0945678129',
        bankAccount: '5200985647',
        teamLead: 'TL010-Tony Stark',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL011-Peter Parker`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL011-Peter Parker',
        email: 'peter@test.com',
        name: 'Peter',
        lastName: 'Parker',
        mobileNo: '0989871121',
        bankAccount: '1120085643',
        teamLead: 'TL010-Tony Stark',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL012-Peni Parker`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL012-Peni Parker',
        email: 'peni@test.com',
        name: 'Peni',
        lastName: 'Parker',
        mobileNo: '0982234657',
        bankAccount: '0998124560',
        teamLead: 'TL0010-Tony Stark',
      },
    };
  });
  fetchMock.get(`${process.env.REACT_APP_API_URL}/sales/TL013-Adam Beginner`, () => {
    return {
      code: '200',
      errorMessage: '',
      data: {
        code: 'TL013-Adam Beginner',
        email: 'adam@test.com',
        name: 'Adam',
        lastName: 'Beginner',
        mobileNo: '09890983475',
        bankAccount: '4445609824',
        teamLead: 'TL005-Adam Beginner',
      },
    };
  });
  fetchMock.post(`${process.env.REACT_APP_API_URL}/sales`, () => {
    saleInfo.push({
      code: 'TL013-Adam Beginner',
      email: 'adam@test.com',
      name: 'Adam',
      lastName: 'Beginner',
      mobileNo: '09890983475',
      bankAccount: '4445609824',
      teamLead: 'TL005-Adam Beginner',
    });
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL001-Somchai Tester`, () => {
    saleInfo[0] = {
      code: 'TL001-Somchai Tester',
      email: 'somchai@test.com',
      name: 'Somchai',
      lastName: 'Tester',
      mobileNo: '09801234567',
      bankAccount: '1234585739',
      teamLead: 'TL001-Somchai Tester',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL002-Bruno Whatever`, () => {
    saleInfo[1] = {
      code: 'TL002-Bruno Whatever',
      email: 'bruno@test.com',
      name: 'Bruno',
      lastName: 'Somewhere',
      mobileNo: '0987888764',
      bankAccount: '0923456972',
      teamLead: 'TL001-Somchai Tester',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL003-Cherprang Aree`, () => {
    saleInfo[2] = {
      code: 'TL003-Cherprang Aree',
      email: 'cherprang@test.com',
      name: 'Cherprang',
      lastName: 'Aree',
      mobileNo: '0998713656',
      bankAccount: '9879087465',
      teamLead: 'TL001-Somchai Tester',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL004-Preecha World`, () => {
    saleInfo[3] = {
      code: 'TL004-Preecha World',
      email: 'preecha@test.com',
      name: 'Preecha',
      lastName: 'World',
      mobileNo: '0875692345',
      bankAccount: '1975428567',
      teamLead: 'TL004-Preecha World',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL005-Sadio Mane`, () => {
    saleInfo[4] = {
      code: 'TL005-Sadio Mane',
      email: 'sadio@test.com',
      name: 'Sadio',
      lastName: 'Mane',
      mobileNo: '0923451195',
      bankAccount: '0009826458',
      teamLead: 'TL006-John Henry',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL006-John Henry`, () => {
    saleInfo[5] = {
      code: 'TL006-John Henry',
      email: 'john@test.com',
      name: 'John',
      lastName: 'Henry',
      mobileNo: '0988897561',
      bankAccount: '0009274856',
      teamLead: 'TL006-John Henry',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL007-Mohamed Salah`, () => {
    saleInfo[6] = {
      code: 'TL007-Mohamed Salah',
      email: 'mohamed@test.com',
      name: 'Mohamed',
      lastName: 'Salah',
      mobileNo: '0877592614',
      bankAccount: '1129576009',
      teamLead: 'TL006-John Henry',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL008-Alisson Becker`, () => {
    saleInfo[7] = {
      code: 'TL008-Alisson Becker',
      email: 'alisson@test.com',
      name: 'Alisson',
      lastName: 'Becker',
      mobileNo: '08888723945',
      bankAccount: '4567444201',
      teamLead: 'TL008-Alisson Becker',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL009-Klopp Normalone`, () => {
    saleInfo[8] = {
      code: 'TL009-Klopp Normalone',
      email: 'klopp@test.com',
      name: 'Klopp',
      lastName: 'Normalone',
      mobileNo: '0900741234',
      bankAccount: '1234765113',
      teamLead: 'TL009-Klopp Normalone',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL010-Tony Stark`, () => {
    saleInfo[9] = {
      code: 'TL010-Tony Stark',
      email: 'toney@ironman.com',
      name: 'Tony',
      lastName: 'Stark',
      mobileNo: '0945678129',
      bankAccount: '5200985647',
      teamLead: 'TL010-Tony Stark',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL011-Peter Parker`, () => {
    saleInfo[10] = {
      code: 'TL011-Peter Parker',
      email: 'peter_parker@test.com',
      name: 'Peter',
      lastName: 'Parker',
      mobileNo: '09293840586',
      bankAccount: '2223495768',
      teamLead: 'TL010-Tony Stark',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL012-Peni Parker`, () => {
    saleInfo[11] = {
      code: 'TL012-Peni Parker',
      email: 'peni@test.com',
      name: 'Peni',
      lastName: 'Parker',
      mobileNo: '0982234657',
      bankAccount: '0998124560',
      teamLead: 'TL001-Somchai Tester',
    };
  });
  fetchMock.patch(`${process.env.REACT_APP_API_URL}/sales/TL013-Adam Beginner`, () => {
    saleInfo[12] = {
      code: 'TL013-Adam Beginner',
      email: 'adam@test.com',
      name: 'Adam',
      lastName: 'Walker',
      mobileNo: '09228098375',
      bankAccount: '4445609824',
      teamLead: 'TL005-Adam Beginner',
    };
  });
})();
