import React, { useEffect, useState, FC } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import Loader from '../../../components/LayoutComponents/Loader';
import { verify } from '../../../redux/user/user-duck';

interface UrlParams {
  token: string;
}
const Verify: FC<PropsFromRedux & RouteComponentProps<UrlParams>> = ({ verify, match }) => {
  const [tokenObj, setTokenObj] = useState<string>('');
  const intl = useIntl();
  useEffect(() => {
    const verifyToken = async () => {
      const successNotification = {
        message: intl.formatMessage({ id: 'verify.success-message' }),
        description: intl.formatMessage({ id: 'verify.success-description' }),
      };
      const { token } = match.params;
      const obj = await verify({ token, successNotification });

      await setTokenObj(obj.payload.token);
    };

    verifyToken();
  }, [intl, match.params, verify]);

  return <div>{tokenObj !== '' ? <Redirect to="/users/onboarding" /> : <Loader />}</div>;
};

const connector = connect(null, { verify });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Verify);
