import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import NewApplicationForm from '../../components/CbsComponents/Application/NewApplicationForm';

const NewApplication: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.applications',
      href: '/applications',
    },
    {
      title: 'add',
      href: `/applications/new`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="application-new.helmet-title"
      pageTitle="application-new.page-title"
      breadcrumbs={breadcrumbs}
    >
      <NewApplicationForm />
    </ContentContainer>
  );
};

export default NewApplication;
