import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { useProductOptions } from '../../../helpers/form/fetch-hook';
import { UserState } from '../../../redux/user/user-duck';

const ProductSelect: FC<PropsFromRedux & SelectProps<any>> = ({ projectId, ...selectProps }) => {
  const productOptions = useProductOptions(projectId, { limit: 10000 });
  const { Option } = Select;
  return (
    <Select showSearch {...selectProps}>
      {productOptions.map(productOption => (
        <Option key={productOption.value} value={productOption.value}>
          {productOption.label}
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductSelect);
