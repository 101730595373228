import React from 'react';
import { Row, Col, Descriptions } from 'antd';
import { FormattedMessage } from 'react-intl';
import ImportButton from './ImportButtonPayments';

const SummaryPayments: React.FC<any> = (props: any) => {
  const { summary = undefined, payments } = props;

  if (payments === undefined) window.location.href = '#/import/payments';

  return (
    <div>
      <div
        style={{
          backgroundColor: '#ffffff',
          marginBottom: '20px',
        }}
      >
        <Descriptions title="Loans" column={{ lg: 4, md: 3, sm: 2, xs: 1 }} size="small" bordered>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.all" />}>
            {summary && summary.all ? summary.all : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.normal" />}>
            {summary && summary.normal ? summary.normal : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.notupdate" />}>
            {summary && summary.notupdate ? summary.notupdate : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.notfound" />}>
            {summary && summary.notfound ? summary.notfound : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.duplicate" />}>
            {summary && summary.duplicate ? summary.duplicate : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.close" />}>
            {summary && summary.close ? summary.close : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.payments.summary.lesszero" />}>
            {summary && summary.lesszero ? summary.lesszero : 0}{' '}
            <FormattedMessage id="import.payments.summary.account" />
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <ImportButton payments={payments} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SummaryPayments;
