import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ImportDisbursementsConfirm from '../../components/CbsComponents/Imports/Disbursements/ImportDisbursementsConfirm';

const ImportDisbursementsTable: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importDisbursements',
      href: `/import/disbursements`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="menu.importDisbursements"
      pageTitle="menu.imports"
      breadcrumbs={breadcrumbs}
    >
      <ImportDisbursementsConfirm />
    </ContentContainer>
  );
};

export default ImportDisbursementsTable;
