import { all, put, call } from 'redux-saga/effects';
import { getLeftMenuData, getTopMenuData } from '../../services/menu';
import { setState } from './menu-duck';

export function* getMenuData() {
  const menuLeftData = yield call(getLeftMenuData);
  const menuTopData = yield call(getTopMenuData);
  yield put(
    setState({
      menuLeftData,
      menuTopData,
    }),
  );
}

export default function* rootSaga() {
  yield all([
    getMenuData(), // run once on app load to fetch menu data
  ]);
}
