import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';
import styles from './thankyou.module.scss';
import { FormattedMessage } from 'react-intl';

interface Props {}
const Thankyou: React.FC<Props> = () => {
  return (
    <div className={styles.container}>
      <Helmet title="Thank you" />
      <Row>
        <Col xs={0} sm={0} md={12} lg={12}>
          <Row style={{ justifyContent: 'center' }}>
            <img
              style={{ marginTop: '350px' }}
              src="resources/images/logo-inverse.svg"
              alt="noburo logo"
            />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.form}>
            <h4 className="text-uppercase">
              <strong>
                <FormattedMessage id="thankyou.thankyou" />
              </strong>
            </h4>
            <br />
            <p>
              <FormattedMessage id="thankyou.please-check-email-to-verify-your-account" />
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Thankyou;
