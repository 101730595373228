import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, Checkbox } from 'antd';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import InputBaht from '../../../../../common/InputBaht';
import InputPercent from '../../../../../common/InputPercent';

interface Props {}

const Product: React.FC<Props> = () => {
  return (
    <div id="ProductInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="product" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="intRate" />} name="intRate">
            <InputPercent />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="safeMargin" />} name="safeMargin">
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="minPayRate" />} name="minPayRate">
            <InputPercent />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="minPayAmount" />} name="minPayAmount">
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="lateFee" />} name="lateFee">
            <InputBaht />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="stampFee" />} name="stampFee">
            <InputPercent />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="bureauFee" />} name="bureauFee">
            <InputBaht />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="bureauMissFee" />} name="bureauMissFee">
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="preterminateFee" />} name="preterminateFee">
            <InputBaht />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="preterminatePeriod" />} name="preterminatePeriod">
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="transferFee" />} name="transferFee">
            <InputBaht />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="interBankTransferFee" />}
            name="interBankTransferFee"
          >
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="openingFee" />} name="openingFee">
            <InputBaht />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="maximumCreditLimit" />} name="maximumCreditLimit">
            <InputBaht disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="sendEStatement" valuePropName="checked">
        <Checkbox>
          <FormattedMessage id="sendEStatement" />
        </Checkbox>
      </Form.Item>
      <Form.Item name="includeInBotReport" valuePropName="checked">
        <Checkbox>
          <FormattedMessage id="includeInBotReport" />
        </Checkbox>
      </Form.Item>
      <Form.Item name="includeInNcbReport" valuePropName="checked">
        <Checkbox>
          <FormattedMessage id="includeInNcbReport" />
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default Product;
