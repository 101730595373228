import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import PublicLayout from './Public';
import LoginLayout from './Login';
import MainLayout from './Main';
import OnboardingLayout from './Onboarding/OnboardingLayout';

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  header: OnboardingLayout,
};

@withRouter
class IndexLayout extends React.PureComponent {
  previousPath = '';

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { prevLocation } = prevProps;
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
    } = this.props;

    // NProgress Management
    const currentPath = pathname + search;
    if (currentPath !== this.previousPath) {
      NProgress.start();
    }

    setTimeout(() => {
      NProgress.done();
      this.previousPath = currentPath;
    }, 300);

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public';
      }
      if (/onboarding/i.test(pathname) || /accept-invite/i.test(pathname)) {
        return 'header';
      }
      if (/^\/users(?=\/|$)/i.test(pathname) || /thankyou/i.test(pathname)) {
        return 'login';
      }
      return 'main';
    };

    const Container = Layouts[getLayout()];

    return (
      <Fragment>
        <Helmet title="Noburo LMS" />
        <Container>{children}</Container>
      </Fragment>
    );
  }
}

export default IndexLayout;
