import React from 'react';
import { connect } from 'react-redux';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Row, Col, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { API_URL } from '../../../../helpers/api/api';
import { setImportWriteOffs } from '../../../../redux/import/import-duck';

const ImportWriteoffsFrom: React.FC<any> = (props: any) => {
  const { currentProjectId } = props;
  let mapData: any = [];
  const acceptTypes = '.csv, .json';

  const pr = {
    name: 'file',
    multiple: true,
    action: `${API_URL}/projects/${currentProjectId}/imports/write-offs/check`,
    onChange(info: any) {
      const { response, status } = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        mapData = response.data;
        message.success(`${info.file.name} file uploaded successfully.`);
        const { setImportWriteOffs } = props;
        setImportWriteOffs({ writeoffs: mapData });
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className="card" style={{ backgroundColor: 'white' }}>
            <strong>
              <FormattedMessage id="import.importWriteoff" />
            </strong>
            <br />
            <Row>
              <Col xs={24} sm={24} md={16} lg={10}>
                <Upload.Dragger
                  accept={acceptTypes}
                  {...pr}
                  name="upload"
                  style={{ width: '100%' }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    <FormattedMessage id="common.dropzoneUpload" />
                  </p>
                </Upload.Dragger>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps, { setImportWriteOffs })(ImportWriteoffsFrom);
