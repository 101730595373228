import React from 'react';
import { Form, Radio, Input, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import BankNameSelect from '../../../../../../../common/BankNameSelect';
import styles from '../../../../../../application.module.scss';
import componentValues from '../../../../../../../../../services/utilities/ComponentValue';

interface Prop {
  application: any;
}

const AccountInfo: React.FC<Prop> = ({ application }) => {
  return (
    <div id="accountInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="accountInfo" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="bankName" />}
            name="bankName"
            rules={[{ required: true }]}
          >
            <BankNameSelect />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="branchName" />} name="branchName">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="accountNo" />} name="accountNo">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }}>
          <Form.Item label={<FormattedMessage id="accountType" />} name="accountType">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="saving">
                <FormattedMessage id="accountType.saving" />
              </Radio.Button>
              <Radio.Button value="current">
                <FormattedMessage id="accountType.current" />
              </Radio.Button>
              <Radio.Button value="fixed">
                <FormattedMessage id="accountType.fixed" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }}>
          <Form.Item
            label={<FormattedMessage id="statementAddress" />}
            name="statementAddress"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="current">
                <FormattedMessage id="addressInfo.current" />
              </Radio.Button>
              <Radio.Button value="permanent">
                <FormattedMessage id="addressInfo.permanent" />
              </Radio.Button>
              <Radio.Button value="company">
                <FormattedMessage id="addressInfo.company" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default AccountInfo;
