export const addcomma = (number: any) => {
  const parts = `${number}`.split('.');
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const removecomma = (number: string) => {
  let removed = number;
  for (let i = 0; i < String(number).length / 3; i += 1) {
    removed = `${removed}`.replace(',', '');
  }
  return removed;
};

export const satangToBath = (satang: number) => {
  const bath: Number = Number(satang) / 100;
  return addcomma(Number(bath).toFixed(2));
};

export const satangToBathNoComma = (satang: number) => {
  const bath: Number = Number(satang) / 100;
  return Number(bath).toFixed(0);
};

export const satangToBathCommaEscape = (satang: number) => {
  return `"${satangToBath(satang)}"`;
};

export function countDecimal(value: number) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
}
