import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <Fragment>
      {breadcrumbs.map((breadcrumb, i) => {
        if (i !== breadcrumbs.length - 1) {
          return (
            <span key={breadcrumb.href}>
              <Link to={breadcrumb.href} className="text-muted">
                <FormattedMessage id={breadcrumb.title} />
              </Link>
              <span className={styles.arrow} />
            </span>
          );
        }
        return (
          <span key={breadcrumb.href}>
            <Link to={breadcrumb.href}>
              <strong>
                <FormattedMessage id={breadcrumb.title} />
              </strong>
            </Link>
          </span>
        );
      })}
    </Fragment>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default Breadcrumbs;
