import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Row, Col, Descriptions, notification, Input } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { datediff } from '../../../../helpers/date/date';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { satangToBath } from '../../../../helpers/format/satangToBath';

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  lastTransaction: any;
  loan: any;
}

const OutStandingBalance: FC<any> = ({ osb }) => {
  const intl = useIntl();
  const { asOfDate, principal, interest, fee, total } = osb;
  return (
    <Descriptions column={4} title={intl.formatMessage({ id: 'osbAt' }, { asOfDate })}>
      <Descriptions.Item label={<FormattedMessage id="principal" />}>{principal}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="interest" />}>{interest}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="fee" />}>{fee}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="osb" />}>{total}</Descriptions.Item>
    </Descriptions>
  );
};

const FilingModal: FC<Props> = ({
  projectId,
  visible,
  onCancel,
  cancelText,
  okText,
  loan,
  lastTransaction,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const initialOsb = { principal: 0, interest: 0, fee: 0, total: 0 };
  const [osb, setOsb] = useState<any>(initialOsb);

  const calculateOsb = (asOfDate: string, intRate: number, lastTransaction: any) => {
    const { cfPrincipal, cfInterest, cfFee } = lastTransaction;
    const principal = cfPrincipal / 100;
    const duration = datediff(asOfDate, lastTransaction.transDate);
    const dueInterest = ((cfPrincipal * intRate) / 365) * duration;
    const interest = (cfInterest + dueInterest) / 100;
    const fee = cfFee / 100;
    const total = principal + interest + fee;
    return {
      asOfDate,
      total: satangToBath(total * 100),
      principal: satangToBath(principal * 100),
      interest: satangToBath(interest * 100),
      fee: satangToBath(fee * 100),
    };
  };
  const onFilingDateChange = (effectiveDate: any) => {
    const osb = calculateOsb(effectiveDate, loan.intRate, lastTransaction);
    setOsb(osb);
  };
  const onFinish = async (value: Store) => {
    try {
      const { blackCaseNo, filingDate } = value;
      let values: any = {
        loanId: loan.id,
        effectiveDate: filingDate,
        filingDate,
        litigationStatus: 'under_litigation',
      };
      values = blackCaseNo ? { ...values, blackCaseNo } : values;
      const type = 'filing';
      await postJson(`${API_URL}/projects/${projectId}/request-approvals`, { values, type });
      form.resetFields();
      setOsb(initialOsb);
      notification.success({ message: intl.formatMessage({ id: 'filing.success-message' }) });
      onCancel();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="filing" />
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="filingDate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="filingDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" onChange={onFilingDateChange} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="blackCaseNo" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="blackCaseNo"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <OutStandingBalance osb={osb} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FilingModal;
