import React, { Component, ChangeEvent, KeyboardEvent } from 'react';
import { Input } from 'antd';
import { removecomma, addcomma } from '../../../helpers/format/satangToBath';

interface Props {
  onChange?: (value: string | number | undefined | null) => void;
  placeHolder?: string;
  'data-__meta'?: {
    initialValue: string;
  };
  style?: object;
  disabled?: boolean;
  value?: any;
}
interface State {
  displayValue?: string;
}

function countDecimals(value: number) {
  if (Math.floor(value) !== value) return value.toString().split('.')[1].length || 0;
  return 0;
}

class InputBaht extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.toSatang = this.toSatang.bind(this);

    const { 'data-__meta': meta, onChange } = this.props;
    const initialValue = meta ? meta.initialValue || '' : '';
    this.state = {};
    if (onChange) {
      if (initialValue !== '') {
        onChange(initialValue);
        this.state = {
          // Need to convert number after toFixed to truncate unnecessary 0, eg. Number('100.5300') => 100.53
          displayValue: `${Number(
            (Number(initialValue) / 100).toFixed(countDecimals(Number(initialValue)) + 2),
          )}`, // initialValue from backend is in satang unit
        };
      }
    }
  }

  toSatang(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const { onChange } = this.props;
    if (onChange) {
      if (value === '') {
        onChange(null);
        this.setState({ displayValue: '' });
      } else {
        const baht = Number(removecomma(value));
        if (!Number.isNaN(baht)) {
          onChange(baht * 100);
          // Cannot set displayValue to baht because it will cause
          // decimal point after input like 15. to be converted to 15
          this.setState({ displayValue: `${removecomma(value)}` });
        } else {
          onChange(null);
          this.setState({ displayValue: '' });
        }
      }
    }
  }

  /* eslint-disable class-methods-use-this */
  handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const checkNumber = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const value = checkNumber.includes(e.key);
    const code = e.keyCode;
    // This is not a number
    if (!value && code !== 8 && code !== 37 && code !== 39 && code !== 9 && code !== 190) {
      e.preventDefault();
    }
  }

  render() {
    const { placeHolder, style, disabled, value } = this.props;
    const { displayValue } = this.state;
    // Initial value set by setFieldValue command, these value sometimes can be undefined when not specified
    // sometimes can be null if the database column set null as default value
    const initialValue = Number.isNaN(Number.parseFloat(value))
      ? ''
      : // Need to convert number after toFixed to truncate unnecessary 0, eg. Number('100.5300') => 100.53
        `${Number((Number(value) / 100).toFixed(countDecimals(Number(value)) + 2))}`;
    // eslint-disable-next-line no-underscore-dangle
    const _displayValue =
      (displayValue || initialValue) === '' ? '' : addcomma(displayValue || initialValue);

    return (
      <Input
        placeholder={placeHolder}
        onKeyDown={this.handleKeyDown}
        onChange={this.toSatang}
        value={_displayValue}
        style={style}
        disabled={disabled}
      />
    );
  }
}

export default InputBaht;
