import React, { FC, Fragment } from 'react';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
  reloadData: () => void;
  permissions: any;
}
const ActionButtonPanel: FC<Props> = ({
  projectId,
  loan,
  transactions,
  reloadData,
  permissions,
}) => {
  return (
    <Fragment>
      <LeftPanel projectId={projectId} loan={loan} transactions={transactions} />
      <RightPanel
        projectId={projectId}
        loan={loan}
        reloadData={reloadData}
        transactions={transactions}
        permissions={permissions}
      />
    </Fragment>
  );
};

export default ActionButtonPanel;
