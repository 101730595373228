import React, { FC } from 'react';
import PaymentButton from './PaymentButton';
import WithdrawButton from './WithdrawButton';
import CloseLoanButton from './CloseLoanButton';
import CloseContractButton from './CloseContractButton';
import { hasPermission } from '../../../../helpers/permission';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';
import AdjButton from './AdjButton';

function checkPermission(requiredPermissions: string | string[], permissions: any) {
  if (hasPermission(requiredPermissions, permissions)) {
    return true;
  }
  return false;
}

interface Props {
  projectId: string;
  loan: any;
  reloadData: () => void;
  transactions: any[];
  permissions: any;
}
const RightPanel: FC<Props> = ({ projectId, loan, reloadData, transactions, permissions }) => {
  let isFd = false;
  if (transactions) {
    transactions.forEach(tran => {
      const { trc } = tran;
      if (trc === 'FD') {
        isFd = true;
      }
    });
  }

  const renderPaymentButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['transactions.payment.create'],
      <PaymentButton projectId={projectId} loanId={loan.id} reloadData={reloadData} />,
    );
    return <PermissionChecked />;
  };
  const renderWithdrawButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['withdraws.*.create'],
      <WithdrawButton projectId={projectId} loan={loan} reloadData={reloadData} />,
    );
    if (
      (loan.paymentType === 'installment' && isFd) ||
      (loan.paymentType === 'installment' && loan.withdrawAmount === 0)
    ) {
      return null;
    }
    return <PermissionChecked />;
  };
  const renderCloseButton = (loan: any) => {
    const PermissionChecked = checkPermission(['transactions.close.create'], permissions);
    const CloseContractPermissionChecked = checkPermission(['loans.*.read'], permissions);
    if (
      projectId === '760296' &&
      CloseContractPermissionChecked === true &&
      loan.status === 'close'
    ) {
      return <CloseContractButton projectId={projectId} loanId={loan.id} />;
    }
    if (PermissionChecked === true) {
      return (
        <CloseLoanButton
          projectId={projectId}
          loanId={loan.id}
          reloadData={reloadData}
          permissionCheck="true"
          transactions={transactions}
          loan={loan}
        />
      );
    }
    return (
      <CloseLoanButton
        projectId={projectId}
        loanId={loan.id}
        reloadData={reloadData}
        permissionCheck="false"
        transactions={transactions}
        loan={loan}
      />
    );
  };
  const renderAdjButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['transactions.adj.create'],
      <AdjButton projectId={projectId} loanId={loan.id} reloadData={reloadData} />,
    );
    return <PermissionChecked />;
  };
  if (typeof loan === 'undefined') {
    return null;
  }

  return (
    <div style={{ float: 'right' }}>
      {renderPaymentButton(loan)}
      {renderAdjButton(loan)}
      {renderWithdrawButton(loan)}
      {renderCloseButton(loan)}
    </div>
  );
};

export default RightPanel;
