import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../../helpers/format/satangToBath';

const WriteoffsTable: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;
  const column = [
    {
      title: <FormattedMessage id="import.collectorLoans.loanId" />,
      dataIndex: 'loanId',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.importWriteoff.writeoffDate" />,
      dataIndex: 'writeoffDate',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.importWriteoff.writeoffAmount" />,
      dataIndex: 'writeoffAmount',
      render: (writeoffAmount: Number) => {
        return satangToBath(Number(writeoffAmount));
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.column.status" />,
      dataIndex: 'status',
      width: '10%',
    },
  ];
  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={column} />
    </div>
  );
};

export default WriteoffsTable;
