import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Descriptions } from 'antd';

interface Props {
  application: any;
}

const ApplicationSummary: React.FC<Props> = ({ application }) => {
  return (
    <Descriptions column={{ lg: 4, md: 3, sm: 2, xs: 1 }} size="small" bordered>
      <Descriptions.Item label={<FormattedMessage id="firstName" />}>
        {application.firstName}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="lastName" />}>
        {application.lastName}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="citizenId" />}>
        {application.citizenId}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="status" />}>
        {application.status}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="loanType" />}>
        {<FormattedMessage id={`loanType.${application.loanType}`} />}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="product" />}>
        {application.productName}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="intRate" />}>
        {(application.intRate * 100).toFixed(2)}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="saleCode" />}>
        {application.saleCode}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ApplicationSummary;
