import React, { FC } from 'react';
import AmortizeTableButton from './AmortizeTableButton';
import EditLoanButton from './EditLoanButton';
import WriteoffButton from './WriteoffButton';
import FilingButton from './FilingButton';
import RestructureButton from './RestructureButton';
import ContractButton from './ContractButton';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';
import LawsuitButton from './LawsuitButton';

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
}
const LeftPanel: FC<Props> = ({ projectId, loan, transactions }) => {
  const renderAmortizeTableButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['loans.*.read'],
      <AmortizeTableButton projectId={projectId} loanId={loan.id} type="loan" />,
    );
    if (loan.paymentType === 'installment') {
      return <PermissionChecked />;
    }
    return null;
  };
  const renderEditLoanButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['requestapprovals.editloan.create'],
      <EditLoanButton projectId={projectId} loan={loan} transactions={transactions} />,
    );
    return <PermissionChecked />;
  };
  const renderWriteoffButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['requestapprovals.writeoff.create'],
      <WriteoffButton projectId={projectId} loan={loan} transactions={transactions} />,
    );
    if (loan.status === 'normal') {
      return <PermissionChecked />;
    }
    return null;
  };
  const renderFilingButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['requestapprovals.filing.create'],
      <FilingButton projectId={projectId} loan={loan} transactions={transactions} />,
    );
    if (loan.status === 'normal') {
      return <PermissionChecked />;
    }
    return null;
  };
  const renderRestructureButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['requestapprovals.restructure.create'],
      <RestructureButton projectId={projectId} loan={loan} transactions={transactions} />,
    );
    if (loan.status === 'normal' || loan.status === 'writeoff') {
      return <PermissionChecked />;
    }
    return null;
  };
  const renderContractButton = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['loans.*.read'],
      <ContractButton projectId={projectId} loanId={loan.id} />,
    );
    if (loan.paymentType === 'installment') {
      return <PermissionChecked />;
    }
    return null;
  };
  const renderLawsuit = (loan: any) => {
    const PermissionChecked = renderIfHasPermissisons(
      ['loans.*.read'],
      <LawsuitButton projectId={projectId} loan={loan} />,
    );
    const checkProject = ['760296', '640579'];
    if (checkProject.includes(projectId)) {
      return <PermissionChecked />;
    }
    return null;
  };
  if (typeof loan === 'undefined') {
    return null;
  }

  return (
    <div style={{ float: 'left' }}>
      {renderAmortizeTableButton(loan)}
      {renderEditLoanButton(loan)}
      {renderWriteoffButton(loan)}
      {renderFilingButton(loan)}
      {renderRestructureButton(loan)}
      {renderContractButton(loan)}
      {renderLawsuit(loan)}
    </div>
  );
};

export default LeftPanel;
