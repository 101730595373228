import React, { FC, Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import PaymentModal from './PaymentModal';

interface Props {
  projectId: string;
  loanId: string;
  reloadData: () => void;
}

const PaymentButton: FC<Props> = ({ projectId, loanId, reloadData }) => {
  const intl = useIntl();
  const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        type="primary"
        style={{
          marginLeft: '8px',
        }}
        onClick={() => setPaymentModalVisible(true)}
      >
        <FormattedMessage id="payment" />
      </Button>

      <PaymentModal
        projectId={projectId}
        loanId={loanId}
        visible={paymentModalVisible}
        onCancel={() => setPaymentModalVisible(false)}
        okText={intl.formatMessage({ id: 'payment' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        reloadData={reloadData}
      />
    </Fragment>
  );
};

export default PaymentButton;
