import React, { useState } from 'react';
import { Form, Tabs, notification } from 'antd';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';
import TransactionTable from './TransactionTable';
// import Note from './Note/Note';
import { API_URL, patchJson, showError, getJson } from '../../../helpers/api/api';
import Address from './Address/Address';
import ContactPerson from './ContactPerson/ContactPerson';
import General from './General/General';
import Guarantor from './Guarantor/Guarantor';
import Note from './Note/Note';
import ApproveInfo from './ApproveInfo/ApproveInfo';
import ConfirmModal from './Address/ConfirmModal';

interface Props {
  loading: boolean;
  dataSource: any;
  getDataSource: () => void;
  pagination: any;
  projectId: string;
  onChange: any;
}
const updateLoan = async ({
  intl,
  projectId,
  loanId,
  loanIds,
  value,
  reloadData,
}: {
  intl: IntlShape;
  projectId: string;
  loanId?: string;
  loanIds?: string[];
  value: any;
  reloadData: () => void;
}) => {
  try {
    if (loanId) {
      await patchJson(`${API_URL}/projects/${projectId}/loans/${loanId}`, { ...value });
    } else if (loanIds) {
      await patchJson(`${API_URL}/projects/${projectId}/loans/${loanIds[0]}/address`, {
        ...value,
        loanIds,
      });
    }
    notification.success({
      message: intl.formatMessage({ id: 'update-loan.success-message' }),
    });
    reloadData();
  } catch (error) {
    showError(error);
  }
};
const TransactionsTab: React.FC<Props> = ({
  loading,
  dataSource,
  getDataSource,
  pagination,
  projectId,
  onChange,
}) => {
  // const { loading, dataSource, getDataSource, pagination, form, updateLoan, projectId } = props;
  const { TabPane } = Tabs;
  const intl = useIntl();

  const tabPanes = [
    { tab: <FormattedMessage id="transaction" />, key: 'transaction' },
    { tab: <FormattedMessage id="address" />, key: 'address' },
    { tab: <FormattedMessage id="approvalInfo" />, key: 'approvalInfo' },
    { tab: <FormattedMessage id="contactPerson" />, key: 'contactPerson' },
    { tab: <FormattedMessage id="general" />, key: 'general' },
    { tab: <FormattedMessage id="guarantor1" />, key: 'guarantor1' },
    { tab: <FormattedMessage id="guarantor2" />, key: 'guarantor2' },
    { tab: <FormattedMessage id="note" />, key: 'note' },
  ];
  const { loans, transactions } = dataSource;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressTable, setAddressTable] = useState<any>([]);
  const [dataAddress, setDataAddress] = useState<any>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getLoansByCitizenId = async (projectId: string, citizenId: string) => {
    try {
      const getLoans = await getJson(
        `${API_URL}/projects/${projectId}/loans/${citizenId}/address-table`,
      );
      setAddressTable(getLoans.data);
    } catch (error) {
      showError(error);
    }
  };

  const editAddress = async (loanIds: string[], data: any) => {
    if (loanIds.length === 1) {
      updateLoan({ intl, projectId, loanId: loanIds[0], value: data, reloadData: getDataSource });
    } else {
      updateLoan({ intl, projectId, loanIds, value: data, reloadData: getDataSource });
    }
    handleCancel();
  };

  const onFormFinish = (name: string, info: any) => {
    const { values } = info;
    let updateData = { ...values };
    if (name === 'guarantor') {
      const guarantorInLoan = loans.guarantors;
      if (guarantorInLoan !== undefined) {
        // case guarantor0
        if (values.guarantors[0] && values.guarantors[1] === undefined) {
          if (guarantorInLoan[1] !== undefined || guarantorInLoan[1] !== null) {
            updateData = { guarantors: [values.guarantors[0], guarantorInLoan[1]] };
          }
        }
        if (values.guarantors[1] && values.guarantors[0] === undefined) {
          // case guarantor1
          if (guarantorInLoan[0] !== undefined && guarantorInLoan[0] !== null) {
            updateData = { guarantors: [guarantorInLoan[0], values.guarantors[1]] };
          }
        }
      }
    } else if (name === 'addressForm') {
      const { citizenId } = loans;
      getLoansByCitizenId(projectId, citizenId);
      setDataAddress(updateData);
      showModal();
    }
    switch (name) {
      case 'contactPerson':
      case 'general':
      case 'guarantor':
        updateLoan({
          intl,
          projectId,
          loanId: loans.id,
          value: updateData,
          reloadData: getDataSource,
        });
    }
  };
  // const handleCreateNote = () => {
  //   form.validateFields(async (err: any, values: any) => {
  //     if (err) {
  //       notification.error({
  //         message: 'Please check required fields',
  //       });
  //       return;
  //     }
  //     const { id, appId, citizenId, firstName, lastName, mobileNo } = loans;
  //     const { note } = values;
  //     try {
  //       const data = {
  //         appId,
  //         loanId: id,
  //         citizenId,
  //         firstName,
  //         lastName,
  //         mobileNo,
  //         ...note,
  //       };
  //       await postJson(`${API_URL}/projects/${projectId}/notes`, data);
  //       notification.success({
  //         message: 'Create note complete',
  //         description: 'You have successfully create note',
  //       });
  //     } catch (error) {
  //       notification.error({
  //         message: 'Cannot create note',
  //       });
  //     }
  //   });
  // };
  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Tabs size="large" type="line">
        {tabPanes.map(tab => {
          switch (tab.key) {
            case 'transaction': // 'ข้อมูลการชำระเงิน':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <TransactionTable
                    projectId={projectId}
                    loan={loans}
                    transactions={transactions}
                    pagination={pagination}
                    loading={loading}
                    reloadData={getDataSource}
                    onChange={onChange}
                  />
                </TabPane>
              );
            case 'note':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <Note loan={loans} projectId={projectId} />
                </TabPane>
              );
            case 'address':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <Address loan={loans} />
                  <ConfirmModal
                    loan={loans}
                    visible={isModalOpen}
                    addressTable={addressTable}
                    onCancel={handleCancel}
                    editAddress={editAddress}
                    dataAddress={dataAddress}
                  />
                </TabPane>
              );
            case 'contactPerson':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <ContactPerson loan={loans} />
                </TabPane>
              );
            case 'general':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <General loan={loans} />
                </TabPane>
              );
            case 'guarantor1':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <Guarantor index={0} loan={loans} />
                </TabPane>
              );
            case 'guarantor2':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <Guarantor index={1} loan={loans} />
                </TabPane>
              );
            case 'approvalInfo':
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  <ApproveInfo projectId={projectId} loan={loans} />
                </TabPane>
              );
            default:
              return (
                <TabPane tab={tab.tab} key={tab.key}>
                  Coming Soon
                </TabPane>
              );
          }
        })}
      </Tabs>
    </Form.Provider>
  );
};

export default TransactionsTab;
