import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ImportCollectorLoansFrom from '../../components/CbsComponents/Imports/CollectorLoans/ImportCollectorLoansForm';

const ImportCollectorLoans: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importCollectorLoans',
      href: `/import/collectorloans`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="menu.importDisbursements"
      pageTitle="menu.imports"
      breadcrumbs={breadcrumbs}
    >
      <ImportCollectorLoansFrom />
    </ContentContainer>
  );
};

export default ImportCollectorLoans;
