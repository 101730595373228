import { all, takeEvery, put } from 'redux-saga/effects';
import store from 'store';
import qs from 'qs';
import { history, store as reduxStore } from '../store';
import { CHANGE_SETTING, changeSetting, setState } from './settings-duck';

export function* changeSettingSaga({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value);
  yield put(setState({ [setting]: value }));
}

export function* setup() {
  // load settings from url on app load
  const loadSettingsFromUrl = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    Object.keys(query).forEach(key => {
      reduxStore.dispatch(changeSetting({ setting: key, value: query[key] === 'true' }));
    });
  };
  yield loadSettingsFromUrl(history.location.search);
  // yield history.listen(params => {
  //   const { search } = params;
  //   loadSettingsFromUrl(search);
  // });

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768;
    const prevState = store.get('app.settings.isMobileView');
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      });
    }
  };
  yield isMobileView(true);
  yield window.addEventListener('resize', () => {
    isMobileView();
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(CHANGE_SETTING, changeSettingSaga),
    setup(), // run once on app load to init listeners
  ]);
}
