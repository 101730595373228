/**
 * Values for antd components.
 */
const componentValues = {
  gutterValue: 50,
  gutterButtonValue: 25,
  precisionValue: 2,
  inputStringMaxLenth: 255,
  fullColSpan: 24,
  halfColSpan: 12,
  minValue: 0,
  maxPercentValue: 100,
  mdButtonSpan: 7,
};

export default componentValues;
