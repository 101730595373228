import React from 'react';
import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import english from '../../../locales/en-US';
import thai from '../../../locales/th-TH';

const locales = {
  'en-US': english,
  'th-TH': thai,
};

class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props;
    const currentLocale = locales[locale];
    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    );
  }
}
Localization.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    isMobileView: PropTypes.bool.isRequired,
    isMobileMenuOpen: PropTypes.bool.isRequired,
    isLightTheme: PropTypes.bool.isRequired,
    isSettingsOpen: PropTypes.bool.isRequired,
    isMenuTop: PropTypes.bool.isRequired,
    isMenuCollapsed: PropTypes.bool.isRequired,
    isBorderless: PropTypes.bool.isRequired,
    isSquaredBorders: PropTypes.bool.isRequired,
    isFixedWidth: PropTypes.bool.isRequired,
    isMenuShadow: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ settings }) => ({ settings }))(Localization);
