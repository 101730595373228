import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { patchJson, API_URL, showError } from '../../../helpers/api/api';
import styles from './reset.module.scss';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';

const Reset: FC<PropsFromRedux> = ({ push }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { token } = useParams();
  const onFinish = async (value: Store) => {
    try {
      const reset = {
        token,
        password: value.password,
      };
      await patchJson(`${API_URL}/users/reset-password`, reset);
      form.resetFields();
      notification.success({
        message: intl.formatMessage({ id: 'reset.success-message' }),
      });
      push('/users/login');
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  return (
    <div className={styles.container}>
      <Helmet title={intl.formatMessage({ id: 'reset.helmet-title' })} />
      <Row>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Row style={{ justifyContent: 'center' }}>
            <img
              style={{ marginTop: '350px' }}
              src="resources/images/logo-inverse.svg"
              alt="noburo logo"
            />
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className={styles.form}>
            <h4 className="text-uppercase">
              <strong>
                <FormattedMessage id="reset.page-title" />
              </strong>
            </h4>
            <br />
            <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                label={<FormattedMessage id="newPassword" />}
                name="password"
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id="confirmNewPassword" />}
                name="confirmPassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        intl.formatMessage({ id: 'validatemsg.confirmpass-mismatch' }),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="form-actions">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    <FormattedMessage id="resetPassword" />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const connector = connect(null, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reset);
