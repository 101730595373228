import React, { useState } from 'react';
import { Table, Button, Row, Col, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DataSource, Withdraw, WithdrawStatus } from '../WithdrawsTabs';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';
import { iso8601ToLocalDate } from '../../../../helpers/date/date';

interface TableProp {
  dataSource: DataSource[];
  patchWithdraws: any;
  loading: boolean;
  // push: Push;
  pagination: object;
  onChange: any;
}
const columnsAll = [
  {
    title: <FormattedMessage id="withdraw.column.id" />,
    dataIndex: 'id',
    sorter: (a: any, b: any) => a.id.localeCompare(b.id),
  },
  { title: <FormattedMessage id="withdraw.column.loanId" />, dataIndex: 'loanId' },
  {
    title: <FormattedMessage id="withdraw.column.name" />,
    render: (data: Withdraw) => {
      return `${data.firstName} ${data.lastName}`;
    },
  },
  { title: <FormattedMessage id="company" />, dataIndex: 'companyName' },
  { title: <FormattedMessage id="withdraw.column.productName" />, dataIndex: 'productName' },
  {
    title: <FormattedMessage id="withdraw.column.amount" />,
    dataIndex: 'amount',
    render: (amount: number) => {
      return satangToBath(amount);
    },
  },
  { title: <FormattedMessage id="withdraw.column.bankName" />, dataIndex: 'bankName' },
  { title: <FormattedMessage id="withdraw.column.accountNo" />, dataIndex: 'accountNo' },
  {
    title: <FormattedMessage id="withdraw.column.createDate" />,
    dataIndex: 'createDate',
    render: (date: any) => {
      return iso8601ToLocalDate(date);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.status" />,
    render: (record: any) => (
      <div>
        <Tag style={{ textAlign: 'center' }} color="error">
          {record.status}
        </Tag>
      </div>
    ),
  },
];

const TablePending: React.FC<TableProp> = ({
  dataSource,
  loading,
  patchWithdraws,
  pagination,
  onChange,
}) => {
  const [rows, setSelectedRows] = useState<any>([]);
  const [rowKeys, setRowKeys] = useState<any>([]);

  const rowSelection: any = {
    selectedRowKeys: rowKeys,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      const oldRows = rows;
      let newRows = [];
      if (selected === true) {
        newRows = oldRows;
        newRows.push(record);
      }
      if (selected === false) {
        if (oldRows.length > 0) {
          newRows = oldRows.filter((row: any) => row.id !== record.id);
        }
      }
      const rowkeys = newRows.map((row: any) => row.id);
      setRowKeys(rowkeys);
      setSelectedRows(newRows);
    },

    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      const oldRows = rows;
      let newRows = [];
      if (selected === true) {
        newRows = oldRows;
        changeRows.map((row: any) => newRows.push(row));
      }
      if (selected === false) {
        if (oldRows.length > 0) {
          newRows = oldRows.filter(
            (oldRows: any) => !changeRows.find((changeRow: any) => changeRow.id === oldRows.id),
          );
        }
      }
      const rowkeys = newRows.map((row: any) => row.id);
      setRowKeys(rowkeys);
      setSelectedRows(newRows);
    },
  };

  const ResetButton = renderIfHasPermissisons(
    'withdraws.*.update',
    <div style={{ marginBottom: '16px' }}>
      <Row gutter={6}>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Button
            danger
            disabled={rows.length === 0}
            onClick={() => {
              patchWithdraws(rows, WithdrawStatus.PENDING);
            }}
            ghost
          >
            <FormattedMessage id="withdraw.button.reset" />
          </Button>
        </Col>
      </Row>
    </div>,
  );

  return (
    <div>
      <ResetButton />
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={dataSource}
        loading={loading}
        columns={columnsAll}
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default connect(null, { push })(TablePending);
