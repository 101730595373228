import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ApplicationForm from '../../components/CbsComponents/Application/ApplicationForm/ApplicationForm';
import { UserState } from '../../redux/user/user-duck';

const EditApplication: React.FC<PropsFromRedux> = ({ projectId }) => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: 'menu.applications',
      href: '/applications',
    },
    {
      title: id,
      href: `/applications/${id}`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="application-edit.helmet-title"
      helmetTitleParams={{ id }}
      pageTitle="application-edit.page-title"
      breadcrumbs={breadcrumbs}
    >
      <ApplicationForm projectId={projectId} applicationId={id} />
    </ContentContainer>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => {
  return {
    projectId: user.currentProjectId,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EditApplication);
