import React from 'react';
import { FormattedMessage } from 'react-intl';

export async function getLeftMenuData() {
  return [
    {
      title: <FormattedMessage id="menu.dashboard" />,
      key: 'dashboard',
      url: '/dashboard/alpha',
      icon: 'icmn icmn-home',
    },
    {
      title: <FormattedMessage id="menu.applications" />,
      key: 'applications',
      icon: 'icmn icmn-file-text2',
      url: '/applications',
      requiredPermissions: ['applications.*.*'],
    },
    {
      title: <FormattedMessage id="menu.loans" />,
      key: 'loans',
      icon: 'icm icmn-coin-dollar',
      url: '/loans',
      requiredPermissions: ['loans.*.*'],
      children: [
        {
          title: <FormattedMessage id="menu.loans" />,
          key: 'loans',
          url: '/loans',
          requiredPermissions: ['loans.*.*'],
        },
        {
          title: <FormattedMessage id="menu.adj" />,
          key: 'adj',
          url: '/adj',
          requiredPermissions: ['transactions.adj.create'],
        },
        {
          title: <FormattedMessage id="menu.acc" />,
          key: 'acc',
          url: '/acc',
          requiredPermissions: ['transactions.adj.create'],
        },
        {
          title: <FormattedMessage id="menu.statementCsv" />,
          key: 'statement',
          url: '/statement',
          requiredPermissions: ['transactions.adj.create'],
        },
        {
          title: <FormattedMessage id="menu.statementPdf" />,
          key: 'statementPdf',
          url: '/statement/pdf',
          requiredPermissions: ['transactions.adj.create'],
        },
        // {
        //   title: <FormattedMessage id="menu.sms" />,
        //   key: 'loans sms',
        //   url: '/sms',
        //   requiredPermissions: ['sms.*.*'],
        // },
      ],
    },
    {
      title: <FormattedMessage id="menu.sms" />,
      key: 'sms',
      icon: 'icm icmn-bubble',
      url: '/sms/history',
      requiredPermissions: ['sms.*.*'],
      children: [
        {
          title: <FormattedMessage id="menu.sms.history" />,
          key: 'sms',
          url: '/sms/history',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
        {
          title: <FormattedMessage id="menu.sms.waitConfirm" />,
          key: 'send sms wait confirm',
          url: '/sms/waitConfirm',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
        {
          title: <FormattedMessage id="notify-approval.page-title" />,
          key: 'send sms notify approval',
          url: '/sms/notify/approval',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
        {
          title: <FormattedMessage id="notify-cash.page-title" />,
          key: 'send sms notify cash',
          url: '/sms/notify/cash',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
        {
          title: <FormattedMessage id="menu.sms.statement" />,
          key: 'send sms statement',
          url: '/sms/statement',
          requiredPermissions: ['sms.loan.create', 'sms.loan.read'],
        },
        {
          title: <FormattedMessage id="menu.sms.estatement" />,
          key: 'send e-statement sms',
          url: '/sms/estatement',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
        {
          title: <FormattedMessage id="menu.sms.loanClose" />,
          key: 'send sms loan close',
          url: '/sms/loan/close',
          requiredPermissions: ['sms.loan.create', 'sms.loan.read'],
        },
        {
          title: <FormattedMessage id="menu.sms.withdraw" />,
          key: 'send sms status withdraw',
          url: '/sms/withdraw',
          requiredPermissions: ['sms.*.create', 'sms.*.read'],
        },
      ],
    },
    {
      title: <FormattedMessage id="menu.requestapprovals" />,
      key: 'requestapprovals',
      icon: 'icm icmn-pen',
      url: '/requestapprovals',
      requiredPermissions: ['requestapprovals.*.update', 'requestapprovals.*.read'],
    },
    {
      title: <FormattedMessage id="menu.withdraws" />,
      key: 'withdraws',
      icon: 'icmn icmn-notification',
      url: '/withdraws',
      requiredPermissions: ['withdraws.*.*'],
    },
    {
      title: <FormattedMessage id="menu.imports" />,
      key: 'importstab',
      icon: 'icm icmn-cloud-upload',
      requiredPermissions: [
        'applications.*.create',
        'transactions.payment.create',
        'creditreports.payment.create',
        'withdraws.*.update',
      ],
      children: [
        {
          title: <FormattedMessage id="menu.importApplications" />,
          key: 'import applications',
          url: '/import/applications',
          requiredPermissions: ['applications.*.create'],
        },
        {
          title: <FormattedMessage id="menu.importPayments" />,
          key: 'import payments',
          url: '/import/payments',
          requiredPermissions: ['transactions.payment.create'],
        },
        {
          title: <FormattedMessage id="menu.importCreditreports" />,
          key: 'import creditreports',
          url: '/import/creditreports',
          requiredPermissions: ['creditreports.payment.create'],
        },
        {
          title: <FormattedMessage id="menu.importDisbursements" />,
          key: 'import disbursements',
          url: '/import/disbursements',
          requiredPermissions: ['withdraws.*.update'],
        },
        {
          title: <FormattedMessage id="menu.importCollectorLoans" />,
          key: 'import colector loans',
          url: '/import/collectorloans',
          requiredPermissions: ['collectorloans.*.create'],
        },
        {
          title: <FormattedMessage id="menu.importWriteoff" />,
          key: 'import write off loans',
          url: '/import/writeoffs',
          requiredPermissions: ['writeoffloans.*.create'],
        },
      ],
    },
    {
      title: <FormattedMessage id="menu.products" />,
      key: 'products',
      icon: 'icmn icmn-library',
      url: '/products',
      requiredPermissions: ['products.*.*'],
    },
    {
      title: <FormattedMessage id="menu.policies" />,
      key: 'policies',
      icon: 'icmn icmn-profile',
      url: '/policies',
      requiredPermissions: ['policies.*.*'],
    },
    // {
    //   title: <FormattedMessage id="menu.roles" />,
    //   key: 'roles',
    //   icon: 'icm icmn-man-woman',
    //   url: '/roles',
    // },
    {
      title: <FormattedMessage id="menu.sales" />,
      key: 'sales',
      icon: 'icm icmn-user-tie',
      url: '/sales',
      requiredPermissions: ['sales.*.*'],
    },
    {
      title: <FormattedMessage id="menu.reports" />,
      key: 'reports',
      icon: 'icm icmn-file-text2',
      url: '/reports',
      requiredPermissions: ['reports.*.*'],
    },
    {
      title: <FormattedMessage id="menu.projects" />,
      key: 'projects',
      icon: 'icm icmn-file-text2',
      url: '/projects',
      requiredPermissions: ['projects.*.*'],
    },
  ];
}
export async function getTopMenuData() {
  return [
    // {
    //   title: 'Dashboard Alpha',
    //   key: 'dashboardAlpha',
    //   url: '/dashboard/alpha',
    //   icon: 'icmn icmn-home',
    // },
    {
      title: <FormattedMessage id="menu.application" />,
      key: 'application',
      icon: 'icmn icmn-file-text2',
      url: '/applications',
    },
    {
      title: <FormattedMessage id="menu.loans" />,
      key: 'loans',
      icon: 'icm icmn-coin-dollar',
      url: '/loans',
    },
    // {
    //   title: 'Withdraws',
    //   key: 'withdraws',
    //   icon: 'icmn icmn-notification',
    //   url: '/withdraws',
    // },
    {
      title: <FormattedMessage id="menu.imports" />,
      key: 'imports',
      icon: 'icm icmn-cloud-upload',
      url: '/importPayment',
    },
    {
      title: <FormattedMessage id="menu.products" />,
      key: 'products',
      icon: 'icmn icmn-library',
      url: '/products',
    },
    {
      title: <FormattedMessage id="menu.policies" />,
      key: 'policies',
      icon: 'icmn icmn-profile',
      url: '/policies',
    },
    // {
    //   title: <FormattedMessage id="menu.roles" />,
    //   key: 'roles',
    //   icon: 'icm icmn-man-woman',
    //   url: '/roles',
    // },
    {
      title: <FormattedMessage id="menu.sales" />,
      key: 'sales',
      icon: 'icm icmn-user-tie',
      url: '/sales',
    },
    {
      title: <FormattedMessage id="menu.adj" />,
      key: 'adj',
      icon: 'icm icmn-calendar',
      url: '/adj',
    },
    {
      title: <FormattedMessage id="menu.statement" />,
      key: 'statement',
      icon: 'icm icmn-calendar',
      url: '/statement',
    },
    {
      title: <FormattedMessage id="menu.report" />,
      key: 'reports',
      icon: 'icm icmn-file-text2',
      url: '/reports',
    },
  ];
}
