import React, { useState } from 'react';
import { Table, Row, Button, Col, Tag } from 'antd';
import { WrappedFormInternalProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataSource, Withdraw, WithdrawStatus } from '../WithdrawsTabs';
import CancelWithdraw from '../WithdrawsModal/CancelWithdraw';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';
import { iso8601ToLocalDate } from '../../../../helpers/date/date';

interface TableProp {
  dataSource: DataSource[];
  patchWithdraws: any;
  loading: boolean;
  push: Push;
  pagination: object;
  onChange: any;
}
const columnsAll = [
  {
    title: <FormattedMessage id="withdraw.column.id" />,
    dataIndex: 'id',
    sorter: (a: any, b: any) => a.id.localeCompare(b.id),
  },
  { title: <FormattedMessage id="withdraw.column.loanId" />, dataIndex: 'loanId' },
  {
    title: <FormattedMessage id="withdraw.column.name" />,
    render: (data: Withdraw) => {
      return `${data.firstName} ${data.lastName}`;
    },
  },
  { title: <FormattedMessage id="company" />, dataIndex: 'companyName' },
  { title: <FormattedMessage id="withdraw.column.productName" />, dataIndex: 'productName' },
  {
    title: <FormattedMessage id="withdraw.column.amount" />,
    dataIndex: 'amount',
    render: (amount: number) => {
      return satangToBath(amount);
    },
  },
  { title: <FormattedMessage id="withdraw.column.bankName" />, dataIndex: 'bankName' },
  { title: <FormattedMessage id="withdraw.column.accountNo" />, dataIndex: 'accountNo' },
  {
    title: <FormattedMessage id="withdraw.column.createDate" />,
    dataIndex: 'createDate',
    render: (date: any) => {
      return iso8601ToLocalDate(date);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.status" />,
    render: (record: any) => (
      <Tag style={{ textAlign: 'center' }} color="processing">
        {record.status}
      </Tag>
    ),
  },
];

const TablePending: React.FC<TableProp> = ({
  dataSource,
  patchWithdraws,
  loading,
  push,
  pagination,
  onChange,
}) => {
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [rows, setSelectedRows] = useState<any>([]);
  const [rowKeys, setRowKeys] = useState<any>([]);
  let formRef: any;
  const intl = useIntl();

  const rowSelection: any = {
    selectedRowKeys: rowKeys,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      const oldRows = rows;
      let newRows = [];
      if (selected === true) {
        newRows = oldRows;
        newRows.push(record);
      }
      if (selected === false) {
        if (oldRows.length > 0) {
          newRows = oldRows.filter((row: any) => row.id !== record.id);
        }
      }
      const rowkeys = newRows.map((row: any) => row.id);
      setRowKeys(rowkeys);
      setSelectedRows(newRows);
    },

    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      const oldRows = rows;
      let newRows = [];
      if (selected === true) {
        newRows = oldRows;
        changeRows.map((row: any) => newRows.push(row));
      }
      if (selected === false) {
        if (oldRows.length > 0) {
          newRows = oldRows.filter(
            (oldRows: any) => !changeRows.find((changeRow: any) => changeRow.id === oldRows.id),
          );
        }
      }
      const rowkeys = newRows.map((row: any) => row.id);
      setRowKeys(rowkeys);
      setSelectedRows(newRows);
    },
  };

  function handleCancel() {
    setCancelModalVisible(false);
  }

  async function handleCreateCancel() {
    const { form } = formRef.props;
    form.validateFields(async (err: any) => {
      if (err) {
        return;
      }
      patchWithdraws(rows, WithdrawStatus.CANCELLED);
      form.resetFields();
      setCancelModalVisible(false);
    });
  }

  async function handlePatch(data: any, status: string) {
    await patchWithdraws(data, status);
    setSelectedRows([]);
  }

  function saveFormRef(formRefs: WrappedFormInternalProps) {
    formRef = formRefs;
  }

  const ActionButtonPanel = () => {
    return (
      <div style={{ marginBottom: '16px' }}>
        <Row gutter={6}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Button
              type="primary"
              disabled={dataSource.length === 0}
              onClick={() => {
                handlePatch(dataSource, WithdrawStatus.DOWNLOADED);
              }}
              loading={loading}
            >
              <FormattedMessage id="withdraw.button.downloadAll" />
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              type="primary"
              disabled={rows.length === 0}
              onClick={() => {
                handlePatch(rows, WithdrawStatus.DOWNLOADED);
              }}
              ghost
              loading={loading}
            >
              <FormattedMessage id="withdraw.button.download" />
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              danger
              disabled={rows.length === 0}
              onClick={() => {
                setCancelModalVisible(true);
              }}
              ghost
              loading={loading}
            >
              <FormattedMessage id="withdraw.button.cancel" />
            </Button>
          </Col>
        </Row>
        <CancelWithdraw
          wrappedComponentRef={saveFormRef}
          visible={cancelModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreateCancel}
          cancelText={intl.formatMessage({ id: 'no' })}
          okText={intl.formatMessage({ id: 'yes' })}
        />
      </div>
    );
  };
  const CheckPermission = renderIfHasPermissisons('withdraws.*.update', <ActionButtonPanel />);
  return (
    <div>
      <CheckPermission />
      <Table
        rowSelection={rowSelection}
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        columns={columnsAll}
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default connect(null, { push })(TablePending);
