import React, { Fragment, useState, useEffect } from 'react';
import { Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import PersonalInfo from './Panel/PersonalInfo';
import SpouseInfo from './Panel/SpouseInfo';
import Address from './Panel/Address';
import EmploymentInfo from './Panel/EmploymentInfo';

interface Props {
  form: FormInstance;
  index: number;
  application: any;
}
const Gurantor: React.FC<Props> = ({ form, index, application }) => {
  let maritalStatusApp: any;
  if (application.guarantors && application.guarantors[index]) {
    maritalStatusApp = application.guarantors[index].maritalStatus;
  }
  const [maritalStatus, setMaritalStatus] = useState<any>(maritalStatusApp);
  const [appState, setAppState] = useState<any>(application);
  // try to use componetWillReceiveProps
  useEffect(() => {
    if (appState !== application) {
      setAppState(application);
      setMaritalStatus(maritalStatusApp);
    }
  });
  const onChangeMaritalStatus = (e: any) => {
    const { value } = e.target;
    if (value) setMaritalStatus(value);
  };

  const isMarried = maritalStatus === 'married' || maritalStatus === 'notregister';
  return (
    <div>
      <PersonalInfo form={form} index={index} onChangeMaritalStatus={onChangeMaritalStatus} />
      {isMarried && (
        <Fragment>
          <SpouseInfo index={index} />
          <Divider dashed />
        </Fragment>
      )}
      <Divider dashed />
      <Address form={form} index={index} />
      <EmploymentInfo form={form} index={index} />
    </div>
  );
};

export default Gurantor;
