import React from 'react';
import { Table } from 'antd';

const SmsStatementTable: React.FC<any> = (props: any) => {
  const { dataSource, loading, rowSelection } = props;
  const column = [
    {
      title: 'Id',
      dataIndex: 'loanId',
      key: 'loanId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ];
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        loading={loading}
        columns={column}
        pagination={{ pageSize: 5000000 }}
      />
    </div>
  );
};

export default SmsStatementTable;
