import React from 'react';
import ApproveInfo from './Panel/ApproveInfo';

interface Props {
  projectId: string;
  application: any;
}
const Approval: React.FC<Props> = ({ projectId, application }) => {
  return (
    <div>
      <ApproveInfo projectId={projectId} application={application} />
    </div>
  );
};

export default Approval;
