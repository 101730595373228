import React, { Fragment, useState, FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import AmortizeTableModal from './AmortizeTableModal';

interface Props {
  projectId: string;
  loanId: string;
  type: 'application' | 'loan';
}
const AmortizeTableButton: FC<Props> = ({ projectId, loanId, type }) => {
  const [amortizeModalVisible, setAmortizeModalVisible] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <Fragment>
      <Button type="primary" ghost onClick={() => setAmortizeModalVisible(true)}>
        <FormattedMessage id="amortizeTable" />
      </Button>

      <AmortizeTableModal
        visible={amortizeModalVisible}
        onCancel={() => setAmortizeModalVisible(false)}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        okText={intl.formatMessage({ id: 'ok' })}
        projectId={projectId}
        loanId={loanId}
        type={type}
      />
    </Fragment>
  );
};

export default AmortizeTableButton;
