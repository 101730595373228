import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import store from 'store';
import { Redirect } from 'react-router-dom';
import Loader from '../components/LayoutComponents/Loader';

const mapStateToProps = (state: any) => {
  return {
    accessToken: state.user.accessToken,
    status: state.user.status,
    organisationId: state.user.organisationId,
    pathname: state.router.location.pathname,
  };
};

export default (Page: ComponentType) => {
  return connect(mapStateToProps)(
    ({
      accessToken,
      organisationId,
      status,
      pathname,
    }: {
      accessToken: string;
      organisationId: string;
      status: string;
      pathname: string;
    }) => {
      const authorized = store.get('app.user.authorized');

      if (!authorized) {
        return <Redirect to={`/users/login?redirect=${pathname}`} />;
      }
      if (authorized && typeof accessToken === 'undefined') {
        return <Loader />;
      }
      // Login but user's already onboarding redirect to home page
      if (authorized && status === 'active' && organisationId !== null) {
        return <Redirect to="/" />;
      }
      return <Page />;
    },
  );
};
