import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import IndexLayout from './layouts/IndexLayout';
import requireVerify from './layouts/requireVerify';
import requireOnboarding from './layouts/requireOnboarding';
import NotFoundPage from './pages/404';
// import Dashboards from './pages/dashboard/alpha';
import Dashboards from './pages/dashboards/index';
import Onboarding from './pages/user/onboarding/Onboarding';
import Login from './pages/user/login/Login';
import Forgot from './pages/user/forgot/Forgot';
import Reset from './pages/user/reset/Reset';
import Register from './pages/user/register/Register';
import Thankyou from './pages/user/thankyou/Thankyou';
import Verify from './pages/user/verify/Verify';
import ListProduct from './pages/product/product-list/ListProduct';
import NewProduct from './pages/product/product-new/NewProduct';
import EditProduct from './pages/product/product-edit/EditProduct';
import ListSale from './pages/sale/sale-list/ListSale';
import NewSale from './pages/sale/sale-new/NewSale';
import EditSale from './pages/sale/sale-edit/EditSale';
import ListRole from './pages/role/role-list/ListRole';
import NewRole from './pages/role/role-new/NewRole';
import EditRole from './pages/role/role-edit/EditRole';
import ListUser from './pages/user/user-list/ListUser';
import InviteUser from './pages/user/user-invite/InviteUser';
import EditUser from './pages/user/user-edit/EditUser';
import AcceptInvite from './pages/user/verify/AcceptInvite';
import ListApplication from './pages/applications/application-list';
import EditApplication from './pages/applications/application-edit';
import NewApplication from './pages/applications/application-new';
import ListLoan from './pages/loans/loan-list';
import EditLoan from './pages/loans/loan-edit';
import WithdrawsTab from './pages/withdraws/WithdrawsTab';
import ImportPayments from './pages/imports/ImportPayments';
import ImportPaymentsTable from './pages/imports/ImportPaymentsTable';
import Adj from './pages/adj/AdjPage';
import Acc from './pages/acc/AccPage';
import Statement from './pages/statement/statementPage';
import StatementPdf from './pages/statement/statementPdfPage';
import ImportApplications from './pages/imports/ImportApplications';
// import Sms from './pages/loans/sms';
import Report from './pages/report/reportPage';
import SmsNotifyApproval from './pages/sms/SmsNotifyApproval';
import SmsNotifyCash from './pages/sms/SmsNotifyCash';
import importApplicationsTable from './pages/imports/ImportApplicationsTable';
import ImportCreditreports from './pages/imports/ImportCreditreports';
import ImportCreditreportsTable from './pages/imports/ImportCreditreportsTable';
import RequestApprovalsTab from './pages/requestapprovals/RequestApprovalsTab';
import ImportDisbursements from './pages/imports/ImportDisbursements';
import ImportDisbursementsTable from './pages/imports/ImportDisbursementsTable';
import SmsHistory from './pages/sms/history/smsHistory';
import SmsLoanClose from './pages/sms/SmsLoanClose';
import SmsStatement from './pages/sms/SmsStatement';
import SmsWaitConfirm from './pages/sms/SmsWaitConfirm';
import ListProducts from './pages/projects/projects-list/ListProjects';
import NewProject from './pages/projects/projects-new/NewProject';
import EditProject from './pages/projects/projects-edit/EditProject';
import SmsEstatement from './pages/sms/SmsEstatement';
import SmsWithdraw from './pages/sms/SmsWithdraw';
import ImportCollectorLoans from './pages/imports/ImportCollectorLoans';
import ImportCollectorLoansTable from './pages/imports/ImportCollectorLoansTable';
import ImportWriteoffs from './pages/imports/ImportWriteoffs';
import ImportWriteoffsTable from './pages/imports/ImportWriteoffsTable';

const routes = [
  // System Pages
  {
    path: '/users/login',
    component: Login,
    exact: true,
  },
  {
    path: '/users/forgot',
    component: Forgot,
    exact: true,
  },
  {
    path: '/users/register',
    component: Register,
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    component: requireOnboarding(Dashboards),
  },
  // On Boarding
  {
    path: '/users/onboarding',
    component: requireVerify(Onboarding),
    exact: true,
  },
  {
    path: '/users/thankyou',
    component: Thankyou,
    exact: true,
  },
  {
    path: '/users/verify/:token',
    component: Verify,
    exact: true,
  },
  // Reset password
  {
    path: '/users/reset/:token',
    component: Reset,
    exact: true,
  },
  // Users
  {
    path: '/policies',
    component: requireOnboarding(ListUser),
    exact: true,
  },
  {
    path: '/policies/new',
    component: requireOnboarding(InviteUser),
    exact: true,
  },
  {
    path: '/users/accept-invite',
    component: AcceptInvite,
    exact: true,
  },
  {
    path: '/policies/:email',
    component: requireOnboarding(EditUser),
    exact: true,
  },
  // Products
  {
    path: '/products',
    component: requireOnboarding(ListProduct),
    exact: true,
  },
  {
    path: '/products/new',
    component: requireOnboarding(NewProduct),
    exact: true,
  },
  {
    path: '/products/:id',
    component: requireOnboarding(EditProduct),
    exact: true,
  },
  // Sales
  {
    path: '/sales',
    component: requireOnboarding(ListSale),
    exact: true,
  },
  {
    path: '/sales/new',
    component: requireOnboarding(NewSale),
    exact: true,
  },
  {
    path: '/sales/:id',
    component: requireOnboarding(EditSale),
    exact: true,
  },
  // Roles
  {
    path: '/roles',
    component: requireOnboarding(ListRole),
    exact: true,
  },
  {
    path: '/roles/new',
    component: requireOnboarding(NewRole),
    exact: true,
  },
  {
    path: '/roles/:id',
    component: requireOnboarding(EditRole),
    exact: true,
  },
  {
    path: '/applications',
    component: requireOnboarding(ListApplication),
    exact: true,
  },
  {
    path: '/applications/new',
    component: requireOnboarding(NewApplication),
    exact: true,
  },
  {
    path: '/applications/:id',
    component: requireOnboarding(EditApplication),
    exact: true,
  },
  // Loan
  {
    path: '/loans',
    component: requireOnboarding(ListLoan),
    exact: true,
  },
  {
    path: '/loans/:id',
    component: requireOnboarding(EditLoan),
    exact: true,
  },
  // Withdraws
  {
    path: '/withdraws',
    component: requireOnboarding(WithdrawsTab),
  },
  // import
  {
    path: '/importPayment',
    component: requireOnboarding(ImportPayments),
    exact: true,
  },
  {
    path: '/import/payments/table',
    component: requireOnboarding(ImportPaymentsTable),
    exact: true,
  },
  {
    path: '/adj',
    component: requireOnboarding(Adj),
    exact: true,
  },
  {
    path: '/acc',
    component: requireOnboarding(Acc),
    exact: true,
  },
  {
    path: '/statement',
    component: requireOnboarding(Statement),
    exact: true,
  },
  {
    path: '/statement/pdf',
    component: requireOnboarding(StatementPdf),
    exact: true,
  },
  {
    path: '/import/applications',
    component: requireOnboarding(ImportApplications),
    exact: true,
  },
  {
    path: '/import/applications/table',
    component: requireOnboarding(importApplicationsTable),
    exact: true,
  },
  {
    path: '/import/payments',
    component: requireOnboarding(ImportPayments),
    exact: true,
  },
  {
    path: '/import/creditreports',
    component: requireOnboarding(ImportCreditreports),
    exact: true,
  },
  {
    path: '/import/creditreports/table',
    component: requireOnboarding(ImportCreditreportsTable),
    exact: true,
  },
  {
    path: '/import/disbursements',
    component: requireOnboarding(ImportDisbursements),
    exact: true,
  },
  {
    path: '/import/disbursements/table',
    component: requireOnboarding(ImportDisbursementsTable),
    exact: true,
  },
  {
    path: '/import/collectorloans',
    component: requireOnboarding(ImportCollectorLoans),
    exact: true,
  },
  {
    path: '/import/collectorloans/table',
    component: requireOnboarding(ImportCollectorLoansTable),
    exact: true,
  },
  {
    path: '/import/writeoffs',
    component: requireOnboarding(ImportWriteoffs),
    exact: true,
  },
  {
    path: '/import/writeoffs/table',
    component: requireOnboarding(ImportWriteoffsTable),
    exact: true,
  },
  {
    path: '/reports',
    component: requireOnboarding(Report),
    exact: true,
  },
  // {
  //   path: '/sms',
  //   component: requireOnboarding(Sms),
  //   exact: true,
  // },
  {
    path: '/sms/notify/approval',
    component: requireOnboarding(SmsNotifyApproval),
    exact: true,
  },
  {
    path: '/sms/notify/cash',
    component: requireOnboarding(SmsNotifyCash),
    exact: true,
  },
  {
    path: '/requestapprovals',
    component: requireOnboarding(RequestApprovalsTab),
    exact: true,
  },
  {
    path: '/sms/history',
    component: requireOnboarding(SmsHistory),
    exact: true,
  },
  {
    path: '/sms/loan/close',
    component: requireOnboarding(SmsLoanClose),
    exact: true,
  },
  {
    path: '/sms/statement',
    component: requireOnboarding(SmsStatement),
    exact: true,
  },
  {
    path: '/sms/waitConfirm',
    component: requireOnboarding(SmsWaitConfirm),
    exact: true,
  },
  {
    path: '/sms/estatement',
    component: requireOnboarding(SmsEstatement),
    exact: true,
  },
  {
    path: '/sms/withdraw',
    component: requireOnboarding(SmsWithdraw),
    exact: true,
  },
  {
    path: '/projects',
    component: requireOnboarding(ListProducts),
    exact: true,
  },
  {
    path: '/projects/new',
    component: requireOnboarding(NewProject),
    exact: true,
  },
  {
    path: '/projects/:id',
    component: requireOnboarding(EditProject),
    exact: true,
  },
];

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <IndexLayout>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route exact path="/users/accept-invite" render={() => <AcceptInvite />} />
        {routes.map(route => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </IndexLayout>
  </ConnectedRouter>
);
Router.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Router;
