import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Form } from '@ant-design/compatible';
import { Row, Col, DatePicker, notification } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import moment from 'moment';
import ChartCard from '../../components/CleanUIComponents/ChartCard';
import { API_URL, postJson } from '../../helpers/api/api';
import { addcomma } from '../../helpers/format/satangToBath';

const { RangePicker } = DatePicker;
const monthEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const ApplicationsInfo: React.FC<any> = ({ form, projectId }) => {
  const { getFieldDecorator } = form;
  const [stackedBarData, setLabels] = useState<any>({
    labels: ['Jan', 'Feb', 'Mar', 'Apr'],
    series: [
      [50, 100, 0, 0],
      [250, 600, 0, 0],
    ],
  });
  const [allApp, setAllApp] = useState<any>('1000');
  const [allOpen, setAllOpen] = useState<any>('150');
  const [allReject, setAllReject] = useState<any>('850');
  const [allWait, setAllWait] = useState<any>('0');
  const [approvalRate, setApprovalRate] = useState<any>('15.00%');

  const stackedBarOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    legend: {
      position: 'bottom',
    },
  };

  const handleSubmit = async (rangeDate: any) => {
    try {
      const applications = await postJson(
        `${API_URL}/projects/${projectId}/dashboards/applications`,
        { rangeDate },
      );
      const { allMonth, allLoanOpen, allReject, allWaitSubmit, total } = applications.data;
      const newSeries = [];
      newSeries.push(allLoanOpen);
      newSeries.push(allWaitSubmit);
      newSeries.push(allReject);
      const mapMonthEn = allMonth.map((month: any) => {
        return monthEn[month - 1];
      });
      const open = allLoanOpen.reduce((a: any, b: any) => a + b, 0);
      const reject = allReject.reduce((a: any, b: any) => a + b, 0);
      const wait = allWaitSubmit.reduce((a: any, b: any) => a + b, 0);
      const datasets = [
        {
          label: 'Confirm',
          backgroundColor: '#0190fe',
          data: allLoanOpen,
        },
        {
          label: 'Wait Confirm',
          backgroundColor: '#46be8a',
          data: allWaitSubmit,
        },
        {
          label: 'Reject',
          backgroundColor: '#ff0',
          data: allReject,
        },
      ];
      const barData = {
        labels: mapMonthEn,
        datasets,
      };
      setLabels(barData);
      setAllApp(addcomma(total));
      setAllOpen(addcomma(open));
      setAllReject(addcomma(reject));
      setAllWait(addcomma(wait));
      const approveRate = total > 0 ? ((Number(open) * 100) / total).toFixed(2) : 0;
      setApprovalRate(`${approveRate}%`);
    } catch (error) {
      notification.error({
        message: "Cannot get application's for date range",
      });
    }
  };

  useEffect(() => {
    const endDate = moment();
    const startDate = moment(endDate).subtract(3, 'month');
    const rangeDate: any = [startDate, endDate];

    handleSubmit(rangeDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className="card" style={{ backgroundColor: 'white' }}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }}>
                <i style={{ marginLeft: '10%' }} className="icmn icmn-file-text2" />{' '}
                <FormattedMessage id="application" />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                style={{ textAlign: 'right' }}
              >
                <Form layout="inline">
                  <Form.Item label={<FormattedMessage id="dashboards.rangetime" />}>
                    {getFieldDecorator('rangeDate')(
                      <RangePicker
                        onCalendarChange={(value: any) => {
                          if (value[1]) handleSubmit(value);
                        }}
                      />,
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 4 }}>
                <ChartCard title="All" amount={allApp} />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 4 }}>
                <ChartCard title="Confirm" amount={allOpen} />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 4 }}>
                <ChartCard title="Wait Confirm" amount={allWait} />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 4 }}>
                <ChartCard title="Reject" amount={allReject} />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                <ChartCard title="Approval Rate" amount={approvalRate} />
              </Col>
            </Row>

            <div className="col-lg-10">
              <div className="mb-5">
                <Bar data={stackedBarData} options={stackedBarOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// export default ApplicationsInfo;
const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
  };
};

const Applications = Form.create<any>()(ApplicationsInfo);
export default connect(mapStateToProps, { push })(Applications);
