import React from 'react';
import { Form, Radio, Input, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import SaleCodeSelect from '../../../common/SaleCodeSelect';
import StatementDateSelect from '../../../common/StatementDateSelect';
import styles from '../../../Application/application.module.scss';
import componentValues from '../../../../../services/utilities/ComponentValue';

interface Prop {}

const LoanInfo: React.FC<Prop> = () => {
  return (
    <div id="loanInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="loanInfo" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="statementDate" />}
            name="statementDate"
            rules={[{ required: true }]}
          >
            <StatementDateSelect />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="dueDate" />}
            name="dueDate"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="saleCode" />} name="saleCode">
            <SaleCodeSelect />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default LoanInfo;
