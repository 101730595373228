import { notification } from 'antd';
import { store } from '../../redux/store';

if (process.env.REACT_APP_USE_MOCK_API === true) {
  require('./mock'); // eslint-disable-line global-require
}

function createHeader(customHeaders) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const headers = customHeaders || defaultHeaders;
  const { user } = store.getState();
  if (typeof user.accessToken !== 'undefined') {
    headers.Authorization = `Bearer ${user.accessToken}`;
  }
  return headers;
}

async function handleError(response) {
  let errorMessage = '';
  let json;
  try {
    json = await response.json();
    // eslint-disable-next-line prefer-destructuring
    errorMessage = json.errorMessage;
  } catch (error) {
    // api does not return error object use default statusText
    errorMessage = `
      Cannot parse response.body as json, statusText is ${response.statusText}`;
  }
  if (!response.ok) {
    const error = new Error(errorMessage);
    if (json) {
      error.code = json.code;
    }
    error.code = error.code || response.status;
    error.response = response;
    throw error;
  }
  return json;
}
const fetchOption = {
  mode: 'cors',
  credentials: 'include',
};

export const API_URL = process.env.REACT_APP_API_URL;

export async function postJson(url, jsonBody) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: createHeader(),
      ...fetchOption,
      body: JSON.stringify(jsonBody),
    });
    const json = await handleError(response);
    return json;
  } catch (error) {
    // error is Error object of shape
    // { code: <http code>, message: <error message>, response: <response obj> }
    throw error;
  }
}

export async function getJson(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: createHeader(),
      ...fetchOption,
    });
    const json = await handleError(response);
    return json;
  } catch (error) {
    // error is Error object of shape
    // { code: <http code>, message: <error message>, response: <response obj> }
    throw error;
  }
}

export async function patchJson(url, jsonBody) {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: createHeader(),
      ...fetchOption,
      body: JSON.stringify(jsonBody),
    });
    const json = await handleError(response);
    return json;
  } catch (error) {
    // error is Error object of shape
    // { code: <http code>, message: <error message>, response: <response obj> }
    throw error;
  }
}

export async function deleteJson(url, jsonBody) {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: createHeader(),
      ...fetchOption,
      body: JSON.stringify(jsonBody),
    });
    const json = await handleError(response);
    return json;
  } catch (error) {
    // error is Error object of shape
    // { code: <http code>, message: <error message>, response: <response obj> }
    throw error;
  }
}

export function showWarning({ code, message }) {
  notification.warning({
    message: `Error ${code}`,
    description: message,
  });
}

export function showError({ code, message }) {
  notification.error({
    message: `Error ${code}`,
    description: message,
  });
}
