import React, { useState, useEffect } from 'react';
import SummaryDisbursements from './SummaryDisbursements';
import DisbursementsTable from './DisbursementsTable';
import { store } from '../../../../redux/store';

const ImportDisbursementsConfirm: React.FC<any> = (props: any) => {
  const [disbursements, setDisbursements] = useState<any>([]);
  const [summary, setSummary] = useState<any>({});
  const [transferredDate, setTransferredDate] = useState<any>({});
  const [pagination, setPagination] = useState<any>([]);

  const disbursement = store.getState().import;

  useEffect(() => {
    setDisbursements(disbursement?.data?.disbursements);
    setSummary(disbursement?.data?.summary);
    setTransferredDate(disbursement?.data?.transferredDate);
    setPagination(disbursement.meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryDisbursements
          disbursements={disbursements}
          summary={summary}
          transferredDate={transferredDate}
        />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <DisbursementsTable
          dataSource={disbursements}
          pagination={pagination}
          transferredDate={transferredDate}
        />
      </div>
    </div>
  );
};

export default ImportDisbursementsConfirm;
