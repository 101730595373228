import { IntlShape } from 'react-intl';
// eslint-disable-next-line import/prefer-default-export
export const getValidator = (intl: IntlShape, validator: string, context?: any) => {
  const telNoValidator = (rule: any, value: string) => {
    if (typeof value === 'undefined' || value === '') return Promise.resolve();
    // should contain number only length equal 10
    const regex = /^[\d]{9,10}$/;
    if (!regex.test(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        intl.formatMessage(
          { id: 'validatemsg.incorrect-format' },
          {
            name: `'${rule.field}'`,
            format: intl.formatMessage({ id: 'validatemsg.format.tel-no' }),
          },
        ),
      );
    }
    return Promise.resolve();
  };
  const registerIdValidator = (rule: any, value: string) => {
    let i = 0;
    let sum = 0;
    const id = value;
    if (id !== undefined && id !== '') {
      for (i = 0, sum = 0; i < 12; i += 1) sum += parseFloat(id.charAt(i)) * (13 - i);
      if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) {
        return Promise.reject(
          intl.formatMessage(
            { id: 'validatemsg.incorrect-format' },
            {
              name: `'${rule.field}'`,
              format: intl.formatMessage({ id: 'validatemsg.format.register-id' }),
            },
          ),
        );
      }
    }
    return Promise.resolve();
  };
  const citizenIdValidator = (rule: any, value: string) => {
    let i = 0;
    let sum = 0;
    const id = value;
    if (id !== undefined && id !== '') {
      for (i = 0, sum = 0; i < 12; i += 1) sum += parseFloat(id.charAt(i)) * (13 - i);
      if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) {
        return Promise.reject(
          intl.formatMessage(
            { id: 'validatemsg.incorrect-format' },
            {
              name: `'${rule.field}'`,
              format: intl.formatMessage({ id: 'validatemsg.format.citizen-id' }),
            },
          ),
        );
      }
    }
    return Promise.resolve();
  };
  const maxBathValidator = (rule: any, value: string) => {
    const { maxAmount } = context;
    let maxBathAmount: any = 0;
    maxBathAmount = maxAmount;
    const amount = Number(value) / 100;
    if (amount > maxBathAmount) {
      return Promise.reject(
        intl.formatMessage(
          { id: 'validatemsg.inputbaht.max-value-exceed' },
          {
            name: `${rule.field}`,
            maxValue: intl.formatNumber(maxBathAmount),
          },
        ),
      );
    }
    return Promise.resolve();
  };
  const minBathValidator = (rule: any, value: string) => {
    const { minAmount } = context;
    let minBathAmount: any = 0;
    minBathAmount = minAmount;
    const amount = Number(value) / 100;
    if (amount < minBathAmount) {
      return Promise.reject(
        intl.formatMessage(
          { id: 'validatemsg.inputbaht.min-value-exceed' },
          {
            name: `${rule.field}`,
            minValue: intl.formatNumber(minBathAmount),
          },
        ),
      );
    }
    return Promise.resolve();
  };

  if (validator === 'telNo') {
    return telNoValidator;
  }
  if (validator === 'registerId') {
    return registerIdValidator;
  }
  if (validator === 'citizenId') {
    return citizenIdValidator;
  }
  if (validator === 'maxBathAmount') {
    return maxBathValidator;
  }
  if (validator === 'minBathAmount') {
    return minBathValidator;
  }
  return () => Promise.resolve();
};
