/**
 * Convert from percent value to decimal value.
 * @param {number} input - Number in percent unit
 * @returns {number} - 2 decimal places value
 */
export function convertPercentToDecimal(input: number): number {
  return input / 100;
}
/**
 * Convert from baht unit to satang unit.
 * @param {number} input - Number in baht unit
 * @returns {number} - Value in satang unit
 */
export function convertBahtToSatang(input: number): number {
  return input * 100;
}
/**
 * Convert from decimal value to percent value.
 * @param {number} input - Decimal number
 * @returns {number} - Value in percent unit
 */
export function convertDecimalToPercent(val: number): number {
  return val * 100;
}
/**
 * Convert from satang unit to baht unit.
 * @param {number} input - Number in satang unit
 * @returns {number} - Value in baht unit
 */
export function convertSatangToBaht(val: number): number {
  return val / 100;
}
