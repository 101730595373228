import React from 'react';
import { Row, Col, Descriptions } from 'antd';
import { FormattedMessage } from 'react-intl';
import ImportButton from './ImportButtonCollectorLoans';

const SummaryCollectorLoans: React.FC<any> = (props: any) => {
  const { summary = undefined, collectorLoans } = props;

  if (collectorLoans === undefined) window.location.href = '#/import/collectorloans';

  return (
    <div>
      <div
        style={{
          backgroundColor: '#ffffff',
          marginBottom: '20px',
        }}
      >
        <Descriptions
          title="applications"
          column={{ lg: 4, md: 3, sm: 2, xs: 1 }}
          size="small"
          bordered
        >
          <Descriptions.Item label={<FormattedMessage id="import.collectorLoans.summary.all" />}>
            {summary && summary.all ? summary.all : 0}
            {'  '}
            <FormattedMessage id="import.collectorLoans.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="import.collectorLoans.summary.normal" />}>
            {summary && summary.normal ? summary.normal : 0}
            {'  '}
            <FormattedMessage id="import.collectorLoans.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.collectorLoans.summary.duplicate" />}
          >
            {summary && summary.duplicate ? summary.duplicate : 0}
            {'  '}
            <FormattedMessage id="import.collectorLoans.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.collectorLoans.summary.missing" />}
          >
            {summary && summary.missing ? summary.missing : 0}
            {'  '}
            <FormattedMessage id="import.collectorLoans.summary.list" />
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="import.collectorLoans.summary.notfound" />}
          >
            {summary && summary.notFound ? summary.notFound : 0}
            {'  '}
            <FormattedMessage id="import.collectorLoans.summary.list" />
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <ImportButton collectorLoans={collectorLoans} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SummaryCollectorLoans;
