import React, { Fragment, useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Divider, Button, Row, Col } from 'antd';
import PersonalInfo from '../../Application/ApplicationForm/DataTabs/KeyData/KeyDataTabs/Guarantor/Panel/PersonalInfo';
import SpouseInfo from '../../Application/ApplicationForm/DataTabs/KeyData/KeyDataTabs/Guarantor/Panel/SpouseInfo';
import Address from '../../Application/ApplicationForm/DataTabs/KeyData/KeyDataTabs/Guarantor/Panel/Address';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { ErrorInfo } from '../../../../types/form-types';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface Props {
  index: number;
  loan: any;
}
const Guarantor: React.FC<Props> = ({ index, loan }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  let maritalStatusApp: any;
  if (loan.guarantors && loan.guarantors[index]) {
    maritalStatusApp = loan.guarantors[index].maritalStatus;
  }
  const [maritalStatus, setMaritalStatus] = useState<any>(maritalStatusApp);
  const [loanState, setLoanState] = useState<any>(loan);
  // try to use componetWillReceiveProps
  useEffect(() => {
    if (loanState !== loan) {
      setLoanState(loan);
      setMaritalStatus(maritalStatusApp);
    }
  });
  const onChangeMaritalStatus = (e: any) => {
    const { value } = e.target;
    if (value) setMaritalStatus(value);
  };
  const SaveButton = renderIfHasPermissisons(
    'loans.*.update',
    <Button type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );

  const isMarried = maritalStatus === 'married' || maritalStatus === 'notregister';
  return (
    <div>
      <Form
        name="guarantor"
        layout="vertical"
        initialValues={loan}
        form={form}
        onFinishFailed={onFinishFailed}
      >
        <PersonalInfo form={form} index={index} onChangeMaritalStatus={onChangeMaritalStatus} />
        {isMarried && (
          <Fragment>
            <SpouseInfo index={index} />
            <Divider dashed />
          </Fragment>
        )}
        <Divider dashed />
        <Address form={form} index={index} />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Guarantor;
