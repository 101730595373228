import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Row, Col, Radio, Button, Divider, Select } from 'antd';
import InputBaht from '../../../../../common/InputBaht';
import { useAddressAutoComplete } from '../../../../../../../helpers/form/fetch-hook';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import { FormInstance } from 'antd/lib/form';
import { getValidator } from '../../../../../../../helpers/validator';

interface Props {
  form: FormInstance;
}
const Address: React.FC<Props> = ({ form }) => {
  const { setFieldsValue, getFieldValue } = form;
  const intl = useIntl();
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();

  function copyFromCurrent() {
    const current = {
      address: getFieldValue(['addressInfo', 'current', 'address']),
      subDistrict: getFieldValue(['addressInfo', 'current', 'subDistrict']),
      district: getFieldValue(['addressInfo', 'current', 'district']),
      province: getFieldValue(['addressInfo', 'current', 'province']),
      postCode: getFieldValue(['addressInfo', 'current', 'postCode']),
    };
    setFieldsValue({
      addressInfo: {
        permanent: current,
      },
    });
  }

  return (
    <Fragment>
      <div id="currentAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="addressInfo.current" />
        </strong>
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'current', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'current', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'current', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'current', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'current', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <FormattedMessage id="yearOfStay" />
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item name={['addressInfo', 'current', 'stayYear']}>
              <Input placeholder={intl.formatMessage({ id: 'year' })} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item name={['addressInfo', 'current', 'stayMonth']}>
              <Input placeholder={intl.formatMessage({ id: 'month' })} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="mobileNo" />}
              name="mobileNo"
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <Form.Item
              label={<FormattedMessage id="mobilePackage" />}
              name={['personalInfo', 'mobilePackage']}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="prepaid">
                  <FormattedMessage id="prepaid" />
                </Radio.Button>
                <Radio.Button value="postpaid">
                  <FormattedMessage id="postpaid" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNo" />}
              name={['addressInfo', 'current', 'phoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNoExt" />}
              name={['addressInfo', 'current', 'phoneNoExt']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="contactTime" />}
              name={['personalInfo', 'contactTime']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<FormattedMessage id="residentType" />}
          name={['addressInfo', 'current', 'residentType']}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="house">
              <FormattedMessage id="residentType.house" />
            </Radio.Button>
            <Radio.Button value="townHouse">
              <FormattedMessage id="residentType.townHouse" />
            </Radio.Button>
            <Radio.Button value="condo">
              <FormattedMessage id="residentType.condo" />
            </Radio.Button>
            <Radio.Button value="apartment">
              <FormattedMessage id="residentType.apartment" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="residentOwner" />}
          name={['addressInfo', 'current', 'residentOwner']}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="own">
              <FormattedMessage id="residentOwner.own" />
            </Radio.Button>
            <Radio.Button value="family">
              <FormattedMessage id="residentOwner.family" />
            </Radio.Button>
            <Radio.Button value="welfare">
              <FormattedMessage id="residentOwner.welfare" />
            </Radio.Button>
            <Radio.Button value="rent">
              <FormattedMessage id="residentOwner.rent" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={<FormattedMessage id="rent" />} name={['addressInfo', 'current', 'rent']}>
          <InputBaht />
        </Form.Item>
      </div>
      <Divider dashed />
      <div id="permanentAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="addressInfo.permanent" />
          <Button style={{ marginLeft: '10px' }} type="primary" onClick={copyFromCurrent}>
            <FormattedMessage id="useCurrentAddress" />
          </Button>
        </strong>
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'permanent', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'permanent', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'permanent', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'permanent', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'permanent', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNo" />}
              name={['addressInfo', 'permanent', 'phoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNoExt" />}
              name={['addressInfo', 'permanent', 'phoneNoExt']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Address;
