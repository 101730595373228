import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useAddressAutoComplete } from '../../../helpers/form/fetch-hook';
import { onFinishFailedHandler } from '../../../helpers/form';
import { ErrorInfo } from '../../../types/form-types';
import styles from './onboarding.module.scss';
import { getValidator } from '../../../helpers/validator';

interface Props {
  next: () => void;
  values: any;
  updateValues: (update: Store) => void;
}

const CompanyInfo: FC<Props> = ({ next, values, updateValues }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();
  const { setFieldsValue } = form;

  const onFinish = (values: Store) => {
    updateValues(values);
    next();
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'onboarding.failed-message', errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={values}
        scrollToFirstError
      >
        <Row gutter={50}>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="organisation.name" />}
              name="companyName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="registerId" />}
              name="registerId"
              hasFeedback
              rules={[{ required: true, len: 13, validator: getValidator(intl, 'registerId') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id="address" />}
              name="address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name="subDistrict"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'subDistrict')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name="district"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'district')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name="province"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'province')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name="postCode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'postCode')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className={styles.button}>
                <FormattedMessage id="next" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CompanyInfo;
