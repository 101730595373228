import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Button, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../common/DateOnlyPicker';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import { ErrorInfo } from '../../../types/form-types';
import { UserState } from '../../../redux/user/user-duck';
import { onFinishFailedHandler } from '../../../helpers/form';

const downloadCsv = require('download-csv');

const StatementForm: FC<PropsFromRedux> = ({ projectId }) => {
  const intl = useIntl();

  const onFinish = async (value: Store) => {
    try {
      const payload = { statementDate: value.statementDate, type: 'csv' };
      const result = await postJson(`${API_URL}/projects/${projectId}/loans/statement`, payload);

      const columns = {
        loanId: 'Loan Id',
        citizenId: 'Citizen Id',
        firstName: 'First Name',
        lastName: 'Last Name',
        productName: 'Product Name',
        loanType: 'Loan Type',
        paymentType: 'Payment Type',
        saleCode: 'Sale Code',
        cfPrincipal: 'Cf Principal',
        cfInterest: 'Cf Interest',
        cfFee: 'Cf Fee',
        outstandingBalance: 'Outstanding Balance',
        minDue: 'Min Due',
      };
      downloadCsv(result.data, columns, `statement_${value.statementDate}`);

      notification.success({
        message: intl.formatMessage({ id: 'statement.success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="utils__title">
            <strong>
              <FormattedMessage id="statementCsv.helmet-title" />
            </strong>
          </div>
        </div>
        <div className="card-body">
          <Form layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label={<FormattedMessage id="statementDate" />}
              name="statementDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="submit" />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatementForm);
