// eslint-disable-next-line import/prefer-default-export
export function applicationStatusToStage(status: string): string {
  switch (status) {
    case 'keying_data':
    case 'wait_verify':
    case 'verifying':
    case 'reprocessing':
      return 'dataentry';

    case 'analyzing':
    case 'wait_guarantor':
      return 'analyst';

    case 'confirming':
    case 'wait_confirm':
      return 'confirming';

    case 'approving':
    case 'reject':
    case 'loan_opened':
      return status;

    default:
      return status;
  }
}
