import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Select, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { patchJson, API_URL, showError } from '../../../../../helpers/api/api';
import { Application } from '../../../../../types/application-types';

const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};
// Convert eg. keying_data => Keying Data
const formatStatus = (status: string) => {
  const words = status.split('_');
  const formattedStatus = words.map(word => capitalize(word)).join(' ');
  return formattedStatus;
};
const nextEligibleStatuses = (currentStatus: string) => {
  switch (currentStatus) {
    case 'keying_data':
    case 'wait_verify':
    case 'verifying':
    case 'reprocessing':
      return ['keying_data', 'wait_verify', 'verifying', 'analyzing'];
    case 'wait_guarantor':
    case 'analyzing':
      return ['wait_guarantor', 'analyzing', 'approving'];
    case 'approving':
      return ['approving', 'confirming', 'wait_confirm'];
    default:
      return ['confirming'];
  }
};
const renderOption = (status: string) => {
  const { Option } = Select;
  return (
    <Option key={status} value={status}>
      {formatStatus(status)}
    </Option>
  );
};

interface Props {
  visible: boolean;
  projectId: string;
  application: Application;
  onCancel: () => void;
  onUpdateComplete: () => void;
}

const SubmitModal: FC<Props> = ({
  visible,
  projectId,
  application,
  onCancel,
  onUpdateComplete,
}) => {
  const [form] = Form.useForm();
  const { status } = application;
  const options = nextEligibleStatuses(status);

  const onFinish = async (value: Store) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}/status`, {
        status: value.status,
      });
      onUpdateComplete();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={<FormattedMessage id="status" />}
          name="status"
          rules={[{ required: true }]}
          initialValue={status}
        >
          <Select>{options.map(option => renderOption(option))}</Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubmitModal;
