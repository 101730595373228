import 'rc-drawer/assets/index.css';
import React from 'react';
import DrawerMenu from 'rc-drawer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuLeft from './MenuLeft';
import MenuTop from './MenuTop';
import { changeSetting } from '../../../redux/settings/settings-duck';

const mapStateToProps = ({ settings }) => ({
  isMenuTop: settings.isMenuTop,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  isLightTheme: settings.isLightTheme,
});

@withRouter
@connect(
  mapStateToProps,
  { changeSetting },
)
class AppMenu extends React.Component {
  toggleOpen = () => {
    const { isMobileMenuOpen, changeSetting } = this.props;
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      );
    changeSetting({
      setting: 'isMobileMenuOpen',
      value: !isMobileMenuOpen,
    });
  };

  render() {
    const { isMenuTop, isMobileMenuOpen, isMobileView, isLightTheme } = this.props;
    const BootstrappedMenu = () => {
      if (isMobileView) {
        return (
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            <MenuLeft />
          </DrawerMenu>
        );
      }
      if (isMenuTop) {
        return <MenuTop />;
      }
      return <MenuLeft />;
    };

    return BootstrappedMenu();
  }
}

export default AppMenu;
