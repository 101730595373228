import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Form, Modal, Row, Col, Input, Checkbox, Table, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import componentValues from '../../../../services/utilities/ComponentValue';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { ErrorInfo } from '../../../../types/form-types';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import { TableRowSelection } from 'antd/lib/table/interface';

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  loans: any;
}

const LawsuitModal: FC<Props> = ({ projectId, visible, onCancel, cancelText, okText, loans }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [loansList, setLoansList] = useState<any>([]);
  const [propertyInputVisible, setPropertyInputVisible] = useState(false);
  const [supportingInputVisible, setSupportingInputVisible] = useState(false);
  const [legalManagerInputVisible, setLegalManagerInputVisible] = useState(false);
  const [managingDirectorInputVisible, setManagingDirectorInputVisible] = useState(false);

  const onFinish = async (value: Store) => {
    const { id, title, firstName, lastName, citizenId } = loans[0];
    const lastPay = new Date(
      Math.max.apply(
        null,
        loansList.map(function(e: any) {
          return e.lastpayDate ? new Date(e.lastpayDate) : new Date(e.createDate);
        }),
      ),
    );

    const values = {
      ...value,
      title,
      firstName,
      lastName,
      loansList,
      asOfDate: moment().format('DD/MM/YYYY'),
      citizenId,
      lastPay: moment(lastPay).format('DD/MM/YYYY'),
      expireDate: moment(lastPay)
        .add(5, 'year')
        .format('DD/MM/YYYY'),
    };
    try {
      await postJson(`${API_URL}/projects/${projectId}/loans/lawsuit`, {
        values,
      });
      onCancel();
      notification.success({
        message: intl.formatMessage({ id: 'download-close-contract.sucess-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const handleCheckboxChange = (checkedValues: any, check: string, setData: any) => {
    const isOtherChecked = checkedValues.includes(check);
    setData(isOtherChecked);
  };

  const columns = [
    {
      title: 'Loan ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Loan Type',
      dataIndex: 'loanType',
      key: 'loanType',
    },
    {
      title: 'OSB',
      dataIndex: 'osb',
      key: 'osb',
      render: (record: any) => {
        return satangToBath(record);
      },
    },
    {
      title: 'Int Rate',
      dataIndex: 'intRate',
      key: 'intRate',
      render: (record: any) => {
        return `${record * 100}%`;
      },
    },
  ];

  const onSelectChange = (selectedRowKeys: number[], selectedRows: any) => {
    setLoansList(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
  } as TableRowSelection<any>;

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="1000px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} initialValues={loans} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="lawsuit" />
          </h2>
        </Row>
        <hr />
        <div>
          <Table rowKey="id" columns={columns} dataSource={loans} rowSelection={rowSelection} />
        </div>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            ที่ทำงาน
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="workplace">
              <Checkbox.Group>
                <Checkbox value="1">ทำงานประจำ</Checkbox>
                <Checkbox value="2">ไม่ได้ทำงาน</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            รายได้
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="income">
              <Checkbox.Group>
                <Checkbox value="1">{'รายได้ > 20,000'}</Checkbox>
                <Checkbox value="2">{'รายได้< 20,000'}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            ทรัพย์สิน
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="property">
              <Checkbox.Group
                onChange={value => {
                  handleCheckboxChange(value, '1', setPropertyInputVisible);
                }}
              >
                <Checkbox value="1">มี</Checkbox>
                <Checkbox value="2">ไม่มี</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            {propertyInputVisible && (
              <Form.Item name="propertyInput">
                <Input placeholder="โปรดระบุ" />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            ภาระหนี้
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="debts">
              <Checkbox.Group>
                <Checkbox value="1">{'ภาระหนี้>100,000'}</Checkbox>
                <Checkbox value="2">{'ภาระหนี้<100,000'}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item label={<FormattedMessage id="ชำระครั้งสุดท้ายเมื่อ" />} name="lastPay">
              <Input />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item label={<FormattedMessage id="ขาดอายุความวันที่" />} name="expireDate">
              <Input />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item label={<FormattedMessage id="ผู้ขออนุมัติ" />} name="approval">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.fullColSpan }}
          >
            <Form.Item name="supporting">
              <Checkbox.Group
                onChange={value => {
                  handleCheckboxChange(value, '7', setSupportingInputVisible);
                }}
              >
                <Checkbox value="1">ใบสมัครสินเชื่อ</Checkbox>
                <Checkbox value="2">สำเนาบัตรประชาชน</Checkbox>
                <Checkbox value="3">สำเนาทะเบียนบ้าน</Checkbox>
                <Checkbox value="4">Statement ของลูกหนี้</Checkbox>
                <Checkbox value="5">หน้าสมุดบัญชีธนาคารของลูกหนี้</Checkbox>
                <Checkbox value="6">การ์ดภาระหนี้แสดงการผ่อนชำระ</Checkbox>
                <Checkbox value="7">อื่นๆ</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            {supportingInputVisible && (
              <Form.Item name="supportingInput">
                <Input placeholder="โปรดระบุ" />
              </Form.Item>
            )}
          </Col>
        </Row>
        {'เหตุผลอื่นๆ เพื่อประกอบการพิจารณา'}
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ width: '425px' }}
              label={<FormattedMessage id="เหตุผลที่ 1" />}
              name="consideration1"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ width: '425px' }}
              label={<FormattedMessage id="เหตุผลที่ 2" />}
              name="consideration2"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ width: '425px' }}
              label={<FormattedMessage id="เหตุผลที่ 3" />}
              name="consideration3"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              style={{ width: '425px' }}
              label={<FormattedMessage id="เหตุผลที่ 4" />}
              name="consideration4"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            Legal Manager
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="legalManager">
              <Checkbox.Group
                onChange={value => {
                  handleCheckboxChange(value, '3', setLegalManagerInputVisible);
                }}
              >
                <Checkbox value="1">เห็นควรอนุมัติ</Checkbox>
                <Checkbox value="2">ไม่เห็นควรอนุมัติ</Checkbox>
                <Checkbox value="3">อื่นๆ</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            {legalManagerInputVisible && (
              <Form.Item name="legalManagerInput">
                <Input placeholder="โปรดระบุ" />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            Managing Director
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="managingDirector">
              <Checkbox.Group
                onChange={value => {
                  handleCheckboxChange(value, '3', setManagingDirectorInputVisible);
                }}
              >
                <Checkbox value="1">เห็นควรอนุมัติ</Checkbox>
                <Checkbox value="2">ไม่เห็นควรอนุมัติ</Checkbox>
                <Checkbox value="3">อื่นๆ</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            {managingDirectorInputVisible && (
              <Form.Item name="managingDirectorInput">
                <Input placeholder="โปรดระบุ" />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default LawsuitModal;
