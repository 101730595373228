import React, { useState, useEffect, Fragment } from 'react';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Upload, Popconfirm, message, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WrappedFormInternalProps } from '@ant-design/compatible/lib/form/Form';
import UploadForm from './UploadForm';
import DocumentTable from './DocumentTable';
import { getJson, patchJson, API_URL, deleteJson } from '../../../../../../helpers/api/api';
import { store } from '../../../../../../redux/store';
import { iso8601ToLocalDate } from '../../../../../../helpers/date/date';
import { applicationStatusToStage } from '../../../../../../helpers/application';
import renderIfHasPermissisons from '../../../../../../layouts/renderIfHasPermissisons';

interface Props {
  application: any;
}
const Document: React.FC<Props> = ({ application }) => {
  const { id } = application;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<any>('');
  const projectId = store.getState().user.currentProjectId;

  const [document, setDocument] = useState<any>([]);

  async function getDataSource() {
    const url = `${API_URL}/projects/${projectId}/documents/application/${id}`;
    const { data } = await getJson(url);
    setDocument(data);
  }

  const column = [
    {
      title: <FormattedMessage id="fileName" />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id="description" />,
      dataIndex: 'description',
    },
    {
      title: <FormattedMessage id="category" />,
      dataIndex: 'category',
      render: (category: string) => {
        let color = '';
        switch (category) {
          case 'application':
            color = 'red';
            break;
          case 'citizenCard':
            color = 'volcano';
            break;
          case 'incomeStatement':
            color = 'orange';
            break;
          case 'consent':
            color = 'yellow';
            break;
          case 'letterOfGuarantee':
            color = 'lime';
            break;
          case 'IncreaseCreditLimitDocuments':
            color = 'green';
            break;
          case 'dissureDocuments':
            color = 'cyan';
            break;
          case 'other':
            color = 'purple';
            break;
          case 'bankbook':
            color = 'cyan';
            break;
          case 'signature':
            color = 'purple';
            break;
          default:
            color = 'magenta';
            break;
        }
        return (
          <span>
            <Tag color={color} key={category}>
              <FormattedMessage id={`documents.${category}`} />
            </Tag>
          </span>
        );
      },
    },
    {
      title: <FormattedMessage id="createDate" />,
      dataIndex: 'createDate',
      render: (date: any) => {
        return iso8601ToLocalDate(date);
      },
    },
    {
      title: '',
      align: 'center',
      key: 'action',
      render: (value: any) => {
        return (
          <span>
            <Popconfirm
              title={<FormattedMessage id="question.areYouSure" />}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              cancelText={<FormattedMessage id="common.no" />}
              okText={<FormattedMessage id="common.yes" />}
              onConfirm={async e => {
                if (e) {
                  const docId = value.id;
                  e.stopPropagation();
                  try {
                    await deleteJson(`${API_URL}/projects/${projectId}/documents`, {
                      id: docId,
                    });
                    getDataSource();
                  } catch (err) {
                    message.error(`${err}`);
                  }
                }
              }}
              onCancel={e => e && e.stopPropagation()}
            >
              <a
                href="#"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <i className="icmn-bin" />
              </a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  let formRef: any;

  const handleCreate = async () => {
    const { form } = formRef.props;
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return;
      }
      const doc = {
        description: values.description,
        category: values.category,
      };
      await patchJson(`${API_URL}/projects/${projectId}/documents/${documentId[0].id}/`, doc);
      form.resetFields();
      setModalVisible(false);
      getDataSource();
    });
  };

  const saveFormRef = (formRefs: WrappedFormInternalProps) => {
    formRef = formRefs;
  };
  const props = {
    name: 'file',
    multiple: true,
    action: `${API_URL}/projects/${projectId}/applications/${id}/documents/`,
    async onChange(info: any) {
      const { response, status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setDocumentId(response.data);

        setModalVisible(true);
      } else if (status === 'error') {
        const { errorMessage } = response;
        message.error(`${info.file.name} ${errorMessage}`);
      }
    },
  };

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stage = applicationStatusToStage(application.status);
  const Uploader = renderIfHasPermissisons(
    [`applications.${stage}.update`],
    <Fragment>
      <Upload.Dragger {...props} name="upload" style={{ margin: '20' }} withCredentials>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          <FormattedMessage id="common.dropzoneUpload" />
        </p>
      </Upload.Dragger>
      <UploadForm
        wrappedComponentRef={saveFormRef}
        visible={modalVisible}
        onCreate={handleCreate}
      />
    </Fragment>,
  );

  return (
    <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
      <div className="card-body">
        <Uploader />
        <br />
        <DocumentTable columns={column} offset={5} keyValue="name" appId={id} document={document} />
      </div>
    </div>
  );
};

export default Document;
