import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';
import WithdrawsTabs from '../../components/CbsComponents/Withdraws/WithdrawsTabs';

const WithdrawsTab: React.FC<WrappedComponentProps> = ({ intl }) => {
  const breadcrumbs = [
    {
      title: 'menu.withdraws',
      href: '/withdraws',
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.withdraws' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.withdraws" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <WithdrawsTabs />;
      </div>
    </Layout.Content>
  );
};

export default injectIntl(WithdrawsTab);
