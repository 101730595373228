import React, { useEffect, useState } from 'react';
import { Input, Divider } from 'antd';
import { getJson, API_URL } from '../../../../helpers/api/api';
import AddNote from './AddNote';
import TableNote from './TableNote';

interface Props {
  loan: any;
  projectId: string;
}

const Note: React.FC<Props> = ({ loan, projectId }) => {
  const { appId } = loan;
  const [notes, setNotes] = useState<any>([]);

  async function getDataSource() {
    if (appId) {
      const url = `${API_URL}/projects/${projectId}/notes?appId=${appId}`;
      const { data } = await getJson(url);
      setNotes(data);
    }
  }

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <div>
      <AddNote loan={loan} projectId={projectId} getDataSource={getDataSource} />
      <Divider dashed />
      <TableNote dataSource={notes} projectId={projectId} getDataSource={getDataSource} />
    </div>
  );
};

export default Note;
