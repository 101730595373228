import React from 'react';
import { Form, Radio, Input, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import SaleCodeSelect from '../../../../../../../common/SaleCodeSelect';
import InputBaht from '../../../../../../../common/InputBaht';
import StatementDateSelect from '../../../../../../../common/StatementDateSelect';
import styles from '../../../../../../application.module.scss';
import componentValues from '../../../../../../../../../services/utilities/ComponentValue';

interface Prop {
  application: any;
}

const LoanInfo: React.FC<Prop> = ({ application }) => {
  const isInstallment = application.paymentType === 'installment';
  return (
    <div id="loanInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="loanInfo" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="requestAmount" />}
            name="requestAmount"
            rules={[{ required: true }]}
          >
            <InputBaht />
          </Form.Item>
        </Col>
        {isInstallment && (
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="installTerm" />}
              name="installTerm"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!isInstallment && (
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              label={<FormattedMessage id="firstDisburseAmount" />}
              name="firstDisburseAmount"
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="statementDate" />}
            name="statementDate"
            rules={[{ required: true }]}
          >
            <StatementDateSelect />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="dueDate" />}
            name="dueDate"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="saleCode" />} name="saleCode">
            <SaleCodeSelect />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default LoanInfo;
