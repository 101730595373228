import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import StatementComponent from '../../components/CbsComponents/Statement/StatmentComponent';

const StatementPage: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.loans',
      href: '/loans',
    },
    {
      title: 'menu.statementCsv',
      href: `/statement`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="statement.helmet-title"
      pageTitle="statement.page-title"
      breadcrumbs={breadcrumbs}
    >
      <StatementComponent />
    </ContentContainer>
  );
};
export default StatementPage;
