import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';

function renderOptions(options: any): JSX.Element[] {
  const { Option } = Select;

  return options.map((option: any) => (
    <Option key={option.value} value={option.value}>
      <FormattedMessage id={`${option.value}`} />
    </Option>
  ));
}

const StatementDateSelect: FC<any> = React.forwardRef((props, ref) => {
  const statementDateOptions = [];
  for (let i = 1; i <= 28; i += 1) {
    statementDateOptions.push({ value: i });
  }
  return (
    <Select showSearch {...props} ref={ref}>
      {renderOptions(statementDateOptions)}
    </Select>
  );
});

export default StatementDateSelect;
