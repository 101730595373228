import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Radio, Input, Row, Col, Divider } from 'antd';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import { getValidator } from '../../../../../../../helpers/validator';

interface Props {}
const ContactPerson: React.FC<Props> = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <div id="contactPerson" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="contactPerson" />
        </strong>
      </div>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="title" />} name={['contactPersons', 0, 'title']}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="นาย">
                <FormattedMessage id="title.mr" />
              </Radio.Button>
              <Radio.Button value="นาง">
                <FormattedMessage id="title.mrs" />
              </Radio.Button>
              <Radio.Button value="นางสาว">
                <FormattedMessage id="title.miss" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstName" />}
            name={['contactPersons', 0, 'firstName']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastName" />}
            name={['contactPersons', 0, 'lastName']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="mobileNo" />}
            name={['contactPersons', 0, 'phoneNo']}
            rules={[{ validator: getValidator(intl, 'telNo') }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="relation" />}
            name={['contactPersons', 0, 'relation']}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="spouse">
                <FormattedMessage id="relation.spouse" />
              </Radio.Button>
              <Radio.Button value="parents">
                <FormattedMessage id="relation.parents" />
              </Radio.Button>
              <Radio.Button value="child">
                <FormattedMessage id="relation.child" />
              </Radio.Button>
              <Radio.Button value="sibling">
                <FormattedMessage id="relation.sibling" />
              </Radio.Button>
              <Radio.Button value="relative">
                <FormattedMessage id="relation.relative" />
              </Radio.Button>
              <Radio.Button value="friend">
                <FormattedMessage id="relation.friend" />
              </Radio.Button>
              <Radio.Button value="colleague">
                <FormattedMessage id="relation.colleague" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider dashed />
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="title" />} name={['contactPersons', 1, 'title']}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="นาย">
                <FormattedMessage id="title.mr" />
              </Radio.Button>
              <Radio.Button value="นาง">
                <FormattedMessage id="title.mrs" />
              </Radio.Button>
              <Radio.Button value="นางสาว">
                <FormattedMessage id="title.miss" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstName" />}
            name={['contactPersons', 1, 'firstName']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastName" />}
            name={['contactPersons', 1, 'lastName']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="mobileNo" />}
            name={['contactPersons', 1, 'phoneNo']}
            rules={[{ validator: getValidator(intl, 'telNo') }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="relation" />}
            name={['contactPersons', 1, 'relation']}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="spouse">
                <FormattedMessage id="relation.spouse" />
              </Radio.Button>
              <Radio.Button value="parents">
                <FormattedMessage id="relation.parents" />
              </Radio.Button>
              <Radio.Button value="child">
                <FormattedMessage id="relation.child" />
              </Radio.Button>
              <Radio.Button value="sibling">
                <FormattedMessage id="relation.sibling" />
              </Radio.Button>
              <Radio.Button value="relative">
                <FormattedMessage id="relation.relative" />
              </Radio.Button>
              <Radio.Button value="friend">
                <FormattedMessage id="relation.friend" />
              </Radio.Button>
              <Radio.Button value="colleague">
                <FormattedMessage id="relation.colleague" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ContactPerson;
