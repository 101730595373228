import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'antd';
import Applications from './applications';
import CashIn from './cashIn';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';

const index: React.FC<any> = () => {
  const breadcrumbs = [
    {
      title: 'menu.dashboards',
      href: '/dashboards',
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title="dashboards" />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.dashboards" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <Applications />
        <CashIn />
      </div>
    </Layout.Content>
  );
};

export default index;
