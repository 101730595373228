import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Button, Input, Row, Col, notification, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import componentValues from '../../../services/utilities/ComponentValue';
import { UserState } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';
import DateOnlyPicker from '../common/DateOnlyPicker';
import SmsWaitConfirmTable from './SmsWaitConfirmTable';

const { TextArea } = Input;
const { Option } = Select;

const SmsWaitConfirmForm: FC<PropsFromRedux> = ({ projectId }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [rows, setSelectedRows] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [disableSend, setDisableSend] = useState<any>(true);

  const { setFieldsValue } = form;

  const rowSelection: any = {
    selectedRowKeys: rows,
    onChange: (selectedRowKeys: any) => {
      setSelectedRows(selectedRowKeys);
      if (selectedRowKeys.length > 0) {
        setDisableSend(false);
      } else {
        setDisableSend(true);
      }
    },
  };
  const onFinish = async (value: Store) => {
    try {
      const body = value;
      body.sendMessages = rows;
      const { fromDate, toDate } = value;
      body.fromDate = fromDate && fromDate !== 'Invalid date' ? fromDate : undefined;
      body.toDate = toDate && toDate !== 'Invalid date' ? toDate : undefined;

      const result = await postJson(`${API_URL}/projects/${projectId}/sms/wait-confirm`, body);
      if (body.sendSMS === 'n') {
        setData(result.data);
        setDisableSend(true);
        setSelectedRows([]);
        notification.success({
          message: intl.formatMessage({ id: 'sms-wait-confirm.search-success' }),
        });
      }
      if (body.sendSMS === 'y') {
        notification.success({
          message: intl.formatMessage({ id: 'sms-wait-confirm.success-message' }),
        });
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SearchButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button type="primary" onClick={() => setFieldsValue({ sendSMS: 'n' })} htmlType="submit">
      <FormattedMessage id="search" />
    </Button>,
  );
  const SendButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button
      style={{ marginLeft: '8px' }}
      type="primary"
      htmlType="submit"
      onClick={() => setFieldsValue({ sendSMS: 'y' })}
      disabled={disableSend}
    >
      <FormattedMessage id="notify-approval.search-button" />
    </Button>,
  );

  const changeStatus = (status: string) => {
    if (status === 'keying_data,reprocessing') {
      setFieldsValue({ message: 'ได้รับใบสมัครสินเชื่อ {productName} {appId} แล้ว' });
    } else if (status === 'verifying,wait_verify') {
      setFieldsValue({ message: 'สินเชื่อ {productName} {appId} อยู่ระหว่างยืนยันตัวตน' });
    } else if (status === 'analyzing,wait_guarantor' || status === 'approving') {
      setFieldsValue({ message: 'สินเชื่อ {productName} {appId} อยู่ระหว่างการพิจารณา' });
    } else if (status === 'wait_confirm' || status === 'confirming') {
      setFieldsValue({
        message:
          'สินเชื่อ {productName} {appId} ได้รับการอนุมัติจำนวนเงิน {approveAmount} บาท กรุณายืนยันเพื่อเปิดสัญญา',
      });
    } else {
      setFieldsValue({ message: 'สินเชื่อ{productName} {appId} อยู่สถานะ {status} แล้ว' });
    }
  };
  return (
    <div className="card">
      <div className="card-body">
        <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                style={{ display: 'inline-block', marginRight: '16px' }}
                label={<FormattedMessage id="startDate" />}
                name="fromDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', marginRight: '16px' }}
                label={<FormattedMessage id="endDate" />}
                name="toDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', marginRight: '16px' }}
                label={<FormattedMessage id="status" />}
                name="status"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '130px' }}
                  onChange={(value: string) => changeStatus(value)}
                >
                  <Option value="keying_data,reprocessing">keying_data</Option>
                  <Option value="verifying,wait_verify">verify</Option>
                  <Option value="analyzing,wait_guarantor">analyzing</Option>
                  <Option value="approving">approving</Option>
                  <Option value="confirming">confirming</Option>
                  <Option value="wait_confirm">wait_confirm</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="sms.format" />}
                name="message"
                rules={[{ required: true }]}
                initialValue="สินเชื่อ{productName} {appId} อยู่สถานะ {status} แล้ว"
              >
                <TextArea autoSize style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'left' }}>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SearchButton />
              </Form.Item>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SendButton />
              </Form.Item>
            </Col>
          </Row>
          <SmsWaitConfirmTable
            dataSource={data}
            pagination={undefined}
            rowSelection={rowSelection}
          />
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({
  projectId: user.currentProjectId,
});
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SmsWaitConfirmForm);
