import React from 'react';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import RequestApprovalsTabs from '../../components/CbsComponents/RequestApprovals/RequestApprovalsTabs';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';

const RequestApprovalsTab: React.FC<WrappedComponentProps> = ({ intl }) => {
  const breadcrumbs = [
    {
      title: 'menu.requestapprovals',
      href: '/requestapprovals',
    },
  ];
  return (
    <Layout.Content>
      <Helmet title={intl.formatMessage({ id: 'menu.requestapprovals' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.requestapprovals" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <RequestApprovalsTabs />
      </div>
    </Layout.Content>
  );
};

export default injectIntl(RequestApprovalsTab);
