import React, { Fragment, useState, FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { API_URL, showError, getJson } from '../../../../helpers/api/api';
import LawsuitModal from './LawsuitModal';

interface Props {
  projectId: string;
  loan: any;
}

const LawsuitButton: FC<Props> = ({ projectId, loan }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lawsuitModalVisible, setPrintModalVisible] = useState<boolean>(false);
  const [loans, setLoans] = useState<[{}]>([{}]);
  const { citizenId } = loan;

  const intl = useIntl();

  const PrintLoan = async () => {
    try {
      setLoading(true);
      const getLoans = await getJson(
        `${API_URL}/projects/${projectId}/loans/${citizenId}/lawsuit-document`,
      );
      const { code, data } = getLoans;
      if (code === 200) {
        setLoans(data);
      }
      setLoading(false);
      setPrintModalVisible(true);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        loading={loading}
        disabled={loading}
        onClick={() => PrintLoan()}
      >
        <FormattedMessage id="lawsuit" />
      </Button>

      <LawsuitModal
        projectId={projectId}
        visible={lawsuitModalVisible}
        onCancel={() => setPrintModalVisible(false)}
        okText={intl.formatMessage({ id: 'submit' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        loans={loans}
      />
    </Fragment>
  );
};

export default LawsuitButton;
