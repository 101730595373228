import React, { useState, useEffect } from 'react';
import ApplicationsTable from './ApplicationsTable';
import { store } from '../../../redux/store';
import SummaryApplications from './SummaryApplications';

const ImportApplicationsConfirm: React.FC<any> = (props: any) => {
  const [applications, setImportApp] = useState<any>([]);
  const [summary, setSummary] = useState<any>({});

  const apps = store.getState().import;

  useEffect(() => {
    setImportApp(apps?.applications?.applications);
    setSummary(apps?.applications?.summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryApplications applications={applications} summary={summary} />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <ApplicationsTable dataSource={applications} />
      </div>
    </div>
  );
};

export default ImportApplicationsConfirm;
