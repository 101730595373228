import React from 'react';
import { Form, Radio, Input, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from '../../../../../../application.module.scss';
import componentValues from '../../../../../../../../../services/utilities/ComponentValue';
import { FormInstance } from 'antd/lib/form';
import { getValidator } from '../../../../../../../../../helpers/validator';
import DateOnlyPicker from '../../../../../../../common/DateOnlyPicker';

interface Prop {
  form: FormInstance;
  index: number;
  onChangeMaritalStatus: any;
}

const PersonalInfo: React.FC<Prop> = ({ form, index, onChangeMaritalStatus }) => {
  const { setFields } = form;
  const intl = useIntl();
  return (
    <div id="personalInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="personalInfo" />}</strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item label={<FormattedMessage id="title" />} name={['guarantors', index, 'title']}>
            <Radio.Group
              buttonStyle="solid"
              onChange={e => {
                const { value } = e.target;
                switch (value) {
                  case 'นาย':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นาย' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Mr' }]);
                    break;
                  case 'นาง':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นาง' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Mrs' }]);
                    break;
                  case 'นางสาว':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นางสาว' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Miss' }]);
                    break;
                }
              }}
            >
              <Radio.Button value="นาย">นาย</Radio.Button>
              <Radio.Button value="นาง">นาง</Radio.Button>
              <Radio.Button value="นางสาว">นางสาว</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="titleEn" />}
            name={['guarantors', index, 'titleEn']}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={e => {
                const { value } = e.target;
                switch (value) {
                  case 'Mr':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นาย' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Mr' }]);
                    break;
                  case 'Mrs':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นาง' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Mrs' }]);
                    break;
                  case 'Miss':
                    setFields([{ name: ['guarantors', index, 'title'], value: 'นางสาว' }]);
                    setFields([{ name: ['guarantors', index, 'titleEn'], value: 'Miss' }]);
                    break;
                }
              }}
            >
              <Radio.Button value="Mr">Mr</Radio.Button>
              <Radio.Button value="Mrs">Mrs</Radio.Button>
              <Radio.Button value="Miss">Miss</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstName" />}
            name={['guarantors', index, 'firstName']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastName" />}
            name={['guarantors', index, 'lastName']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstNameEn" />}
            name={['guarantors', index, 'firstNameEn']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="lastNameEn" />}
            name={['guarantors', index, 'lastNameEn']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="citizenId" />}
            name={['guarantors', index, 'citizenId']}
            rules={[{ validator: getValidator(intl, 'citizenId') }]}
          >
            <Input type="number" prefix={<i className="icmn-credit-card" />} />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="birthDate" />}
            name={['guarantors', index, 'birthDate']}
          >
            <DateOnlyPicker format="DD-MM-YYYY" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item
            label={<FormattedMessage id="maritalStatus" />}
            name={['guarantors', index, 'maritalStatus']}
          >
            <Radio.Group buttonStyle="solid" onChange={onChangeMaritalStatus}>
              <Radio.Button value="single">
                <FormattedMessage id="maritalStatus.single" />
              </Radio.Button>
              <Radio.Button value="married">
                <FormattedMessage id="maritalStatus.married" />
              </Radio.Button>
              <Radio.Button value="notregister">
                <FormattedMessage id="maritalStatus.notregister" />
              </Radio.Button>
              <Radio.Button value="divorced">
                <FormattedMessage id="maritalStatus.divorced" />
              </Radio.Button>
              <Radio.Button value="widow">
                <FormattedMessage id="maritalStatus.widow" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PersonalInfo;
