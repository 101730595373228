import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Row, Col, DatePicker } from 'antd';
import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../../helpers/format/satangToBath';

interface Prop extends FormComponentProps {
  visible: boolean;
  onCancel: any;
  onCreate: any;
  rows: any;
}
interface State {
  yearVisible: boolean;
  summary: any;
}
const TransferWithdraw = Form.create<Prop>({ name: 'form_in_modal' })(
  class extends React.Component<Prop, State> {
    constructor(props: Prop) {
      super(props);
      this.state = { yearVisible: false, summary: {} };
    }

    componentDidMount(): void {
      const { rows } = this.props;
      const summary = this.calculateSummary(rows);
      this.setState({ summary });
    }

    calculateSummary = (rows: any) => {
      const sumAmount =
        rows.length > 0 ? rows.reduce((acc: number, cur: any) => acc + cur.amount, 0) : 0;
      return {
        sumTrans: rows.length,
        sumAmount: satangToBath(sumAmount),
      };
    };

    render() {
      const { visible, onCancel, onCreate } = this.props;
      const { summary } = this.state;

      return (
        <Modal visible={visible} okText="ยืนยัน" width="700px" onCancel={onCancel} onOk={onCreate}>
          <Row>
            <h3>
              <FormattedMessage id="withdraws-list.cimb.transfer" />
            </h3>
          </Row>
          <hr />
          <Row style={{ marginBottom: 12 }}>
            <Col span={24}>
              <h3>กรุณากด ยืนยัน เพื่อทำรายการ</h3>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <h4>จำนวนรายการ: </h4>
            </Col>
            <Col span={18}>
              <h4>{summary.sumTrans}</h4>
            </Col>
            <Col span={6}>
              <h4>จำนวนเงิน: </h4>
            </Col>
            <Col span={18}>
              <h4>{summary.sumAmount}</h4>
            </Col>
          </Row>
        </Modal>
      );
    }
  },
);

export default TransferWithdraw;
