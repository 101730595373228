import React, { FC } from 'react';
import { Button, Modal, Table } from 'antd';

interface Props {
  loan: any;
  visible: boolean;
  onCancel: any;
  addressTable: any;
  editNote: any;
  dataNote: any;
}

const ConfirmNoteModal: FC<Props> = ({
  loan,
  visible,
  onCancel,
  addressTable,
  editNote,
  dataNote,
}) => {
  const columns = [
    {
      title: 'Loan Id',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'App Id',
      dataIndex: 'appId',
      key: 'appId',
      width: '10%',
    },
  ];

  const submitOne = () => {
    const { id, appId } = loan;
    const values = [
      {
        loanId: id,
        appId,
        ...dataNote,
      },
    ];
    editNote(values);
  };

  const submitAll = () => {
    const { address } = addressTable;
    const values: object[] = [];
    address.forEach((data: { id: any; appId: any }) => {
      const { id, appId } = data;
      const value = {
        loanId: id,
        appId,
        ...dataNote,
      };
      values.push(value);
    });
    editNote(values);
  };

  return (
    <Modal
      title="Address"
      width={1000}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} danger ghost>
          ยกเลิก
        </Button>,
        <Button key="submit" onClick={submitOne} type="primary" ghost>
          สัญญาเดียว
        </Button>,
        <Button key="allsubmit" onClick={submitAll} type="primary">
          ทุกสัญญา
        </Button>,
      ]}
    >
      <Table dataSource={addressTable?.address} columns={columns} pagination={false} />
    </Modal>
  );
};

export default ConfirmNoteModal;
