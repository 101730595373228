import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Row, Col, notification } from 'antd';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { Store } from 'antd/lib/form/interface';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';

interface Props {
  visible: boolean;
  onCancel: any;
  cancelText: any;
  okText: any;
  projectId: string;
  loanId: string;
  type: 'application' | 'loan';
}

const AmortizeModal: FC<Props> = ({
  visible,
  onCancel,
  cancelText,
  okText,
  projectId,
  loanId,
  type,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const onFinish = async (value: Store) => {
    try {
      const newValue = value;
      newValue.type = type;
      const dataForAmortizeTable = await postJson(
        `${API_URL}/projects/${projectId}/loans/${loanId}/amortize-table`,
        newValue,
      );
      const { data } = dataForAmortizeTable;
      const { header, table } = data;
      const {
        amountOfLoan,
        intRate,
        installTerm,
        installAmount,
        firstDisburseDate,
        firstDueDate,
      } = header;
      let csvContent: any = 'data:text/csv;charset=utf-8,';
      // eslint-disable-next-line prefer-template
      csvContent += `Amount of Loan,"${amountOfLoan}"\nInterest Rate,${intRate}%\nMonths,${installTerm}\nPayments,"${installAmount}"\nFirst Disbursement Date,"${firstDisburseDate}"\nFirst Due Date,"${firstDueDate}"\n\n`;
      csvContent += `Period,Beginning Balance,Payment,Principal,Interest,Fee,Cumulative Principle,Cumulative Interest,Cumulative Fee,Ending Balance\n`;
      table.map((row: any) => {
        const {
          period,
          beginningBalance,
          payment,
          principal,
          interest,
          fee,
          cumulativePrinciple,
          cumulativeInterest,
          cumulativeFee,
          endingBalance,
        } = row;
        const newRow = `"${period}","${beginningBalance}","${payment}","${principal}","${interest}","${fee}","${cumulativePrinciple}","${cumulativeInterest}","${cumulativeFee}","${endingBalance}"\n`;
        csvContent += newRow;
        return newRow;
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${loanId}_amortizetable.csv`);
      document.body.appendChild(link);
      link.click();
      form.resetFields();
      onCancel();
      notification.success({
        message: intl.formatMessage({ id: 'download-amortize-table.success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };

  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Row>
        <h2>
          <FormattedMessage id="amortizeTable" />
        </h2>
      </Row>
      <hr />
      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="firstDisburseDate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="firstDisburseDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="firstDueDate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="firstDueDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AmortizeModal;
