import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import SmsLoanCloseForm from '../../components/CbsComponents/Sms/SmsLoanCloseForm';

const SmsLoanClose: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'sms-loan-close.page-title',
      href: `/sms/loan/close`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="sms-loan-close.helmet-title"
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsLoanCloseForm />
    </ContentContainer>
  );
};

export default SmsLoanClose;
