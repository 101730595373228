import React from 'react';
import { Row, Col } from 'antd';
import ImportButton from './ImportButtonCreditreports';

const SummaryCreditreport: React.FC<any> = (props: any) => {
  const { creditreports } = props;

  return (
    <div>
      <div
        style={{
          backgroundColor: '#ffffff',
          marginBottom: '20px',
        }}
      >
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <ImportButton creditreports={creditreports} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SummaryCreditreport;
