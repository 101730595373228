import React, { useState, useEffect } from 'react';
import { Tabs, notification, Input } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import qs from 'query-string';
import moment from 'moment';
import styles from '../SearchableTable/searchable-table.module.scss';
import { FormattedMessage } from 'react-intl';
import TablePending from './WithdrawsTables/TablePending';
import TableDownloaded from './WithdrawsTables/TableDownloaded';
import TableTransferred from './WithdrawsTables/TableTransferred';
import TableCancelled from './WithdrawsTables/TableCancelled';
import { store } from '../../../redux/store';
import { iso8601ToLocalDate } from '../../../helpers/date/date';
import { getJson, patchJson, API_URL } from '../../../helpers/api/api';
import { satangToBathNoComma } from '../../../helpers/format/satangToBath';

const downloadCsv = require('download-csv');

export interface DataSource {
  transferId: number;
  loanId: string;
  name: string;
  products: string;
  amount: string;
  bankName: string;
  accountNo: string;
  approvedDate: string;
  approvedBy: string;
  status: string;
}

export enum WithdrawStatus {
  WAIT_PENDING = 'wait_pending',
  TRANSFERRED = 'transferred',
  PENDING = 'pending',
  DOWNLOADED = 'downloaded',
  CANCELLED = 'cancelled',
}

export interface Withdraw {
  id: string;
  projectId: string;
  appId: string;
  loanId: string;
  firstName: string;
  lastName: string;
  type: string;
  productId: string;
  productName: string;
  amount: number;
  netAmount: number;
  stampFee: number;
  bureauFee: number;
  transferFee: number;
  bankName: string;
  accountName: string;
  accountNo: string;
  branchName: string;
  branchId: string;
  bankCode: string;
  status: WithdrawStatus;
  transferredDate: string;
  actionId: string;
  createDate: string;
  createBy: string;
  lastModifyDate: string;
  lastModifyBy: string;
  pgDate: string;
  pgUser: string;
  validPeriod: string;
}

function useData(fetchUrl: string, pageSize: number, page: any): [any, () => void] {
  const [data, setData] = useState({
    loading: true,
    pagination: { current: page, pageSize, total: 0 },
    data: [],
  });
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());
  const reloadData = () => {
    setTimestamp(new Date().getTime());
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const condition = {
          offset: page ? (page - 1) * pageSize : undefined,
          limit: pageSize,
        };
        const response = await getJson(`${fetchUrl}&&${qs.stringify(condition)}`);
        const { total, offset, limit } = response.meta;
        const pagination = {
          current: parseInt(offset, 10) / parseInt(limit, 10) + 1,
          pageSize: parseInt(limit, 10),
          total,
        };
        setData({
          loading: false,
          pagination,
          data: response.data,
        });
      } catch (error) {
        const { code, message } = error;
        notification.error({ message: code, description: message });
        setData({
          loading: false,
          pagination: { current: page, pageSize, total: 0 },
          data: [],
        });
      }
    }
    fetchData();
  }, [fetchUrl, page, pageSize, timestamp]);
  return [data, reloadData];
}

const WithdrawsTabs: React.FC = () => {
  const { Search } = Input;
  const projectId = store.getState().user.currentProjectId;
  const [activeKey, setActiveKey] = useState<string>(WithdrawStatus.PENDING);
  const [status, setStatus] = useState<string>(WithdrawStatus.PENDING);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchterm, setSearchterm] = useState<string>('');

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const fetchUrl = `${API_URL}/projects/${projectId}/withdraws?status=${status}&&searchterm=${searchterm}`;
  const [withdraw, reloadWithdraw] = useData(fetchUrl, pageSize, page);

  const tabPanes = [
    { tab: 'Pending', key: WithdrawStatus.PENDING },
    { tab: 'Downloaded', key: WithdrawStatus.DOWNLOADED },
    { tab: 'Transferred', key: WithdrawStatus.TRANSFERRED },
    { tab: 'Cancelled', key: WithdrawStatus.CANCELLED },
  ];

  const changePage = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    setPage(Number(current));
    setPageSize(Number(pageSize));
  };

  async function patchWithdraws(datas: any, patchStatus: string) {
    try {
      setLoading(true);
      if (patchStatus === WithdrawStatus.DOWNLOADED) {
        const columnName = {
          id: 'Id',
          customId: 'Custom Id',
          fullNameEn: 'Fullname (En)',
          amount: 'Amount',
          bankCode: 'Bank Code',
          accountNo: 'Account No',
          productName: 'Product Name',
          loanId: 'Loan Id',
          fullName: 'Fullname',
          createDate: 'Create Date',
          type: 'Type',
          citizenId: 'Citizen Id',
          creditLimit: 'Creditlimit',
          saleCode: 'Sale Code',
          branchCode: 'Branch Code',
          bankName: 'Bank Name',
          mobileNo: 'Mobile No',
          companyName: 'Company Name',
        };
        const dataCSV = datas.map((data: any) => {
          return {
            id: data.id,
            customId: data.customId,
            fullNameEn:
              data?.firstNameEn != null
                ? `${data.titleEn} ${data.firstNameEn} ${data.lastNameEn}`
                : '',
            EmployeeId: data.employeeId,
            amount: satangToBathNoComma(Number(data.amount)),
            bankCode: data.bankCode,
            accountNo: data.accountNo,
            productName: data.productName,
            loanId: data.loanId,
            fullName: `${data.title} ${data.firstName} ${data.lastName}`,
            createDate: iso8601ToLocalDate(data.createDate),
            type: data.type === 'FD' ? 'First Disburse' : 'Disburse',
            citizenId: data.citizenId,
            creditLimit: satangToBathNoComma(data.creditLimit),
            saleCode: data.saleCode,
            branchCode: `0${data.accountNo.slice(0, 3)}`,
            bankName: data.bankName,
            mobileNo: data.mobileNo,
            companyName: data.companyName,
          };
        });
        downloadCsv(dataCSV, columnName, `withdraw_${moment().format('YYYYMMDD_HHmmss')}`);
      }
      const ids = datas.map((data: any) => {
        return data.id;
      });
      const value = {
        ids,
        status: patchStatus,
      };
      await patchJson(`${API_URL}/projects/${projectId}/withdraws`, value);
      reloadWithdraw();
      setLoading(false);
    } catch (error) {
      const { code, message } = error;
      notification.error({ message: code, description: message });
      setLoading(false);
    }
  }

  async function transferWithdraws(
    datas: any,
    patchStatus: string,
    transferredDate: any,
    type?: string,
  ) {
    try {
      setLoading(true);
      const withdrawsKey = datas.map((data: any) => {
        const { id, amount, loanId } = data;
        return { id, amount, loanId };
      });
      const value = {
        withdrawsKey,
        status: patchStatus,
        transferredDate,
        type,
      };
      await patchJson(`${API_URL}/projects/${projectId}/withdraws/transfer`, value);
      reloadWithdraw();
      setLoading(false);
    } catch (error) {
      const { code, message } = error;
      notification.error({ message: code, description: message });
      setLoading(false);
    }
  }

  const { TabPane } = Tabs;

  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className="card" style={{ backgroundColor: 'white' }}>
            <div className={styles.actionBar}>
              <Search
                placeholder="Search"
                onSearch={() => {
                  reloadWithdraw();
                }}
                style={{ width: '400px', float: 'right' }}
                onChange={e => {
                  setSearchterm(e.target.value);
                }}
                value={searchterm}
              />
              <div style={{ clear: 'both' }} />
            </div>
            <Tabs
              size="large"
              onChange={e => {
                setStatus(e);
                setActiveKey(e);
              }}
              type="line"
              activeKey={activeKey}
            >
              {tabPanes.map(tab => {
                switch (tab.tab) {
                  case 'Pending':
                    return (
                      <TabPane tab={<FormattedMessage id="withdraw.tab.Pending" />} key={tab.key}>
                        <TablePending
                          dataSource={withdraw.data}
                          patchWithdraws={patchWithdraws}
                          loading={loading}
                          pagination={withdraw.pagination}
                          onChange={changePage}
                        />
                      </TabPane>
                    );
                  case 'Downloaded':
                    return (
                      <TabPane
                        tab={<FormattedMessage id="withdraw.tab.Downloaded" />}
                        key={tab.key}
                      >
                        <TableDownloaded
                          dataSource={withdraw.data}
                          patchWithdraws={patchWithdraws}
                          transferWithdraws={transferWithdraws}
                          loading={loading}
                          pagination={withdraw.pagination}
                          onChange={changePage}
                        />
                      </TabPane>
                    );
                  case 'Transferred':
                    return (
                      <TabPane
                        tab={<FormattedMessage id="withdraw.tab.Transferred" />}
                        key={tab.key}
                      >
                        <TableTransferred
                          dataSource={withdraw.data}
                          patchWithdraws={patchWithdraws}
                          loading={loading}
                          pagination={withdraw.pagination}
                          onChange={changePage}
                        />
                      </TabPane>
                    );
                  case 'Cancelled':
                    return (
                      <TabPane tab={<FormattedMessage id="withdraw.tab.Cancelled" />} key={tab.key}>
                        <TableCancelled
                          dataSource={withdraw.data}
                          patchWithdraws={patchWithdraws}
                          loading={loading}
                          pagination={withdraw.pagination}
                          onChange={changePage}
                        />
                      </TabPane>
                    );
                  default:
                    return (
                      <TabPane tab={tab.tab} key={tab.key}>
                        <TablePending
                          dataSource={withdraw.data}
                          patchWithdraws={patchWithdraws}
                          loading={loading}
                          pagination={withdraw.pagination}
                          onChange={changePage}
                        />
                      </TabPane>
                    );
                }
              })}
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithdrawsTabs;
