import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Layout } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import { getJson, API_URL } from '../../../helpers/api/api';
import SaleForm, { Sale } from '../../../components/CbsComponents/SaleForm/SaleForm';

interface EditSaleParams {
  id: string;
}
interface EditSaleProps {
  projectId: string;
}

const EditSale: React.FC<RouteComponentProps<EditSaleParams> &
  EditSaleProps &
  WrappedComponentProps> = ({ projectId, match, intl }) => {
  const { id } = match.params;
  const [data, setData] = useState<Sale>();
  const [teamLeads, setTeamLeads] = useState<string[]>();
  const breadcrumbs = [
    {
      title: 'menu.sales',
      href: '/sales',
    },
    {
      title: id,
      href: `/sales/${id}`,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const sale = await getJson(`${API_URL}/projects/${projectId}/sales/${id}`);
      const list = await getJson(`${API_URL}/projects/${projectId}/sales`);
      setTeamLeads(list.data);
      setData(sale.data);
    };
    fetchData();
  }, [projectId, id]);

  if (!data || !teamLeads) return null;

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={`${intl.formatMessage({ id: 'menu.sales' })} | ${id}`} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.sales" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SaleForm id={match.params.id} teamLeads={teamLeads} saleData={data} />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
  };
};

export default connect(mapStateToProps)(injectIntl(EditSale));
