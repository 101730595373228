import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../helpers/format/satangToBath';

const ImportApplicationsTable: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;
  const column = [
    {
      title: <FormattedMessage id="loan.name" />,
      render: (resolve: any) => {
        return `${resolve.title} ${resolve.firstName} ${resolve.lastName}`;
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="citizenId" />,
      dataIndex: 'citizenId',
      width: '15%',
    },
    {
      title: <FormattedMessage id="accountNo" />,
      dataIndex: 'accountNo',
      width: '15%',
    },
    {
      title: <FormattedMessage id="saleCode" />,
      dataIndex: 'saleCode',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.requestAmount" />,
      dataIndex: 'requestAmount',
      render: (requestAmount: Number) => {
        return satangToBath(Number(requestAmount));
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="mobileNo" />,
      dataIndex: 'mobileNo',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.column.status" />,
      dataIndex: 'mapStatus',
      width: '10%',
    },
  ];
  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={column} />
    </div>
  );
};

export default ImportApplicationsTable;
