import React from 'react';
import ContentContainer from '../../../components/LayoutComponents/ContentContainer';
import SmsForm from '../../../components/CbsComponents/Sms/SmsHistoryForm';
import { useParams } from 'react-router-dom';

const Sms: React.FC = () => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: 'sms.helmet-title',
      href: `/sms`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="sms.helmet-title"
      helmetTitleParams={{ id }}
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsForm />
    </ContentContainer>
  );
};

export default Sms;
