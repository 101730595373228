import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Select, Button, Input, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import { getSmsNotifyApprovalType } from '../../../services/utilities/Choice';
import componentValues from '../../../services/utilities/ComponentValue';
import { UserState } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';
import DateOnlyPicker from '../common/DateOnlyPicker';
import SmsNotifyApprovalTable from './SmsNotifyTable';

const { Option } = Select;
const { TextArea } = Input;

const SmsNotifyApprovalForm: FC<PropsFromRedux> = ({ projectId }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [rows, setSelectedRows] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [disableSend, setDisableSend] = useState<any>(true);

  const { setFieldsValue } = form;
  function renderOptions(type: string, optionKeys: string[]): JSX.Element[] {
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index]} value={optionKeys[index]}>
          <FormattedMessage id={`${type}.${optionKeys[index]}`} />
        </Option>,
      );
    }
    return options;
  }

  const rowSelection: any = {
    selectedRowKeys: rows,
    onChange: (selectedRowKeys: any) => {
      setSelectedRows(selectedRowKeys);
      if (selectedRowKeys.length > 0) {
        setDisableSend(false);
      } else {
        setDisableSend(true);
      }
    },
  };
  const onFinish = async (value: Store) => {
    try {
      const body = value;
      body.applicationIds = rows;

      if (value.type === 'approve') {
        body.type = 'loan_opened';
      }

      const result = await postJson(`${API_URL}/projects/${projectId}/sms/notify-approval`, body);
      if (body.sendSMS === 'n') {
        setData(result.data);
        setDisableSend(true);
        setSelectedRows([]);
        notification.success({
          message: intl.formatMessage({ id: 'notify-approval.search-success' }),
        });
      }
      if (body.sendSMS === 'y') {
        notification.success({
          message: intl.formatMessage({ id: 'notify-approval.success-message' }),
        });
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SearchButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button type="primary" onClick={() => setFieldsValue({ sendSMS: 'n' })} htmlType="submit">
      <FormattedMessage id="search" />
    </Button>,
  );
  const SendButton = renderIfHasPermissisons(
    ['sms.*.create', 'sms.*.update'],
    <Button
      style={{ marginLeft: '8px' }}
      type="primary"
      htmlType="submit"
      onClick={() => setFieldsValue({ sendSMS: 'y' })}
      disabled={disableSend}
    >
      <FormattedMessage id="notify-approval.search-button" />
    </Button>,
  );
  return (
    <div className="card">
      <div className="card-body">
        <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="category" />}
                name="type"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={value => {
                    if (value === 'approve') {
                      setFieldsValue({
                        message:
                          'เรียนคุณ {firstName} {lastName} สินเชื่อ{productName} {loanId} ได้รับการอนุมัติแล้ว เป็นจำนวนเงิน {approveAmount} บาท',
                      });
                    }
                    if (value === 'reject') {
                      setFieldsValue({
                        message:
                          'บริษัทฯไม่สามารถอนุมัติใบสมัครให้ท่านได้ เนื่องจากคุณสมบัติไม่ผ่านเกณฑ์ของบริษัทฯ และไม่เกี่ยวข้องกับข้อมูลเครดิตของท่าน',
                      });
                    }
                  }}
                >
                  {renderOptions('notify-approval-types', getSmsNotifyApprovalType())}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                style={{ display: 'inline-block', marginRight: '16px' }}
                label={<FormattedMessage id="startDate" />}
                name="fromDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block' }}
                label={<FormattedMessage id="endDate" />}
                name="toDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="sms.format" />}
                name="message"
                rules={[{ required: true }]}
              >
                <TextArea autoSize style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'left' }}>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SearchButton />
              </Form.Item>
              <Form.Item name="sendSMS" style={{ display: 'inline-block' }}>
                <SendButton />
              </Form.Item>
            </Col>
          </Row>
          <SmsNotifyApprovalTable
            dataSource={data}
            pagination={undefined}
            rowSelection={rowSelection}
          />
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({
  projectId: user.currentProjectId,
});
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SmsNotifyApprovalForm);
