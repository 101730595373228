import React, { FC } from 'react';
import { Tabs, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import KeyData from './KeyData/KeyData';
import Document from './Document/Document';
import Approval from './Approval/Approval';
// import Ncb from './Ncb/Ncb';
import { API_URL, postJson } from '../../../../../helpers/api/api';
// import NoteTab from './Note/Note';
import Note from './Note/Note';
import Extra from './Extra/Extra';

interface Props {
  projectId: string;
  application: any;
  reloadApplication: () => void;
}

const renderApproveTab = (projectId: string, application: any) => {
  const { TabPane } = Tabs;
  const renderApprovalStatuses = [
    'analyzing',
    'approving',
    'confirming',
    'loan_opened',
    'wait_confirm',
  ];
  return renderApprovalStatuses.includes(application.status) ? (
    <TabPane tab={<FormattedMessage id="loanApproval" />} key="4">
      <Approval projectId={projectId} application={application} />
    </TabPane>
  ) : null;
};
const DataTabs: FC<Props> = ({ application, reloadApplication, projectId }) => {
  const { TabPane } = Tabs;

  // const ncbType = ['checking_ncb', 'analyzing', 'approving', 'confirming', 'loan_opened'];
  // const ncb = ncbType.includes(application.status) ? (
  //   <TabPane tab={<FormattedMessage id="ncb" />} key="3">
  //     <Ncb form={form} application={application} />
  //   </TabPane>
  // ) : null;

  // const renderApprovalStatuses = ['analyzing', 'approving', 'confirming', 'loan_opened'];
  // const ApprovalTab = renderApprovalStatuses.includes(application.status) ? (
  //   <TabPane tab={<FormattedMessage id="loanApproval" />} key="4">
  //     <Approval form={form} application={application} />
  //   </TabPane>
  // ) : null;
  return (
    <Row>
      <Col span={24}>
        <Tabs>
          {renderApproveTab(projectId, application)}
          <TabPane tab={<FormattedMessage id="application-edit.tabname.application" />} key="1">
            <KeyData
              projectId={projectId}
              application={application}
              reloadApplication={reloadApplication}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="application-edit.tabname.document" />} key="5">
            <Document application={application} />
          </TabPane>
          <TabPane tab={<FormattedMessage id="note" />} key="2">
            <Note application={application} />
          </TabPane>
          <TabPane tab={<FormattedMessage id="extra" />} key="3">
            <Extra application={application} />
          </TabPane>
          {/* <TabPane tab={<FormattedMessage id="note" />} key="2">
            <NoteTab application={application} onCreate={handleCreateNote} />
          </TabPane> */}
          {/* {ncb} */}
        </Tabs>
      </Col>
    </Row>
  );
};

export default DataTabs;
