import React, { FC, Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import WriteoffModal from './WriteoffModal';

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
}
const WriteoffButton: FC<Props> = ({ projectId, loan, transactions }) => {
  const intl = useIntl();
  const [writeoffModalVisible, setWriteoffModalVisible] = useState<any>(false);
  const lastTransaction = transactions ? transactions[0] : {};
  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        onClick={() => setWriteoffModalVisible(true)}
      >
        <FormattedMessage id="writeOff" />
      </Button>

      <WriteoffModal
        projectId={projectId}
        visible={writeoffModalVisible}
        onCancel={() => setWriteoffModalVisible(false)}
        okText={intl.formatMessage({ id: 'writeOff' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        lastTransaction={lastTransaction}
        loan={loan}
      />
    </Fragment>
  );
};

export default WriteoffButton;
// const WriteoffLoanButton: React.FC<any> = (props: any) => {
//   let formRef: any;
//   const { intl, loanId, loans, transactions } = props;
//   const lastTransaction = transactions ? transactions[0] : {};
//   const projectId = store.getState().user.currentProjectId;
//   const [WithdrawModalVisible, setWithdrawModalVisible] = useState<any>(false);
//   const [calculateField, setCalculateField] = useState<any>({
//     principal: '0',
//     interest: '0',
//     fee: '0',
//     osb: '0',
//   });
//   function toggleWithdrawModal() {
//     setWithdrawModalVisible(!WithdrawModalVisible);
//   }

//   async function handleCreate() {
//     const { form } = formRef.props;
//     form.validateFields(async (err: any, value: any) => {
//       if (err) {
//         message.error(err);
//       } else {
//         try {
//           const { loans } = props;
//           const { intRate } = loans;
//           const values = {
//             loanId,
//             ...value,
//             intRate,
//           };
//           const type = 'writeoff';
//           await postJson(`${API_URL}/projects/${projectId}/request-approvals`, { values, type });
//           form.resetFields();
//           setWithdrawModalVisible(false);
//           setCalculateField({
//             principal: '0',
//             interest: '0',
//             fee: '0',
//             osb: '0',
//           });
//           message.success('create request approval success');
//         } catch (err) {
//           console.log('err: ', err);
//           message.warning(`${err}`);
//         }
//       }
//     });
//   }

//   function saveFormRef(formRefs: WrappedFormInternalProps) {
//     formRef = formRefs;
//   }

//   return (
//     <Fragment>
//       <Button
//         style={{
//           marginLeft: '8px',
//         }}
//         type="primary"
//         ghost
//         onClick={toggleWithdrawModal}
//       >
//         <FormattedMessage id="writeOff" />
//       </Button>

//       <WriteoffModal
//         wrappedComponentRef={saveFormRef}
//         visible={WithdrawModalVisible}
//         onCancel={toggleWithdrawModal}
//         onCreate={handleCreate}
//         okText={intl.formatMessage({ id: 'writeOff' })}
//         cancelText={intl.formatMessage({ id: 'cancel' })}
//         lastTransaction={lastTransaction}
//         loan={loans}
//         calculateField={calculateField}
//         setCalculateField={setCalculateField}
//       />
//     </Fragment>
//   );
// };

// export default WriteoffLoanButton;
