import React, { FC } from 'react';
import RoleForm from '../../../components/CbsComponents/RoleForm/RoleForm';

const NewRole: React.FC = () => {
  // const [scopes, setscopes] = useState<string[]>();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const projectId = store.getState().user.currentProjectId;
  //     const scopeValue = await getJson(`${API_URL}/projects/${projectId}/roles/scopes`);
  //     setscopes(scopeValue.data);
  //   };
  //   fetchData();
  // }, []);

  // if (!scopes) return null;
  const scopes = ['applications.*.*'];
  return (
    <div>
      <RoleForm scopesData={scopes} />
    </div>
  );
};

export default NewRole;
