import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Divider, Row, Col, Button, Radio } from 'antd';
import CurrentPermanentAddress from './Panel/CurrentPermanentAddress';
import CompanyAddress from './Panel/CompanyAddress';
import componentValues from '../../../../services/utilities/ComponentValue';
import styles from '../../Application/application.module.scss';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { ErrorInfo } from '../../../../types/form-types';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface Props {
  loan: any;
}

const General: React.FC<Props> = ({ loan }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SaveButton = renderIfHasPermissisons(
    'loans.*.update',
    <Button type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );
  return (
    <div className="card-body">
      <Form
        name="addressForm"
        layout="vertical"
        initialValues={loan}
        form={form}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: componentValues.fullColSpan }}>
            <strong className={styles.title}>
              <FormattedMessage id="statementAddress" />
            </strong>
            <Form.Item
              label={<FormattedMessage id="statementAddress" />}
              name="statementAddress"
              rules={[{ required: true }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="current">
                  <FormattedMessage id="addressInfo.current" />
                </Radio.Button>
                <Radio.Button value="permanent">
                  <FormattedMessage id="addressInfo.permanent" />
                </Radio.Button>
                <Radio.Button value="company">
                  <FormattedMessage id="addressInfo.company" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider dashed />
        <CurrentPermanentAddress form={form} />
        <Divider dashed />
        <CompanyAddress form={form} />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default General;
