import React, { useState } from 'react';
import { Table, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../helpers/date/date';

const { Search } = Input;

const PaymentsTable: React.FC<any> = (props: any) => {
  const { dataSource = [], loading } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (value: any) => {
    setSearchQuery(value);
  };

  const filteredData = dataSource.filter((item: any) => {
    if (searchQuery.length === 0) return true;
    return item.fullname.toString().includes(searchQuery);
  });

  const statusSet = new Set<string>();
  dataSource.forEach((item: any) => statusSet.add(item.status));
  const uniqueStatuses = Array.from(statusSet);

  const column = [
    {
      title: <FormattedMessage id="import.payments.loanId" />,
      dataIndex: 'loanId',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.payments.name" />,
      dataIndex: 'fullname',
      width: '20%',
    },
    {
      title: <FormattedMessage id="import.payments.transdate" />,
      dataIndex: 'transDate',
      render: (transDate: string) => {
        return iso8601ToLocalDate(transDate);
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.payments.code" />,
      dataIndex: 'code',
      width: '7%',
    },
    {
      title: <FormattedMessage id="import.payments.bankCode" />,
      dataIndex: 'bankCode',
      width: '10%',
    },
    {
      title: <FormattedMessage id="import.payments.amount" />,
      dataIndex: 'amount',
      render: (amount: number) => {
        return satangToBath(amount);
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.payments.status" />,
      dataIndex: 'status',
      width: '15%',
      filters: uniqueStatuses.map(item => {
        return { text: item, value: item };
      }),
      onFilter: (value: any, record: any) => record.status.includes(value),
    },
  ];
  return (
    <div>
      <Search
        placeholder="Search name"
        onSearch={handleSearch}
        style={{ marginBottom: 20, width: 400 }}
      />
      <Table dataSource={filteredData} loading={loading} columns={column} />
    </div>
  );
};

export default PaymentsTable;
