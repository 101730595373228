import React, { useState, useEffect } from 'react';
import WriteoffsTable from './WriteoffsTable';
import { store } from '../../../../redux/store';
import SummaryWriteoffs from './SummaryWriteoffs';

const ImportWriteoffsConfirm: React.FC<any> = (props: any) => {
  const [writeoffs, setImportWriteOffs] = useState<any>([]);
  const [summary, setSummary] = useState<any>({});

  const dataStore = store.getState().import;

  useEffect(() => {
    setImportWriteOffs(dataStore?.writeoffs?.writeoffs);
    setSummary(dataStore?.writeoffs?.summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryWriteoffs writeoffs={writeoffs} summary={summary} />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <WriteoffsTable dataSource={writeoffs} />
      </div>
    </div>
  );
};

export default ImportWriteoffsConfirm;
