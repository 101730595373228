import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { satangToBath } from '../../../../../../../helpers/format/satangToBath';

const DebtSummary: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;

  const columns: any = [
    {
      title: <FormattedMessage id="loan.id" />,
      key: 'id',
      render: (record: any) => {
        return record.id;
      },
    },
    {
      title: <FormattedMessage id="product" />,
      key: 'productName',
      render: (record: any) => {
        return record.productName;
      },
    },
    {
      title: <FormattedMessage id="paymentType" />,
      key: 'paymentType',
      render: (record: any) => {
        return record.paymentType;
      },
    },
    {
      title: <FormattedMessage id="creditLimit" />,
      key: 'creditLimit',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.creditLimit);
      },
    },
    {
      title: <FormattedMessage id="osb" />,
      key: 'osb',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.osb);
      },
    },
    {
      title: <FormattedMessage id="cfPrincipal" />,
      key: 'cfPrincipal',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfPrincipal);
      },
    },
    {
      title: <FormattedMessage id="cfInterest" />,
      key: 'cfInterest',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfInterest);
      },
    },
    {
      title: <FormattedMessage id="cfFee" />,
      key: 'cfFee',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfFee);
      },
    },
    {
      title: <FormattedMessage id="minDue" />,
      key: 'minDue',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.minDue);
      },
    },
    {
      title: <FormattedMessage id="status" />,
      key: 'status',
      render: (record: any) => {
        return record.status;
      },
    },
    {
      title: <FormattedMessage id="debtSummary.overdue" />,
      key: 'b',
      align: 'right',
      render: (record: any) => {
        return record.b;
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource ? dataSource.map((data: any) => ({ ...data, key: data.id })) : []}
        loading={loading}
        columns={columns}
      />
    </div>
  );
};

export default DebtSummary;
