import React from 'react';
import { Row, Col, Input, Form, Button, notification } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ErrorInfo } from '../../../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../../../helpers/form';
import { API_URL, patchJson, showError } from '../../../../../../helpers/api/api';

interface Props {
  application: any;
}

interface PropsItem {
  extra: string;
}

const ItemBox: React.FC<PropsItem> = ({ extra }) => {
  return (
    <div>
      <Row>
        <FormattedMessage id={extra} />
      </Row>
      <Row>
        <Col className="gutter-row" span={24}>
          <Form.Item name={extra}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const Extra: React.FC<Props> = ({ application }) => {
  const { projectId } = application;

  const [form] = Form.useForm();
  const intl = useIntl();

  const onFinish = async (values: any) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}`, values);
      notification.success({
        message: intl.formatMessage({ id: 'application-edit.save-success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={application}
      >
        <Row>
          <Col className="gutter-row" span={11}>
            <ItemBox extra="extra1" />
          </Col>
          <Col className="gutter-row" span={2} />
          <Col className="gutter-row" span={11}>
            <ItemBox extra="extra2" />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" span={11}>
            <ItemBox extra="extra3" />
          </Col>
          <Col className="gutter-row" span={2} />
          <Col className="gutter-row" span={11}>
            <ItemBox extra="extra4" />
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" span={11}>
            <ItemBox extra="extra5" />
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Extra;
