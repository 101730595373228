import React, { useState, useEffect } from 'react';
import CollectorLoansTable from './CollectorLoansTable';
import { store } from '../../../../redux/store';
import SummaryCollectorLoans from './SummaryCollectorLoans';

const ImportCollectorLoansConfirm: React.FC<any> = (props: any) => {
  const [collectorLoans, setImportCollectorLoans] = useState<any>([]);
  const [summary, setSummary] = useState<any>({});

  const dataStore = store.getState().import;

  useEffect(() => {
    setImportCollectorLoans(dataStore?.collectorLoans?.collectorLoans);
    setSummary(dataStore?.collectorLoans?.summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <SummaryCollectorLoans collectorLoans={collectorLoans} summary={summary} />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <CollectorLoansTable dataSource={collectorLoans} />
      </div>
    </div>
  );
};

export default ImportCollectorLoansConfirm;
