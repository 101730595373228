import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Row, Col, Button, Input } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import componentValues from '../../../../services/utilities/ComponentValue';
import { store } from '../../../../redux/store';
import { FormattedMessage } from 'react-intl';

interface Prop {
  form: WrappedFormUtils;
  handleSubmitReport: any;
}
const UpdateCreditreportReport: React.FC<Prop> = ({ form, handleSubmitReport }) => {
  const { getFieldDecorator } = form;
  const { email } = store.getState().user;
  return (
    <div>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="loan.id" />}>
            {getFieldDecorator('loanId', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="startDate" />}>
            {getFieldDecorator('startMonth', {
              rules: [
                {
                  required: true,
                  message: 'please select fromMonth',
                },
              ],
            })(<DatePicker picker="month" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="endDate" />}>
            {getFieldDecorator('endMonth', {
              rules: [
                {
                  required: true,
                  message: 'please select toMonth',
                },
              ],
            })(<DatePicker picker="month" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button style={{ marginLeft: 8 }} type="primary" onClick={handleSubmitReport}>
            {<FormattedMessage id="comon.sendTo" />}
            &nbsp;
            {`${email}`}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateCreditreportReport;
