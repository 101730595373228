import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Input, Tabs } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useUrlState, useData } from '../../../../helpers/form/fetch-hook';
import { UserState } from '../../../../redux/user/user-duck';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';
import { API_URL } from '../../../../helpers/api/api';
import DefaultTable from './Tables/DefaultTable';
import RejectTable from './Tables/RejectTable';
import ConfirmTable from './Tables/ConfirmTable';
import WaitConfirmTable from './Tables/WaitConfirmTable';
import styles from '../../SearchableTable/searchable-table.module.scss';

interface Props {}
const ApplicationList: FC<Props & PropsFromRedux> = ({ projectId }) => {
  const intl = useIntl();
  const [urlState, patchUrlState] = useUrlState('/applications');
  const [searchterm, setSearchterm] = useState(urlState.searchterm);
  const [pageSize, setPageSize] = useState<number>(10);
  const [data, reloadData] = useData(`${API_URL}/projects/${projectId}/applications`, pageSize);

  const sanitizeStatus = (status: string | string[] | null | undefined) => {
    const validStatuses = [
      '*',
      'keying_data,reprocessing',
      'verifying,wait_verify',
      'analyzing,wait_guarantor',
      'approving',
      'confirming',
      'wait_confirm',
      'loan_opened',
      'reject',
    ];
    if (typeof status !== 'string') {
      return '*';
    }
    return validStatuses.includes(status) ? status : '*';
  };
  const tabPanes = [
    { tab: 'ALL', key: '*' },
    { tab: 'KEY DATA', key: 'keying_data,reprocessing' },
    { tab: 'VERIFY', key: 'verifying,wait_verify' },
    { tab: 'ANALYZE', key: 'analyzing,wait_guarantor' },
    { tab: 'APPROVE', key: 'approving' },
    { tab: 'CONFIRM', key: 'confirming' },
    { tab: 'WAIT CONFIRM', key: 'wait_confirm' },
    { tab: 'OPEN', key: 'loan_opened' },
    { tab: 'REJECT', key: 'reject' },
  ];
  const { Search } = Input;
  const { TabPane } = Tabs;
  const changePage = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    patchUrlState({ page: current });
    setPageSize(Number(pageSize));
  };
  const NewApplicationButton = renderIfHasPermissisons(
    ['applications.dataentry.create'],
    <Link to="/applications/new">
      <Button type="primary" icon={<PlusCircleOutlined />}>
        {intl.formatMessage({ id: 'add' })}
      </Button>
    </Link>,
  );
  return (
    <section className="card">
      <div className="card-body">
        <div className={styles.actionBar}>
          <NewApplicationButton />
          <Search
            placeholder={intl.formatMessage({ id: 'application-list.search-fields' })}
            onSearch={(value: any) => {
              patchUrlState({ page: 1, searchterm: value, status: '*' });
            }}
            style={{ width: '400px', float: 'right' }}
            onChange={e => setSearchterm(e.target.value)}
            value={searchterm}
          />
          <div style={{ clear: 'both' }} />
        </div>
        <Tabs
          size="large"
          onChange={value => {
            setSearchterm(undefined);
            patchUrlState({ page: 1, status: value, searchterm: undefined });
          }}
          type="line"
          activeKey={sanitizeStatus(urlState.status)}
        >
          {tabPanes.map(tab => {
            switch (tab.tab) {
              case 'ALL':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <DefaultTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                      reloadData={reloadData}
                    />
                  </TabPane>
                );
              case 'CONFIRM':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <ConfirmTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                      reloadData={reloadData}
                    />
                  </TabPane>
                );
              case 'WAIT CONFIRM':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <WaitConfirmTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                      reloadData={reloadData}
                    />
                  </TabPane>
                );
              case 'REJECT':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <RejectTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                      reloadData={reloadData}
                    />
                  </TabPane>
                );
              default:
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <DefaultTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                      reloadData={reloadData}
                    />
                  </TabPane>
                );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
};

const mapStateToProps = ({ user }: { user: UserState; settings: any }) => {
  return {
    projectId: user.currentProjectId,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ApplicationList);
