import React from 'react';
import { Table, message } from 'antd';
import { getJson, API_URL } from '../../../../../../helpers/api/api';
import { store } from '../../../../../../redux/store';

interface DocumentTableProps {
  offset: number;
  // fetchUrl: string;
  keyValue: string;
  columns: any;
  appId: string;
  document: any;
}

const DocumentTable: React.FC<DocumentTableProps> = (props: DocumentTableProps) => {
  const { columns, appId, document } = props;
  const projectId = store.getState().user.currentProjectId;
  return (
    <div>
      <Table
        onRow={(record: any) => {
          return {
            onClick: async () => {
              try {
                const { data } = await getJson(
                  `${API_URL}/projects/${projectId}/documents/${record.id}/applications/${appId}`,
                );
                window.open(data, '_blank');
              } catch (err) {
                message.error(`${err}`);
              }
            },
          };
        }}
        columns={columns}
        dataSource={document}
        // loading={fetchData.loading}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default DocumentTable;
