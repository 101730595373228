import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Row, Col, Divider, Input } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { UserState } from '../../../redux/user/user-duck';
import { onFinishFailedHandler } from '../../../helpers/form';
import { ErrorInfo } from '../../../types/form-types';
import styles from './onboarding.module.scss';

interface Props {
  values: Store;
  resendOtp: () => void;
  otpData: any;
  prev: () => void;
  verifyOtp: (otps: string, ref: string) => void;
}
const VerifyOtp: FC<Props & PropsFromRedux> = ({
  values,
  resendOtp,
  otpData,
  prev,
  verifyOtp,
  user,
}) => {
  const intl = useIntl();

  const onFinish = (values: Store) => {
    verifyOtp(values.otp, otpData.data.ref);
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'onboarding.failed-message', errorInfo);
  };
  return (
    <div>
      <h5>
        <strong>{<FormattedMessage id="onboarding.company-information" />}:</strong>
      </h5>
      <Row gutter={50}>
        <Col span={12}>
          <strong>{<FormattedMessage id="organisation.name" />}: </strong>
          {values.companyName}
          <br />
          <strong>{<FormattedMessage id="registerId" />}: </strong>
          {values.registerId}
        </Col>
        <Col span={12}>
          <strong>{<FormattedMessage id="address" />}: </strong>
          {values.address}
          <Row gutter={50}>
            <Col span={12}>
              <strong>{<FormattedMessage id="subDistrict" />}: </strong>
              {values.subDistrict}
              <br />
              <strong>{<FormattedMessage id="province" />}: </strong>
              {values.province}
            </Col>
            <Col span={12}>
              <strong>{<FormattedMessage id="district" />}: </strong>
              {values.district}
              <br />
              <strong>{<FormattedMessage id="postCode" />}: </strong>
              {values.postCode}
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Divider type="horizontal" />
      <br />
      <h5>
        <strong>{<FormattedMessage id="onboarding.admin-information" />}:</strong>
      </h5>
      <Row gutter={50}>
        <Col span={12}>
          <strong>{<FormattedMessage id="email" />}: </strong>
          {values.email}
          <br />
          <strong>{<FormattedMessage id="mobileNo" />}: </strong>
          {values.mobileNo}
        </Col>
        <Col span={12}>
          <strong>{<FormattedMessage id="firstName" />}: </strong>
          {values.firstName}
          <br />
          <strong>{<FormattedMessage id="lastName" />}: </strong>
          {values.lastName}
        </Col>
      </Row>
      <br />
      <div style={{ marginRight: 10 }}>
        <strong>Ref No: </strong>
        {otpData.ref}
      </div>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="OTP No:"
                  labelCol={{
                    span: 5,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  name="otp"
                  rules={[{ required: true, len: 6 }]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={10} lg={5}>
                <Form.Item
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Button onClick={() => resendOtp()} className={styles.button}>
                    <FormattedMessage id="resend-otp" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />

        <Row>
          <Col xs={24} sm={24} md={3} lg={3} style={{ marginRight: 10 }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
                loading={user.loading}
                disabled={user.loading}
              >
                <FormattedMessage id="confirm" />
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} style={{ marginRight: 10 }}>
            <Form.Item>
              <Button
                className={styles.button}
                onClick={() => prev()}
                loading={user.loading}
                disabled={user.loading}
              >
                <FormattedMessage id="previous" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ user });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VerifyOtp);
