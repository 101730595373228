import React, { useState } from 'react';
import { Table, Row, Button, Col, Descriptions, Tag } from 'antd';
import { WrappedFormInternalProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { DataSource, RequestApprovalStatus } from '../RequestApprovalsTabs';
import ApproveRequestApproval from '../RequestApprovalsModals/ApproveRequestApproval';
import CancelRequestApproval from '../RequestApprovalsModals/CancelRequestApproval';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../../helpers/date/date';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface TableProp {
  dataSource: DataSource[];
  patchRequestApprovals: any;
  loading: boolean;
  push: Push;
}

const columnsAll = [
  {
    title: <FormattedMessage id="withdraw.column.id" />,
    dataIndex: 'id',
    sorter: (a: any, b: any) => a.id.localeCompare(b.id),
  },
  {
    title: <FormattedMessage id="requestApproval.tab.appId" />,
    dataIndex: 'appId',
  },
  { title: <FormattedMessage id="import.payments.loanId" />, dataIndex: 'loanId' },
  {
    title: <FormattedMessage id="withdraw.column.name" />,
    render: (data: any) => {
      return `${data.firstName} ${data.lastName}`;
    },
  },
  { title: <FormattedMessage id="citizenId" />, dataIndex: 'citizenId' },
  { title: <FormattedMessage id="requestApproval.tab.type" />, dataIndex: 'type' },
  {
    title: <FormattedMessage id="withdraw.column.amount" />,
    render: (record: any) => {
      return satangToBath(record.context.writeoffAmount || 0);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.effectiveDate" />,
    render: (record: any) => {
      return iso8601ToLocalDate(record.context.effectiveDate);
    },
  },
  {
    title: <FormattedMessage id="withdraw.column.status" />,
    render: (record: any) => (
      <div>
        <Tag style={{ textAlign: 'center' }} color="processing">
          {record.status}
        </Tag>
      </div>
    ),
  },
];

const restructureType: any = {
  debtRestructuring: 'Debt Restructuring',
  sam: 'SAM',
  settlementAgreement: 'Settlement Agreement',
  defaultJudgement: 'Default Judgement',
};

const TablePending: React.FC<TableProp> = ({ dataSource, patchRequestApprovals, loading }) => {
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rows, setSelectedRows] = useState<any>([]);
  let formRef: any;

  const rowSelection: any = {
    onChange: (_: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  function handleCancel() {
    setCancelModalVisible(false);
    setApproveModalVisible(false);
  }

  async function handleCreateReject() {
    const { form } = formRef.props;
    form.validateFields(async (err: any) => {
      if (err) {
        return;
      }
      patchRequestApprovals(rows, RequestApprovalStatus.REJECT);
      form.resetFields();
      setCancelModalVisible(false);
    });
  }

  async function handleCreateApprove() {
    const { form } = formRef.props;
    form.validateFields(async (err: any) => {
      if (err) {
        return;
      }
      patchRequestApprovals(rows, RequestApprovalStatus.APPROVE);
      form.resetFields();
      setApproveModalVisible(false);
    });
  }

  function saveFormRef(formRefs: WrappedFormInternalProps) {
    formRef = formRefs;
  }

  function checkEdit(context: any) {
    const {
      creditLimit,
      intRate,
      safeMargin,
      minPayRate,
      minPayAmount,
      lateFee,
      stampFee,
      bureauFee,
      bureauMissFee,
      preterminateFee,
      preterminatePeriod,
      transferFee,
      interBankTransferFee,
      writeoffAmount,
      writeoffDate,
      cfPrincipal,
      cfInterest,
      cfFee,
      filingDate,
      blackCaseNo,
      restructureDate,
      paymentType,
      redCaseNo,
      installTerm,
      type,
      gracePeriod,
      useGracePeriod,
    } = context;
    const mapLoan = [
      {
        name: 'Credit Limit',
        value: creditLimit || creditLimit === 0 ? satangToBath(creditLimit) : undefined,
      },
      {
        name: 'Interest Rate',
        value: intRate || intRate === 0 ? `${Number(intRate * 100).toFixed(0)} %` : undefined,
      },
      {
        name: 'Safe Margin',
        value: safeMargin || safeMargin === 0 ? satangToBath(safeMargin) : undefined,
      },
      {
        name: 'Minimum Payment Rate',
        value: minPayRate || minPayRate === 0 ? `${minPayRate * 100} %` : undefined,
      },
      {
        name: 'Minimum Payment Amount',
        value: minPayAmount || minPayAmount === 0 ? satangToBath(minPayAmount) : undefined,
      },
      { name: 'Late Fee', value: lateFee || lateFee === 0 ? satangToBath(lateFee) : undefined },
      {
        name: 'Stamp Fee',
        value: stampFee || stampFee === 0 ? `${stampFee * 100} %` : undefined,
      },
      {
        name: 'Bureau Hit Fee',
        value: bureauFee || bureauFee === 0 ? satangToBath(bureauFee) : undefined,
      },
      {
        name: 'Bureau Miss Fee',
        value: bureauMissFee || bureauMissFee === 0 ? satangToBath(bureauMissFee) : undefined,
      },
      {
        name: 'Preterminate Fee',
        value: preterminateFee || preterminateFee === 0 ? satangToBath(preterminateFee) : undefined,
      },
      {
        name: 'Preterminate Period',
        value: preterminatePeriod || preterminatePeriod === 0 ? `${preterminatePeriod}` : undefined,
      },
      {
        name: 'Transfer Fee',
        value: transferFee || transferFee === 0 ? satangToBath(transferFee) : undefined,
      },
      {
        name: 'Inter bank transfer Fee',
        value:
          interBankTransferFee || interBankTransferFee === 0
            ? satangToBath(interBankTransferFee)
            : undefined,
      },
      {
        name: 'Writeoff Amount',
        value: writeoffAmount || writeoffAmount === 0 ? satangToBath(writeoffAmount) : undefined,
      },
      { name: 'Writeoff Date', value: writeoffDate },
      {
        name: 'cfPrincipal',
        value: cfPrincipal || cfPrincipal === 0 ? satangToBath(cfPrincipal) : undefined,
      },
      {
        name: 'cfInterest',
        value: cfInterest || cfInterest === 0 ? satangToBath(cfInterest) : undefined,
      },
      { name: 'cfFee', value: cfFee || cfFee === 0 ? satangToBath(cfFee) : undefined },
      {
        name: 'filingDate',
        value: filingDate ? iso8601ToLocalDate(filingDate) : undefined,
      },
      {
        name: 'blackCaseNo',
        value: blackCaseNo ? `${blackCaseNo}` : undefined,
      },
      {
        name: 'blackCaseNo',
        value: blackCaseNo ? `${blackCaseNo}` : undefined,
      },
      {
        name: 'restructureDate',
        value: restructureDate ? iso8601ToLocalDate(restructureDate) : undefined,
      },
      {
        name: 'paymentType',
        value: paymentType ? `${paymentType}` : undefined,
      },
      {
        name: 'redCaseNo',
        value: redCaseNo ? `${redCaseNo}` : undefined,
      },
      {
        name: 'installTerm',
        value: installTerm ? `${installTerm}` : undefined,
      },
      {
        name: 'type',
        value: type ? `${restructureType[type]}` : undefined,
      },
      {
        name: 'gracePeriod',
        value: gracePeriod ? `${gracePeriod}` : undefined,
      },
      {
        name: 'useGracePeriod',
        value:
          useGracePeriod === true || useGracePeriod === false ? `${useGracePeriod}` : undefined,
      },
    ];
    const mapValue: any = [];
    mapLoan.forEach((loan: any) => {
      if (loan.value)
        mapValue.push(<Descriptions.Item label={loan.name}>{loan.value}</Descriptions.Item>);
    });
    return (
      <Descriptions
        title="Loan"
        column={{ lg: 4, md: 4, sm: 4, xs: 3 }}
        size="small"
        // bordered
      >
        {mapValue}
      </Descriptions>
    );
  }
  const ActionButtonPanel = () => {
    return (
      <div style={{ marginBottom: '16px' }}>
        <Row gutter={6}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Button
              type="primary"
              disabled={rows.length === 0}
              onClick={() => {
                setApproveModalVisible(true);
              }}
              ghost
              loading={loading}
            >
              <FormattedMessage id="requestApproval.button.approve" />
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              danger
              disabled={rows.length === 0}
              onClick={() => {
                setCancelModalVisible(true);
              }}
              ghost
              loading={loading}
            >
              <FormattedMessage id="requestApproval.button.reject" />
            </Button>
          </Col>
        </Row>
        <ApproveRequestApproval
          wrappedComponentRef={saveFormRef}
          visible={approveModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreateApprove}
        />
        <CancelRequestApproval
          wrappedComponentRef={saveFormRef}
          visible={cancelModalVisible}
          onCancel={handleCancel}
          onCreate={handleCreateReject}
        />
      </div>
    );
  };
  const CheckPermission = renderIfHasPermissisons(
    'requestapprovals.*.update',
    <ActionButtonPanel />,
  );

  return (
    <div>
      <CheckPermission />
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        expandedRowRender={(record: any) =>
          record.type === 'editloan' ||
          record.type === 'writeoff' ||
          record.type === 'filing' ||
          record.type === 'restructure' ? (
            <div>{checkEdit(record.context)}</div>
          ) : (
            undefined
          )
        }
        dataSource={dataSource}
        loading={loading}
        columns={columnsAll}
      />
    </div>
  );
};

export default connect(null, { push })(TablePending);
