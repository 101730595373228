import React, { FC, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Form, Input, Select, Button, Row, Col, notification } from 'antd';
import { API_URL, postJson, patchJson, showError } from '../../../helpers/api/api';
import componentValues from '../../../services/utilities/ComponentValue';
import { UserState } from '../../../redux/user/user-duck';
import { Store } from 'antd/lib/form/interface';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';

export interface Users {
  email: string;
  roles: string[];
}
interface Props {
  projectId: string;
  roles: string[];
  data?: Users;
  reloadData?: () => void;
  isNew?: boolean;
}

const PolicieForm: FC<Props & PropsFromRedux> = ({
  roles,
  data,
  projectId,
  push,
  isNew = false,
  reloadData,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  useEffect(form.resetFields, [data]);
  function loadOptionsFromApi(optionKeys: any[]): JSX.Element[] {
    const { Option } = Select;
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index].name} value={optionKeys[index].name}>
          {optionKeys[index].name}
        </Option>,
      );
    }
    return options;
  }
  const onFinish = async (value: Store) => {
    try {
      if (isNew) {
        await postJson(`${API_URL}/projects/${projectId}/policies`, value);
        notification.success({
          message: intl.formatMessage({ id: 'user-new.success-message' }),
        });
      } else {
        await patchJson(`${API_URL}/projects/${projectId}/policies/${value.email}`, value);
        notification.success({
          message: intl.formatMessage({ id: 'user-edit.success-message' }),
        });
      }
      if (reloadData) {
        reloadData();
      } else {
        push('/policies');
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SaveButton = renderIfHasPermissisons(
    'policies.*.update',
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
      {isNew ? <FormattedMessage id="add" /> : <FormattedMessage id="save" />}
    </Button>,
  );
  return (
    <div className="card">
      <div className="card-body">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={data}
        >
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="email" />}
                name="email"
                rules={[{ required: true }, { type: 'email' }]}
              >
                <Input disabled={!isNew} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="role" />}
                name="roles"
                rules={[{ required: true }]}
              >
                <Select mode="multiple">{loadOptionsFromApi(roles)}</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" type="primary" ghost>
                <Link to="/policies">
                  <FormattedMessage id="cancel" />
                </Link>
              </Button>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PolicieForm);
