import React, { FC, useState, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Store } from 'antd/lib/form/interface';
import { Form, Select, Radio, Input, Row, Col, Button, notification, Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import DateOnlyPicker from '../common/DateOnlyPicker';
import InputBaht from '../common/InputBaht';
import SaleCodeSelect from '../common/SaleCodeSelect';
import BankNameSelect from '../common/BankNameSelect';
import ProductSelect from '../common/ProductSelect';
import StatementDateSelect from '../common/StatementDateSelect';
import SpouseInfo from './ApplicationForm/DataTabs/KeyData/KeyDataTabs/General/Panel/SpouseInfo';
import { useProductOptions, useAddressAutoComplete } from '../../../helpers/form/fetch-hook';
import { ErrorInfo } from '../../../types/form-types';
import componentValues from '../../../services/utilities/ComponentValue';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import { onFinishFailedHandler } from '../../../helpers/form';
import { UserState } from '../../../redux/user/user-duck';
import { getValidator } from '../../../helpers/validator';
import './application.module.scss';

interface AddressInfoProps {
  form: FormInstance;
  selectedAddress: string;
}

const AddressInfo: FC<AddressInfoProps> = ({ form, selectedAddress }) => {
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();
  const statementAddresses = ['current', 'permanent', 'company'];
  const { setFieldsValue } = form;
  const renderAddressInput = (statementAddress: string) => {
    return selectedAddress === statementAddress ? (
      <Fragment key={statementAddress}>
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', statementAddress, 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', statementAddress, 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', statementAddress])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', statementAddress, 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', statementAddress])
                }
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'district')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', statementAddress, 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', statementAddress])
                }
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'province')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', statementAddress, 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', statementAddress])
                }
                onSearch={(value: string) => setAddressesFromSearchResult(value, 'postCode')}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    ) : null;
  };
  return <Fragment>{statementAddresses.map(renderAddressInput)}</Fragment>;
};

interface Props {
  projectId: string;
}

const NewApplicationForm: FC<Props & PropsFromRedux> = ({ push, projectId }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isInstallment, setIsInstallment] = useState<boolean>(false);
  const productOptions = useProductOptions(projectId, { limit: 10000 });
  const { setFieldsValue, getFieldValue } = form;

  const isMarried = () => {
    const maritalStatus = getFieldValue('maritalStatus');
    const isMarried = maritalStatus === 'married' || maritalStatus === 'notregister';
    return isMarried;
  };

  const onFinish = async (value: Store) => {
    try {
      await postJson(`${API_URL}/projects/${projectId}/applications`, value);
      notification.success({
        message: intl.formatMessage({ id: 'application-new.success-message' }),
      });
      push('/applications');
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  return (
    <div className="card">
      <div className="card-body">
        <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item label={<FormattedMessage id="saleCode" />} name="saleCode">
            <SaleCodeSelect />
          </Form.Item>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="title" />}
                name="title"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  buttonStyle="solid"
                  onChange={e => {
                    const { value } = e.target;
                    switch (value) {
                      case 'นาย':
                        setFieldsValue({ titleEn: 'Mr' });
                        break;
                      case 'นาง':
                        setFieldsValue({ titleEn: 'Mrs' });
                        break;
                      case 'นางสาว':
                        setFieldsValue({ titleEn: 'Miss' });
                        break;
                    }
                  }}
                >
                  <Radio.Button value="นาย">นาย</Radio.Button>
                  <Radio.Button value="นาง">นาง</Radio.Button>
                  <Radio.Button value="นางสาว">นางสาว</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="titleEn" />}
                name="titleEn"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  buttonStyle="solid"
                  onChange={e => {
                    const { value } = e.target;
                    switch (value) {
                      case 'Mr':
                        setFieldsValue({ title: 'นาย' });
                        break;
                      case 'Mrs':
                        setFieldsValue({ title: 'นาง' });
                        break;
                      case 'Miss':
                        setFieldsValue({ title: 'นางสาว' });
                        break;
                    }
                  }}
                >
                  <Radio.Button value="Mr"> Mr</Radio.Button>
                  <Radio.Button value="Mrs">Mrs</Radio.Button>
                  <Radio.Button value="Miss">Miss</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="firstName" />}
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="lastName" />}
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item label={<FormattedMessage id="firstNameEn" />} name="firstNameEn">
                <Input />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="lastNameEn" />} name="lastNameEn">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="citizenId" />}
                name="citizenId"
                hasFeedback
                rules={[{ required: true }, { validator: getValidator(intl, 'citizenId') }]}
              >
                <Input prefix={<i className="icmn-credit-card" />} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="birthDate" />}
                name="birthDate"
                rules={[{ required: true }]}
              >
                <DateOnlyPicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col>
              <Form.Item label={<FormattedMessage id="maritalStatus" />} name="maritalStatus">
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="single">
                    <FormattedMessage id="maritalStatus.single" />
                  </Radio.Button>
                  <Radio.Button value="married">
                    <FormattedMessage id="maritalStatus.married" />
                  </Radio.Button>
                  <Radio.Button value="notregister">
                    <FormattedMessage id="maritalStatus.notregister" />
                  </Radio.Button>
                  <Radio.Button value="divorced">
                    <FormattedMessage id="maritalStatus.divorced" />
                  </Radio.Button>
                  <Radio.Button value="widow">
                    <FormattedMessage id="maritalStatus.widow" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item shouldUpdate>
            {() => {
              return (
                isMarried() && (
                  <Fragment>
                    <SpouseInfo />
                    <Divider dashed />
                  </Fragment>
                )
              );
            }}
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="product" />}
            name="productId"
            rules={[{ required: true }]}
          >
            <ProductSelect
              onChange={(value: string) => {
                productOptions.forEach(productOption => {
                  if (productOption.value === value) {
                    setIsInstallment(productOption.info.paymentType === 'installment');
                  }
                });
              }}
            />
          </Form.Item>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="requestAmount" />}
                name="requestAmount"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
            {isInstallment && (
              <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
                <Form.Item
                  label={<FormattedMessage id="installTerm" />}
                  name="installTerm"
                  rules={[{ required: true }]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            )}
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="mobileNo" />}
                name="mobileNo"
                rules={[{ required: true }, { validator: getValidator(intl, 'telNo') }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="statementDate" />}
                name="statementDate"
                rules={[{ required: true }]}
              >
                <StatementDateSelect />
              </Form.Item>
            </Col>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="dueDate" />}
                name="dueDate"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Divider dashed />
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="bankName" />}
                name="bankName"
                rules={[{ required: true }]}
              >
                <BankNameSelect />
              </Form.Item>
            </Col>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="accountNo" />}
                name="accountNo"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col sm={componentValues.fullColSpan} md={componentValues.halfColSpan}>
              <Form.Item
                label={<FormattedMessage id="statementAddress" />}
                name="statementAddress"
                rules={[{ required: true }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="current">
                    <FormattedMessage id="addressInfo.current" />
                  </Radio.Button>
                  <Radio.Button value="permanent">
                    <FormattedMessage id="addressInfo.permanent" />
                  </Radio.Button>
                  <Radio.Button value="company">
                    <FormattedMessage id="addressInfo.company" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.statementAddress !== currentValues.statementAddress;
            }}
          >
            {({ getFieldValue }) => {
              const statementAddress = getFieldValue('statementAddress');
              return <AddressInfo form={form} selectedAddress={statementAddress} />;
            }}
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" ghost>
                <Link to="/applications">
                  <FormattedMessage id="cancel" />
                </Link>
              </Button>
              <Button htmlType="submit" style={{ marginLeft: 8 }} type="primary">
                <FormattedMessage id="add" />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });

const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewApplicationForm);
