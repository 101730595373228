import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { Bank, getBankName } from '../../../services/utilities/Bank';

function renderOptions(options: Bank[]): JSX.Element[] {
  const { Option } = Select;

  return options.map(option => (
    <Option key={option.shortName} value={option.shortName}>
      <FormattedMessage id={`bank.${option.shortName}`} />
    </Option>
  ));
}

const BankNameSelect: FC<any> = React.forwardRef((props, ref) => {
  const bankOptions = getBankName();
  return (
    <Select showSearch {...props} ref={ref}>
      {renderOptions(bankOptions)}
    </Select>
  );
});

export default BankNameSelect;
