import fetchMock from 'fetch-mock/src/client';
import { API_URL } from '../../../helpers/api/api';

export const productsList = [
  {
    name: 'ldfm',
    id: 'pdname-278122',
    description: '',
    loanType: 'ploan',
    paymentType: 'installment',
    intType: 'flat',
    intRate: 0.24,
    minPayRate: 0.39,
    minPayAmount: 12300,
    transferFee: 24050,
    lateFee: 2950,
    preterminateFee: 14300,
    safeMargin: 230,
    sendEStatement: true,
  },
  {
    name: 'ddt',
    id: 'ddt-278102',
    description: 'Mock Descektlgkd',
    loanType: 'nano',
    paymentType: 'installment',
    intType: 'effective',
    intRate: 0.12,
    minPayRate: 0.19,
    minPayAmount: 11100,
    transferFee: 20000,
    lateFee: 29000,
    preterminateFee: 10000,
    safeMargin: 2000,
    sendEStatement: true,
  },
  {
    name: 'dvmkdlv',
    id: 'ttok-278142',
    description: 'Mock Desc llfrg',
    loanType: 'staff',
    paymentType: 'revolving',
    intType: 'effective',
    intRate: 0.29,
    minPayRate: 0.2,
    minPayAmount: 102029,
    transferFee: 20300,
    lateFee: 403944,
    preterminateFee: 29409,
    safeMargin: 409049,
    sendEStatement: true,
  },
  {
    name: 'dkglvmdkg',
    id: 'wekle-238102',
    description: 'Mock Desc.',
    loanType: 'ploan',
    paymentType: 'revolving',
    intType: 'flat',
    intRate: 0.89,
    minPayRate: 0.23,
    minPayAmount: 19023,
    transferFee: 24100,
    lateFee: 294430,
    preterminateFee: 11930,
    safeMargin: 3004,
    sendEStatement: true,
  },
];

export const usersList = [
  {
    email: 'abc@gk.com',
    password: 'pwd',
    name: 'somk',
    lastName: 'fomd',
    mobileNo: '0932993034',
    role: ['Reader', 'Writer'],
  },
  {
    email: 'cdlsrc@gk.com',
    password: 'pddd',
    name: 'dlckvf',
    lastName: 'efvfvdv',
    mobileNo: '0929099094',
    role: ['Writer'],
  },
  {
    email: 'kdlkmvk@gk.com',
    password: 'dfvkpmdof',
    name: 'vapooqerf',
    lastName: 'qorpofm',
    mobileNo: '0945678901',
    role: ['Admin', 'Writer'],
  },
];

export const roleInfo = [
  {
    id: 'admin-123456',
    name: 'Admin',
    description: 'Can do everythings',
    permissions: ['Read', 'Write', 'Loan'],
  },
  {
    id: 'reader-234567',
    name: 'Reader',
    description: 'Read only',
    permissions: ['Read'],
  },
  {
    id: 'writer-111111',
    name: 'Writer',
    description: 'Write only',
    permissions: ['Write'],
  },
];

export const saleInfo = [
  {
    code: 'TL001-Somchai Tester',
    email: 'somchai@test.com',
    name: 'Somchai',
    lastName: 'Tester',
    mobileNo: '0897654968',
    bankAccount: '1111111111',
    teamLead: 'TL001-Somchai Tester',
  },
  {
    code: 'TL002-Bruno Whatever',
    email: 'bruno@test.com',
    name: 'Bruno',
    lastName: 'Whatever',
    mobileNo: '0987888764',
    bankAccount: '1234512345',
    teamLead: 'TL001-Somchai Tester',
  },
  {
    code: 'TL003-Cherprang Aree',
    email: 'cherprang@test.com',
    name: 'Cherprang',
    lastName: 'Aree',
    mobileNo: '0998713656',
    bankAccount: '9879087465',
    teamLead: 'TL006-John Henry',
  },
  {
    code: 'TL004-Preecha World',
    email: 'preecha@test.com',
    name: 'Preecha',
    lastName: 'World',
    mobileNo: '0875692345',
    bankAccount: '1975428567',
    teamLead: 'TL006-John Henry',
  },
  {
    code: 'TL005-Sadio Mane',
    email: 'sadio@test.com',
    name: 'Sadio',
    lastName: 'Mane',
    mobileNo: '0923451195',
    bankAccount: '0009826458',
    teamLead: 'TL005-Sadio Mane',
  },
  {
    code: 'TL006-John Henry',
    email: 'john@test.com',
    name: 'John',
    lastName: 'Henry',
    mobileNo: '0988897561',
    bankAccount: '1112480096',
    teamLead: 'TL006-John Henry',
  },
  {
    code: 'TL007-Mohamed Salah',
    email: 'mohamed@test.com',
    name: 'Mohamed',
    lastName: 'Salah',
    mobileNo: '0877592614',
    bankAccount: '1129576009',
    teamLead: 'TL001-Somchai Tester',
  },
  {
    code: 'TL008-Alisson Becker',
    email: 'alisson@test.com',
    name: 'Alisson',
    lastName: 'Becker',
    mobileNo: '0898975600',
    bankAccount: '4567444201',
    teamLead: 'TL008-Alisson Becker',
  },
  {
    code: 'TL009-Klopp Normalone',
    email: 'klopp@test.com',
    name: 'Klopp',
    lastName: 'Normalone',
    mobileNo: '0900741234',
    bankAccount: '1234765113',
    teamLead: 'TL008-Alisson Becker',
  },
  {
    code: 'TL010-Tony Stark',
    email: 'tony@test.com',
    name: 'Tony',
    lastName: 'Stark',
    mobileNo: '0945678129',
    bankAccount: '5200985647',
    teamLead: 'TL010-Tony Stark',
  },
  {
    code: 'TL011-Peter Parker',
    email: 'peter@test.com',
    name: 'Peter',
    lastName: 'Parker',
    mobileNo: '0989871121',
    bankAccount: '1120085643',
    teamLead: 'TL010-Tony Stark',
  },
  {
    code: 'TL012-Peni Parker',
    email: 'peni@test.com',
    name: 'Peni',
    lastName: 'Parker',
    mobileNo: '0982234657',
    bankAccount: '0998124560',
    teamLead: 'TL0010-Tony Stark',
  },
];

function filterData(param, info) {
  const temp = [];
  info.forEach(element => {
    if (element.name.includes(param)) {
      temp.push(element);
    }
  });

  return temp;
}

function loadInfo(url) {
  switch (url) {
    case 'users':
      return usersList;
    case 'products':
      return productsList;
    case 'roles':
      return roleInfo;
    case 'sales':
      return saleInfo;
    default:
  }
  return null;
}

export default function mockWithParam(url, param) {
  const info = loadInfo(url);
  if (param) {
    fetchMock.mock(
      `${API_URL}/${url}/${param}`,
      {
        status: 200,
        body: filterData(param, info),
      },
      { overwriteRoutes: false },
    );
  } else {
    fetchMock.mock(
      `${API_URL}/${url}/`,
      {
        status: 200,
        body: info,
      },
      { overwriteRoutes: false },
    );
  }
}
