import React, { FC } from 'react';
import { Form, Modal, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useIntl, FormattedMessage } from 'react-intl';
import InputBaht from '../../common/InputBaht';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { getValidator } from '../../../../helpers/validator';

interface Props {
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  reloadData: () => void;
  projectId: string;
  loan: any;
}

const WithdrawModal: FC<Props> = ({
  visible,
  onCancel,
  cancelText,
  okText,
  reloadData,
  projectId,
  loan,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  let availableLimit = 0;
  if (loan) {
    availableLimit = Number((loan.withdrawAmount / 100).toFixed(2));
  }
  const onFinish = async (value: Store) => {
    try {
      const payload = {
        ...value,
      };
      await postJson(`${API_URL}/projects/${projectId}/withdraws/${loan.id}`, payload);
      notification.success({ message: intl.formatMessage({ id: 'withdraw.success-message' }) });
      form.resetFields();
      onCancel();
      reloadData();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="withdraw" />
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={intl.formatMessage(
                { id: 'withdraw.maxamount' },
                { maxAmount: intl.formatNumber(availableLimit) },
              )}
              name="amount"
              rules={[
                { required: true },
                { validator: getValidator(intl, 'maxBathAmount', { maxAmount: availableLimit }) },
                { validator: getValidator(intl, 'minBathAmount', { minAmount: 1 }) },
              ]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WithdrawModal;
