import React, { FC, useEffect, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Form, Select, Button, Input, Row, Col, notification, Checkbox, InputNumber } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { API_URL, postJson, patchJson, showError } from '../../../helpers/api/api';
import {
  getLoanType,
  getPaymentType,
  getInterestType,
  getStampFeePayer,
} from '../../../services/utilities/Choice';
import componentValues from '../../../services/utilities/ComponentValue';
import InputPercent from '../common/InputPercent';
import InputBaht from '../common/InputBaht';
import { UserState } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';

export interface Product {
  name: string;
  id: string;
  description: string;
  loanType: string;
  paymentType: string;
  intType: string;
  intRate: number;
  minPayRate: number;
  minPayAmount: number;
  lateFee: number;
  stampFee: number;
  bureauFee: number;
  bureauMissFee: number;
  preterminateFee: number;
  preterminatePeriod: number;
  transferFee: number;
  interBankTransferFee: number;
  safeMargin: number;
  openingFee: number;
  sendEStatement: boolean;
  includeInBotReport: boolean;
  includeInNcbReport: boolean;
  chargeStampFeeForCopy: boolean;
  stampFeePayer: string;
}

interface Props {
  data?: Product;
  push: Push;
  isNew: boolean;
  reloadData?: () => void;
}

const { Option } = Select;
const { TextArea } = Input;

const ProductForm: FC<Props & PropsFromRedux> = ({ projectId, data, push, isNew, reloadData }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { setFieldsValue, getFieldValue } = form;

  const isGracePeriod = () => {
    const useGracePeriod = getFieldValue('useGracePeriod');
    const isGracePeriod = useGracePeriod;
    return isGracePeriod;
  };

  useEffect(form.resetFields, [data]);
  function renderOptions(type: string, optionKeys: string[]): JSX.Element[] {
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index]} value={optionKeys[index]}>
          <FormattedMessage id={`${type}.${optionKeys[index]}`} />
        </Option>,
      );
    }
    return options;
  }
  const onFinish = async (value: Store) => {
    try {
      const { id, ...productData } = value;
      const product = { ...productData };
      product.lateFee = productData.lateFee * 100;
      if (isNew) {
        await postJson(`${API_URL}/projects/${projectId}/products`, product);
        notification.success({
          message: intl.formatMessage({ id: 'product-new.success-message' }),
        });
      } else {
        await patchJson(`${API_URL}/projects/${projectId}/products/${id}`, product);
        notification.success({
          message: intl.formatMessage({ id: 'product-edit.success-message' }),
        });
      }
      if (reloadData) {
        reloadData();
      } else {
        push('/products');
      }
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const SaveButton = renderIfHasPermissisons(
    ['products.*.create', 'products.*.update'],
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
      <FormattedMessage id={isNew ? 'add' : 'save'} />
    </Button>,
  );
  return (
    <div className="card">
      <div className="card-body">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...data,
            sendEStatement: data?.sendEStatement || false,
            includeInBotReport: data?.includeInBotReport || false,
            includeInNcbReport: data?.includeInNcbReport || false,
            chargeStampFeeForCopy: data?.chargeStampFeeForCopy || false,
            lateFee: data && data.lateFee > 0 ? data.lateFee / 100 : data?.lateFee,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="product.name" />}
                name="name"
                rules={[{ required: true }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="product.id" />} name="id">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={<FormattedMessage id="description" />} name="description">
            <TextArea autoSize style={{ resize: 'none' }} />
          </Form.Item>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="loanType" />}
                name="loanType"
                rules={[{ required: true }]}
              >
                <Select>{renderOptions('loanType', getLoanType())}</Select>
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="paymentType" />}
                name="paymentType"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={value => {
                    if (value === 'installment') {
                      setFieldsValue({ minPayRate: 0, minPayAmount: 0 });
                    }
                  }}
                >
                  {renderOptions('paymentType', getPaymentType())}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="intType" />}
                name="intType"
                rules={[{ required: true }]}
              >
                <Select>{renderOptions('intType', getInterestType())}</Select>
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="intRate" />}
                name="intRate"
                rules={[{ required: true }]}
              >
                <InputPercent />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Row
                  gutter={componentValues.gutterValue}
                  style={{
                    display: getFieldValue('paymentType') === 'revolving' ? 'flex' : 'none',
                  }}
                >
                  <Col
                    sm={{ span: componentValues.fullColSpan }}
                    md={{ span: componentValues.halfColSpan }}
                  >
                    <Form.Item
                      label={<FormattedMessage id="minPayRate" />}
                      name="minPayRate"
                      shouldUpdate
                      rules={[{ required: true }]}
                    >
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col
                    sm={{ span: componentValues.fullColSpan }}
                    md={{ span: componentValues.halfColSpan }}
                  >
                    <Form.Item
                      label={<FormattedMessage id="minPayAmount" />}
                      name="minPayAmount"
                      shouldUpdate
                      rules={[{ required: true }]}
                    >
                      <InputBaht />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }}
          </Form.Item>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="lateFee" />}
                name="lateFee"
                rules={[{ required: true }]}
              >
                <InputNumber min={0} max={100} style={{ width: '100%', height: '100%' }} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="stampFee" />}
                name="stampFee"
                rules={[{ required: true }]}
              >
                <InputPercent />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="bureauFee" />}
                name="bureauFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="bureauMissFee" />}
                name="bureauMissFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="preterminateFee" />}
                name="preterminateFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="preterminatePeriod" />}
                name="preterminatePeriod"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="transferFee" />}
                name="transferFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="interBankTransferFee" />}
                name="interBankTransferFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="safeMargin" />}
                name="safeMargin"
                help={<FormattedMessage id="safeMarginDescription" />}
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="openingFee" />}
                name="openingFee"
                rules={[{ required: true }]}
              >
                <InputBaht />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="stampFeePayer" />}
                name="stampFeePayer"
                rules={[{ required: true }]}
              >
                <Select>{renderOptions('stampFeePayer', getStampFeePayer())}</Select>
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="maximumCreditLimit" />}
                name="maximumCreditLimit"
              >
                <InputBaht />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="useGracePeriod" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="useGracePeriod" />
            </Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => {
              return (
                isGracePeriod() && (
                  <Fragment>
                    <Row gutter={componentValues.gutterValue}>
                      <Col
                        sm={{ span: componentValues.fullColSpan }}
                        md={{ span: componentValues.halfColSpan }}
                      >
                        <Form.Item label={<FormattedMessage id="gracePeriod" />} name="gracePeriod">
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Fragment>
                )
              );
            }}
          </Form.Item>
          <Form.Item name="chargeStampFeeForCopy" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="chargeStampFeeForCopy" />
            </Checkbox>
          </Form.Item>
          <Form.Item name="sendEStatement" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="sendEStatement" />
            </Checkbox>
          </Form.Item>
          <Form.Item name="includeInBotReport" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="includeInBotReport" />
            </Checkbox>
          </Form.Item>
          <Form.Item name="includeInNcbReport" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="includeInNcbReport" />
            </Checkbox>
          </Form.Item>
          <Form.Item name="restructure" valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="restructureType.debtRestructuring" />
            </Checkbox>
          </Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" type="primary" ghost>
                <Link to="/products">
                  <FormattedMessage id="cancel" />
                </Link>
              </Button>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({
  projectId: user.currentProjectId,
});
const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductForm);
