import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Tag, Popconfirm } from 'antd';
import { API_URL, deleteJson } from '../../../helpers/api/api';
import SearchableTable from '../../../components/CbsComponents/SearchableTable/SearchableTable';
import { Role } from '../../../types/role-types';
import { getSortOrderFromQueryString } from '../../../helpers/querystring';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';

const ListRole: React.FC = (props: any) => {
  const { queryString, pathname, currentProjectId, intl } = props;
  const columns = [
    {
      title: <FormattedMessage id="common.id" />,
      dataIndex: 'id',
      width: '100px',
      fixed: 'left',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Role, b: Role) => (a.id < b.id ? -1 : 1),
      sortOrder: getSortOrderFromQueryString({ dataIndex: 'id', queryString }),
      render: (roleId: string, role: Role) => {
        return role.projectId !== currentProjectId ? (
          <span>{roleId}</span>
        ) : (
          <Link className="utils__link--blue utils__link--underlined" to={`roles/${roleId}`}>
            {roleId}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="roles.name" />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id="common.description" />,
      dataIndex: 'description',
    },
    {
      title: <FormattedMessage id="roles.scopes" />,
      dataIndex: 'scopes',
      render: (scopes: any) => (
        <span>
          {scopes.map((scope: any) => {
            return <Tag key={scope}>{scope}</Tag>;
          })}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="common.action" />,
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (role: Role) => {
        return role.projectId !== currentProjectId ? null : (
          <span>
            <Popconfirm
              title={<FormattedMessage id="question.areYouSure" />}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              cancelText={<FormattedMessage id="common.no" />}
              okText={<FormattedMessage id="common.yes" />}
              onConfirm={async (e: any) => {
                const roleId = role.id;
                await deleteJson(`${API_URL}/projects/${currentProjectId}/roles/${roleId}`);
                window.location.reload();
              }}
              onCancel={e => e && e.stopPropagation()}
            >
              <a href="#" onClick={e => e.stopPropagation()}>
                <i className="icmn-bin" />
              </a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  const breadcrumbs = [
    {
      title: 'menu.roles',
      href: '/roles',
    },
  ];

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.roles' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.roles" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SearchableTable
          columns={columns}
          fetchUrl={`${API_URL}/projects/${currentProjectId}/roles`}
          addUrl="roles/new"
          pathname={pathname}
          search={queryString}
          rowKey="id"
          enableDownload
        />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps)(injectIntl(ListRole));
