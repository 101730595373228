import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';
import ImportPaymentsForm from '../../components/CbsComponents/Imports/ImportPaymentsForm';

const ImportPayments: React.FC<WrappedComponentProps> = ({ intl }) => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importPayments',
      href: `/import/payments`,
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.importPayments' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.imports" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <ImportPaymentsForm />
      </div>
    </Layout.Content>
  );
};

export default injectIntl(ImportPayments);
