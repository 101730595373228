import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import {
  Form,
  Button,
  notification,
  Input,
  Row,
  Col,
  Select,
  Upload,
  Switch,
  Checkbox,
  Divider,
} from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { store } from '../../../redux/store';
import { UserState, renewToken } from '../../../redux/user/user-duck';
import { API_URL, postJson, patchJson, showError } from '../../../helpers/api/api';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';
import componentValues from '../../../services/utilities/ComponentValue';
import { useAddressAutoComplete } from '../../../helpers/form/fetch-hook';
import styles from '../Application/application.module.scss';

export interface Project {
  email: string;
  code: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  bankName: string;
  branch: string;
  accountNo: string;
  accountName: string;
  accountType: string;
  teamLead: string;
}

interface Props {
  projectData?: any;
  projectId: string;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    notification.error({ message: 'You can only upload JPG/PNG file!' });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    notification.error({ message: 'Image must smaller than 2MB!' });
  }
  return isJpgOrPng && isLt2M;
};

const billerOptions = [
  { value: 'loanId', label: 'เลขที่สัญญา' },
  { value: 'citizenId', label: 'เลขบัตรประชาชน' },
];

const ProjectForm: React.FC<Props & PropsFromRedux> = ({ push, projectData, projectId }) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const intl = useIntl();
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();
  const [renderHeaderUrl, setRenderHeaderUrl] = useState<string>();
  const [renderFooterUrl, setRenderFooterUrl] = useState<string>();
  const [headerUrl, setHeaderUrl] = useState<string>();
  const [footerUrl, setFooterUrl] = useState<string>();
  const [useMindue, setUseMindue] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  const AddButton = renderIfHasPermissisons(
    'projects.*.create',
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
      <FormattedMessage id="add" />
    </Button>,
  );
  const SaveButton = renderIfHasPermissisons(
    'projects.*.update',
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );

  const onFinish = async (value: Store) => {
    try {
      const data = { ...value };
      data.headerUrl = headerUrl;
      data.footerUrl = footerUrl;

      if (!data.billerRef1) data.billerRef1 = null;
      if (!data.billerRef2) data.billerRef2 = null;

      if (projectData) {
        await patchJson(`${API_URL}/projects/${projectId}/projects`, {
          id: projectData.id,
          data,
        });
        notification.success({
          message: intl.formatMessage({ id: 'projects-edit.success-message' }),
        });
        push('/projects');
      } else {
        await postJson(`${API_URL}/projects/${projectId}/projects`, {
          data,
        });
        notification.success({
          message: intl.formatMessage({ id: 'projects-create.success-message' }),
        });
        push('/projects');
      }
      store.dispatch(renewToken());
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  const handleChangeHeader: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, url => {
        setLoading(false);
        setRenderHeaderUrl(url);
      });
      setHeaderUrl(info.file.response.data.fileName);
    }
  };
  const handleChangeFooter: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, url => {
        setLoading(false);
        setRenderFooterUrl(url);
      });
      setFooterUrl(info.file.response.data.fileName);
    }
  };
  const removeHeader = () => {
    setRenderHeaderUrl('');
    setHeaderUrl('');
  };
  const removeFooter = () => {
    setRenderFooterUrl('');
    setFooterUrl('');
  };
  const handleClickUseMindue = () => {
    setUseMindue(!useMindue);
  };

  const headerProps: UploadProps = {
    name: 'upload',
    listType: 'picture-card',
    showUploadList: false,
    action: `${API_URL}/projects/${projectId}/projects/upload`,
    beforeUpload,
    onChange: handleChangeHeader,
  };
  const footerProps: UploadProps = {
    name: 'upload',
    listType: 'picture-card',
    showUploadList: false,
    action: `${API_URL}/projects/${projectId}/projects/upload`,
    beforeUpload,
    onChange: handleChangeFooter,
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (projectData?.headerUrl) setRenderHeaderUrl(projectData.headerUrl);
    if (projectData?.footerUrl) setRenderFooterUrl(projectData.footerUrl);
    if (projectData?.useMindue) setUseMindue(projectData.useMindue);
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={projectData}
        >
          {/* name and companyName */}
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="projects.name" />}
                name="name"
                rules={[{ required: true }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="organisation.name" />}
                name="companyName"
                rules={[{ required: true }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
          </Row>
          {/* contractFirstName and contractLastName */}
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="firstName" />}
                name="contactFirstName"
                rules={[{ required: true }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="lastName" />}
                name="contactLastName"
                rules={[{ required: true }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
          </Row>
          {/* contractMail and contractMobileNo */}
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="email" />}
                name="contactMail"
                rules={[
                  {
                    type: 'email',
                    message: <FormattedMessage id="common.email.invalidMessage" />,
                  },
                  { required: true },
                ]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label={<FormattedMessage id="mobileNo" />}
                name="contactMobileNo"
                rules={[
                  {
                    len: 10,
                    message: <FormattedMessage id="common.mobileNo.invalidMessage" />,
                  },
                  { required: true },
                ]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
          </Row>
          {/* registerId */}
          <Form.Item
            label={<FormattedMessage id="registerId" />}
            name="registerId"
            rules={[{ required: true }]}
          >
            <Input maxLength={componentValues.inputStringMaxLenth} />
          </Form.Item>
          {/* address */}
          <Form.Item
            label={<FormattedMessage id="address" />}
            name="address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* autocomplete address */}
          <Row gutter={componentValues.gutterValue}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
              <Form.Item
                label={<FormattedMessage id="subDistrict" />}
                name="subDistrict"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                  onSearch={(value: string) => {
                    setAddressesFromSearchResult(value, 'subDistrict');
                  }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                >
                  {renderFilteredAddresses()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
              <Form.Item
                label={<FormattedMessage id="district" />}
                name="district"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                  onSearch={(value: string) => {
                    setAddressesFromSearchResult(value, 'district');
                  }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                >
                  {renderFilteredAddresses()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
              <Form.Item
                label={<FormattedMessage id="province" />}
                name="province"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                  onSearch={(value: string) => {
                    setAddressesFromSearchResult(value, 'province');
                  }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                >
                  {renderFilteredAddresses()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
              <Form.Item
                label={<FormattedMessage id="postCode" />}
                name="postCode"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={(value: string) => setSelectedAddress(value, setFieldsValue)}
                  onSearch={(value: string) => {
                    setAddressesFromSearchResult(value, 'postCode');
                  }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                >
                  {renderFilteredAddresses()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider dashed />
          {/* NCB config */}
          <strong className={styles.title}>NCB CONFIG</strong>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="NCB Future Use" name="ncbFutureUse" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item
                label="NCB Installment Freq"
                name="ncbInstallmentFreq"
                rules={[{ required: false }]}
              >
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="NCB Member Code" name="ncbMemberCode" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="NCB Member Name" name="ncbMemberName" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="NCB Password" name="ncbPassword" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="NCB Version" name="ncbVersion" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
          </Row>
          <Divider dashed />
          {/* Biller config */}
          <strong className={styles.title}>BILLER CONFIG</strong>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Biller ID" name="billerId" rules={[{ required: false }]}>
                <Input maxLength={componentValues.inputStringMaxLenth} />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Biller Ref 1" name="billerRef1" rules={[{ required: false }]}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                  options={billerOptions}
                />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Biller Ref 2" name="billerRef2" rules={[{ required: false }]}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                  options={billerOptions}
                />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Biller Mindue" name="useMindue" rules={[{ required: false }]}>
                <Switch checked={useMindue} onClick={handleClickUseMindue} />
              </Form.Item>
            </Col>
          </Row>
          <Divider dashed />
          {/* BOT config */}
          <Form.Item label="BOT Member Code" name="botMemberCode">
            <Input maxLength={componentValues.inputStringMaxLenth} />
          </Form.Item>
          {/* Header and Footer */}
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Header URL" name="headerUrl">
                {renderHeaderUrl ? (
                  <>
                    <img
                      src={renderHeaderUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '8rem' }}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={removeHeader}
                        style={{ marginTop: '10px' }}
                        type="primary"
                        danger
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                ) : (
                  <Upload {...headerProps}>{uploadButton} </Upload>
                )}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label="Footer URL" name="footerUrl">
                {renderFooterUrl ? (
                  <>
                    <img
                      src={renderFooterUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '8rem' }}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={removeFooter}
                        style={{ marginTop: '10px' }}
                        type="primary"
                        danger
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                ) : (
                  <Upload {...footerProps}>{uploadButton}</Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item>{!projectData ? <AddButton /> : <SaveButton />}</Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => {
  return { projectId: user.currentProjectId };
};
const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ProjectForm);
