import { all, takeEvery, put } from 'redux-saga/effects';
import {
  SET_IMPORT_PAYMENT,
  setImportPaymentSuccess,
  setImportPaymentFail,
  SET_IMPORT_APPLICATIONS,
  setImportApplicationsSuccess,
  setImportApplicationsFail,
  SET_IMPORT_CREDITREPORTS,
  setImportCreditreportsSuccess,
  setImportCreditreportsFail,
  SET_IMPORT_DISBURSEMENTS,
  setImportDisbursementsSuccess,
  setImportDisbursementsFail,
  SET_IMPORT_COLLECTOR_LOANS,
  setImportCollectorLoansSuccess,
  setImportCollectorLoansFail,
  SET_IMPORT_WRITEOFFS,
  setImportWriteOffsSuccess,
  setImportWriteOffsFail,
} from './import-duck';
import { push } from 'connected-react-router';
import { showWarning } from '../../helpers/api/api';

export function* importPayment({ payload }) {
  const redirect = '/import/payments/table';
  try {
    yield put(setImportPaymentSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportPaymentFail(error));
  }
}

export function* importApplications({ payload }) {
  const redirect = '/import/applications/table';
  try {
    yield put(setImportApplicationsSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportApplicationsFail(error));
  }
}

export function* importCreditreports({ payload }) {
  const redirect = '/import/creditreports/table';
  try {
    yield put(setImportCreditreportsSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportCreditreportsFail);
  }
}

export function* importDisbursements({ payload }) {
  const redirect = '/import/disbursements/table';
  try {
    yield put(setImportDisbursementsSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportDisbursementsFail);
  }
}

export function* importCollectorLoans({ payload }) {
  const redirect = '/import/collectorloans/table';
  try {
    yield put(setImportCollectorLoansSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportCollectorLoansFail(error));
  }
}

export function* importWriteoffs({ payload }) {
  const redirect = '/import/writeoffs/table';
  try {
    yield put(setImportWriteOffsSuccess(payload));
    yield put(push(redirect));
  } catch (error) {
    showWarning(error);
    yield put(setImportWriteOffsFail(error));
  }
}

export default function* importSaga() {
  yield all([takeEvery(SET_IMPORT_PAYMENT, importPayment)]);
  yield all([takeEvery(SET_IMPORT_APPLICATIONS, importApplications)]);
  yield all([takeEvery(SET_IMPORT_CREDITREPORTS, importCreditreports)]);
  yield all([takeEvery(SET_IMPORT_DISBURSEMENTS, importDisbursements)]);
  yield all([takeEvery(SET_IMPORT_COLLECTOR_LOANS, importCollectorLoans)]);
  yield all([takeEvery(SET_IMPORT_WRITEOFFS, importWriteoffs)]);
}
