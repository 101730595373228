import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { useSaleOptions } from '../../../helpers/form/fetch-hook';
import { SelectOption } from '../../../types/form-types';
import { UserState } from '../../../redux/user/user-duck';

const SaleCodeSelect: FC<PropsFromRedux & SelectProps<any>> = ({ projectId, ...selectProps }) => {
  const saleOptions = useSaleOptions(projectId, { limit: 5000 });
  const { Option } = Select;
  return (
    <Select showSearch {...selectProps}>
      {saleOptions.map((saleOption: SelectOption) => (
        <Option key={saleOption.value} value={saleOption.value}>
          {saleOption.label}
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SaleCodeSelect);
