import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Button, notification } from 'antd';
import DateOnlyPicker from '../common/DateOnlyPicker';
import { API_URL, postJson, showError } from '../../../helpers/api/api';
import { UserState } from '../../../redux/user/user-duck';
import { Store } from 'antd/lib/form/interface';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';

export interface Product {
  name: string;
  id: string;
  description: string;
  loanType: string;
  paymentType: string;
  intType: string;
  intRate: number;
  minPayRate: number;
  minPayAmount: number;
  lateFee: number;
  stampFee: number;
  bureauFee: number;
  bureauMissFee: number;
  preterminateFee: number;
  preterminatePeriod: number;
  transferFee: number;
  interBankTransferFee: number;
  safeMargin: number;
  sendEStatement: boolean;
  includeInBotReport: boolean;
  includeInNcbReport: boolean;
}

interface Props {}

const AdjComponent: React.FC<Props & PropsFromRedux> = ({ projectId }) => {
  const intl = useIntl();
  const onFinish = async (value: Store) => {
    try {
      const statementDate = { statementDate: value.statementDate };
      await postJson(`${API_URL}/projects/${projectId}/loans/adj`, statementDate);
      notification.success({
        message: intl.formatMessage({ id: 'adj.success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="utils__title">
          <strong>
            <FormattedMessage id="form.header.adj" />
          </strong>
        </div>
      </div>
      <div className="card-body">
        <Form layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label={<FormattedMessage id="statementDate" />}
            name="statementDate"
            rules={[{ required: true }]}
          >
            <DateOnlyPicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="submit" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => {
  return { projectId: user.currentProjectId };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AdjComponent);
