import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Row, Col, notification, Input, Select, Descriptions, Checkbox } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { datediff } from '../../../../helpers/date/date';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { getRestructureType, getPaymentType } from '../../../../services/utilities/Choice';
import InputBaht from '../../common/InputBaht';
import InputPercent from '../../common/InputPercent';
import { satangToBath } from '../../../../helpers/format/satangToBath';

const { Option } = Select;

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  lastTransaction: any;
  loan: any;
}

const FilingModal: FC<Props> = ({
  projectId,
  visible,
  onCancel,
  cancelText,
  okText,
  loan,
  lastTransaction,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;
  const [cfPrincipal, setCfPrincipal] = useState<any>('');
  const [cfInterest, setCfInterest] = useState<any>('');
  const [cfFee, setCfFee] = useState<any>('');
  const [osb, setOsb] = useState<any>('');

  function renderOptions(type: string, optionKeys: string[]): JSX.Element[] {
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index]} value={optionKeys[index]}>
          <FormattedMessage id={`${type}.${optionKeys[index]}`} />
        </Option>,
      );
    }
    return options;
  }
  const calculateOsb = (asOfDate: string, intRate: number, lastTransaction: any) => {
    const { cfPrincipal, cfInterest, cfFee } = lastTransaction;
    const principal = cfPrincipal / 100;
    const duration = datediff(asOfDate, lastTransaction.transDate);
    const dueInterest = ((cfPrincipal * intRate) / 365) * duration;
    const interest = (cfInterest + dueInterest) / 100;
    const fee = cfFee / 100;

    const total = principal + interest + fee;
    const mapCFPrincipal = Number(principal * 100).toFixed(0);
    const mapCfInterest = Number(interest * 100).toFixed(0);
    const mapCfFee = Number(fee * 100).toFixed(0);
    const mapOsb = Number((principal + interest + fee) * 100).toFixed(0);
    setCfPrincipal(mapCFPrincipal);
    setCfInterest(mapCfInterest);
    setCfFee(mapCfFee);
    setOsb(mapOsb);

    return {
      asOfDate,
      total: total.toFixed(2),
      principal: principal.toFixed(2),
      interest: interest.toFixed(2),
      fee: fee.toFixed(2),
    };
  };
  const calcInstallAmount = (principal: any, intRate: any, installTerm: any) => {
    const mapPrincipal = Number(principal);
    const mapIntRate = Number(intRate);
    const mapInstallTerm = Number(installTerm);

    // Interest rate is 0 just divide principal equally througout install term period
    if (mapIntRate === 0) {
      return mapPrincipal / mapInstallTerm;
    }

    const intPerMonth = mapIntRate / 12;
    const installAmount =
      mapPrincipal / ((1 - 1 / (1 + intPerMonth) ** mapInstallTerm) / intPerMonth);
    return installAmount;
  };
  const onRestructureDateChange = (effectiveDate: any) => {
    calculateOsb(effectiveDate, loan.intRate, lastTransaction);
  };
  const onFinish = async (value: Store) => {
    console.log(value);
    try {
      const {
        type: typeRestructure,
        restructureDate,
        newCfPrincipal,
        newCfInterest,
        newCfFee,
        intRate,
        lateFee,
        paymentType,
        creditLimit,
        // can be null
        judgementDate,
        redCaseNo,
        minPayRate,
        minPayAmount,
        installTerm,
        installAmount,
        payWithRestructure,
      } = value;
      let values: any = {
        effectiveDate: restructureDate,
        loanId: loan.id,
        type: typeRestructure,
        restructureDate,
        cfPrincipal,
        cfInterest,
        cfFee,
        newCfPrincipal,
        newCfInterest,
        newCfFee,
        intRate,
        lateFee,
        paymentType,
        creditLimit,
      };
      values = judgementDate ? { ...values, judgementDate } : values;
      values = redCaseNo ? { ...values, redCaseNo } : values;
      values = minPayRate ? { ...values, minPayRate } : values;
      values = minPayAmount ? { ...values, minPayAmount } : values;
      values = installTerm ? { ...values, installTerm } : values;
      values = installAmount ? { ...values, installAmount } : values;
      values = payWithRestructure ? { ...values, payWithRestructure } : values;

      const type = 'restructure';
      await postJson(`${API_URL}/projects/${projectId}/request-approvals`, { values, type });
      form.resetFields();
      notification.success({ message: intl.formatMessage({ id: 'restructure.success-message' }) });
      onCancel();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const calNewOsb = () => {
    const newCfPrincipal = getFieldValue('newCfPrincipal');
    const newCfInterest = getFieldValue('newCfInterest');
    const newCfFee = getFieldValue('newCfFee');
    const creditLimit = Number(newCfPrincipal) + Number(newCfInterest) + Number(newCfFee);

    if (creditLimit) {
      setFieldsValue({ creditLimit });
    }
  };

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="restructure" />
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="type" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="type"
              rules={[{ required: true }]}
            >
              <Select
                onChange={value => {
                  if (value === 'installment') {
                    setFieldsValue({ minPayRate: 0, minPayAmount: 0 });
                  }
                }}
              >
                {renderOptions('restructureType', getRestructureType())}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="restructureDate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="restructureDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" onChange={onRestructureDateChange} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Descriptions title={<FormattedMessage id="currentOsb" />}>
              <Descriptions.Item label={<FormattedMessage id="cfPrincipal" />}>
                {satangToBath(cfPrincipal)}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="cfInterest" />}>
                {satangToBath(cfInterest)}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="cfFee" />}>
                {satangToBath(cfFee)}
              </Descriptions.Item>
              <Descriptions.Item label={<FormattedMessage id="osb" />}>
                {satangToBath(osb)}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item
                  label={<FormattedMessage id="newCfPrincipal" />}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 20 }}
                  rules={[{ required: true }]}
                  name="newCfPrincipal"
                >
                  <InputBaht
                    onChange={() => {
                      calNewOsb();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={<FormattedMessage id="newCfInterest" />}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 20 }}
                  rules={[{ required: true }]}
                  name="newCfInterest"
                >
                  <InputBaht
                    onChange={() => {
                      calNewOsb();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={<FormattedMessage id="newCfFee" />}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 20 }}
                  rules={[{ required: true }]}
                  name="newCfFee"
                >
                  <InputBaht
                    onChange={() => {
                      calNewOsb();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="creditLimit" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="creditLimit"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="intRate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="intRate"
              rules={[{ required: true }]}
            >
              <InputPercent />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="lateFee" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="lateFee"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item shouldUpdate noStyle>
              {() => {
                return (
                  <Row
                    gutter={24}
                    style={{
                      display:
                        getFieldValue('type') === 'settlementAgreement' ||
                        getFieldValue('type') === 'defaultJudgement'
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <Col span={24}>
                      <Form.Item
                        label={<FormattedMessage id="judgementDate" />}
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 20 }}
                        name="judgementDate"
                        rules={
                          getFieldValue('type') === 'settlementAgreement' ||
                          getFieldValue('type') === 'defaultJudgement'
                            ? [{ required: true }]
                            : [{ required: false }]
                        }
                      >
                        <DateOnlyPicker format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="paymentType" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="paymentType"
              rules={[{ required: true }]}
            >
              <Select
                onChange={value => {
                  if (value === 'installment') {
                    setFieldsValue({ minPayRate: 0, minPayAmount: 0 });
                  }
                }}
              >
                {renderOptions('paymentType', getPaymentType())}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item shouldUpdate noStyle>
              {() => {
                return (
                  <Row
                    gutter={24}
                    style={{
                      display: getFieldValue('paymentType') === 'revolving' ? 'flex' : 'none',
                    }}
                  >
                    <Col span={12}>
                      <Form.Item
                        label={<FormattedMessage id="minPayRate" />}
                        name="minPayRate"
                        shouldUpdate
                        rules={
                          getFieldValue('paymentType') === 'revolving'
                            ? [{ required: true }]
                            : [{ required: false }]
                        }
                      >
                        <InputPercent />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={<FormattedMessage id="minPayAmount" />}
                        name="minPayAmount"
                        shouldUpdate
                        rules={
                          getFieldValue('paymentType') === 'revolving'
                            ? [{ required: true }]
                            : [{ required: false }]
                        }
                      >
                        <InputBaht />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {() => {
                return (
                  <Row
                    gutter={24}
                    style={{
                      display: getFieldValue('paymentType') === 'installment' ? 'flex' : 'none',
                    }}
                  >
                    <Col span={24}>
                      <Form.Item
                        label={<FormattedMessage id="installTerm" />}
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 20 }}
                        name="installTerm"
                        shouldUpdate
                        rules={
                          getFieldValue('paymentType') === 'installment'
                            ? [{ required: true }]
                            : [{ required: false }]
                        }
                      >
                        <Input
                          onChange={() => {
                            const creditLimit = getFieldValue('creditLimit');
                            const intRate = getFieldValue('intRate');
                            const installTerm = getFieldValue('installTerm');
                            const inAmount = calcInstallAmount(creditLimit, intRate, installTerm);
                            setFieldsValue({ installAmount: inAmount });
                          }}
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={<FormattedMessage id="installAmount" />}
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 20 }}
                        name="installAmount"
                        shouldUpdate
                        rules={
                          getFieldValue('paymentType') === 'installment'
                            ? [{ required: true }]
                            : [{ required: false }]
                        }
                      >
                        <InputBaht />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="redCaseNo" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="redCaseNo"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="payWithRestructure" valuePropName="checked">
              <Checkbox>
                <FormattedMessage id="payWithRestructure" />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FilingModal;
