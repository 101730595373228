// Products

/**
 * Get loan types.
 * @returns {string[]} loanTypes - All available types of loan.
 */
export function getLoanType(): string[] {
  const loanTypes = ['nano', 'ploan', 'staff'];
  return loanTypes;
}
/**
 * Get payment types.
 * @returns {string[]} paymentTypes - All available types of payment.
 */
export function getPaymentType(): string[] {
  const paymentTypes = ['revolving', 'installment'];
  return paymentTypes;
}
/**
 * Get interest types.
 * @returns {string[]} interestTypes - All available types of interest.
 */
export function getInterestType(): string[] {
  // const interestTypes = ['effective', 'flat'];
  const interestTypes = ['effective'];
  return interestTypes;
}

// Sales

/**
 * Get loan types.
 * @returns {string[]} loanTypes - All available types of loan.
 */
export function getAccountType(): string[] {
  const loanTypes = ['saving', 'current', 'fixed'];
  return loanTypes;
}

// Sms

/**
 * Get sms notify approval types.
 * @returns {string[]} smsApprovalStatus - status of sms notify approval.
 */
export function getSmsNotifyApprovalType(): string[] {
  const notifyApprovalTypes = ['approve', 'reject'];
  return notifyApprovalTypes;
}

/**
 * Get sms notify cash types.
 * @returns {string[]} smsCashStatus - status of sms notify cash.
 */
export function getSmsNotifyCashType(): string[] {
  const notifyApprovalTypes = ['cashin', 'cashout'];
  return notifyApprovalTypes;
}

/**
 * Get restructure types.
 * @returns {string[]} paymentTypes - All available types of payment.
 */
export function getRestructureType(): string[] {
  const restructureTypes = ['debtRestructuring', 'sam', 'settlementAgreement', 'defaultJudgement'];
  return restructureTypes;
}

export function getStampFeePayer(): string[] {
  const stampFeePayer = ['borrower', 'lender'];
  return stampFeePayer;
}
