import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ImportWriteoffsFrom from '../../components/CbsComponents/Imports/Writeoffs/ImportWriteoffsForm';

const ImportWriteOff: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importWriteoff',
      href: `/import/writeoffs`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="menu.importWriteoff"
      pageTitle="menu.imports"
      breadcrumbs={breadcrumbs}
    >
      <ImportWriteoffsFrom />
    </ContentContainer>
  );
};

export default ImportWriteOff;
