import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Input, AutoComplete, Select, Button } from 'antd';
import DateOnlyPicker from '../../../../common/DateOnlyPicker';
import componentValues from '../../../../../../services/utilities/ComponentValue';
import { API_URL, postJson, showError } from '../../../../../../helpers/api/api';
import { ErrorInfo } from '../../../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../../../helpers/form';
import InputBaht from '../../../../common/InputBaht';

interface Props {
  application: any;
  getDataSource: any;
}

const { TextArea } = Input;
const { Option } = Select;

const AddNote: React.FC<Props> = ({ application, getDataSource }) => {
  const { id, projectId, citizenId, firstName, lastName, mobileNo } = application;
  const [form] = Form.useForm();
  const intl = useIntl();
  const onFinish = async (values: any) => {
    const note = {
      ...values,
      citizenId,
      appId: id,
      firstName,
      lastName,
      mobileNo,
    };
    try {
      await postJson(`${API_URL}/projects/${projectId}/notes`, note);
      getDataSource();
      form.resetFields();
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const dataSource = [
    'ปัญหาการให้บริการเสนอขาย', // medium
    'ติดตามทวงถามหนี้', // medium
    'ไม่ได้รับใบแจ้งยอด', // high
    'ผลการพิจารณา เกิน 3 วันทำการ', // high
    'สอบถามยอดเงินโอนและวันที่โอนเงิน', // low
    'ขอเพิ่มวงเงิน/เบิกถอนวงเงิน', // low
    'สอบถามประเภทสินเชื่อ/เอกสารประกอบการสมัคร', // low
    'สอบถามช่องทางการชำระเงิน/วิธีการชำระเงิน', // low
    'สอบถามวันที่ชำระเงินงวดแรก/ยอดผ่อนชำระ', // low
    'สอบถามสถานะใบสมัคร/ช่องทางการส่งใบสมัคร', // low
    'แจ้งเปลี่ยนแปลงที่อยู่และข้อมูลส่วนตัว', // low
    'ร้องเรียนเรื่องทุจริต', // high
    'สอบถามยอดการชำระ/ใบแจ้งยอด',
    'เบิกถอนวงเงิน/เพิ่มวงเงิน',
    'ปิดบัญชีถาวร/ชั่วคราว',
    'เปลี่ยนเบอร์มือถือ/เบอร์ที่ทำงาน',
    'เปลี่ยนแปลงที่อยู่/บุคคลอ้างอิง',
    'เปลี่ยนชื่อ-นามสกุล',
    'เปลี่ยนบัญชีธนาคารที่ใช้โอนเงิน',
    'สอบถามการสมัครสินเชื่อ',
    'สอบถามช่องการชำระเงิน/วิธีการชำระเงิน',
    'สอบถามประเภทสินเชื่อ/ช่องทางการส่งใบสมัคร',
    'สอบถามผลการพิจารณา/สถานะใบสมัคร',
    'ติดตามทวงถามหนี้',
    'อื่นๆ', // unknow
  ];
  const channelDataSource = [
    'Line',
    'Facebook',
    'Mobile Phone',
    'SMS',
    'OC - โทรออก',
    'LC - รับสาย',
  ];
  const priorityDataSource = ['low', 'medium', 'high'];

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="category" label={<FormattedMessage id="note.category" />}>
              <AutoComplete dataSource={dataSource} placeholder="ปัญหา" />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="followupDate" label={<FormattedMessage id="note.followupDate" />}>
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="channel" label={<FormattedMessage id="note.channel" />}>
              <AutoComplete dataSource={channelDataSource} placeholder="ช่องทางการติดต่อ" />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="priority" label={<FormattedMessage id="note.priority" />}>
              <AutoComplete dataSource={priorityDataSource} placeholder="ความสำคัญ" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="repayDate" label={<FormattedMessage id="note.repayDate" />}>
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="repayAmount" label={<FormattedMessage id="note.repayAmount" />}>
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item name="followupAmount" label={<FormattedMessage id="note.followupAmount" />}>
              <InputBaht />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              name="status"
              label={<FormattedMessage id="note.status" />}
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="AA">AA - ชำระแล้ว</Option>
                <Option value="AB">AB - นัดชำระ</Option>
                <Option value="BA">BA - อยู่ในระหว่างการเจรจา</Option>
                <Option value="BB">BB - ติดต่อลูกค้าไม่ได้</Option>
                <Option value="BK">BK - ผิดนัดชำระ</Option>
                <Option value="TL14">TL14 - คัด ทร14</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item
              name="message"
              label={<FormattedMessage id="note.message" />}
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Message" style={{ width: '800px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddNote;
