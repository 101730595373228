import React from 'react';
import ProjectSelector from './ProjectSelector/ProjectSelector';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import styles from './topbar.module.scss';

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-auto">
          <ProjectSelector />
        </div>
        <div className="mr-4">
          <LanguageSelector />
        </div>
        <ProfileMenu />
      </div>
    );
  }
}

export default TopBar;
