import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import SmsWithdrawForm from '../../components/CbsComponents/Sms/SmsWithdrawForm';

const SmsWithdraw: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'sms-withdraw.page-title',
      href: `/sms/withdraw`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="sms-withdraw.page-title"
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsWithdrawForm />
    </ContentContainer>
  );
};

export default SmsWithdraw;
