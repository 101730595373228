import antdData from 'antd/lib/locale-provider/th_TH';

const messages = {
  // Common use
  'common.name': 'ชื่อ',
  'common.name.inputMessage': 'กรุณาใส่ชื่อ!',
  'common.lastName': 'นามสกุล',
  'common.lastName.inputMessage': 'กรุณาใส่นามสกุล!',
  'common.email': 'อีเมล',
  'common.email.invalidMessage': 'ไม่ใช่อีเมลที่ถูกต้อง!',
  'common.email.inputMessage': 'กรุณาใส่อีเมล!',
  'common.mobileNo': 'เบอร์มือถือ',
  'common.mobileNo.inputMessage': 'กรุณาใส่เบอร์มือถือ!',
  'common.mobileNo.invalidMessage': 'ไม่ใช่เบอร์มือถือที่ถูกต้อง!',
  'common.description': 'รายละเอียด',
  'common.description.inputMessage': 'กรุณาใส่รายละเอียด!',
  'common.role': 'ตำแหน่ง',
  'common.role.placeholder': 'กรุณาเลือกตำแหน่ง',
  'common.role.inputMessage': 'กรุณาเลือกตำแหน่งอย่างน้อย 1 ตำแหน่ง!',
  'common.id': 'รหัส',
  'common.id.inputMessage': 'กรุณาใส่รหัส!',
  'common.action': 'แอ๊คชั่น',
  'common.delete': 'ลบ',
  'common.yes': 'ใช่',
  'common.no': 'ไม่ใช่',
  'common.step': 'ขั้นตอน',
  'common.next': 'ถัดไป',
  'common.previous': 'ก่อนหน้า',
  'common.done': 'เสร็จสิ้น',
  'common.day': 'วัน',
  'comon.send': 'ส่ง',
  'comon.sendTo': 'ส่งถึง',
  'common.date': 'วันที่',
  'common.month': 'เดือน',

  // Menu
  'menu.home': 'หน้าหลัก',
  'menu.dashboard': 'สรุป',
  'menu.products': 'ผลิตภัณฑ์',
  'menu.addProduct': 'เพิ่มผลิตภัณฑ์',
  'menu.projects': 'โครงการ',
  'menu.addProjects': 'เพิ่มโครงการ',
  'menu.policies': 'ผู้ใช้งาน',
  'menu.addPolicy': 'เพิ่มผู้ใช้งาน',
  'menu.roles': 'สิทธิการใช้งาน',
  'menu.sales': 'พนักงานขาย',
  'menu.addSale': 'เพิ่มพนักงานขาย',
  'menu.undefined': ' ',
  'menu.applications': 'ใบสมัคร',
  'menu.addApplication': 'เพิ่มใบสมัคร',
  'menu.loans': 'สินเชื่อ',
  'menu.withdraws': 'เบิกถอน',
  'menu.imports': 'นำเข้าข้อมูล',
  'menu.setting': 'ตั้งค่า',
  'menu.adj': 'ตัดรอบใบแจ้งหนี้',
  'menu.acc': 'ตัดรอบข้อมูลบัญชี',
  'menu.statementCsv': 'พิมพ์ใบแจ้งหนี้ CSV',
  'menu.statementPdf': 'พิมพ์ใบแจ้งหนี้ PDF',
  'menu.importstab': 'นำเข้าข้อมูล',
  'menu.importApplications': 'นำเข้าใบสมัคร',
  'menu.importPayments': 'นำเข้าการชำระเงิน',
  'menu.importDisbursements': 'นำเข้าการเบิกถอน',
  'menu.importCollectorLoans': 'นำเข้าข้อมูลผู้ติดตามสัญญา',
  'menu.importWriteoff': 'นำเข้ารายการตัดหนี้สูญ',

  'menu.reports': 'รายงาน',
  'menu.sms': 'ข้อความ',
  'menu.importCreditreports': 'ข้อมูลเครดิต',
  'menu.requestapprovals': 'รายการรออนุมัติ',
  'menu.dashboards': 'ภาพรวม',
  'menu.sms.history': 'ประวัติข้อความ',
  'menu.sms.statement': 'แจ้งเตือนการชำระหนี้ ',
  'menu.sms.loanClose': 'แจ้งผลการปิดสัญญา',
  'menu.sms.waitConfirm': 'แจ้งสถานะใบสมัคร',
  'menu.sms.estatement': 'ส่งใบแจ้งหนี้อิเล็กทรอนิกส์',
  'menu.sms.withdraw': 'แจ้งสถานะการเบิกถอน',

  // Dashboards
  'dashboards.rangetime': 'ช่วงวันที่',
  'dashboards.all': 'ทั้งหมด',
  'dashboards.confirm': 'อนุมัติ',
  'dashboards.waitConfirm': 'ระหว่างรออนุมัติ',
  'dashboards.reject': 'ปฏิเสธ',
  'dashboards.approvalRate': 'อัตราอนุมัติ',

  // Form
  'form.header.policies': 'ข้อมูลิทธิการเข้าถึง',
  'form.header.products': 'ข้อมูลผลิตภัณฑ์',
  'form.header.roles': 'ข้อมูลตำแหน่ง',
  'form.header.sales': 'ข้อมูลผู้ขาย',
  'form.header.projects': 'ข้อมูลโครงการ',
  'form.login': 'ลงชื่อเข้าใช้',
  'form.register': 'ลงทะเบียน',
  'form.password': 'รหัสผ่าน',
  'form.password.inputMessage': 'กรุณาใส่รหัสผ่าน!',
  'form.add': 'เพิ่ม',
  'form.save': 'บันทึก',
  'form.cancel': 'ยกเลิก',
  'form.reject': 'ปฏิเสธ',
  'form.submit': 'เสนอ',
  'form.header.adj': 'ตัดรอบใบแจ้งหนี้',
  'form.header.acc': 'ตัดรอบข้อมูลบัญชี',
  'form.header.statement': 'ออกใบแจ้งหนี้',
  'form.header.report': 'รายงานข้อมูล',

  // Currency unit
  'currency.thaiBaht': 'บาท',

  // Account Type
  'account.accountNo': 'เลขบัญชีธนาคาร',
  'account.accountNo.inputMessage': 'กรุณาใส่เลขบัญชีธนาคารของคุณ!',
  'account.accountNo.invalidMessage': 'ไม่ใช่เลขที่บัญชีธนาคารที่ถูกต้อง!',
  'account.accountName': 'ชื่อบัญชีธนาคาร',
  'account.accountName.inputMessage': 'กรุณาใส่ชื่อบัญชีธนาคารของคุณ!',
  'account.accountType': 'ประเภทบัญชี',
  'account.accountType.placeholder': 'กรุณาเลือกประเภทบัญชี',
  'account.accountType.inputMessage': 'กรุณาเลือกประเภทบัญชีของคุณ!',
  'account.saving': 'ออมทรัพย์',
  'account.current': 'กระแสรายวัน',
  'account.fixed deposit': 'ฝากประจำ',

  // Formatted use in Ncb
  'ncb.ncb.ncbButton': 'เรียกดูNCB',
  'ncb.ncb.lastDate': 'วันที่เรียกดูล่าสุด',
  'ncb.ncb.paymentHistoryHeader': 'ประวัติการชำระ (12 เดือน)',
  'ncb.ncb.realPaymentHistoryHeader': 'ประวัติการชำระจริง',
  'ncb.ncb.overDueBalance6monthHeader': 'ค้างชำระ 6 เดือนย้อนหลัง',
  'ncb.ncb.outstandingBalanceHeader': 'ยอดหนี้คงเหลือ',
  'ncb.ncb.minimumPaymentHeader': 'ชำระขั้นต่ำ',
  'ncb.ncb.openDateHeader': 'เปิดบัญชี',
  'ncb.ncb.lastPaymentDateHeader': 'ชำระหนี้ล่าสุด',
  'ncb.ncb.loansHeader': 'สินเชื่อทั้งหมด',
  'ncb.ncb.loanAccount': 'บัญชี',
  'ncb.ncb.openLoanTitle': 'เปิด',
  'ncb.ncb.closeLoanTitle': 'ปิด',
  'ncb.ncb.kindLoanTitle': 'เคยเป็นลูกค้าสินเชื่อใจดี',
  'ncb.ncb.loanWithoutCarAndHouseTitle': 'สินเชื่อที่ไม่รวมบ้านกับรถ',
  'ncb.ncb.enquiryHistory3monthTitle': 'ประวัติการสืบค้นย้อนหลัง 3 เดือน',
  'ncb.ncb.newApplicationTitle': 'อนุมัติสินเชื่อใหม่',
  'ncb.ncb.reviewCreditTitle': 'ทบทวนสินเชื่อ',
  'ncb.ncb.institution': 'สถาบัน',
  'ncb.ncb.amountMinimumPaymentTitle': 'ยอดชำระขั้นต่ำ',
  'ncb.ncb.currency': 'บาท',
  'ncb.ncb.case': 'ราย',

  // Formatted use in Profile
  'ncb.profile.profileHeader': 'ข้อมูลส่วนตัว',
  'ncb.profile.nameTitle': 'ชื่อ-นามสกุล',
  'ncb.profile.letterOfConsentTitle': 'หนังสือให้ความยินยอม',
  'ncb.profile.letterOfConsentStatusundefined': 'ไม่มีข้อมูล',
  'ncb.profile.letterOfConsentStatusTitle': 'สถานะหนังสือให้ความยินยอมฯ',
  'ncb.profile.letterOfConsentStatusY': 'มี',
  'ncb.profile.letterOfConsentStatusN': 'ไม่มี',
  'ncb.profile.letterOfConsentStatusC': 'ยกเลิก',
  'ncb.profile.identificationNumberTitle': 'เลขประจำตัว',
  'ncb.profile.idTitleundefined': 'ไม่ระบุ',
  'ncb.profile.idTitle0': 'ไม่ระบุ',
  'ncb.profile.idTitle1': 'บัตรประชาชน',
  'ncb.profile.idTitle2': 'บัตรประจำตัวประชาชนเจ้าหน้าที่รัฐ',
  'ncb.profile.idTitle3': 'Enterprise',
  'ncb.profile.idTitle4': 'Trade No.',
  'ncb.profile.idTitle5': 'บัตรประจำตัวผู้ไม่มีสัญชาติไทย',
  'ncb.profile.idTitle6': 'พาสปอร์ตประเทศไทย',
  'ncb.profile.idTitle7': 'พาสปอร์ตต่างประเทศ',
  'ncb.profile.idTitle9': 'อื่นๆ',
  'ncb.profile.personalInformationTitle': 'ข้อมูลบุคคล',
  'ncb.profile.dateOfBirthTitle': 'วันเดือนปีเกิด',
  'ncb.profile.nationalityTitle': 'สัญชาติ',
  'ncb.profile.nationalityundefined': 'ไม่มีข้อมูล',
  'ncb.profile.nationality0': 'ไม่ระบุ',
  'ncb.profile.nationality1': 'ไทย',
  'ncb.profile.nationality2': 'จีน',
  'ncb.profile.nationality3': 'ญี่ปุ่น',
  'ncb.profile.nationality4': 'อเมริกา',
  'ncb.profile.nationality99': 'อื่นๆ',
  'ncb.profile.genderTitle': 'เพศ',
  'ncb.profile.genderundefined': 'ไม่มีข้อมูล',
  'ncb.profile.gender1': 'หญิง',
  'ncb.profile.gender2': 'ชาย',
  'ncb.profile.maritalTitle': 'สถานะสมรส',
  'ncb.profile.maritalStatusundefined': 'ไม่มีข้อมูล',
  'ncb.profile.maritalStatus0': 'ไม่ระบุ',
  'ncb.profile.maritalStatus1': 'สมรส',
  'ncb.profile.maritalStatus2': 'โสด',
  'ncb.profile.maritalStatus3': 'เป็นม่าย',
  'ncb.profile.maritalStatus4': 'หย่าร้าง',
  'ncb.profile.numberOfChildrenTitle': 'จำนวนบุตร',
  'ncb.profile.occupationTitle': 'อาชีพ',
  'ncb.profile.occupationundefined': 'ไม่มีข้อมูล',
  'ncb.profile.occupation0': 'ไม่ระบุ',
  'ncb.profile.occupation1': 'ลูกจ้าง',
  'ncb.profile.occupation2': 'พนักงานรัฐวิสาหกิจ',
  'ncb.profile.occupation3': 'ข้าราชการ',
  'ncb.profile.occupation4': 'จ้างตัวเอง',
  'ncb.profile.occupation5': 'อาชีพเฉพาะทาง',
  'ncb.profile.occupation6': 'นายหน้า',

  // Formatted use in Address
  'ncb.address.addressHeader': 'ข้อมูลที่อยู่',
  'ncb.address.tableColumnAddress': 'ที่อยู่',
  'ncb.address.tableColumnAddressType': 'ประเภทที่อยู่',
  'ncb.address.tableColumnAddressStatus': 'สถานะ',
  'ncb.address.tableColumnDate': 'วันที่รายงาน',
  'ncb.address.addressTypeundefined': ' ',
  'ncb.address.addressType0': 'Undefined',
  'ncb.address.addressType1': 'Home Registration',
  'ncb.address.addressType2': 'Mailing Address',
  'ncb.address.addressType3': 'ID Registration',
  'ncb.address.addressType4': 'Office Address',
  'ncb.address.residentialStatusundefined': ' ',
  'ncb.address.residentialStatus0': 'Unspecified',
  'ncb.address.residentialStatusP': 'Living with relation',
  'ncb.address.residentialStatusH': 'Owns',
  'ncb.address.residentialStatusR': 'Rents',
  'ncb.address.residentialStatusC': 'Company Provided',
  'ncb.address.residentialStatusF': 'Owns - Mortgage',

  // Formatted use in Account, AccountRenderer and PaymentHistory
  'ncb.account.accountDescriptionHeader': 'รายละเอียดบัญชี',
  'ncb.account.lenderTitle': 'ผู้ให้สินเชื่อ',
  'ncb.account.memberTypeTitle': 'ประเภทสมาชิก',
  'ncb.account.informationDate': 'ข้อมูล ณ วันที่',
  'ncb.account.accountNumber': 'เลขที่บัญชี',
  'ncb.account.loanTypeTitle': 'ประเภทสินเชื่อ',
  'ncb.account.accountOpenedDate': 'วันที่เปิดบัญชี',
  'ncb.account.debtRestructuringDate': 'วันที่ปรับปรุงโครงสร้างหนี้',
  'ncb.account.accountClosedDate': 'วันที่ปิดบัญชี',
  'ncb.account.creditCardTypeTitle': 'ประเภทของบัตรเครดิต',
  'ncb.account.creditCardType1': 'VISA',
  'ncb.account.creditCardType2': 'DC (Diner Club)',
  'ncb.account.creditCardType3': 'MC (Master Card)',
  'ncb.account.creditCardType4': 'AMEX (Charged Card)',
  'ncb.account.creditCardType5': 'AMEX (Credit Card)',
  'ncb.account.creditCardType6': 'JCB',
  'ncb.account.creditCardType13': 'VSMC (VISA/MC)',
  'ncb.account.creditCardType15': 'VSJC (VISA,JCB)',
  'ncb.account.creditCardType20': 'LBCB (Local Bank Credit Card)',
  'ncb.account.creditCardType21': 'VSL (VISA/Local Bank Credit Card)',
  'ncb.account.creditCardType23': 'MSL (MC/Local Bank Credit Card)',
  'ncb.account.creditCardType25': 'JSL (JCB/Local Bank Credit Card)',
  'ncb.account.creditCardType26': 'AMEX BBL',
  'ncb.account.creditCardType35': 'MCJC (MC/JCB)',
  'ncb.account.creditCardType41': 'VML (VISA/MC/LBCD)',
  'ncb.account.creditCardType42': 'VJL (VISA/JCB/LBCD)',
  'ncb.account.creditCardType43': 'MJL (MC/JCB/LBCD)',
  'ncb.account.creditCardType44': 'VMJ (VISA/MC/JCB)',
  'ncb.account.creditCardType60': 'VMJL (VISA/MC/JCB/LBCD)',
  'ncb.account.creditCardType65': 'CCC (Central Card)',
  'ncb.account.creditCardType66': 'Power Buy Card',
  'ncb.account.creditCardType67': 'First Choice Card',
  'ncb.account.creditCardType68': 'Department Store Card',
  'ncb.account.creditCardType69': 'Other Private Label Card',
  'ncb.account.creditCardType99': 'Other',
  'ncb.account.creditCardTypeundefined': 'ไม่มีข้อมูล',
  'ncb.account.ownershipIndicatorTitle': 'ลักษณะการกู้',
  'ncb.account.ownershipIndicatorundefined': 'ไม่มีข้อมูล',
  'ncb.account.ownershipIndicator1': 'Individual',
  'ncb.account.ownershipIndicator2': 'Supplementary Card',
  'ncb.account.ownershipIndicator4': 'Joint',
  'ncb.account.ownershipIndicatorA': 'DSPT Individual',
  'ncb.account.ownershipIndicatorB': 'DSPT Supplementary Card',
  'ncb.account.ownershipIndicatorC': 'Remark Closed Account at AMC',
  'ncb.account.ownershipIndicatorD': 'DSPT Joint',
  'ncb.account.ownershipIndicatorF': 'INVS Individual',
  'ncb.account.ownershipIndicatorG': 'INVS Supplementary Card',
  'ncb.account.ownershipIndicatorH': 'INVS Authorized',
  'ncb.account.ownershipIndicatorI': 'INVS Joint',
  'ncb.account.numberOfCoBorrowersTitle': 'จำนวนผู้กู้ร่วม',
  'ncb.account.creditLimitTitle': 'วงเงินสินเชื้อ/วงเงินบัตรเครดิต',
  'ncb.account.installmentAmountTitle': 'ยอดผ่อนชำระในแต่ละงวด/ยอดชำระในแต่ละงวด',
  'ncb.account.installmentNumberOfPaymentTitle': 'จำนวนงวดที่ต้องผ่อนชำระ',
  'ncb.account.installmentFrequencyundefined': ' ',
  'ncb.account.installmentFrequency0': 'ไม่ระบุ',
  'ncb.account.installmentFrequency1': 'ต่อสัปดาห์',
  'ncb.account.installmentFrequency2': 'ต่อ2อาทิตย์',
  'ncb.account.installmentFrequency3': 'ต่อเดือน',
  'ncb.account.installmentFrequency4': 'ต่อ2เดือน',
  'ncb.account.installmentFrequency5': 'ต่อ3เดือน',
  'ncb.account.installmentFrequency6': 'ต่อครึ่งเดือน',
  'ncb.account.installmentFrequency7': 'กรณีพิเศษ',
  'ncb.account.installmentFrequency8': 'ต่อครึ่งปี',
  'ncb.account.installmentFrequency9': 'ต่อปี',
  'ncb.account.minimumInstallmentTitle': 'ยอดขั้นต่ำที่ต้องผ่อนชำระ(%)',
  'ncb.account.unitMakeTitle': 'ยี่ห้อ',
  'ncb.account.unitModelTitle': 'รุ่น',
  'ncb.account.creditTypeFlagTitle': 'หลักประกัน',
  'ncb.account.outstandingBalanceTitle': 'ยอดหนี้คงเหลือ/ยอดเงินที่ใช้',
  'ncb.account.overdueBalanceTitle': 'ยอดหนี้ที่เกินกำหนดชำระ',
  'ncb.account.dateOfLastPaymentTitle': 'วันที่ชำระหนี้ครั้งล่าสุด',
  'ncb.account.defaultDateTitle': 'วันที่เริ่มมีการผิดนัดชำระ',
  'ncb.account.accountStatusHeader': 'สถานะ',
  'ncb.account.objectiveHeader': 'วัตถุประสงค์',
  'ncb.account.objecttiveTitle': 'วัตถุประสงค์',
  'ncb.account.paymentHistoryHeader': 'ประวัติการชำระ',
  'ncb.account.month': 'เดือน',
  'ncb.account.tableColumnPaymentType': 'ลักษณะการชำระ',
  'ncb.account.overdueBalanceInformationHeader': 'ข้อมูลการค้างชำระ(36เดือน)',

  // Forrmatted message use in Enquiry
  'ncb.enquiryHistory.enquiryHistoryHeader': 'ประวัติการสืบค้น',
  'ncb.enquiryHistory.tableColumnDate': 'วัน/เวลา',
  'ncb.enquiryHistory.tableColumnLender': 'ผู้ให้สินเชื่อ',
  'ncb.enquiryHistory.tableColumnEnquiryObjective': 'วัตถุประสงค์การสืบค้น',
  'ncb.enquiryHistory.searchObjective1': '01:อนุมัติสินเชื่อใหม่',
  'ncb.enquiryHistory.searchObjective2': '02:ทบทวนสินเชื่อ',

  // Formatted message use more than 1 page
  'ncb.common.accountStatusTitle': 'สถานะบัญชี',
  'ncb.common.noData': 'ไม่มีข้อมูล',
  'ncb.common.accountHeader': 'ข้อมูลบัญชี',
  'ncb.common.loanTypeundefined': 'ไม่มีข้อมูล',
  'ncb.common.loanType1': 'สินเชื่อเพื่อการพาณิชย์',
  'ncb.common.loanType4': 'สินเชื่อวงเงินเบิกเกินบัญชี',
  'ncb.common.loanType5': 'สินเชื่อบุคคล',
  'ncb.common.loanType6': 'สินเชื่อที่อยู่อาศัย',
  'ncb.common.loanType7': 'สินเชื่อเพื่อซื้อหลักทรัพย์',
  'ncb.common.loanType8': 'สินเชื่อตั๋วเงินในประเทศ',
  'ncb.common.loanType9': 'สินเชื่อซื้อลดตั๋วเงิน',
  'ncb.common.loanType10': 'สินเชื่อรับซื้อตั๋วเงินค่าสินค้าเข้า',
  'ncb.common.loanType11': 'สินเชื่อรับซื้อตั๋วเงินค่าสินค้าส่งออก',
  'ncb.common.loanType12': 'สินเชื่อการรับซื้อตั๋วเงินค่าสินค้านำเข้าที่ทำทรัสต์รีซีท',
  'ncb.common.loanType13': 'สินเชื่อรับซื้อตั๋วเงินอื่นๆ',
  'ncb.common.loanType14': 'สินเชื่อเพื่อเตรียมการส่งออกตาม L/C',
  'ncb.common.loanType15': 'เงินชดใช้ตามภาระผูกพัน - Aval',
  'ncb.common.loanType16': 'เงินชดใช้ตามภาระผูกพัน - ',
  'ncb.common.loanType17': 'เงินชดใช้ตามภาระผูกพัน - L/C',
  'ncb.common.loanType18': 'เงินชดใช้ตามภาระผูกพัน - L/G',
  'ncb.common.loanType19': 'เงินชดใช้ตามภาระผูกพันอื่นๆ',
  'ncb.common.loanType20': 'สินเชื่อให้เช่าแบบลิสซิ่งรถยนต์',
  'ncb.common.loanType21': 'สินเชื่อเช่าซื้ออื่นๆ',
  'ncb.common.loanType22': 'สินเชื่อบัตรเครดิต',
  'ncb.common.loanType27': 'สินเชื่อเช่าซื้อรถยนต์',
  'ncb.common.loanType28': 'วงเงินสินเชื่อ',
  'ncb.common.loanType29': 'สินเชื่อให้เช่าแบบลิสซิ่งอื่นๆ',
  'ncb.common.loanType31': 'สินเชื่อเช่าซื้อเพื่อการเกษตรที่มีจำนวนเงินชำระต่องวดไม่คงที่',
  'ncb.common.loanType32': 'สินเชื่อเช่าซื้อเพื่อการเกษตร',
  'ncb.common.loanType33': 'สินเชื่อเพื่อการเกษตร',
  'ncb.common.loanType34': 'สินเชื่อแฟ็กเตอริง',
  'ncb.common.loanType35': 'สัญยาซื้่อขายล่วงหน้า',
  'ncb.common.loanType36': 'เงินกู้สหกรณื',
  'ncb.common.loanType37': 'สินเชื่อรายย่อยเพื่อการประกอบอาชีพ',
  'ncb.common.loanType50': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อเพื่อการพาณิชย์',
  'ncb.common.loanType51': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบุคคล',
  'ncb.common.loanType52': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อที่อยู่อาศัย',
  'ncb.common.loanType53': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลิสซิ่งรถยนต์/',
  'ncb.common.loanType54': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่ออื่นๆ',
  'ncb.common.loanType55': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบัตรเครดิต',
  'ncb.common.loanType56': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อรถยนต์',
  'ncb.common.loanType57': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลิสซิ่งอื่นๆ',
  'ncb.common.loanType58': 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อวงเงินเบิกเกินบัญชี',
  'ncb.common.loanType90': 'สินเชื่อที่มีการปรับปรุงโคตรสร้างหนี้',
  'ncb.common.loanType99': 'สินเชื่ออื่นๆ',
  'ncb.common.accountStatusundefined': 'ไม่มีข้อมูล',
  'ncb.common.accountStatus10': 'ปกติ',
  'ncb.common.accountStatus11': 'ปิดบัญชี',
  'ncb.common.accountStatus12': 'พักชำระหนี้ ตามนโยบายของสมาชิก',
  'ncb.common.accountStatus13': 'พักชำระหนี้ ตามนโยบายของรัฐ',
  'ncb.common.accountStatus14': 'พักชำระหนี้เกษตร ตามนโยบายของสมาชิก',
  'ncb.common.accountStatus20': 'หนี้ค้างชำระเกิน 90 วัน',
  'ncb.common.accountStatus30': 'อยู่ในกระบวนการทางกฎหมาย',
  'ncb.common.accountStatus31': 'อยู่ระหว่างชำระหนี้ตามคำพิพากษาตามยอม',
  'ncb.common.accountStatus32': 'ศาลพิพากษายกฟ้องเนื่องจากขาดอายุความ',
  'ncb.common.accountStatus33': 'ปิดบัญชีเนื่องจากตัดหนี้สูญ',
  'ncb.common.accountStatus40': 'อยู่ระหว่างชำระสินเชื่อปิดบัญชี',
  'ncb.common.accountStatus41': 'เจ้าของข้อมูลขอตรวจสอบรายการ',
  'ncb.common.accountStatus42': 'โอนหรือขายหนี้',
  'ncb.common.accountStatus43': 'โอนหรือขายหนี้ และชำระหนี้เสร็จสิ้น',

  // Loan options
  'loan.status': 'สถานะ',
  'loan.effectiveDate': 'วันที่มีผลบังคับใช้',

  // List
  'list.allList': 'รายการทั้งหมด',
  'list.allListInfo': 'ข้อมูลรายการทั้งหมด',

  // Roles
  'roles.name': 'ชื่อตำแหน่ง',
  'roles.name.inputMessage': 'กรุณาใส่ชื่อตำแหน่งของคุณ!',
  'roles.scopes': 'สิทธิการใช้งาน',
  'roles.scopes.placeholder': 'กรุณาเลือกประเภทการอนุญาต',
  'roles.scopes.inputMessage': 'กรุณาเลือกการอนุญาตอย่างน้อย 1 ประเภท!',

  // Sales
  'sales.id': 'รหัสผู้ขาย',
  'sales.code': 'โค้ด',
  'sales.code.inputMessage': 'กรุณาใส่โค้ดของคุณ!',
  'sales.teamLead': 'หัวหน้าทีม',
  'sales.teamLead.checkBox': 'ฉันคือหัวหน้าทีม',
  'sales.teamLead.select.placeholder': 'กรุณาเลือกหัวหน้าทีม',
  'sales.teamLead.inputMessage': 'กรุณาเลือกหัวหน้าทีมของคุณ!',

  // Projects
  'projects.id': 'รหัสโครงการ',
  'projects.name': 'ชื่อโครงการ',

  // Questionare
  'question.areYouSure': 'คุณแน่ใจหรือไม่?',

  'documents.uploadHit': 'คลิ๊กหรือลากไฟล์ไว้ตรงนี้',
  'documents.fileNameHeader': 'ชื่อไฟล์',
  'documents.descriptionHeader': 'คำอธืบาย',
  'documents.descriptionMessage': `กรุณาใส่คำอธิบาย!`,
  'documents.categoryHeader': 'ประเภท',
  'documents.categoryMessage': `กรุณาใส่ประเภท`,
  'documents.application': 'ใบสมัครสินเชื่อ',
  'documents.citizenCard': 'บัตรประชาชน',
  'documents.incomeStatement': 'เอกสารแสดงรายได้',
  'documents.consent': 'หนังสือให้ความยินยอม',
  'documents.letterOfGuarantee': 'หนังสือค้ำประกัน',
  'documents.increaseCreditLimitDocuments': 'เอกสารเพิ่มวงเงิน',
  'documents.dissurseDocuments': 'เอกสารเบิกถอน',
  'documents.bankbook': 'สมุดบัญชี',
  'documents.signature': 'ลายเซ็น',
  'documents.other': 'อื่นๆ',
  'documents.submit': 'ตกลง',

  'requireMessage.select': 'กรุณาเลือก{field}',
  'requireMessage.fill': 'กรุณาใส่{field}',
  'requireMessage.maritalStatus': 'กรุณาเลือกสถานภาพสมรส',
  'requireMessage.statementDate': 'กรุณาเลือกวันออกใบแจ้งหนี้',
  'requireMessage.dueDate': 'กรุณาใส่วันครบกำหนดชำระ',
  'requireMessage.bankName': 'กรุณาเลือกธนาคาร',
  'requireMessage.accountNo': 'กรุณาใส่เลขบัญชี',
  'requireMessage.statementAddress': 'กรุณาเลือกที่อยู่จัดส่งใบแจ้งหนี้/ใบเสร็จ',
  'requireMessage.analystAmount': 'กรุณาใส่วงเงินอนุมัติ',

  // invite user
  'inviteUser.userInformation': 'ข้อมูลผู้ใช้',

  // title
  'education.title': 'ระดับการศึกษา / สถานะการทำงาน / รายได้',
  // report
  'report.name': 'ชื่อรายงาน',
  // withdraw
  'withdraw.tab.Pending': 'รอดำเนินการ',
  'withdraw.tab.Downloaded': 'ดาวน์โหลด',
  'withdraw.tab.Transferred': 'โอนแล้ว',
  'withdraw.tab.Cancelled': 'ยกเลิก',
  'withdraw.column.id': 'รหัส',
  'withdraw.column.loanId': 'เลขบัญชี',
  'withdraw.column.name': 'ชื่อ - นามสกุล',
  'withdraw.column.productName': 'ผลิตภัณฑ์',
  'withdraw.column.amount': 'จำนวนเงิน',
  'withdraw.column.bankName': 'ธนาคาร',
  'withdraw.column.accountNo': 'เลขที่บัญชี',
  'withdraw.column.transferDate': 'วันที่โอน',
  'withdraw.column.effectiveDate': 'วันที่เริ่มผลกระทบ',
  'withdraw.column.status': 'สถานะ',
  'withdraw.column.createDate': 'วันที่สร้าง',
  'withdraw.button.downloadAll': 'ดาวน์โหลดทั้งหมด',
  'withdraw.button.download': 'ดาวน์โหลด',
  'withdraw.button.transfer': 'โอน',
  'withdraw.button.cancel': 'ยกเลิก',
  'withdraw.button.reset': 'ย้อนสถานะแรก',
  'withdraw.button.transfer.cimb': 'โอน CIMB',

  // disbursements
  'disbursements.name': 'ชื่อ - นามสกุล',
  'disbursements.accountNo': 'เลขที่บัญชี',
  'disbursements.amount': 'จำนวนเงิน',
  'disbursements.status': 'สถานะ',
  'disbursements.transferredDate': 'วันที่โอน',
  'disbursements.rejectDesc': 'หมายเหตุ',

  // import
  'import.applications': 'นำเข้าใบสมัครสินเชื่อ',
  'import.payments': 'นำเข้าการชำระเงิน',
  'import.requestAmount': 'วงเงินกู้',
  'import.applications.summary.all': 'รายการทั้งหมด',
  'import.applications.summary.normal': 'พร้อมนำเข้า',
  'import.applications.summary.duplicate': 'รายการซ้ำ',
  'import.applications.summary.applications': 'ใบสมัคร',
  'import.column.status': 'สถานะ',
  'import.payments.summary.all': 'รายการทั้งหมด',
  'import.payments.summary.normal': 'พร้อมนำเข้า',
  'import.payments.summary.notupdate': 'ไม่ใช่รายการล่าสุด',
  'import.payments.summary.notfound': 'ไม่พบเลขที่สัญญา',
  'import.payments.summary.duplicate': 'รายการซ้ำ',
  'import.payments.summary.close': 'บัญชีปิดแล้ว',
  'import.payments.summary.account': 'บัญชี',
  'import.payments.loanId': 'เลขบัญชีสินเชื่อ',
  'import.payments.name': 'ชื่อ',
  'import.payments.transdate': 'วันที่จ่าย',
  'import.payments.code': 'รหัส',
  'import.payments.bankCode': 'รหัสธนาคาร',
  'import.payments.amount': 'จำนวนเงิน',
  'import.payments.status': 'สถานะ',
  'import.applications.confirmImport': 'นำเข้า',
  'import.payments.confirmImport': 'นำเข้า',
  'import.payments.summary.lesszero': 'เงินไม่เกิน 0',
  'import.creditreports': 'นำเข้าข้อมูลเครดิต',
  'import.creditreports.loanCount': 'สินเชื่อทั้งหมด',
  'import.creditreports.osb': 'ยอดหนี้คงเหลือ',
  'import.creditreports.confirmImport': 'นำเข้า',
  'import.disbursements': 'นำเข้าการเบิกถอน',
  'import.disbursements.summary.all': 'รายการทั้งหมด',
  'import.disbursements.summary.normal': 'พร้อมนำเข้า',
  'import.disbursements.summary.duplicate': 'มีมากกว่า1รายการ',
  'import.disbursements.summary.notfound': 'ไม่พบรายการนี้',
  'import.disbursements.summary.fail': 'โอนไม่สำเร็จ',
  'import.disbursements.summary.list': 'รายการ',
  'import.disbursements.summary.amountSubmit': 'จำนวนเงินที่นำเข้าได้',
  'import.disbursements.summary.amountReject': 'จำนวนเงินที่นำเข้าไม่ได้',
  'import.disbursements.summary.amountFail': 'จำนวนเงินที่โอนไม่สำเร็จ',
  'import.disbursements.summary.baht': 'บาท',
  'import.disbursements.confirmImport': 'นำเข้า',
  'import.collectorLoans': 'นำเข้าข้อมูลผู้ติดตามสัญญา',
  'import.collectorLoans.summary.all': 'รายการทั้งหมด',
  'import.collectorLoans.summary.normal': 'พร้อมนำเข้า',
  'import.collectorLoans.summary.duplicate': 'มีมากกว่า1รายการ',
  'import.collectorLoans.summary.notfound': 'ไม่พบรายการนี้',
  'import.collectorLoans.summary.missing': 'ข้อมูลไม่ครบ',
  'import.collectorLoans.summary.list': 'รายการ',
  'import.collectorLoans.period': 'งวด',
  'import.collectorLoans.loanId': 'เลขที่สัญญา',
  'import.collectorLoans.collector': 'ผู้ติดตามสัญญา',
  'import.collectorLoans.fromDate': 'จากวันที่',
  'import.importWriteoff': 'นำเข้ารายการตัดหนี้สูญ',
  'import.importWriteoff.summary.writeoff': 'ตัดหนี้สูญไปแล้ว',
  'import.importWriteoff.summary.notLatest': 'ไม่ใช่รายการล่าสุด',
  'import.importWriteoff.writeoffDate': 'วันที่ตัดหนี้สูญ',
  'import.importWriteoff.writeoffAmount': 'จำนวนเงินที่ตัดหนี้สูญ',

  // note
  note: 'บันทึก',
  'note.name': 'ชื่อ-นามสกุล',
  'note.mobileNo': 'หมายเลขโทรศัพท์',
  'note.createDate': 'วันที่สร้าง',
  'note.category': 'ปัญหา',
  'note.followupDate': 'วันที่นัดชำระ',
  'note.channel': 'ช่องทางการติดต่อ',
  'note.priority': 'ความสำคัญ',
  'note.repayDate': 'วันที่ชำระ',
  'note.followupAmount': 'จำนวนเงินที่นัดชำระ',
  'note.repayAmount': 'จำนวนเงินที่ชำระ',
  'note.collectionStatus': 'สถานะการติดตาม',
  'note.status': 'สถานะการติดตาม',
  'note.message': 'ข้อความ',
  // requestApproval
  'requestApproval.tab.appId': 'เลขที่ใบสมัคร',
  'requestApproval.tab.type': 'ชนิด',
  'requestApproval.button.approve': 'อนุมัติ',
  'requestApproval.button.reject': 'ไม่อนุมัติ',

  action: 'แอ๊คชั่น',
  ok: 'ตกลง',
  search: 'ค้นหา',

  startDate: 'ตั้งแต่วันที่',
  endDate: 'ถึงวันที่',
  withdraw: 'เบิกถอน',
  filing: 'ส่งฟ้อง',
  restructure: 'ปรับโครงสร้างหนี้',
  writeOff: 'ตัดหนี้สูญ',
  writeOffDate: 'วันตัดหนี้ศูนย์',
  amortizeTable: 'ตารางผ่อนชำระ',
  contract: 'หนังสือสัญญา',
  closeContract: 'หนังสือปิดสัญญา',
  firstDueDate: 'วันครบกำหนดชำระงวดแรก',
  firstDisburseDate: 'วันเบิกถอนงวดแรก',
  firstStatementDate: 'วันตัดรอบครั้งแรก',
  payment: 'ชำระเงิน',
  paymentDate: 'วันที่ชำระ',
  paymentChannel: 'ช่องทางรับชำระ',
  amount: 'จำนวน',
  qrcode: 'คิวอาร์โค้ด',
  'search here': 'ค้นหาที่นี่',
  eidtloan: 'แก้ไขข้อมูล',

  'sms.type': 'ประเภทการส่ง',
  'sms.type.type1': 'แจ้งเตือนชำระหนี้',
  'sms.type.type2': 'ส่ง E-Statement',
  'sms.cutof': 'วันตัดรอบใบแจ้งหนี้',
  'sms.estatement': 'E-Statement',
  'sms.estatement1': 'ทั้งหมด',
  'sms.estatement2': 'ใช่',
  'sms.estatement3': 'ไม่ใช่',
  'sms.format': 'รูปแบบข้อความ',
  'sms.format.format1': 'แจ้งเตือนชำระหนี้หลังวันตัดรอบ',
  'sms.format.format2': 'แจ้งเตือนชำระหนี้ก่อนตก Bucket',
  'sms.format.format3': 'ข้อความอื่นๆ',
  'sms.browse': 'เรียกดู',
  'sms.send': 'ยืนยัน',
  'sms.page-title': 'ข้อความ',
  'sms.helmet-title': 'ประวัติข้อความ',
  'sms.filter.mobileNo': 'เบอร์มือถือ',
  'sms.filter.startDate': 'ตั้งแต่วันที่',
  'sms.filter.endDate': 'ถึงวันที่',
  'sms.projectId': 'เลขโครงการ',
  'sms.mobileNo': 'เบอร์มือถือ',
  'sms.messageId': 'เลขอ้างอิงข้อความ',
  'sms.message': 'ข้อความ',
  'sms.status': 'สถานะ',
  'sms.createBy': 'สร้างโดย',
  'sms.success-search': 'ค้นหาสำเร็จ',
  'sms-loan-close.helmet-title': 'ข้อความปิดสัญญา',
  'sms-loan-close.page-title': 'แจ้งผลการปิดสัญญา',
  'sms-statement.page-title': 'แจ้งเตือนการชำระหนี้ ',
  'sms-estatement.page-title': 'ส่งใบแจ้งหนี้อิเล็กทรอนิกส์',
  'sms-application-waitconfirm.page-title': 'แจ้งสถานะใบสมัคร',
  'sms-withdraw.page-title': 'แจ้งสถานะการเบิกถอน',

  // UI Text
  // Used when form validation fail
  'common.inputvalidation-failed': 'คุณกรอกข้อมูลไม่ถูกต้อง',
  'common.please-check': 'กรุณาตรวจสอบ',
  'common.are-you-sure': 'ต้องการลบรายการนี้? การกระทำนี้ไม่สามารถย้อนกลับได้',
  'common.dropzoneUpload': 'คลิ๊กหรือลากไฟล์ไว้ที่นี่เพื่ออัพโหลด',

  // Login Page
  'login.helmet-title': 'เข้าสู่ระบบ',
  'login.failed-message': 'เข้าสู่ระบบไม่สำเร็จ',
  'login.please-log-in': 'กรุณาเข้าสู่ระบบ',
  'login.remember-me': 'จำรหัสผ่าน',
  'login.forgot-password': 'ลืมรหัสผ่าน?',
  'login.login': 'เข้าสู่ระบบ',
  'login.register': 'สร้างบัญชีใหม่',
  'login.if-you-dont-have-account': 'ถ้าคุณยังไม่มีบัญชี',
  'login.success-message': 'ยินดีต้อนรับเข้าสู่ระบบ',
  'login.success-description': 'เข้าสู่ระบบสำเร็จ ยินดีต้อนรับสู่ระบบ Noburo Cbs',
  // Register page
  'register.title': 'สร้างบัญชีใหม่',
  'register.please-register': 'กรุณาสร้างบัญชีใหม่',
  'register.confirm-password': 'ยืนยันรหัสผ่าน',
  'register.register': 'สร้างบัญชีใหม่',
  'register.login': 'เข้าสู่ระบบ',
  'register.if-you-already-have-account': 'หากมีบัญชีอยู่แล้ว',
  'register.success-message': 'สร้างบัญชีใหม่เรียบร้อย',
  'register.success-description': 'คุณได้สร้างบัญชีใหม่เสร็จสมบูรณ์ กรุณาตรวจสอบอีเมล์',
  'register.failed-message': 'สร้างบัญชีใหม่ไม่สำเร็จ',
  // Thankyou page
  'thankyou.thankyou': 'ขอบคุณที่ลงทะเบียนใช้บริการ Noburo Cbs',
  'thankyou.please-check-email-to-verify-your-account':
    'กรุณาตรวจสอบอีเมล์ที่ใช้ในการลงทะเบียน เพื่อยืนยันตัวตน',
  // Verify page
  'verify.success-message': 'อีเมล์ได้รับการยืนยัน',
  'verify.success-description': 'การยืนยันอีเมล์สำเร็จเรียบร้อย',
  // Forgot password
  'forgot.helmet-title': 'ลืมรหัสผ่าน',
  'forgot.page-title': 'ลืมรหัสผ่าน?',
  'forgot.instruction':
    'ลืมรหัสผ่าน? กรุณากรอกอีเมลล์ของคุณในช่องด้านล่าง เราจะส่งลิงค์สำหรับตั้งค่ารหัสผ่านใหม่ไปที่อีเมลล์ของคุณ',
  'forgot.success-message': 'เราได้ส่งลิงค์สำหรับตั้งค่ารหัสผ่านไปที่อีเมลล์ของคุณ',
  'forgot.back-to-login': 'กลับไปหน้า login',
  // Reset password
  'reset.helmet-title': 'ตั้งรหัสผ่านใหม่',
  'reset.page-title': 'ตั้งรหัสผ่านใหม่',
  'reset.success-message': 'ตั้งรหัสผ่านใหม่เรียบร้อย',
  // Onboarding
  'onboarding.step': 'ขั้นตอน',
  'onboarding.company-information': 'ข้อมูลบริษัท',
  'onboarding.admin-information': 'ข้อมูลผู้ดูแล',
  'onboarding.verify-otp': 'ยืนยัน Otp',
  'onboarding.success-message': 'กรอกข้อมูลเบื้องต้นเรียบร้อย',
  'onboarding.success-description': 'คุณได้กรอกข้อมูลเบื้องต้นสำหรับ Noburo Cbs เสร็จสมบูรณ์',
  'onboarding.failed-message': 'กรุณากรอกข้อมูลให้ครบถ้วน',
  // Accept Invite
  'accept-invite.user-info': 'ข้อมูลส่วนตัว',
  'accept-invite.confirm': 'ยืนยัน',

  // Application List
  'application-list.helmet-title': 'ใบสมัคร',
  'application-list.page-title': 'ใบสมัคร',
  'application-list.search-fields': 'ค้นหาจาก เลขที่ใบสมัคร, ชื่อ, นามสกุล หรือ เลขประจำตัวประชาชน',

  // New Application
  'application-new.helmet-title': 'เพิ่มใบสมัคร',
  'application-new.page-title': 'เพิ่มใบสมัคร',
  'application-new.success-message': 'เพิ่มใบสมัครเรียบร้อย',

  // Edit Application
  'application-edit.helmet-title': 'ใบสมัคร | {id}',
  'application-edit.page-title': 'ใบสมัคร',
  'application-edit.save-success-message': 'แก้ไขใบสมัครเรียบร้อย',
  'application-edit.update-status-success-message': 'เปลี่ยนสถานะใบสมัครสำเร็จ',
  'application-edit.send-for-reprocessing': 'ต้องการส่งใบสมัครกลับไปดำเนินการใหม่?',
  'application-edit.tabname.application': 'ข้อมูลใบสมัคร',
  'application-edit.tabname.document': 'เอกสารประกอบ',
  // DebtSummary
  debtSummary: 'ข้อมูลสรุปยอดหนี้',
  'debtSummary.totalCreditLimit': 'วงเงินทั้งหมด(บาท)',
  'debtSummary.totalOsb': 'ยอดหนี้คงเหลือทั้งหมด(บาท)',
  'debtSummary.osbDivineSalary': 'ยอดหนี้คงเหลือทั้งหมด / เงินเดือน',
  'debtSummary.osbDivineIncome': 'ยอดหนี้คงเหลือทั้งหมด / เงินเดือน + รายได้พิเศษ',
  'debtSummary.overdue': 'ค้างชำระ',

  'loan-list.helmet-title': 'สินเชื่อ',
  'loan-list.page-title': 'สินเชื่อ',
  'loan-list.search-fields': 'ค้นหาจาก เลขที่สินเชื่อ, ชื่อ, นามสกุล หรือ เลขประจำตัวประชาชน',

  'loan-edit.helmet-title': 'สินเชื่อ | {id}',
  'loan-edit.page-title': 'สินเชื่อ',
  'loan-edit.delete-transaction': 'ลบรายการ',
  'loan-edit.show-delete-transaction': 'แสดงรายการที่ถูกลบ',
  'update-loan.success-message': 'แก้ไขข้อมูลเรียบร้อย',
  'editloan.success-message': 'ส่งคำขอแก้ไขเงื่อนไขสัญญาเรียบร้อย โปรดรอการอนุมัติ',
  'download-amortize-table.success-message': 'ดาวน์โหลดตารางผ่อนชำระเรียบร้อย',
  'download-contract.success-message': 'ดาวน์โหลดหนังสือสัญญาเรียบร้อย',
  'download-close-contract.sucess-message': 'ดาวน์โหลดหนังสือปิดสัญญาเรียบร้อย',
  'download-statement.success-message': 'ดาวน์โหลดใบแจ้งหนี้เรียบร้อย',
  'download-lawsuit.success-message': 'ดาวน์โหลดเอกสารคดีความเรียบร้อย',
  'writeoff.success-message': 'ส่งคำขอตัดหนี้สูญเรียบร้อย โปรดรอการอนุมัติ',
  'payment.success-message': 'ชำระเรียบร้อย',
  'withdraw.success-message': 'ถอนเงินเรียบร้อย',
  'withdraw.maxamount': 'จำนวน (สูงสุด {maxAmount} บาท)',
  'closeloan.sucess-message': 'ปิดสัญญาเรียบร้อย',
  'delete-transaction.success-message': 'ลบรายการเรียบร้อย',
  'options.effective': 'Effective',
  'options.flat': 'Flat',
  'rollback-transaction.success-message': 'ลบรายการเรียบร้อย',
  'loan-edit.show-rollback-transaction': 'แสดงรายการที่สามารถลบได้',

  'adj.helmet-title': 'ตัดรอบใบแจ้งหนี้',
  'acc.helmet-title': 'ตัดรอบข้อมูลบัญชี',
  'adj.page-title': 'สินเชื่อ',
  'acc.page-title': 'สินเชื่อ',
  'adj.success-message': 'ตัดรอบใบแจ้งหนี้เรียบร้อย',
  'acc.success-message': 'ตัดรอบข้อมูลบัญชีเรียบร้อย',

  'statementCsv.helmet-title': 'ออกใบแจ้งหนี้ CSV',
  'statementPdf.helmet-title': 'ออกใบแจ้งหนี้ PDF',
  'statement.helmet-title': 'ออกใบแจ้งหนี้ ',
  'statement.page-title': 'สินเชื่อ',
  'statement.success-message': 'ออกใบแจ้งหนี้เรียบร้อย',

  'requestapproval.approve.success-message': 'อนุมัติรายการเรียบร้อย',
  'requestapproval.reject.success-message': 'ปฏิเสธรายการเรียบร้อย',

  'product-new.helmet-title': 'เพิ่มผลิตภัณฑ์',
  'product-new.page-title': 'เพิ่มผลิตภัณฑ์',
  'product-new.success-message': 'เพ่ิมผลิตภัณฑ์เรียบร้อย',

  'product-edit.helmet-title': 'ผลิตภัณฑ์ | {id}',
  'product-edit.page-title': 'ผลิตภัณฑ์',
  'product-edit.success-message': 'แก้ไขผลิตภัณฑ์เรียบร้อย',

  'user-new.helmet-title': 'เพิ่มผู้ใช้งาน',
  'user-new.page-title': 'เพิ่มผู้ใช้งาน',
  'user-new.success-message': 'เพิ่มผู้ใช้งานเรียบร้อย',

  'user-edit.helmet-title': 'ผู้ใช้งาน',
  'user-edit.page-title': 'ผู้ใช้งาน',
  'user-edit.success-message': 'แก้ไขผู้ใช้งานเรียนร้อย',

  'withdraws-list.transfer': 'โอนเงิน',
  'withdraws-list.cimb.transfer': 'โอนเงิน CIMB',
  'withdraws-list.do-you-want-to-cancel': 'ต้องการยกเลิกรายการถอนเงินใช่ไหม?',

  // sms notify approval page
  'notify-approval.page-title': 'แจ้งผลอนุมัติสินเชื่อ',
  'notify-approval-types.approve': 'แจ้งอนุมัติสินเชื่อ',
  'notify-approval-types.reject': 'แจ้งปฏิเสธสินเชื่อ',
  'notify-approval.success-message': 'ส่งข้อความสำเร็จ',
  'notify-approval.search-success': 'ค้นหาข้อความสำเร็จ',
  'notify-approval.search-button': 'ส่งข้อความ',
  'sms-loan-close.search-success': 'ค้นหาสัญญาสำเร็จ',
  'sms-loan-close.success-message': 'ส่งข้อความสำเร็จ',

  // sms notify cash page
  'notify-cash.page-title': 'แจ้งเงินเข้าเงินออก',
  'notify-approval-types.cashin': 'แจ้งรับชำระเงิน',
  'notify-approval-types.cashout': 'แจ้งโอนเงิน',

  // project notify cash page
  'projects-create.success-message': 'เพิ่มโครงการสำเร็จ',
  'projects-edit.success-message': 'แก้ไขโครงการสำเร็จ',

  // UI Label
  next: 'ถัดไป',
  previous: 'ย้อนกลับ',
  'resend-otp': 'ขอ Otp อีกครั้ง',
  confirm: 'ยืนยัน',
  'change-password': 'เปลี่ยนรหัสผ่าน',
  logout: 'ออกจากระบบ',
  yes: 'ใช่',
  no: 'ไม่ใช่',
  submit: 'ดำเนินการ',
  'full-name': 'ชื่อ - สกุล',
  reject: 'ปฏิเสธใบสมัคร',
  reprocessing: 'ส่งไปดำเนินการใหม่',
  changestatus: 'เปลี่ยนสถานะ',
  save: 'บันทึก',
  cancel: 'ยกเลิก',
  download: 'ดาวน์โหลด',
  downloadAll: 'ดาวน์โหลดทั้งหมด',
  yearOfStay: 'อาศัยมาเป็นเวลา',
  year: 'ปี',
  month: 'เดือน',
  prepaid: 'เติมเงิน',
  postpaid: 'รายเดือน',
  useCurrentAddress: 'ใช้ที่อยู่ปัจจุบัน',
  editloan: 'แก้ไขเงื่อนไขสัญญา',
  principal: 'เงินต้น',
  interest: 'ดอกเบี้ย',
  fee: 'ค่าธรรมเนียม',
  osb: 'ยอดหนี้คงเหลือ',
  osbAt: 'ยอดหนี้คงเหลือ ณ วันที่ {asOfDate}',
  closeloan: 'ปิดสัญญา',
  calculateCloseLoan: 'คำนวนปิดสัญญา',
  transaction: 'รายการ',
  add: 'เพิ่ม',
  sendAgain: 'ส่ง Otp อีกครั้ง',
  resetPassword: 'ตั้งรหัสผ่านใหม่',
  newPassword: 'รหัสผ่านใหม่',
  confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',
  transferDate: 'วันที่โอนเงิน',

  // Field Name
  firstName: 'ชื่อ',
  lastName: 'นามสกุล',
  mobileNo: 'เบอร์มือถือ',
  email: 'อีเมล์',
  password: 'รหัสผ่าน',
  registerId: 'เลขที่นิติบุคคล',
  address: 'ที่อยู่',
  subDistrict: 'แขวง/ตำบล',
  district: 'เขต/อำเภอ',
  province: 'จังหวัด',
  postCode: 'รหัสไปรษณีย์',
  saleCode: 'รหัสพนักงานขาย',
  title: 'คำนำหน้า',
  titleEn: 'คำนำหน้า (อังกฤษ)',
  firstNameEn: 'ชื่อ (อังกฤษ)',
  lastNameEn: 'นามสกุล (อังกฤษ)',
  citizenId: 'เลขประจำตัวประชาชน',
  birthDate: 'วันเกิด',
  product: 'ผลิตภัณฑ์',
  requestAmount: 'วงเงินร้องขอ',
  installTerm: 'ระยะเวลาผ่อนชำระ (เดือน)',
  installAmount: 'จำนวนผ่อนชำระ',
  status: 'สถานะ',
  ncb: 'เครดิตบูโร',
  document: 'เอกสารประกอบ',
  loanApproval: 'อนุมัติสินเชื่อ',
  general: 'ทั่วไป',
  employment: 'สถานที่ทำงาน',
  contactPerson: 'บุคคลที่ติดต่อได้',
  contactPerson1: '1.(บิดา/มารดา สามี/ภรรยา หรือ ผู้สืบสายเลือดเท่านั้น)',
  contactPerson2: '2.(ญาติ หรือเพื่อนร่วมงาน)',
  guarantor: 'ผู้ค้ำประกัน',
  guarantor1: 'ผู้ค้ำประกัน 1',
  guarantor2: 'ผู้ค้ำประกัน 2',
  personalInfo: 'ข้อมูลส่วนตัว',
  loanInfo: 'ข้อมูลสินเชื่อ',
  accountInfo: 'ช่องทางรับเงิน',
  approvalInfo: 'การอนุมัติ',
  statementDate: 'วันออกใบแจ้งหนี้',
  closeDate: 'วันปิดสัญญา',
  dueDate: 'วันครบกำหนดชำระ',
  bankName: 'ธนาคาร',
  branchName: 'สาขา',
  accountNo: 'เลขที่บัญชี',
  accountName: 'ชื่อบัญชี',
  accountType: 'ประเภทบัญชี',
  maritalStatus: 'สถานภาพสมรส',
  spouseInfo: 'ข้อมูลคู่สมรส',
  company: 'บริษัท',
  occupation: 'อาชีพ',
  position: 'ตำแหน่ง',
  salary: 'เงินเดือน',
  fileName: 'ชื่อไฟล์',
  description: 'คำอธิบาย',
  category: 'ประเภท',
  createDate: 'วันที่สร้าง',
  lastModifyDate: 'วันแก้ไขล่าสุด',
  lastModifyBy: 'แก้ไขล่าสุดโดย',
  analystComment: 'ความเห็นนักวิเคราะห์',
  analystAmount: 'วงเงินเสนออนุมัติ',
  approveAmount: 'วงเงินที่อนุมัติ',
  rejectReason: 'เหตุผลการปฏิเสธ',
  rejectDate: 'วันที่ปฏิเสธ',
  openDate: 'วันที่เปิดสัญญา',
  firstDisburseAmount: 'จำนวนเงินงวดแรก',
  mobilePackage: 'แพ็กเกจมือถือ',
  residentType: 'ประเภทที่อยู่อาศัย',
  residentOwner: 'ประเภทการอยู่อาศัย',
  rent: 'ค่าเช่า',
  phoneNo: 'เบอร์โทรศัพท์บ้าน',
  phoneNoExt: 'เบอร์ต่อ',
  contactTime: 'เวลาสะดวกให้ติดต่อ',
  statementAddress: 'ที่อยู่จัดส่งใบแจ้งหนี้/ใบเสร็จ',
  role: 'ตำแหน่ง',
  organisationName: 'ชื่อทีม',
  totalMindue: 'ขั้นต่ำรวม',

  'personalInfo.education': 'ระดับการศึกษาสูงสุด',
  'personalInfo.startWorkDate': 'วันเริ่มทำงาน',
  'personalInfo.totalLegalLoan': 'สินเชื่อในระบบ(แห่ง)',
  'personalInfo.totalLegalLoanAmount': 'สินเชื่อในระบบ(บาท)',
  'personalInfo.totalIllegalLoan': 'สินเชื่อนอกระบบ(แห่ง)',
  'personalInfo.totalIllegalLoanAmount': 'สินเชื่อนอกระบบ(บาท)',
  'addressInfo.current': 'ที่อยู่ปัจจุบัน',
  'addressInfo.permanent': 'ที่อยู่ตามทะเบียนบ้าน',
  'addressInfo.company': 'ที่อยู่บริษัท',
  'addressInfo.company.name': 'ชื่อสถานที่ทำงาน',
  'addressInfo.company.isSubcontract': 'เป็นงาน Sub Contract',
  'addressInfo.company.subcontractPhoneNo': 'เบอร์โทร.บริษัทต้นสังกัด',
  'addressInfo.company.hrPhoneNo': 'เบอร์โทร.ฝ่ายบุคคล',
  'addressInfo.company.hrPhoneNoExt': ' ต่อ',
  'addressInfo.company.deptPhoneNo': 'เบอร์โทร.แผนก/ฝ่าย',
  'addressInfo.company.deptPhoneNoExt': 'ต่อ',
  'employmentInfo.employmentStatus': 'สถานะการจ้างงาน',
  'employmentInfo.serviceYear': 'อายุงาน(ปี)',
  'employmentInfo.serviceMonth': 'อายุงาน(เดือน)',
  'employmentInfo.position': 'ตำแหน่ง',
  'employmentInfo.department': 'แผนก/ฝ่าย',
  'employmentInfo.baseSalary': 'ฐานเงินเดือน(บาท)',
  'employmentInfo.extraIncome': 'รายได้อื่นๆ(บาท)',
  'employmentInfo.extraIncomeSource': 'แหล่งที่มารายได้อื่นๆ',
  'employmentInfo.salaryChannel': 'วิธีรับเงินเดือน',
  'employmentInfo.salaryFrequency': 'รับเงินเดือน เดือนละ(ครั้ง)',
  'employmentInfo.salaryDate': 'ทุกวันที่',
  'employmentInfo.occupationType': 'ประเภทอาชีพ',
  'employmentInfo.businessType': 'ประเภทธุรกิจ',
  'employmentInfo.employeeId': 'หมายเลขพนักงาน',
  'employmentInfo.nano.title': 'ธุรกิจ',
  'employmentInfo.nano.companyName': 'ชื่อกิจการ',
  'employmentInfo.nano.businessType': 'ประเภทธุรกิจ',
  'employmentInfo.nano.hrPhoneNo': 'เบอร์โทรศัพท์',
  'employmentInfo.nano.hrPhoneNoExt': 'ต่อ',
  'employmentInfo.nano.startWorkDate': 'วันเริ่มงาน',
  'employmentInfo.nano.totalIncomeNano': 'รายได้ต่อเดือน',
  'employmentInfo.nano.materialExpense': 'ค่าวัตถุดิบ',
  'employmentInfo.nano.wagesExpense': 'ค่าแรง',
  'employmentInfo.nano.rentalExpense': 'ค่าเช่า',
  'employmentInfo.nano.waterExpense': 'ค่าน้ำ',
  'employmentInfo.nano.electricityExpense': 'ค่าไฟ',
  'employmentInfo.nano.otherNanoExpense': 'อื่นๆระบุ',
  'employmentInfo.nano.oneMonth': 'เดือนที่ 1',
  'employmentInfo.nano.twoMonth': 'เดือนที่ 2',
  'employmentInfo.nano.threeMonth': 'เดือนที่ 3',
  'employmentInfo.nano.fourMonth': 'เดือนที่ 4',
  'employmentInfo.nano.fiveMonth': 'เดือนที่ 5',
  'employmentInfo.nano.sixMonth': 'เดือนที่ 6',
  'employmentInfo.nano.profit': 'กำไรต่อเดือน',
  'employmentInfo.nano.extraIncome': 'รายได้อื่นๆ',
  'employmentInfo.nano.extraIncomeSource': 'ที่มาของรายได้อื่นๆ',
  'employmentInfo.nano.ExpensesPerMonth': 'ค่าใช้จ่ายต่อเดือน',
  'employmentInfo.nano.position': 'ตำแหน่ง',
  nanofinance: 'นาโนไฟแนนซ์',
  loanType: 'ประเภทสินเชื่อ',
  paymentType: 'การผ่อนชำระขั้นต่ำ',
  intType: 'ประเภทดอกเบี้ย',
  intRate: 'อัตราดอกเบี้ย(%)',
  minPayRate: 'ขั้นต่ำ(%)',
  minPayAmount: 'ขั้นต่ำ(บาท)',
  transferFee: 'ค่าธรรมเนียมการโอนเงิน(บาท)',
  interBankTransferFee: 'ค่าธรรมเนียมโอนเงินต่างธนาคาร(บาท)',
  lateFee: 'ค่าธรรมเนียมการชำระเงินล่าช้า(บาท)',
  stampFee: 'ค่าอากรแสตมป์(%)',
  bureauFee: 'ค่าตรวจเครดิตบูโร(กรณีพบ​)',
  bureauMissFee: 'ค่าตรวจเครดิตบูโร(กรณีไม่พบ)',
  preterminateFee: 'ค่าธรรมเนียมปิดสัญญาก่อนกำหนด(บาท)',
  preterminatePeriod: 'ระยะเวลาห้ามปิดสัญญา(วัน)',
  safeMargin: 'ส่วนเผื่อขั้นต่ำ(บาท)',
  safeMarginDescription:
    'หมายเหตุ: หากลูกค้าชำระขั้นต่ำต่ำกว่าที่ถูกเรียกเก็บไม่เกินค่านี้จะไม่ถูกเรียกเก็บค่าปรับ',
  openingFee: 'ค่าเปิดสัญญา(บาท)',
  sendEStatement: 'ส่งใบแจ้งอิเล็กทรอนิกส์',
  includeInBotReport: 'ส่งรายงานให้ ธปท',
  includeInNcbReport: 'ส่งรายงานให้ เครดิตบูโร',

  customId: 'เลขที่สินเชื่อกำหนดเอง',
  creditLimit: 'วงเงินสูงสุด',
  transDate: 'วันที่ทำรายการ',
  trc: 'รหัสรายการ',
  duration: 'จำนวนวัน',
  cashIn: 'เงินเข้า',
  cashOut: 'เงินออก',
  cfPrincipal: 'เงินต้น',
  cfInterest: 'ดอกเบี้ย',
  cfFee: 'ค่าธรรมเนียม',
  newCfPrincipal: 'เงินต้นใหม่',
  newCfInterest: 'ดอกเบี้ยใหม่',
  newCfFee: 'ค่าธรรมเนียมใหม่',
  currentOsb: 'ยอดหนี้รวม',
  minDue: 'ขั้นต่ำ',
  minPaid: 'ขั้นต่ำชำระแล้ว',
  bankCode: 'รหัสบัญชี',
  writeoffAmount: 'ยอดตัดหนี้สูญ',
  lastDueDate: 'วันครบกำหนดชำระล่าสุด',
  lastStatementDate: 'วันออกใบแจ้งหนี้ล่าสุด',
  lastpayDate: 'วันที่ชำระล่าสุด',
  lastpayAmount: 'จำนวนชำระล่าสุด',
  'Remaining Withdraw Amount': 'จำนวนเบิกถอนได้',
  'Last 8 period pastdue': 'ประวัติการค้างชำระย้อนหลัง 8 งวด',
  effectiveDate: 'วันที่มีผลบังคับ',
  filingDate: 'วันที่ฟ้อง',
  blackCaseNo: 'เลขคดีดำ',
  redCaseNo: 'เลขคดีแดง',
  payWithRestructure: 'ชำระงวดแรกพร้อมการปรับโครงสร้าง',
  judgementDate: 'วันมีคำพิพากษา',
  restructureDate: 'วันปรับโครงสร้างหนี้',
  type: 'ประเภท',
  stampFeePayer: 'ผู้ชำระค่าอากรสแตมป์',
  chargeStampFeeForCopy: 'ชำระค่าคู่ฉบับสัญญา',
  gracePeriod: 'ระยะผ่อนผัน',
  useGracePeriod: 'ใช้ระยะผ่อนผัน',
  extra: 'เพิ่มเติม',
  extra1: 'เพิ่มเติม1',
  extra2: 'เพิ่มเติม2',
  extra3: 'เพิ่มเติม3',
  extra4: 'เพิ่มเติม4',
  extra5: 'เพิ่มเติม5',
  maximumCreditLimit: 'วงเงินสูงสุดห้ามเกิน',

  // Field Name that might be duplicate in various resource
  'organisation.name': 'ชื่อบริษัท',
  'product.name': 'ชื่อผลิตภัณฑ์',
  'application.id': 'เลขที่ใบสมัคร',
  'loan.id': 'เลขที่สินเชื่อ',
  'transaction.id': 'เลขที่รายการ',
  'product.id': 'เลขที่ผลิตภัณฑ์',

  // Custom validator validate message
  'validatemsg.incorrect-format': '{name} ไม่ใช่่ {format} ที่ถูกต้อง',
  'validatemsg.format.tel-no': 'เบอร์โทรศัพท์',
  'validatemsg.format.register-id': 'เลขที่นิติบุคคล',
  'validatemsg.format.citizen-id': 'เลขประจำตัวประชาชน',

  'validatemsg.confirmpass-mismatch': 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',

  'validatemsg.inputbaht.max-value-exceed': '{name} ต้องมีค่าไม่เกิน {maxValue}',
  'validatemsg.inputbaht.min-value-exceed': '{name} ต้องมีค่าไม่ต่ำกว่า {minValue}',

  // Enum
  // Payment Type
  'paymentType.revolving': 'revolving',
  'paymentType.installment': 'installment',
  // Interest Type
  'intType.effective': 'ลดต้นลดดอก',
  'intType.flat': 'เงินต้นคงที่',
  // Loan Type
  'loanType.nano': 'สินเชื่อนาโนไฟแนนซ์',
  'loanType.ploan': 'สินเชื่อส่วนบุคคล',
  'loanType.staff': 'สินเชื่อสวัสดิการ',
  'loanType.undefined': 'undefined',
  // Account Type
  'accountType.saving': 'ออมทรัพย์',
  'accountType.current': 'กระแสรายวัน',
  'accountType.fixed': 'ฝากประจำ',
  // Marital Status
  'maritalStatus.single': 'โสด',
  'maritalStatus.married': 'สมรส',
  'maritalStatus.notregister': 'สมรส (ไม่จดทะเบียน)',
  'maritalStatus.divorced': 'หย่า',
  'maritalStatus.widow': 'หม้าย',
  // Bank
  'bank.BBL': 'ธนาคารกรุงเทพ จำกัด (มหาชน)',
  'bank.KBANK': 'ธนาคารกสิกรไทย จำกัด (มหาชน)',
  'bank.RBS': 'ธนาคารเดอะรอยัลแบงก์อ๊อฟสกอตแลนด์ เอ็น.วี. สาขากรุงเทพฯ',
  'bank.KTB': 'ธนาคารกรุงไทย จำกัด (มหาชน)',
  'bank.JPMC': 'ธนาคารเจพีมอร์แกน เชส สาขากรุงเทพฯ',
  'bank.UFJ': 'ธนาคารแห่งโตเกียว-มิตซูบิชิ ยูเอฟเจ สาขากรุงเทพฯ',
  'bank.TMB': 'ธนาคารทหารไทย จํากัด (มหาชน)',
  'bank.SCB': 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)',
  'bank.CITI': 'ธนาคารซิตี้แบงก์',
  'bank.SMBC': 'ธนาคารซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น',
  'bank.SCBT': 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)',
  'bank.CIMBT': 'ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)',
  'bank.UOBT': 'ธนาคารยูโอบี จำกัด (มหาชน)',
  'bank.BAY': 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)',
  'bank.MEGA ICBC': 'ธนาคาร เมกะ สากลพาณิชย์ จำกัด (มหาชน)',
  'bank.AMERICA': 'ธนาคารแห่งอเมริกา เนชั่นแนล แอสโซซิเอชั่น',
  'bank.GSB': 'ธนาคารออมสิน',
  'bank.HSBC': 'ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น จำกัด',
  'bank.DB': 'ธนาคารดอยซ์แบงก์',
  'bank.GHB': 'ธนาคารอาคารสงเคราะห์',
  'bank.BAAC': 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  'bank.MHCB': 'ธนาคารมิซูโฮ คอร์ปอเรต จำกัด',
  'bank.BNPP': 'ธนาคารบีเอ็นพี พารีบาส์ สาขากรุงเทพฯ',
  'bank.TBANK': 'ธนาคารธนชาต จำกัด (มหาชน)',
  'bank.ISBT': 'ธนาคารอิสลามแห่งประเทศไทย',
  'bank.TISCO': 'ธนาคารทิสโก้ จำกัด (มหาชน)',
  'bank.KK': 'ธนาคารเกียรตินาคิน จำกัด (มหาชน)',
  'bank.ICBC THAI': 'ธนาคารไอซีบีซี (ไทย) จำกัด (มหาชน)',
  'bank.TCRB': 'ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)',
  'bank.LH BANK': 'ธนาคารแลนด์ แอนด์ เฮ้าส์ เพื่อรายย่อย จำกัด (มหาชน)',
  'bank.TTB': 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)',
  // Restructure
  'restructureType.debtRestructuring': 'ปรับโครงสร้างหนี้',
  'restructureType.sam': 'คลินิคแก้หนี้',
  'restructureType.settlementAgreement': 'สัญญาประณีประนอมยอมความ',
  'restructureType.defaultJudgement': 'พิพากษาฝ่ายเดียว',
  'restructure.success-message': 'ส่งคำขอปรับโครงสร้างหนี้เรียบร้อย โปรดรอการอนุมัติ',
  // Filing
  'filing.success-message': 'ส่งคำขอส่งฟ้องเรียบร้อย โปรดรอการอนุมัติ',

  relation: 'ความสัมพันธ์',
  'relation.relative': 'ญาติ',
  'relation.spouse': 'คู่สมรส',
  'relation.parents': 'บิดา/มารดา',
  'relation.child': 'บุตร',
  'relation.sibling': 'พี่น้อง',
  'relation.friend': 'เพื่อน',
  'relation.colleague': 'เพื่อนร่วมงาน',

  'title.mr': 'นาย',
  'title.mrs': 'นาง',
  'title.miss': 'นางสาว',

  'documentCategory.application': 'ใบสมัครสินเชื่อ',
  'documentCategory.citizenCard': 'บัตรประชาชน',
  'documentCategory.incomeStatement': 'หนังสือรับรองรายได้',
  'documentCategory.consent': 'หนังสือให้ความยินยอม',
  'documentCategory.letterOfGuarantee': 'หนังสือค้ำประกัน',
  'documentCategory.increaseCreditLimitDocuments': 'เอกสารเพิ่มวงเงิน',
  'documentCategory.disburseDocuments': 'เอกสารเบิกถอน',
  'documentCategory.bankbook': 'สมุดบัญชี',
  'documentCategory.signature': 'ลายเซ็น',
  'documentCategory.other': 'อื่นๆ',

  'residentType.house': 'บ้านเดียว',
  'residentType.townHouse': 'ทาวน์เฮาส์',
  'residentType.condo': 'คอนโดมิเนี่ยม',
  'residentType.apartment': 'อพาร์ทเมนท์',

  'residentOwner.own': 'บ้านของตนเอง',
  'residentOwner.family': 'บ้านบิดา/มารดา/ญาติ',
  'residentOwner.welfare': 'บ้านพักสวัสดิการ',
  'residentOwner.rent': 'บ้านเช่า',

  'education.underHighSchool': 'ต่ำกว่ามัธยมปลาย',
  'education.highSchool': 'มัธยมปลาย / ปวช.',
  'education.diploma': 'อนุปริญญา / ปวส.',
  'education.bachelor': 'ปริญญาตรี',
  'education.higherBachelor': 'สูงกว่าปริญญาตรี',

  'employmentStatus.permanent': 'ประจำ',
  'employmentStatus.partTime': 'ชั่วคราว',
  'employmentStatus.daily': 'รายวัน',

  'salaryChannel.bankTransfer': 'โอนผ่านธนาคาร',
  'salaryChannel.cheque': 'รับเป็นเช็ค',
  'salaryChannel.cash': 'รับเป็นเงินสด',

  'stampFeePayer.borrower': 'ผู้กู้',
  'stampFeePayer.lender': 'ผู้ให้กู้',

  lawsuit: 'ขออนุมัติฟ้อง',
};

export default {
  locale: 'th-TH',
  antdData,
  messages,
};
