import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Tag, Layout } from 'antd';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import SearchableTable from '../../../components/CbsComponents/SearchableTable/SearchableTable';
import { API_URL } from '../../../helpers/api/api';

interface Props {
  queryString: string;
  pathname: string;
  currentProjectId: string;
}
export function getTag(status: string) {
  let color = '';
  switch (status) {
    case 'active':
      color = 'success';
      break;
    case 'unverify':
      color = 'processing';
      break;
    default:
      color = 'default';
  }
  return (
    <span>
      <Tag style={{ textAlign: 'center' }} color={color}>
        {status}
      </Tag>
    </span>
  );
}
const ListUser: React.FC<Props & WrappedComponentProps> = ({
  queryString,
  pathname,
  currentProjectId,
  intl,
}) => {
  const columns = [
    {
      title: <FormattedMessage id="email" />,
      dataIndex: 'email',
      width: '200px',
      render: (email: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`policies/${email}`}>
            {email}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="role" />,
      dataIndex: 'roles',
      render: (roles: any) => (
        <span>
          {roles.map((role: any) => {
            return <Tag key={role}>{role}</Tag>;
          })}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="status" />,
      width: '88px',
      dataIndex: 'status',
      render: (status: string) => getTag(status),
    },
  ];
  const breadcrumbs = [
    {
      title: 'menu.policies',
      href: '/policies',
    },
  ];

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.policies' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.policies" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SearchableTable
          columns={columns}
          fetchUrl={`${API_URL}/projects/${currentProjectId}/policies`}
          addUrl="policies/new"
          addPermissions="policies.*.create"
          pathname={pathname}
          search={queryString}
          rowKey="id"
          enableDownload
          onDelete
          urlDelete={`${API_URL}/projects/${currentProjectId}/policies`}
          deleteKeyProp="email"
          deletePermissions="policies.*.delete"
        />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps)(injectIntl(ListUser));
