import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import LoanList from '../../components/CbsComponents/Loans/LoanList/LoanList';

interface Props {}
const ListLoan: React.FC<Props & PropsFromRedux> = ({ projectId, queryString, pathname }) => {
  const breadcrumbs = [
    {
      title: 'menu.loans',
      href: '/loans',
    },
  ];

  return (
    <ContentContainer
      helmetTitle="loan-list.helmet-title"
      pageTitle="loan-list.page-title"
      breadcrumbs={breadcrumbs}
    >
      <LoanList />
    </ContentContainer>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ListLoan);
