import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Row, Col } from 'antd';
import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';

interface Prop extends FormComponentProps {
  visible: boolean;
  onCancel: any;
  onCreate: any;
}
interface State {
  yearVisible: boolean;
}
const ApproveRequestApproval = Form.create<Prop>({ name: 'form_in_modal' })(
  class extends React.Component<Prop, State> {
    constructor(props: Prop) {
      super(props);
      this.state = { yearVisible: false };
    }

    render() {
      const { visible, onCancel, onCreate } = this.props;
      return (
        <Modal visible={visible} okText="OK" width="700px" onCancel={onCancel} onOk={onCreate}>
          <div>
            <Row>
              <h2>การอนุมัติ</h2>
            </Row>
            <hr />
            <Row>
              <Col span={24}>ต้องการอนุมัติใช่หรือไม่</Col>
            </Row>
          </div>
        </Modal>
      );
    }
  },
);

export default ApproveRequestApproval;
