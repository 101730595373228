import React from 'react';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import styles from './topbar.module.scss';

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-auto">
          <img style={{ width: '150px' }} src="resources/images/logo.svg" alt="" />
        </div>
        <div className="mr-4">
          <LanguageSelector />
        </div>
        <ProfileMenu />
      </div>
    );
  }
}

export default TopBar;
