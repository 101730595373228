import React, { useEffect, FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { push, RouterState } from 'connected-react-router';
import { login, UserState } from '../../../redux/user/user-duck';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { ErrorInfo } from '../../../types/form-types';
import { pickQueryParamAsString } from '../../../helpers/querystring';
import { onFinishFailedHandler } from '../../../helpers/form';
import styles from './login.module.scss';

interface Props {}
const Login: FC<Props & PropsFromRedux> = ({ user, push, login, search }) => {
  const intl = useIntl();
  useEffect(() => {
    if (user.authorized) {
      push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authorized]);

  const onFinish = (values: any) => {
    const successNotification = {
      message: intl.formatMessage({ id: 'login.success-message' }),
      description: intl.formatMessage({ id: 'login.success-description' }),
    };
    const redirect = pickQueryParamAsString(search, 'redirect', '/');
    const { email, password } = values;
    login({
      email,
      password,
      successNotification,
      redirect,
    });
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'login.failed-message', errorInfo);
  };

  return (
    <div className={styles.container}>
      <Helmet title={intl.formatMessage({ id: 'login.helmet-title' })} />
      <Row>
        <Col xs={0} sm={0} md={12} lg={12}>
          <Row style={{ justifyContent: 'center' }}>
            <img
              style={{ marginTop: '350px', width: '80%' }}
              src="resources/images/logo-inverse.svg"
              alt="noburo logo"
            />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.form}>
            <h4 className="text-uppercase">
              <strong>
                <FormattedMessage id="login.please-log-in" />
              </strong>
            </h4>
            <br />
            <Form
              layout="vertical"
              hideRequiredMark
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={<FormattedMessage id="email" />}
                name="email"
                rules={[{ type: 'email' }, { required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id="password" />}
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password type="password" />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>
                    <FormattedMessage id="login.remember-me" />
                  </Checkbox>
                </Form.Item>
                <Link
                  to="/users/forgot"
                  className="utils__link--blue utils__link--underlined pull-right"
                >
                  <FormattedMessage id="login.forgot-password" />
                </Link>
              </Form.Item>
              <div className="form-actions">
                <Button
                  type="primary"
                  className="width-150 mr-4"
                  htmlType="submit"
                  loading={user.loading}
                  disabled={user.loading}
                >
                  <FormattedMessage id="login.login" />
                </Button>
                <span className="ml-3">
                  <Link to="/users/register" className="utils__link--blue utils__link--underlined">
                    <FormattedMessage id="login.register" />
                  </Link>{' '}
                  <FormattedMessage id="login.if-you-dont-have-account" />
                </span>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ user, router }: { user: UserState; router: RouterState }) => {
  return {
    user,
    search: router.location.search,
  };
};

const connector = connect(mapStateToProps, { login, push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
