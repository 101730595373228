import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Form, Button, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../common/DateOnlyPicker';
import { API_URL, showError } from '../../../helpers/api/api';
import { ErrorInfo } from '../../../types/form-types';
import { UserState } from '../../../redux/user/user-duck';
import { onFinishFailedHandler } from '../../../helpers/form';

const StatementForm: FC<PropsFromRedux> = ({ projectId, user }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (value: Store) => {
    try {
      setLoading(true);
      const payload = { statementDate: value.statementDate };
      console.log('value.statementDate: ', value.statementDate);
      const url = `${API_URL}/projects/${projectId}/loans/statements`;

      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        }),
        body: JSON.stringify(payload),
      });
      const blob = await response.blob();
      const link = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = link;
      a.target = '_blank';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      notification.success({
        message: intl.formatMessage({ id: 'download-statement.success-message' }),
      });
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="utils__title">
            <strong>
              <FormattedMessage id="statementPdf.helmet-title" />
            </strong>
          </div>
        </div>
        <div className="card-body">
          <Form layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label={<FormattedMessage id="statementDate" />}
              name="statementDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" disabled={loading} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                <FormattedMessage id="submit" />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ user }: { user: UserState }) => ({
  projectId: user.currentProjectId,
  user,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatementForm);
