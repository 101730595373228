import { API_URL, postJson, patchJson } from '../helpers/api/api';

export async function login(email, password) {
  return postJson(`${API_URL}/users/login`, { email, password });
}
export async function renewToken() {
  return postJson(`${API_URL}/users/renew-token`);
}

export async function logout() {
  return postJson(`${API_URL}/users/logout`);
}
export async function register(email, password) {
  return postJson(`${API_URL}/users/register`, { email, password });
}
export async function verify(token) {
  return patchJson(`${API_URL}/users/verify?token=${token}`);
}

export async function otpVerify(otp, ref) {
  return postJson(`${API_URL}/users/onboarding/verify`, { otp, ref });
}
