import React from 'react';
import PropTypes from 'prop-types';
// import Sparkline from '@rowno/sparkline';
import style from './style.module.scss';

function ChartCard({ title, amount }) {
  return (
    <div className={`card ${style.card}`}>
      {/* {chartProps && (
        <div className={style.chart}>
          <Sparkline {...chartProps} />
        </div>
      )} */}
      {amount && <div className={style.amount}>{amount}</div>}
      {title && <div className={style.title}>{title}</div>}
    </div>
  );
}
ChartCard.defaultProps = {
  // chartProps: {},
  title: '',
  amount: '',
};
ChartCard.propTypes = {
  // chartProps: PropTypes.object,
  title: PropTypes.string,
  amount: PropTypes.string,
};

export default ChartCard;
