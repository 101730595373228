import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { satangToBath } from '../../../helpers/format/satangToBath';

const ImportCreditreportsTable: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;
  const column = [
    {
      title: <FormattedMessage id="loan.name" />,
      render: (resolve: any) => {
        const { firstName, lastName1 } = resolve.PN[0];
        return `${firstName} ${lastName1}`;
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="citizenId" />,
      render: (resolve: any) => {
        const { ID } = resolve;
        return ID[0].idNumber;
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.creditreports.loanCount" />,
      render: (resolve: any) => {
        const { TL } = resolve;
        return TL.length;
      },
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.creditreports.osb" />,
      render: (resolve: any) => {
        const { TL } = resolve;
        let osb = 0;
        TL.forEach((tl: any) => {
          osb += tl.amountOwed;
        });
        return satangToBath(osb);
      },
      width: '15%',
    },
  ];
  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={column} />
    </div>
  );
};

export default ImportCreditreportsTable;
