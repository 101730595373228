import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Tabs, Row, Col, Button, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { API_URL, patchJson, showError } from '../../../../../../../helpers/api/api';
import { onFinishFailedHandler } from '../../../../../../../helpers/form';
import General from './General/General';
import Address from './Address';
import Product from './Product';
import EmploymentInfo from './EmploymentInfo';
import NonoFinanceFields from './NonoFinanceFields';
import ContactPerson from './ContactPerson';
import Guarantor from './Guarantor/Guarantor';
import renderIfHasPermission from '../../../../../../../layouts/renderIfHasPermissisons';
import { applicationStatusToStage } from '../../../../../../../helpers/application';
import { ErrorInfo } from '../../../../../../../types/form-types';

interface Props {
  projectId: string;
  application: any;
  reloadApplication: () => void;
}

const KeyDataTabs: React.FC<Props> = ({ projectId, application, reloadApplication }) => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const intl = useIntl();
  useEffect(form.resetFields, [application]);
  const onFinish = async (value: Store) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}`, value);
      notification.success({
        message: intl.formatMessage({ id: 'application-edit.save-success-message' }),
      });
      reloadApplication();
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const stage = applicationStatusToStage(application.status);
  const SaveButton = renderIfHasPermission(
    [`applications.${stage}.update`],
    <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={application}
    >
      <Tabs>
        <TabPane tab={<FormattedMessage id="general" />} key="general" forceRender>
          <General form={form} application={application} />
        </TabPane>
        <TabPane tab={<FormattedMessage id="address" />} key="address" forceRender>
          <Address form={form} />
        </TabPane>
        <TabPane tab={<FormattedMessage id="product" />} key="product" forceRender>
          <Product />
        </TabPane>
        <TabPane tab={<FormattedMessage id="employment" />} key="employment" forceRender>
          <EmploymentInfo form={form} />
        </TabPane>
        <TabPane tab={<FormattedMessage id="nanofinance" />} key="nanofinance" forceRender>
          <NonoFinanceFields form={form} />
        </TabPane>
        <TabPane tab={<FormattedMessage id="contactPerson" />} key="contactPerson" forceRender>
          <ContactPerson />
        </TabPane>
        <TabPane tab={<FormattedMessage id="guarantor1" />} key="guarantor0" forceRender>
          <Guarantor form={form} index={0} application={application} />
        </TabPane>
        <TabPane tab={<FormattedMessage id="guarantor2" />} key="guarantor1" forceRender>
          <Guarantor form={form} index={1} application={application} />
        </TabPane>
      </Tabs>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <SaveButton />
        </Col>
      </Row>
    </Form>
  );
};

export default KeyDataTabs;
