import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Row, Col, notification, Descriptions } from 'antd';
import moment from 'moment';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { datediff } from '../../../../helpers/date/date';
import { addcomma } from '../../../../helpers/format/satangToBath';

interface Props {
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  reloadData: () => void;
  projectId: string;
  loanId: string;
  permissionCheck: any;
  lastTransaction: any;
  loan: any;
}

const OutStandingBalance: FC<any> = ({ osb }) => {
  const intl = useIntl();
  const { asOfDate, principal, interest, fee, total } = osb;
  return (
    <Descriptions column={4} title={intl.formatMessage({ id: 'osbAt' }, { asOfDate })}>
      <Descriptions.Item label={<FormattedMessage id="principal" />}>
        {addcomma(principal)}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="interest" />}>
        {addcomma(interest)}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="fee" />}>{addcomma(fee)}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="osb" />}>{addcomma(total)}</Descriptions.Item>
    </Descriptions>
  );
};

const CloseLoanModal: FC<Props> = ({
  visible,
  onCancel,
  cancelText,
  okText,
  reloadData,
  projectId,
  loanId,
  permissionCheck,
  lastTransaction,
  loan,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const initialOsb = { principal: 0, interest: 0, fee: 0, total: 0 };
  const [osb, setOsb] = useState<any>(initialOsb);
  const [sendPrincipal, setPrincipal] = useState<any>();
  const [sendInterest, setInterest] = useState<any>();
  const [sendFee, setFee] = useState<any>();
  const { setFieldsValue } = form;

  const calculateOsb = (asOfDate: string, intRate: number, lastTransaction: any) => {
    const { cfPrincipal, cfInterest, cfFee } = lastTransaction;
    const principal = cfPrincipal / 100;
    const duration = datediff(asOfDate, lastTransaction.transDate);
    const dueInterest = ((cfPrincipal * intRate) / 365) * duration;
    const interest = (cfInterest + dueInterest) / 100;
    const fee = cfFee / 100;
    setPrincipal((principal * 100).toFixed(0));
    setInterest((interest * 100).toFixed(0));
    setFee((fee * 100).toFixed(0));
    const total = principal + interest + fee;
    return {
      asOfDate,
      total: total.toFixed(2),
      principal: principal.toFixed(2),
      interest: interest.toFixed(2),
      fee: fee.toFixed(2),
    };
  };
  const onEffectiveDateChange = (effectiveDate: any) => {
    const osb = calculateOsb(effectiveDate, loan.intRate, lastTransaction);
    setOsb(osb);
    // Need to convert to satang unit
    setFieldsValue({
      writeoffAmount: Number(osb.total) * 100,
    });
  };
  const onFinish = async (value: Store) => {
    try {
      await postJson(`${API_URL}/projects/${projectId}/loans/${loanId}/close`, {
        closeDate: value.closeDate,
      });
      notification.success({ message: intl.formatMessage({ id: 'closeloan.success-message' }) });
      form.resetFields();
      onCancel();
      reloadData();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  function disabledDate(current: any) {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment(lastTransaction.transDate)
          .add(-1, 'day')
          .endOf('day')
    );
  }
  let okButtonProps = {};
  if (permissionCheck === 'true') {
    okButtonProps = { ghost: true };
  } else {
    okButtonProps = { disabled: true };
  }

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      okType="danger"
      okButtonProps={okButtonProps}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            {permissionCheck === 'true' ? (
              <FormattedMessage id="closeloan" />
            ) : (
              <FormattedMessage id="calculateCloseLoan" />
            )}
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="closeDate" />}
              name="closeDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker
                format="DD-MM-YYYY"
                onChange={onEffectiveDateChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <OutStandingBalance osb={osb} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CloseLoanModal;
