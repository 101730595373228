import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import PolicieForm from '../../../components/CbsComponents/PolicieForm/PolicieForm';
import ContentContainer from '../../../components/LayoutComponents/ContentContainer';
import { UserState } from '../../../redux/user/user-duck';
import { useDataById, useData } from '../../../helpers/form/fetch-hook';
import { API_URL } from '../../../helpers/api/api';

const EditUser: FC<PropsFromRedux> = ({ projectId }) => {
  const { email } = useParams();
  const [roles] = useData(`${API_URL}/projects/${projectId}/roles`, 1000);
  const [user, reloadUser] = useDataById(`${API_URL}/projects/${projectId}/policies/${email}`);

  const breadcrumbs = [
    {
      title: 'menu.policies',
      href: '/policies',
    },
    {
      title: 'add',
      href: `/policies/new`,
    },
  ];

  return (
    <ContentContainer
      helmetTitle="user-edit.helmet-title"
      helmetTitleParams={{ email }}
      pageTitle="user-edit.page-title"
      breadcrumbs={breadcrumbs}
    >
      <PolicieForm roles={roles.data} data={user.data} reloadData={reloadUser} />
    </ContentContainer>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditUser);
