import { Form } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Modal, Row, Col } from 'antd';
import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';

interface Prop extends FormComponentProps {
  visible: boolean;
  onCancel: any;
  onCreate: any;
  cancelText: string;
  okText: string;
}
interface State {
  yearVisible: boolean;
}
const CancelWithdraw = Form.create<Prop>({ name: 'form_in_modal' })(
  class extends React.Component<Prop, State> {
    constructor(props: Prop) {
      super(props);
      this.state = { yearVisible: false };
    }

    render() {
      const { visible, onCancel, onCreate, cancelText, okText } = this.props;
      return (
        <Modal
          visible={visible}
          width="700px"
          onCancel={onCancel}
          onOk={onCreate}
          cancelText={cancelText}
          okText={okText}
          okButtonProps={{ danger: true }}
        >
          <div>
            <Row>
              <h2>
                <FormattedMessage id="cancel" />
              </h2>
            </Row>
            <hr />
            <Row>
              <Col span={24}>
                <FormattedMessage id="withdraws-list.do-you-want-to-cancel" />
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  },
);

export default CancelWithdraw;
