import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Select, Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';

interface Prop extends FormComponentProps {
  visible: boolean;
  onCreate: any;
}
const UploadForm = Form.create<Prop>({ name: 'form_in_modal' })(
  class extends React.Component<Prop> {
    render() {
      const { visible, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const { Option } = Select;
      return (
        <Modal
          visible={visible}
          closable={false}
          title={<FormattedMessage id="document" />}
          okText={<FormattedMessage id="documents.submit" />}
          footer={[
            <Button key="submit" type="primary" onClick={onCreate}>
              <FormattedMessage id="documents.submit" />
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label={<FormattedMessage id="category" />}>
              {getFieldDecorator('category', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id="documents.categoryMessage" />,
                  },
                ],
              })(
                <Select style={{ width: 250 }}>
                  <Option value="application">
                    <FormattedMessage id="documentCategory.application" />
                  </Option>
                  <Option value="citizenCard">
                    <FormattedMessage id="documentCategory.citizenCard" />
                  </Option>
                  <Option value="incomeStatement">
                    <FormattedMessage id="documentCategory.incomeStatement" />
                  </Option>
                  <Option value="consent">
                    <FormattedMessage id="documentCategory.consent" />
                  </Option>
                  <Option value="letterOfGuarantee">
                    <FormattedMessage id="documentCategory.letterOfGuarantee" />
                  </Option>
                  {/* <Option value="increaseCreditLimitDocuments">
                    <FormattedMessage id="documentCategory.increaseCreditLimitDocuments" />
                  </Option> */}
                  <Option value="bankbook">
                    <FormattedMessage id="documentCategory.bankbook" />
                  </Option>
                  <Option value="signature">
                    <FormattedMessage id="documentCategory.signature" />
                  </Option>
                  <Option value="other">
                    <FormattedMessage id="documentCategory.other" />
                  </Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label={<FormattedMessage id="description" />}>
              {getFieldDecorator('description')(<Input type="textarea" />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

export default UploadForm;
