import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Input, Row, Col, Select, notification, AutoComplete } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import InputBaht from '../../common/InputBaht';

interface Props {
  visible: boolean;
  onCancel: () => void;
  cancelText: any;
  okText: any;
  projectId: string;
  loanId: string;
  reloadData: () => void;
}

const bankCodeOption = [
  { value: 'BCA001' },
  { value: 'BSV001' },
  { value: 'BCA002' },
  { value: 'BCA003' },
  { value: 'BSV002' },
  { value: 'BSV003' },
  { value: 'BSV004' },
  { value: 'BSV005' },
  { value: 'BCA007' },
  { value: 'BSV006' },
  { value: 'BSV007' },
  { value: 'BCA004' },
  { value: 'BSV008' },
  { value: 'BCA005' },
  { value: 'BCA006' },
  { value: 'BSV009' },
  { value: 'BSV010' },
  { value: 'BSV011' },
  { value: 'DDSL01' },
];
const PaymentModal: FC<Props> = ({
  visible,
  okText,
  cancelText,
  onCancel,
  reloadData,
  projectId,
  loanId,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { Option } = Select;

  const onFinish = async (value: Store) => {
    const { amount, transDate, trc, bankCode } = value;
    const payment = {
      amount: Number(amount),
      transDate,
      trc,
      bankCode,
    };

    try {
      await postJson(`${API_URL}/projects/${projectId}/loans/${loanId}/payments`, { payment });
      form.resetFields();
      notification.success({
        message: intl.formatMessage({ id: 'payment.success-message' }),
      });
      onCancel();
      reloadData();
    } catch (error) {
      showError(error);
    }
  };

  function caseInsensitiveMatch(inputValue: any, option: any) {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      okType="primary"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
      width="700px"
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <h2>
            <FormattedMessage id="payment" />
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              label={<FormattedMessage id="amount" />}
              name="amount"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="paymentDate" />}
              name="transDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<FormattedMessage id="paymentChannel" />}
              name="trc"
              rules={[{ required: true }]}
            >
              <Select style={{ width: '180px' }}>
                <Option value="CD">Cash Deposit</Option>
                <Option value="BC">Barcode</Option>
                <Option value="CT">Cash Transfer</Option>
                <Option value="PP">PromptPay</Option>
                <Option value="CS">Counter Service</Option>
                <Option value="PR">Pay Roll</Option>
                <Option value="WAB">Waive Balance</Option>
                <Option value="WAM">Waive min pay and bucket</Option>
                <Option value="WAC">Waive Court Order</Option>
                <Option value="LC">Loan Claim</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<FormattedMessage id="bankCode" />} name="bankCode">
              {projectId === '760296' || projectId === '640579' ? (
                <AutoComplete filterOption={caseInsensitiveMatch} options={bankCodeOption} />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PaymentModal;
