import React, { FC } from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import ApplicationList from '../../components/CbsComponents/Application/ApplicationList/ApplicationList';

const ListApplication: FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.applications',
      href: '/applications',
    },
  ];
  return (
    <ContentContainer
      helmetTitle="application-list.helmet-title"
      pageTitle="application-list.page-title"
      breadcrumbs={breadcrumbs}
    >
      <ApplicationList />
    </ContentContainer>
  );
};

export default ListApplication;
