import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl } from 'react-intl';
import { Input, Tabs } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { useUrlState, useData } from '../../../../helpers/form/fetch-hook';
import { UserState } from '../../../../redux/user/user-duck';
import styles from '../../SearchableTable/searchable-table.module.scss';
import { API_URL } from '../../../../helpers/api/api';
import AllTable from './Tables/AllTable';
import CloseTable from './Tables/CloseTable';

interface Props {}
const LoanList: FC<Props & PropsFromRedux> = ({ projectId }) => {
  const { Search } = Input;
  const intl = useIntl();
  const [pageSize, setPageSize] = useState<number>(10);
  const [data] = useData(`${API_URL}/projects/${projectId}/loans`, pageSize);
  const [urlState, patchUrlState] = useUrlState('/loans');
  const [searchterm, setSearchterm] = useState(urlState.searchterm);
  const sanitizeStatus = (status: string | string[] | null | undefined) => {
    const validStatuses = [
      '*',
      'normal',
      'await_review,payment_need,negative_osb',
      'writeoff',
      'close',
    ];
    if (typeof status !== 'string') {
      return '*';
    }
    return validStatuses.includes(status) ? status : '*';
  };

  const { TabPane } = Tabs;
  const changePage = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    patchUrlState({ page: current });
    setPageSize(Number(pageSize));
  };

  const tabPanes = [
    { tab: 'ALL', key: '*' },
    { tab: 'NORMAL', key: 'normal' },
    { tab: 'REVIEW', key: 'await_review,payment_need,negative_osb' },
    { tab: 'WRITEOFF', key: 'writeoff' },
    { tab: 'CLOSE', key: 'close' },
  ];

  return (
    <section className="card">
      <div className="card-body">
        <div className={styles.actionBar}>
          <Search
            placeholder={intl.formatMessage({ id: 'loan-list.search-fields' })}
            onSearch={(value: any) => {
              patchUrlState({ page: 1, searchterm: value, status: '*' });
            }}
            style={{ width: '400px', float: 'right' }}
            onChange={e => {
              setSearchterm(e.target.value);
            }}
            value={searchterm}
          />
          <div style={{ clear: 'both' }} />
        </div>
        <Tabs
          size="large"
          onChange={value => {
            setSearchterm(undefined);
            patchUrlState({ page: 1, searchterm: undefined, status: value });
          }}
          type="line"
          activeKey={sanitizeStatus(urlState.status)}
        >
          {tabPanes.map(tab => {
            switch (tab.tab) {
              case 'ALL':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <AllTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                    />
                  </TabPane>
                );
              case 'WRITEOFF':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <AllTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                    />
                  </TabPane>
                );
              case 'CLOSE':
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <CloseTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                    />
                  </TabPane>
                );
              default:
                return (
                  <TabPane tab={tab.tab} key={tab.key}>
                    <AllTable
                      dataSource={data.data.map((application: any) => ({
                        ...application,
                        key: application.id,
                      }))}
                      loading={data.loading}
                      onChange={changePage}
                      paginations={data.paginations}
                    />
                  </TabPane>
                );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
};

const mapStateToProps = ({ user }: { user: UserState; settings: any }) => {
  return {
    projectId: user.currentProjectId,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoanList);
