import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Select, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';

interface Props {
  visible: boolean;
  onCancel: () => void;
  cancelText: any;
  okText: any;
  projectId: string;
  loanId: string;
  reloadData: () => void;
}

const AdjModal: FC<Props> = ({
  visible,
  okText,
  cancelText,
  onCancel,
  reloadData,
  projectId,
  loanId,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const onFinish = async (value: Store) => {
    try {
      const { statementDate } = value;
      await postJson(`${API_URL}/projects/${projectId}/loans/adj/${loanId}`, { statementDate });
      form.resetFields();
      notification.success({
        message: intl.formatMessage({ id: 'adj.success-message' }),
      });
      onCancel();
      reloadData();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      okType="primary"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
      width="700px"
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <h2>
          <FormattedMessage id="menu.adj" />
        </h2>
        <hr />
        <Form.Item
          label={<FormattedMessage id="statementDate" />}
          name="statementDate"
          rules={[{ required: true }]}
        >
          <DateOnlyPicker format="DD-MM-YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdjModal;
