import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Select, Row, Col, Button } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import componentValues from '../../../../services/utilities/ComponentValue';
import { store } from '../../../../redux/store';
import { FormattedMessage } from 'react-intl';

interface Prop {
  form: WrappedFormUtils;
  handleSubmitReport: any;
}
const TransactionReport: React.FC<Prop> = ({ form, handleSubmitReport }) => {
  const { Option } = Select;
  const { getFieldDecorator } = form;
  const { email } = store.getState().user;
  return (
    <div>
      <Row gutter={componentValues.gutterValue}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Form.Item label={<FormattedMessage id="startDate" />}>
            {getFieldDecorator('startDate', {
              rules: [
                {
                  required: true,
                  message: 'please select statement date',
                },
              ],
            })(<DatePicker />)}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Form.Item label={<FormattedMessage id="endDate" />}>
            {getFieldDecorator('endDate', {
              rules: [
                {
                  required: true,
                  message: 'please select statement date',
                },
              ],
            })(<DatePicker />)}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Form.Item label={<FormattedMessage id="trc" />}>
            {getFieldDecorator('trc')(
              <Select style={{ width: '100%' }}>
                <Option value="LO">LO</Option>
                <Option value="ADJ">ADJ</Option>
                <Option value="CD">CD</Option>
                <Option value="BC">BC</Option>
                <Option value="CT">CT</Option>
                <Option value="PP">PP</Option>
                <Option value="WD">WD</Option>
                <Option value="FD">FD</Option>
                <Option value="PO">PO</Option>
                <Option value="LFE">LFE</Option>
                <Option value="SFE">SFE</Option>
                <Option value="BFE">BFE</Option>
                <Option value="TFE">TFE</Option>
                <Option value="WAB">WAB</Option>
                <Option value="WAM">WAM</Option>
                <Option value="COR">COR</Option>
                <Option value="RSTR">RSTR</Option>
                <Option value="LC">LC</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button style={{ marginLeft: 8 }} type="primary" onClick={handleSubmitReport}>
            {<FormattedMessage id="comon.sendTo" />}
            &nbsp;
            {`${email}`}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionReport;
