import React, { useState, useEffect } from 'react';
import { store } from '../../../redux/store';
import { Tabs, notification } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import TablePending from './RequestApprovalsTables/TablePending';
import TableApproved from './RequestApprovalsTables/TableApproved';
import TableReject from './RequestApprovalsTables/TableReject';
import { getJson, patchJson, API_URL, showError } from '../../../helpers/api/api';

export interface DataSource {
  id: number;
  appId: string;
  loanId: string;
  name: string;
  citizenId: string;
  type: string;
  writeOffAmount: number;
  status: string;
  date: string;
}

export enum RequestApprovalStatus {
  PENDING = 'pending',
  APPROVE = 'approve',
  REJECT = 'reject',
}

const RequestApprovalsTabs: React.FC = () => {
  const intl = useIntl();
  const projectId = store.getState().user.currentProjectId;
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [activeKey, setActiveKey] = useState<string>(RequestApprovalStatus.PENDING);
  const [status, setStatus] = useState<string>(RequestApprovalStatus.PENDING);
  const [loading, setLoading] = useState<boolean>(false);

  const tabPanes = [
    { tab: 'Pending', key: RequestApprovalStatus.PENDING },
    { tab: 'Approve', key: RequestApprovalStatus.APPROVE },
    { tab: 'Reject', key: RequestApprovalStatus.REJECT },
  ];

  async function getDataSource(status: string, search?: string) {
    try {
      setLoading(true);
      const url = `${API_URL}/projects/${projectId}/request-approvals?status=${status}`;
      const { data } = await getJson(url);
      setDataSource(data);
      setLoading(false);
    } catch (error) {
      const { code, message } = error;
      notification.error({ message: code, description: message });
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataSource(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  async function patchRequestApprovals(datas: any, patchStatus: string) {
    try {
      setLoading(true);
      const ids = datas.map((data: any) => {
        return data.id;
      });
      const loanIds = datas.map((data: any) => {
        return data.loanId;
      });
      const types = datas.map((data: any) => {
        return data.type;
      });
      const value = {
        ids,
        loanIds,
        types,
        status: patchStatus,
      };
      await patchJson(`${API_URL}/projects/${projectId}/request-approvals`, value);
      await getDataSource(status);
      setLoading(false);
      notification.success({
        message: intl.formatMessage({ id: `requestapproval.${patchStatus}.success-message` }),
      });
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  }

  const { TabPane } = Tabs;

  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className="card" style={{ backgroundColor: 'white' }}>
            <Tabs
              size="large"
              onChange={e => {
                setStatus(e);
                setActiveKey(e);
              }}
              type="line"
              activeKey={activeKey}
            >
              {tabPanes.map(tab => {
                switch (tab.tab) {
                  case 'Pending':
                    return (
                      <TabPane tab={<FormattedMessage id="withdraw.tab.Pending" />} key={tab.key}>
                        <TablePending
                          dataSource={dataSource}
                          patchRequestApprovals={patchRequestApprovals}
                          loading={loading}
                        />
                      </TabPane>
                    );
                  case 'Approve':
                    return (
                      <TabPane
                        tab={<FormattedMessage id="requestApproval.button.approve" />}
                        key={tab.key}
                      >
                        <TableApproved dataSource={dataSource} loading={loading} />
                      </TabPane>
                    );
                  case 'Reject':
                    return (
                      <TabPane
                        tab={<FormattedMessage id="requestApproval.button.reject" />}
                        key={tab.key}
                      >
                        <TableReject dataSource={dataSource} loading={loading} />
                      </TabPane>
                    );
                  default:
                    return (
                      <TabPane tab={tab.tab} key={tab.key}>
                        <TablePending
                          dataSource={dataSource}
                          patchRequestApprovals={patchRequestApprovals}
                          loading={loading}
                        />
                      </TabPane>
                    );
                }
              })}
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestApprovalsTabs;
