import qs from 'query-string';

export enum SortOrder {
  ascend = 'ascend',
  descend = 'descend',
  any = 'any',
}

export function getSortOrderFromQueryString({
  dataIndex,
  queryString,
  defaultSortOrder,
}: {
  dataIndex: string;
  queryString?: string;
  defaultSortOrder?: SortOrder;
}): string | void {
  const parsed = queryString ? qs.parse(queryString) : {};
  const { sortby, sortorder } = parsed;
  if (sortby === dataIndex) {
    if (sortorder === 'asc') {
      return 'ascend';
    }
    if (sortorder === 'desc') {
      return 'descend';
    }
    return defaultSortOrder !== SortOrder.any ? defaultSortOrder : undefined;
  }
  return defaultSortOrder !== SortOrder.any ? defaultSortOrder : undefined;
}

export function pickQueryParamAsNumber(
  queryString: string,
  param: string,
  defaultValue?: number,
): number | undefined {
  const parsed = qs.parse(queryString);
  if (Array.isArray(parsed[param])) {
    return defaultValue;
  }
  if (typeof parsed[param] === 'string' && Number.isNaN(parseInt(parsed[param] as string, 10))) {
    return defaultValue;
  }
  if (typeof parsed[param] === 'string') {
    return parseInt(parsed[param] as string, 10);
  }
  return defaultValue;
}

export function pickQueryParamAsString(
  queryString: string,
  param: string,
  defaultValue?: string,
): string | undefined {
  const parsed = qs.parse(queryString);
  if (Array.isArray(parsed[param])) {
    const params = parsed[param] as Array<string>;
    return params[0];
  }
  if (typeof parsed[param] === 'string') {
    return parsed[param] as string;
  }
  return defaultValue;
}
