import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import Breadcrumbs from '../Breadcrumbs';

interface Props {
  helmetTitle: string;
  helmetTitleParams?: any;
  pageTitle: string;
  breadcrumbs?: any[];
}
const ContentContainer: FC<Props> = ({
  helmetTitle,
  helmetTitleParams,
  pageTitle,
  breadcrumbs,
  children,
}) => {
  const intl = useIntl();

  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: helmetTitle }, helmetTitleParams)} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id={pageTitle} />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        {children}
      </div>
    </Layout.Content>
  );
};

export default ContentContainer;
