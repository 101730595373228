import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Table, Tag } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { UserState } from '../../../../../redux/user/user-duck';
import { satangToBath } from '../../../../../helpers/format/satangToBath';

interface Props {
  dataSource: any;
  loading: boolean;
  onChange: (pagination: TablePaginationConfig) => void;
  paginations?: TablePaginationConfig;
}

export function getTag(status: string) {
  let color = '';
  switch (status) {
    case 'normal':
      color = 'success';
      break;
    case 'await_review':
      color = 'orange';
      break;
    case 'payment_need':
      color = 'cyan';
      break;
    case 'negative_osb':
      color = 'purple';
      break;
    case 'writeoff':
      color = 'blue';
      break;
    case 'close':
      color = 'red';
      break;
    default:
      color = 'default';
  }
  return (
    <span>
      <Tag style={{ textAlign: 'center' }} color={color}>
        {status}
      </Tag>
    </span>
  );
}

const AllTable: FC<Props & PropsFromRedux> = ({ dataSource, loading, onChange, paginations }) => {
  const columns: any = [
    {
      title: <FormattedMessage id="loan.id" />,
      fixed: 'left',
      dataIndex: 'id',
      width: '120px',
      render: (id: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`loans/${id}`}>
            {id}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="application.id" />,
      fixed: 'left',
      dataIndex: 'appId',
      width: '120px',
      render: (appId: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`applications/${appId}`}>
            {appId}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="full-name" />,
      width: '192px',
      render: (record: any) => {
        const { title, firstName, lastName } = record;
        return `${title} ${firstName} ${lastName}`;
      },
    },
    {
      title: <FormattedMessage id="citizenId" />,
      width: '136px',
      dataIndex: 'citizenId',
    },
    {
      title: <FormattedMessage id="product" />,
      dataIndex: 'productName',
    },
    {
      title: <FormattedMessage id="creditLimit" />,
      dataIndex: 'creditLimit',
      width: '150px',
      align: 'right',
      render: (data: number) => {
        return satangToBath(data);
      },
    },
    {
      title: <FormattedMessage id="status" />,
      fixed: 'right',
      width: '88px',
      dataIndex: 'status',
      render: (status: string) => {
        return getTag(status);
      },
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      loading={loading}
      columns={columns}
      onChange={onChange}
      pagination={paginations}
      scroll={{ x: 'max-content' }}
    />
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AllTable);
