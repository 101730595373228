import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { DownloadOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Button, Input, notification, Popconfirm } from 'antd';
import { TableRowSelection, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { getJson, deleteJson, showError } from '../../../helpers/api/api';
import styles from './searchable-table.module.scss';
import { pickQueryParamAsNumber, pickQueryParamAsString } from '../../../helpers/querystring';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';

/**
 * Return loading and data
 * @param {string} fetchUrl - A string url for fetching data
 * @param {number} pageSize - Pagination's page Size
 * @param {string} queryString - Query string may include condition like searchTerm, sortorder, sortby
 */
function useData(fetchUrl: string, pageSize: number, queryString?: string): [any, () => void] {
  const parsed: {
    page: number | undefined;
    searchterm: string | undefined;
    sortby: string | undefined;
    sortorder: string | undefined;
  } = { page: 1, searchterm: undefined, sortby: undefined, sortorder: undefined };
  if (queryString) {
    parsed.page = pickQueryParamAsNumber(queryString, 'page', 1);
    parsed.searchterm = pickQueryParamAsString(queryString, 'searchterm');
    parsed.sortby = pickQueryParamAsString(queryString, 'sortby');
    parsed.sortorder = pickQueryParamAsString(queryString, 'sortorder');
  }
  const { page, searchterm, sortby, sortorder } = parsed;
  const [data, setData] = useState({
    loading: true,
    pagination: { current: page, pageSize, total: 0 },
    data: [],
  });
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());
  const reloadData = () => {
    setTimestamp(new Date().getTime());
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const condition = {
          offset: page ? (page - 1) * pageSize : undefined,
          limit: pageSize,
          searchterm,
          sortby,
          sortorder,
        };
        const response = await getJson(`${fetchUrl}?${qs.stringify(condition)}`);
        const { total, offset, limit } = response.meta;
        const pagination = {
          current: parseInt(offset, 10) / parseInt(limit, 10) + 1,
          pageSize: parseInt(limit, 10),
          total,
        };
        setData({
          loading: false,
          pagination,
          data: response.data,
        });
      } catch (error) {
        const { code, message } = error;
        notification.error({ message: code, description: message });
        setData({
          loading: false,
          pagination: { current: page, pageSize, total: 0 },
          data: [],
        });
      }
    }
    fetchData();
  }, [fetchUrl, page, pageSize, sortby, sortorder, searchterm, timestamp]);
  return [data, reloadData];
}

interface SearchableTableProps {
  columns: any;
  addUrl?: string;
  addPermissions?: string | string[];
  fetchUrl: string;
  pathname: string;
  search?: string;
  enableDownload?: boolean;
  rowKey: string;
  push: Push;
  pageSize: number;
  onDelete?: any;
  urlDelete?: any;
  deletePermissions?: string | string[];
  deleteKeyProp?: string;
}

const SearchableTable: React.FC<SearchableTableProps & WrappedComponentProps> = props => {
  const {
    columns,
    pathname,
    search,
    fetchUrl,
    addUrl,
    addPermissions,
    rowKey,
    push,
    pageSize,
    intl,
    onDelete,
    urlDelete,
    deletePermissions,
    deleteKeyProp = 'id',
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [isSelectedKeys, setIsSelectedKeys] = useState(false);
  const [fetchData, reloadData] = useData(fetchUrl, pageSize, search);

  async function columnsDelete(deleteItemKey: string) {
    try {
      await deleteJson(`${urlDelete}/${deleteItemKey}`);
      reloadData();
    } catch (error) {
      showError(error);
    }
  }
  const DeleteButton: React.FC<any> = ({ deleteItemKey }: { deleteItemKey: string }) => {
    return (
      <span>
        <Popconfirm
          title={<FormattedMessage id="common.are-you-sure" />}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          cancelText={<FormattedMessage id="no" />}
          okText={<FormattedMessage id="yes" />}
          okButtonProps={{ danger: true }}
          onConfirm={() => columnsDelete(deleteItemKey)}
        >
          <a href="#" onClick={e => e.stopPropagation()}>
            <i className="icmn-bin" />
          </a>
        </Popconfirm>
      </span>
    );
  };

  let newColumns = columns;
  if (onDelete) {
    newColumns = [
      ...columns,
      {
        width: '50px',
        fixed: 'right',
        render: (record: any) => {
          const DeleteIfHasPermission = renderIfHasPermissisons(
            deletePermissions || ['*.*.*'],
            <DeleteButton deleteItemKey={record[deleteKeyProp]} />,
          );
          return <DeleteIfHasPermission />;
        },
      },
    ];
  }

  if (!fetchData) return null;

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedRowKeys(selectedRowKeys);
    setIsSelectedKeys(selectedRowKeys.length > 0);
  };

  function onSearch(searchterm: string) {
    const condition = search ? qs.parse(search) : {};
    if (searchterm) {
      condition.searchterm = searchterm;
    } else {
      delete condition.searchterm;
    }
    condition.page = '1';
    push(`${pathname}?${qs.stringify(condition)}`);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  } as TableRowSelection<any>;

  const onChange = (
    pagination: TablePaginationConfig,
    _: any,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => {
    const condition = search ? qs.parse(search) : {};
    if (pagination) {
      const { current } = pagination;
      condition.page = `${current}`;
    }
    if (!Array.isArray(sorter)) {
      const { field, order } = sorter;
      if (typeof field === 'number') {
        condition.sortby = `${field}`;
      } else if (typeof field === 'string') {
        condition.sortby = field;
      }
      condition.sortorder = order === 'ascend' ? 'asc' : 'desc';
    }
    push(`${pathname}?${qs.stringify(condition)}`);
  };

  const tempEnableDownload = false; // disable download button for now
  const AddButton = renderIfHasPermissisons(
    addPermissions || ['*.*.*'],
    addUrl && (
      <Link to={addUrl}>
        <Button className="mr-3" type="primary" icon={<PlusCircleOutlined />}>
          {intl.formatMessage({ id: 'add' })}
        </Button>
      </Link>
    ),
  );

  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className={styles.actionBar}>
            {addUrl && <AddButton />}
            {tempEnableDownload && (
              <Fragment>
                <Button className="mr-3" icon={<DownloadOutlined />}>
                  <FormattedMessage id="downloadAll" />
                </Button>
                <Button className="mr-3" icon={<DownloadOutlined />} disabled={!isSelectedKeys}>
                  <FormattedMessage id="download" />
                </Button>
              </Fragment>
            )}
          </div>
          {!addUrl && (
            <div style={{ textAlign: 'right' }}>
              <Input.Search
                style={{ width: '20%' }}
                placeholder={intl.formatMessage({ id: 'search here' })}
                onSearch={onSearch}
              />
            </div>
          )}
          <Table
            rowSelection={tempEnableDownload ? rowSelection : undefined}
            columns={newColumns}
            dataSource={fetchData.data}
            className={styles.hoverTable}
            loading={fetchData.loading}
            scroll={{ x: 'max-content' }}
            pagination={fetchData.pagination}
            onChange={onChange}
            rowKey={rowKey}
          />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  pageSize: state.settings.pageSize,
});

export default connect(mapStateToProps, { push })(injectIntl(SearchableTable));
