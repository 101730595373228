import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, Input, Button, Row, Col } from 'antd';
import { ErrorInfo } from '../../../types/form-types';
import { register, UserState } from '../../../redux/user/user-duck';
import styles from './register.module.scss';
import { onFinishFailedHandler } from '../../../helpers/form';

interface Props {}
const Register: FC<Props & PropsFromRedux> = ({ user, register }) => {
  const intl = useIntl();
  const onFinish = (values: any) => {
    const successNotification = {
      message: intl.formatMessage({ id: 'register.success-message' }),
      description: intl.formatMessage({ id: 'register.success-description' }),
    };
    const { email, password } = values;
    register({
      email,
      password,
      successNotification,
    });
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'register.failed-message', errorInfo);
  };
  return (
    <div className={styles.container}>
      <Helmet title={intl.formatMessage({ id: 'register.helmet-title' })} />
      <Row>
        <Col xs={0} sm={0} md={12} lg={12}>
          <Row style={{ justifyContent: 'center' }}>
            <img
              style={{ marginTop: '350px' }}
              src="resources/images/logo-inverse.svg"
              alt="noburo logo"
            />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.form}>
            <h4 className="text-uppercase">
              <strong>
                <FormattedMessage id="register.please-register" />
              </strong>
            </h4>
            <br />
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={<FormattedMessage id="email" />}
                name="email"
                rules={[{ type: 'email' }, { required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id="password" />}
                name="password"
                hasFeedback
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id="register.confirm-password" />}
                name="confirmPassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        intl.formatMessage({ id: 'validatemsg.confirmpass-mismatch' }),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="form-actions">
                <Form.Item>
                  <Button
                    type="primary"
                    className="width-150 mr-4"
                    htmlType="submit"
                    loading={user.loading}
                    disabled={user.loading}
                  >
                    <FormattedMessage id="register.register" />
                  </Button>
                  <span className="ml-3">
                    <Link to="/users/login" className="utils__link--blue utils__link--underlined">
                      <FormattedMessage id="register.login" />
                    </Link>{' '}
                    <FormattedMessage id="register.if-you-already-have-account" />
                  </span>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = ({ user }: { user: UserState }) => ({ user });
const connector = connect(mapStateToProps, { register });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Register);
