import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import jwt from 'jsonwebtoken';
import store from 'store';
import * as userService from '../../services/user';
import {
  LOGIN,
  RENEW_TOKEN,
  LOGOUT,
  logout,
  logoutSuccess,
  logoutFail,
  loginSuccess,
  loginFail,
  renewToken,
  renewTokenSuccess,
  renewTokenFail,
  setCurrentProject,
  SET_CURRENT_PROJECT,
  verifySuccess,
  verifyFail,
  VERIFY,
  REGISTER,
  registerSuccess,
  registerFail,
  ONBOARDING,
  onboardingSuccess,
  onboardingFail,
  ACCEPT_INVITE,
  acceptInviteSuccess,
  acceptInviteFail,
  SCHEDULE_RENEW_TOKEN,
} from './user-duck';
import { showWarning, showError } from '../../helpers/api/api';
import { eventChannel, END } from 'redux-saga';

/**
 * Create channel that will emit renewToken action at interval specified by secs parameter
 * @param {number} secs - Number of token renew interval in second
 */
export function createRenewTokenChannel(secs) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      const authorized = store.get('app.user.authorized');
      if (authorized) {
        emitter(renewToken());
      } else {
        emitter(END);
      }
    }, secs * 1000);
    return () => {
      clearInterval(iv);
    };
  });
}

export function* scheduleTokenRenew() {
  const channel = yield call(createRenewTokenChannel, process.env.REACT_APP_RENEW_TOKEN_INTERVAL);
  yield takeEvery(channel, function* dispatchRenewToken(action) {
    yield put(action);
  });
}

export function* acceptInviteSaga({ payload }) {
  const { accessToken } = payload;
  try {
    const decoded = jwt.decode(accessToken);
    store.set('app.user.authorized', true);

    // Select the first project with type 'project'
    // to be the default project
    let currentProjectId;
    let currentProjectName;
    const { projects } = decoded;

    if (projects.length > 0) {
      // filterout project with origanisation type
      const withoutOrganisations = projects.filter(project => project.type === 'project');
      currentProjectId = withoutOrganisations[0].id;
      currentProjectName = withoutOrganisations[0].name;
      yield put(
        setCurrentProject({ projectId: currentProjectId, projectName: currentProjectName }),
      );
    }

    yield put(
      acceptInviteSuccess({
        ...decoded,
        authorized: true,
        accessToken,
      }),
    );
  } catch (error) {
    showWarning(error);
    yield put(acceptInviteFail(error));
  }
}

export function* verifySaga({ payload }) {
  const { token, successNotification } = payload;
  try {
    yield call(userService.verify, token);
    notification.success(successNotification);
    yield put(verifySuccess());
    yield put(push('/'));
  } catch (error) {
    showError(error);
    yield put(verifyFail(error));
  }
}

export function* registerSaga({ payload }) {
  const { email, password, successNotification } = payload;
  try {
    yield call(userService.register, email, password);
    yield put(registerSuccess());
    notification.success(successNotification);
    yield put(push('/users/thankyou'));
  } catch (error) {
    showError(error);
    yield put(registerFail(error));
  }
}

export function* loginSaga({ payload }) {
  const { email, password, successNotification, redirect } = payload;
  try {
    const json = yield call(userService.login, email, password);
    notification.success(successNotification);
    const { accessToken } = json.data;
    const decoded = jwt.decode(accessToken);
    store.set('app.user.authorized', true);
    // Select the first project with type 'project'
    // to be the default project
    let currentProjectId;
    let currentProjectName;
    const { projects } = decoded;

    if (projects.length > 0) {
      // filterout project with origanisation type
      const withoutOrganisations = projects.filter(project => project.type === 'project');
      currentProjectId = withoutOrganisations[0].id;
      currentProjectName = withoutOrganisations[0].name;
      yield put(
        setCurrentProject({ projectId: currentProjectId, projectName: currentProjectName }),
      );
    }
    yield put(
      loginSuccess({
        ...decoded,
        accessToken,
        authorized: true,
      }),
    );
    yield put(push(redirect));
    yield call(scheduleTokenRenew);
  } catch (error) {
    showWarning(error);
    yield put(loginFail(error));
  }
}

export function* renewTokenSaga() {
  try {
    const json = yield call(userService.renewToken);
    const decoded = jwt.decode(json.data.accessToken);
    yield put(
      renewTokenSuccess({
        ...decoded,
        accessToken: json.data.accessToken,
        authorized: true,
        loading: false,
      }),
    );
  } catch (error) {
    showError(error);
    yield put(renewTokenFail(error));
    yield put(logout());
  }
}

export function* logoutSaga() {
  try {
    store.set('app.user.authorized', false);
    store.set('app.user.currentProjectId', '');
    store.set('app.user.currentProjectName', '');
    yield call(userService.logout);
    yield put(logoutSuccess());
  } catch (error) {
    showError(error);
    yield put(logoutFail(error));
  }
}

export function* saveCurrentProjectSaga({ payload }) {
  store.set('app.user.currentProjectId', payload.projectId);
  store.set('app.user.currentProjectName', payload.projectName);
  yield;
}

export function* onboardingSaga({ payload }) {
  const { otp, ref, successNotification } = payload;
  try {
    const json = yield call(userService.otpVerify, otp, ref);
    notification.success(successNotification);

    const { accessToken } = json.data;
    const decoded = jwt.decode(accessToken);
    // Select the first project with type 'project'
    // to be the default project
    let currentProjectId;
    let currentProjectName;
    const { projects } = decoded;
    if (projects.length > 0) {
      // filterout project with origanisation type
      const withoutOrganisations = projects.filter(project => project.type === 'project');
      currentProjectId = withoutOrganisations[0].id;
      currentProjectName = withoutOrganisations[0].name;
      yield put(
        setCurrentProject({ projectId: currentProjectId, projectName: currentProjectName }),
      );
    }
    yield put(
      onboardingSuccess({
        ...decoded,
        accessToken,
      }),
    );
  } catch (error) {
    showWarning(error);
    yield put(onboardingFail(error));
  }
}

export function* setup() {
  const authorized = store.get('app.user.authorized');
  const currentProjectId = store.get('app.user.currentProjectId');
  const currentProjectName = store.get('app.user.currentProjectName');
  if (authorized) {
    yield put(setCurrentProject({ projectId: currentProjectId, projectName: currentProjectName }));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(LOGIN, loginSaga),
    takeEvery(VERIFY, verifySaga),
    takeEvery(REGISTER, registerSaga),
    takeEvery(RENEW_TOKEN, renewTokenSaga),
    takeEvery(LOGOUT, logoutSaga),
    takeEvery(SET_CURRENT_PROJECT, saveCurrentProjectSaga),
    takeEvery(ONBOARDING, onboardingSaga),
    takeEvery(ACCEPT_INVITE, acceptInviteSaga),
    take(SCHEDULE_RENEW_TOKEN, scheduleTokenRenew),
    setup(), // run once on app load to schedule token renew
  ]);
}
