import React, { FC, Fragment, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Button, notification } from 'antd';
import renderIfHasPermissions from '../../../../../layouts/renderIfHasPermissisons';
import { Application } from '../../../../../types/application-types';
import { applicationStatusToStage } from '../../../../../helpers/application';
import AmortizeTableButton from '../../../Loans/ActionButtonPanel/AmortizeTableButton';
import ReprocessingModal from './ReprocessingModal';
import RejectModal from './RejectModal';
import SubmitModal from './SubmitModal';

interface Props {
  projectId: string;
  application: Application;
}

const tabFromStatus = (status: string) => {
  switch (status) {
    case 'keying_data':
    case 'reprocessing':
      return 'keying_data,reprocessing';
    case 'verify':
    case 'wait_verify':
      return 'verifying,wait_verify';
    case 'analyzing':
    case 'wait_guarantor':
      return 'analyzing,wait_guarantor';
    case 'confirming':
      return 'confirming';
    case 'wait_confirm':
      return 'wait_confirm';
    case 'approving':
    case 'loan_opened':
    case 'reject':
      return status;
    default:
      return '*';
  }
};
const renderReprocessingButton = (status?: string) => {
  if (typeof status === 'undefined') return false;
  // Render reprocessing button if application status is not in these statuses
  const notRenderStatuses = ['keying_data', 'reprocessing', 'loan_opened'];
  if (!notRenderStatuses.includes(status)) {
    return true;
  }
  return false;
};
const renderRejectButton = (status?: string) => {
  if (typeof status === 'undefined') return false;
  // Render reprocessing button if application status is not in these statuses
  const notRenderStatuses = ['loan_opened', 'reject'];
  if (!notRenderStatuses.includes(status)) {
    return true;
  }
  return false;
};
const renderSubmitButton = (status?: string) => {
  if (typeof status === 'undefined') return false;
  // Render reprocessing button if application status is not in these statuses
  const notRenderStatuses = ['loan_opened', 'wait_confirm', 'reject'];
  if (!notRenderStatuses.includes(status)) {
    return true;
  }
  return false;
};

const ActionButtonPanel: FC<Props & PropsFromRedux> = ({ projectId, application, push }) => {
  const intl = useIntl();
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [submitModalVisible, setSubmitModalVisible] = useState<boolean>(false);
  const [reprocessingModalVisible, setReprocessingModalVisible] = useState<boolean>(false);

  const { paymentType, status } = application;
  const stage = applicationStatusToStage(status);
  const ReprocessingButton = renderIfHasPermissions(
    [`applications.${stage}.update`],
    <Button
      style={{ marginLeft: 8 }}
      type="primary"
      ghost
      onClick={() => setReprocessingModalVisible(true)}
    >
      <FormattedMessage id="reprocessing" />
    </Button>,
  );
  const RejectButton = renderIfHasPermissions(
    [`applications.${stage}.update`],
    <Button style={{ marginLeft: 8 }} danger ghost onClick={() => setRejectModalVisible(true)}>
      <FormattedMessage id="reject" />
    </Button>,
  );
  const SubmitButton = renderIfHasPermissions(
    [`applications.${stage}.update`],
    <Button style={{ marginLeft: 8 }} type="primary" onClick={() => setSubmitModalVisible(true)}>
      <FormattedMessage id="changestatus" />
    </Button>,
  );

  return (
    <Fragment>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <div style={{ float: 'left' }}>
            {typeof paymentType !== 'undefined' && paymentType !== 'revolving' && (
              <AmortizeTableButton
                projectId={projectId}
                loanId={application.id}
                type="application"
              />
            )}
          </div>
          <div style={{ float: 'right' }}>
            {renderReprocessingButton(status) && <ReprocessingButton />}
            {renderRejectButton(status) && <RejectButton />}
            {renderSubmitButton(status) && <SubmitButton />}
          </div>
        </Col>
      </Row>
      <ReprocessingModal
        projectId={projectId}
        application={application}
        visible={reprocessingModalVisible}
        onCancel={() => {
          setReprocessingModalVisible(false);
        }}
        onUpdateComplete={() => {
          notification.success({
            message: intl.formatMessage({ id: 'application-edit.update-status-success-message' }),
          });
          setReprocessingModalVisible(false);
          push(`/applications?status=${tabFromStatus(application.status)}`);
        }}
      />
      <RejectModal
        projectId={projectId}
        application={application}
        visible={rejectModalVisible}
        onCancel={() => {
          setRejectModalVisible(false);
        }}
        onUpdateComplete={() => {
          notification.success({
            message: intl.formatMessage({ id: 'application-edit.update-status-success-message' }),
          });
          setRejectModalVisible(false);
          push(`/applications?status=${tabFromStatus(application.status)}`);
        }}
      />
      <SubmitModal
        projectId={projectId}
        application={application}
        visible={submitModalVisible}
        onCancel={() => {
          setSubmitModalVisible(false);
        }}
        onUpdateComplete={() => {
          notification.success({
            message: intl.formatMessage({ id: 'application-edit.update-status-success-message' }),
          });
          setSubmitModalVisible(false);
          push(`/applications?status=${tabFromStatus(application.status)}`);
        }}
      />
    </Fragment>
  );
};

const connector = connect(null, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActionButtonPanel);
