import React from 'react';
import { useParams } from 'react-router-dom';
import SmsNotifyCashForm from '../../components/CbsComponents/Sms/SmsNotifyCashForm';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';

const SmsNotifyCash: React.FC = () => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: 'notify-cash.page-title',
      href: '/sms/notify/cash',
    },
  ];

  return (
    <ContentContainer
      helmetTitle="notify-cash.page-title"
      helmetTitleParams={{ id }}
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsNotifyCashForm />
    </ContentContainer>
  );
};

export default SmsNotifyCash;
