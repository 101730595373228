import React, { FC, useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Input, notification, Form, Button, Table } from 'antd';
import { Store } from 'antd/lib/form/interface';
import qs from 'query-string';
import { TablePaginationConfig } from 'antd/es/table';
import { showError, API_URL, getJson } from '../../../helpers/api/api';
import { UserState } from '../../../redux/user/user-duck';
import { ErrorInfo } from '../../../types/form-types';
import { onFinishFailedHandler } from '../../../helpers/form';
import { iso8601ToLocalDate } from '../../../helpers/date/date';
import DateOnlyPicker from '../common/DateOnlyPicker';

const SmsHistoryForm: FC<PropsFromRedux> = ({ projectId, pageSize }) => {
  const intl = useIntl();
  const [sms, setSms] = useState<any>();
  const [paginations, setPaginations] = useState<any>();
  const [condition, setCondition] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getJson(
          `${API_URL}/projects/${projectId}/sms?limit=${pageSize}&offset=0`,
        );
        const { limit, total } = result.meta;
        const mapPaginations = { current: 1, pageSize: limit, total };
        setPaginations(mapPaginations);
        setSms(result.data);
      } catch (error) {
        showError(error);
      }
    };
    fetchData();
  }, [projectId]);

  const onFinish = async (value: Store) => {
    try {
      const { mobileNo, startDate, endDate } = value;
      const condition = {
        mobileNo,
        startDate: startDate && startDate !== 'Invalid date' ? startDate : undefined,
        endDate: endDate && endDate !== 'Invalid date' ? endDate : undefined,
      };
      setCondition(condition);
      const fetchSms = await getJson(
        `${API_URL}/projects/${projectId}/sms?${qs.stringify(
          condition,
        )}&limit=${pageSize}&offset=0`,
      );
      const { offset, limit, total } = fetchSms.meta;
      const mapPaginations = { current: offset + 1, pageSize: limit, total };
      setPaginations(mapPaginations);
      setSms(fetchSms.data);
      notification.success({
        message: intl.formatMessage({ id: 'sms.success-search' }),
      });
    } catch (error) {
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const changePage = async (pagination: TablePaginationConfig) => {
    const { current } = pagination;
    const result = await getJson(
      `${API_URL}/projects/${projectId}/sms?${qs.stringify(
        condition,
      )}&limit=${pageSize}&offset=${(Number(current) - 1) * 10}`,
    );
    const { limit, total } = result.meta;
    const mapPaginations = { current, pageSize: limit, total };
    setPaginations(mapPaginations);
    setSms(result.data);
  };

  const columns: any = [
    {
      title: <FormattedMessage id="sms.projectId" />,
      dataIndex: 'projectId',
      width: '7%',
    },
    {
      title: <FormattedMessage id="sms.mobileNo" />,
      dataIndex: 'mobileNo',
      width: '10%',
    },
    {
      title: <FormattedMessage id="sms.messageId" />,
      dataIndex: 'messageId',
      width: '10%',
    },
    {
      title: <FormattedMessage id="sms.message" />,
      dataIndex: 'message',
    },
    {
      title: <FormattedMessage id="sms.status" />,
      dataIndex: 'status',
      width: '10%',
    },
    {
      title: <FormattedMessage id="createDate" />,
      render: (record: any) => {
        return iso8601ToLocalDate(`${record.createDate}`);
      },
      width: '10%',
    },
    {
      title: <FormattedMessage id="sms.createBy" />,
      dataIndex: 'createBy',
      width: '10%',
    },
  ];

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="utils__title">
            <strong>
              <FormattedMessage id="sms.helmet-title" />
            </strong>
          </div>
        </div>
        <div className="card-body">
          <Form form={form} layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label={<FormattedMessage id="sms.filter.mobileNo" />}
              name="mobileNo"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="sms.filter.startDate" />}
              name="startDate"
              rules={[{ required: false }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="sms.filter.endDate" />}
              name="endDate"
              rules={[{ required: false }]}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="search" />
              </Button>
            </Form.Item>
          </Form>
          <br />
          <Table
            dataSource={sms}
            columns={columns}
            onChange={changePage}
            pagination={paginations}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, settings }: { user: UserState; settings: any }) => {
  return {
    projectId: user.currentProjectId,
    pageSize: parseInt(settings.pageSize, 10),
  };
};
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SmsHistoryForm);
