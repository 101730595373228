// import store from 'store';

// Action types
export const SET_IMPORT_PAYMENT = 'import/SET_IMPORT_PAYMENT';
const SET_IMPORT_PAYMENT_SUCCESS = 'import/SET_IMPORT_PAYMENT_SUCCESS';
const SET_IMPORT_PAYMENT_FAIL = 'import/SET_IMPORT_PAYMENT_FAIL';

export const SET_IMPORT_APPLICATIONS = 'import/SET_IMPORT_APPLICATIONS';
const SET_IMPORT_APPLICATIONS_SUCCESS = 'import/SET_IMPORT_APPLICATIONS_SUCCESS';
const SET_IMPORT_APPLICATIONS_FAIL = 'import/SET_IMPORT_APPLICATIONS_FAIL';

export const SET_IMPORT_CREDITREPORTS = 'import/SET_IMPORT_CREDITREPORTS';
const SET_IMPORT_CREDITREPORTS_SUCCESS = 'import/SET_IMPORT_CREDITREPORTS_SUCCESS';
const SET_IMPORT_CREDITREPORTS_FAIL = 'import/SET_IMPORT_CREDITREPORTS_FAIL';

export const SET_IMPORT_DISBURSEMENTS = 'import/SET_IMPORT_DISBURSEMENTS';
const SET_IMPORT_DISBURSEMENTS_SUCCESS = 'import/SET_IMPORT_DISBURSEMENTS_SUCCESS';
const SET_IMPORT_DISBURSEMENTS_FAIL = 'import/SET_IMPORT_DISBURSEMENTS_FAIL';

export const SET_IMPORT_COLLECTOR_LOANS = 'import/SET_IMPORT_COLLECTOR_LOANS';
const SET_IMPORT_COLLECTOR_LOANS_SUCCESS = 'import/SET_IMPORT_COLLECTOR_LOANS_SUCCESS';
const SET_IMPORT_COLLECTOR_LOANS_FAIL = 'import/SET_IMPORT_COLLECTOR_LOANS_FAIL';

export const SET_IMPORT_WRITEOFFS = 'import/SET_IMPORT_WRITEOFFS';
const SET_IMPORT_WRITEOFFS_SUCCESS = 'import/SET_IMPORT_WRITEOFFS_SUCCESS';
const SET_IMPORT_WRITEOFFS_FAIL = 'import/SET_IMPORT_WRITEOFFS_FAIL';
// Action creators
/**
 * Action creator for menu/SET_STATE
 * eg. if you want to set state.menuLeft to ['menu1', 'menu2']
 *  and state.menuRight to ['menu3', 'menu4']
 * call setState({ menuLeft: ['menu1', 'menu2'], menuRight: ['menu3', 'menu4']})
 * @param {object} payload - An object contains key/value that will be patched to current state
 */
const initialState = {};
export function setImportPayment(payload) {
  return {
    type: SET_IMPORT_PAYMENT,
    payload,
  };
}

export function setImportPaymentSuccess(payment) {
  return {
    type: SET_IMPORT_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function setImportPaymentFail({ code, message }) {
  return {
    type: SET_IMPORT_PAYMENT_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function setImportApplications(payload) {
  return {
    type: SET_IMPORT_APPLICATIONS,
    payload,
  };
}

export function setImportApplicationsSuccess(applications) {
  return {
    type: SET_IMPORT_APPLICATIONS_SUCCESS,
    payload: applications,
  };
}

export function setImportApplicationsFail({ code, message }) {
  return {
    type: SET_IMPORT_APPLICATIONS_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function setImportCreditreports(payload) {
  return {
    type: SET_IMPORT_CREDITREPORTS,
    payload,
  };
}

export function setImportCreditreportsSuccess(creditreports) {
  return {
    type: SET_IMPORT_CREDITREPORTS_SUCCESS,
    payload: creditreports,
  };
}

export function setImportCreditreportsFail({ code, message }) {
  return {
    type: SET_IMPORT_CREDITREPORTS_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function setImportDisbursements(payload) {
  return {
    type: SET_IMPORT_DISBURSEMENTS,
    payload,
  };
}

export function setImportDisbursementsSuccess(disbursement) {
  return {
    type: SET_IMPORT_DISBURSEMENTS_SUCCESS,
    payload: disbursement,
  };
}

export function setImportDisbursementsFail({ code, message }) {
  return {
    type: SET_IMPORT_DISBURSEMENTS_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function setImportCollectorLoans(payload) {
  return {
    type: SET_IMPORT_COLLECTOR_LOANS,
    payload,
  };
}

export function setImportCollectorLoansSuccess(collectorLoans) {
  return {
    type: SET_IMPORT_COLLECTOR_LOANS_SUCCESS,
    payload: collectorLoans,
  };
}

export function setImportCollectorLoansFail({ code, message }) {
  return {
    type: SET_IMPORT_COLLECTOR_LOANS_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export function setImportWriteOffs(payload) {
  return {
    type: SET_IMPORT_WRITEOFFS,
    payload,
  };
}

export function setImportWriteOffsSuccess(writeoffs) {
  return {
    type: SET_IMPORT_WRITEOFFS_SUCCESS,
    payload: writeoffs,
  };
}

export function setImportWriteOffsFail({ code, message }) {
  return {
    type: SET_IMPORT_WRITEOFFS_FAIL,
    payload: {
      code,
      message,
    },
  };
}

export default function importReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_IMPORT_PAYMENT:
      return { ...state, ...action.payload };
    case SET_IMPORT_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_IMPORT_APPLICATIONS:
      return { ...state, ...action.payload };
    case SET_IMPORT_APPLICATIONS_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_APPLICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_IMPORT_CREDITREPORTS:
      return { ...state, ...action.payload };
    case SET_IMPORT_CREDITREPORTS_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_CREDITREPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_IMPORT_DISBURSEMENTS:
      return { ...state, ...action.payload };
    case SET_IMPORT_DISBURSEMENTS_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_DISBURSEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_IMPORT_COLLECTOR_LOANS:
      return { ...state, ...action.payload };
    case SET_IMPORT_COLLECTOR_LOANS_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_COLLECTOR_LOANS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_IMPORT_WRITEOFFS:
      return { ...state, ...action.payload };
    case SET_IMPORT_WRITEOFFS_SUCCESS:
      return {
        loading: false,
        error: {},
        ...payload,
      };
    case SET_IMPORT_WRITEOFFS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
