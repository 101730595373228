import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { setCurrentProject } from '../../../../redux/user/user-duck';
import styles from './project-selector.module.scss';

const spliter = '#-#';

class ProjectSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultProjectName: '',
    };
  }

  componentDidMount() {
    const { projects, currentProjectId } = this.props;
    const defaultProject = projects.filter(project => project.id === currentProjectId);
    if (defaultProject.length !== 0) {
      this.setState({
        defaultProjectName: `${defaultProject[0].name}-${defaultProject[0].id}`,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { projects, currentProjectId } = this.props;
    if (nextProps.projects !== projects || nextProps.currentProjectId !== currentProjectId) {
      const { projects, currentProjectId } = nextProps;
      const defaultProject = projects.filter(project => project.id === currentProjectId);
      if (defaultProject.length !== 0) {
        this.setState({
          defaultProjectName: `${defaultProject[0].name}-${defaultProject[0].id}`,
        });
      }
    }
  }

  render() {
    const { Option } = Select;
    const { projects, setCurrentProject } = this.props;
    const { defaultProjectName } = this.state;
    return (
      <div>
        <Select
          style={{ width: '220px' }}
          value={defaultProjectName}
          className={styles.select}
          onChange={value => {
            const project = value.split(spliter);
            const projectName = project[0];
            const projectId = project[1];
            setCurrentProject({ projectId, projectName });
          }}
        >
          {projects.map(project => (
            <Option
              style={{ width: '220px' }}
              key={project.id}
              value={`${project.name}${spliter}${project.id}`}
            >
              <b>{`${project.companyName}`}</b>
              <br />
              {project.name}-{project.id}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: state.user.projects,
    currentProjectId: state.user.currentProjectId,
    currentProjectName: state.user.currentProjectName,
    organisationId: state.user.organisationId,
    organisationName: state.user.organisationName,
  };
}
export default connect(mapStateToProps, { setCurrentProject })(ProjectSelector);
