import React, { FC, Fragment, useState } from 'react';
import { Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import WithdrawModal from './WithdrawModal';

interface Props {
  projectId: string;
  loan: any;
  reloadData: () => void;
}

const WithdrawButton: FC<Props> = ({ projectId, loan, reloadData }) => {
  const [withdrawModalVisible, setWithdrawModalVisible] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        onClick={() => setWithdrawModalVisible(true)}
      >
        <FormattedMessage id="withdraw" />
      </Button>

      <WithdrawModal
        visible={withdrawModalVisible}
        onCancel={() => setWithdrawModalVisible(false)}
        okText={intl.formatMessage({ id: 'withdraw' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        reloadData={reloadData}
        projectId={projectId}
        loan={loan}
      />
    </Fragment>
  );
};

export default WithdrawButton;
