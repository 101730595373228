import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import SmsWaitConfirmForm from '../../components/CbsComponents/Sms/SmsWaitConfirmForm';

const SmsWaitConfirm: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'sms-application-waitconfirm.page-title',
      href: `/sms/application/waitconfirm`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="sms-application-waitconfirm.helmet-title"
      pageTitle="sms.page-title"
      breadcrumbs={breadcrumbs}
    >
      <SmsWaitConfirmForm />
    </ContentContainer>
  );
};

export default SmsWaitConfirm;
