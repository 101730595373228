import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Select, Row, Col, Radio } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useAddressAutoComplete } from '../../../../../helpers/form/fetch-hook';
import styles from '../../../Application/application.module.scss';
import componentValues from '../../../../../services/utilities/ComponentValue';
import { getValidator } from '../../../../../helpers/validator';

interface Props {
  form: FormInstance;
}
const CompanyAddress: React.FC<Props> = ({ form }) => {
  const { setFieldsValue } = form;
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();
  const intl = useIntl();

  return (
    <Fragment>
      <div id="currentAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="addressInfo.company" />
        </strong>

        {/* WORK ADDRESS */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.name" />}
              name={['addressInfo', 'company', 'name']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.isSubcontract" />}
              name={['addressInfo', 'company', 'isSubcontract']}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="yes">
                  <FormattedMessage id="yes" />
                </Radio.Button>
                <Radio.Button value="no">
                  <FormattedMessage id="no" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.subcontractPhoneNo" />}
              name={['addressInfo', 'company', 'subcontractPhoneNo']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* ADDRESS */}
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'company', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'company', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'company', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'company', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'company', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* PHONE */}
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.hrPhoneNo" />}
              name={['addressInfo', 'company', 'hrPhoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.hrPhoneNoExt" />}
              name={['addressInfo', 'company', 'hrPhoneNoExt']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.deptPhoneNo" />}
              name={['addressInfo', 'company', 'deptPhoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.deptPhoneNoExt" />}
              name={['addressInfo', 'company', 'deptPhoneNoExt']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CompanyAddress;
