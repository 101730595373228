import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { patchJson, API_URL, showError } from '../../../../../helpers/api/api';
import { Application } from '../../../../../types/application-types';

interface Props {
  visible: boolean;
  projectId: string;
  application: Application;
  onCancel: () => void;
  onUpdateComplete: () => void;
}
const ReprocessingModal: FC<Props> = ({
  visible,
  projectId,
  application,
  onCancel,
  onUpdateComplete,
}) => {
  const handleReprocessing = async () => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}/status`, {
        status: 'reprocessing',
      });
      onUpdateComplete();
    } catch (error) {
      showError(error);
    }
  };
  return (
    <Modal visible={visible} onCancel={onCancel} onOk={handleReprocessing}>
      <FormattedMessage id="application-edit.send-for-reprocessing" />
    </Modal>
  );
};

export default ReprocessingModal;
