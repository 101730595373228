import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd/lib/form';
import { Form, Input, Row, Col, Select, AutoComplete } from 'antd';
import DateOnlyPicker from '../../../../../common/DateOnlyPicker';
import InputBaht from '../../../../../common/InputBaht';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import { getValidator } from '../../../../../../../helpers/validator';
import { useAddressAutoComplete } from '../../../../../../../helpers/form/fetch-hook';

interface Props {
  form: FormInstance;
}

const occupationTypeOptions = [
  { value: 'อาจารย์' },
  { value: 'ตำรวจ/ทหาร' },
  { value: 'แพทย์' },
  { value: 'พยาบาล' },
  { value: 'ธุรการ' },
  { value: 'พนง.การผลิต' },
  { value: 'พนง.บุคคล' },
  { value: 'พนง.บัญชี/การเงิน' },
  { value: 'พนง.ขาย' },
  { value: 'รักษาความปลอดภัย' },
  { value: 'แม่บ้าน' },
];

const NonoFinanceFields: React.FC<Props> = ({ form }) => {
  const intl = useIntl();
  const { setFieldsValue } = form;
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();
  function caseInsensitiveMatch(inputValue: any, option: any) {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  return (
    <Fragment>
      <div id="companyAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="employmentInfo.nano.title" />
        </strong>

        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 32 }} sm={{ span: 16 }} md={{ span: 8 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.companyName" />}
              name={['employmentInfo', 'nano', 'companyName']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 32 }} sm={{ span: 16 }} md={{ span: 8 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.businessType" />}
              name={['employmentInfo', 'nano', 'businessType']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 32 }} sm={{ span: 16 }} md={{ span: 8 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.position" />}
              name={['employmentInfo', 'nano', 'position']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.hrPhoneNo" />}
              name={['employmentInfo', 'nano', 'hrPhoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.hrPhoneNoExt" />}
              name={['employmentInfo', 'nano', 'hrPhoneNoExt']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.startWorkDate" />}
              name={['employmentInfo', 'nano', 'startWorkDate']}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.totalIncomeNano" />}
              name={['employmentInfo', 'nano', 'totalIncomeNano']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>

        <strong className={styles.title}>
          <FormattedMessage id="employmentInfo.nano.ExpensesPerMonth" />
        </strong>

        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.materialExpense" />}
              name={['employmentInfo', 'nano', 'materialExpense']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.wagesExpense" />}
              name={['employmentInfo', 'nano', 'wagesExpense']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.rentalExpense" />}
              name={['employmentInfo', 'nano', 'rentalExpense']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.waterExpense" />}
              name={['employmentInfo', 'nano', 'waterExpense']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.electricityExpense" />}
              name={['employmentInfo', 'nano', 'electricityExpense']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.otherNanoExpense" />}
              name={['employmentInfo', 'nano', 'otherNanoExpense']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.oneMonth" />}
              name={['employmentInfo', 'nano', 'oneMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.twoMonth" />}
              name={['employmentInfo', 'nano', 'twoMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.threeMonth" />}
              name={['employmentInfo', 'nano', 'threeMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.fourMonth" />}
              name={['employmentInfo', 'nano', 'fourMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.fiveMonth" />}
              name={['employmentInfo', 'nano', 'fiveMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.sixMonth" />}
              name={['employmentInfo', 'nano', 'sixMonth']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.profit" />}
              name={['employmentInfo', 'nano', 'profit']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.extraIncome" />}
              name={['employmentInfo', 'nano', 'extraIncome']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 8 }} md={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.nano.extraIncomeSource" />}
              name={['employmentInfo', 'nano', 'extraIncomeSource']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* ADDRESS */}
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['employmentInfo', 'nano', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['employmentInfo', 'nano', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['employmentInfo', 'nano'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['employmentInfo', 'nano', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['employmentInfo', 'nano'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['employmentInfo', 'nano', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['employmentInfo', 'nano'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['employmentInfo', 'nano', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['employmentInfo', 'nano'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NonoFinanceFields;
