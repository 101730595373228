import React, { FC, useState } from 'react';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal, Row, Col, Descriptions, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../common/DateOnlyPicker';
import InputBaht from '../../common/InputBaht';
import { datediff } from '../../../../helpers/date/date';
import { API_URL, postJson, showError } from '../../../../helpers/api/api';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  lastTransaction: any;
  loan: any;
}

const OutStandingBalance: FC<any> = ({ osb }) => {
  const intl = useIntl();
  const { asOfDate, principal, interest, fee, total } = osb;
  return (
    <Descriptions column={4} title={intl.formatMessage({ id: 'osbAt' }, { asOfDate })}>
      <Descriptions.Item label={<FormattedMessage id="principal" />}>{principal}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="interest" />}>{interest}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="fee" />}>{fee}</Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="osb" />}>{total}</Descriptions.Item>
    </Descriptions>
  );
};

const WriteoffModal: FC<Props> = ({
  projectId,
  visible,
  onCancel,
  cancelText,
  okText,
  loan,
  lastTransaction,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const initialOsb = { principal: 0, interest: 0, fee: 0, total: 0 };
  const [osb, setOsb] = useState<any>(initialOsb);
  const [sendPrincipal, setPrincipal] = useState<any>();
  const [sendInterest, setInterest] = useState<any>();
  const [sendFee, setFee] = useState<any>();

  const { setFieldsValue } = form;
  const calculateOsb = (asOfDate: string, intRate: number, lastTransaction: any) => {
    const { cfPrincipal, cfInterest, cfFee } = lastTransaction;
    const principal = cfPrincipal / 100;
    const duration = datediff(asOfDate, lastTransaction.transDate);
    const dueInterest = ((cfPrincipal * intRate) / 365) * duration;
    const interest = (cfInterest + dueInterest) / 100;
    const fee = cfFee / 100;
    setPrincipal((principal * 100).toFixed(0));
    setInterest((interest * 100).toFixed(0));
    setFee((fee * 100).toFixed(0));
    const total = principal + interest + fee;
    return {
      asOfDate,
      total: total.toFixed(2),
      principal: principal.toFixed(2),
      interest: interest.toFixed(2),
      fee: fee.toFixed(2),
    };
  };
  const onEffectiveDateChange = (effectiveDate: any) => {
    const osb = calculateOsb(effectiveDate, loan.intRate, lastTransaction);
    setOsb(osb);
    // Need to convert to satang unit
    setFieldsValue({
      writeoffAmount: Number(osb.total) * 100,
    });
  };
  const onFinish = async (value: Store) => {
    try {
      const values = {
        loanId: loan.id,
        effectiveDate: value.effectiveDate,
        writeoffAmount: Number(value.writeoffAmount),
        cfPrincipal: sendPrincipal,
        cfInterest: sendInterest,
        cfFee: sendFee,
        writeoffDate: value.effectiveDate,
      };
      const type = 'writeoff';
      await postJson(`${API_URL}/projects/${projectId}/request-approvals`, { values, type });
      form.resetFields();
      setOsb(initialOsb);
      notification.success({ message: intl.formatMessage({ id: 'writeoff.success-message' }) });
      onCancel();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };
  function disabledDate(current: any) {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment(lastTransaction.transDate)
          .add(-1, 'day')
          .endOf('day')
    );
  }
  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row>
          <h2>
            <FormattedMessage id="writeOff" />
          </h2>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="effectiveDate" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="effectiveDate"
              rules={[{ required: true }]}
            >
              <DateOnlyPicker
                format="DD-MM-YYYY"
                onChange={onEffectiveDateChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label={<FormattedMessage id="writeoffAmount" />}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 20 }}
              name="writeoffAmount"
              rules={[{ required: true }]}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col span={24}>
            <OutStandingBalance osb={osb} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WriteoffModal;

// const FormItem = Form.Item;

// interface Prop extends FormComponentProps {
//   visible: boolean;
//   onCancel: any;
//   onCreate: any;
//   cancelText: any;
//   okText: any;
//   lastTransaction: any;
//   loan: any;
//   calculateField: any;
//   setCalculateField: any;
// }

// const WriteoffModal = Form.create<Prop>({ name: 'form_in_modal' })(
//   class extends React.Component<Prop> {
//     constructor(props: Prop) {
//       super(props);
//       this.calculateOsb = this.calculateOsb.bind(this);
//       this.calculationInterest = this.calculationInterest.bind(this);
//       this.datediff = this.datediff.bind(this);
//     }

//     datediff = (d1: any, d2: any) => {
//       // Use startOf to compare only date diff ignore time component
//       // Use utcOffset to fix timezone to 7 so we only compare in Bangkok timezone no matter where actual server is located
//       // For instance, duration between 2018-06-05T23:59:59+0000 and 2018-06-06T00:00:00+0000 can be 1 if compared in +0 GMT timezone
//       // However, it will be 0 if compared in +7 GMT timezone because startOf date of both dates will become 2018-06-06
//       const d1date = moment(d1)
//         .utcOffset('+07:00')
//         .startOf('day');
//       const d2date = moment(d2)
//         .utcOffset('+07:00')
//         .startOf('day');
//       return d1date.diff(d2date, 'days');
//     };

//     calculationInterest = (lastTransaction: any, loan: any, asOfDate: string) => {
//       const { cfPrincipal, cfInterest, cfFee, transDate } = lastTransaction;
//       const { intRate } = loan;
//       const newAsOfDate = new Date(asOfDate);
//       const dueInterest = (cfPrincipal * intRate) / 365;
//       const duration = this.datediff(newAsOfDate, transDate);
//       const durationInterest = dueInterest * duration;
//       const newCfInterst = cfInterest + durationInterest;
//       const newOsb = cfPrincipal + newCfInterst + cfFee;
//       return {
//         newOsb: Number(newOsb / 100).toFixed(2),
//         cfPrincipal: Number(cfPrincipal / 100).toFixed(2),
//         newCfInterst: Number(newCfInterst / 100).toFixed(2),
//         cfFee: Number(cfFee / 100).toFixed(2),
//         newWriteOffAmount: newOsb,
//       };
//     };

//     calculateOsb = async (value: any) => {
//       const { form, lastTransaction, loan, setCalculateField } = this.props;
//       const { setFieldsValue } = form;
//       try {
//         const calculateOsb = this.calculationInterest(lastTransaction, loan, value);
//         const { newOsb, cfPrincipal, newCfInterst, cfFee, newWriteOffAmount } = calculateOsb;
//         setCalculateField({
//           principal: cfPrincipal,
//           interest: newCfInterst,
//           fee: cfFee,
//           osb: newOsb,
//         });
//         setFieldsValue({
//           approveAmount: newWriteOffAmount,
//         });
//       } catch (err) {
//         message.warning(`${err}`);
//       }
//       return 'hello';
//     };

//     render() {
//       const { visible, onCancel, onCreate, form, cancelText, okText, calculateField } = this.props;
//       const { getFieldDecorator } = form;
//       const { principal, interest, fee, osb } = calculateField;

//       return (
//         <Modal
//           visible={visible}
//           okText={okText}
//           cancelText={cancelText}
//           width="700px"
//           onCancel={onCancel}
//           cancelButtonProps={{ type: 'primary', ghost: true }}
//           onOk={onCreate}
//         >
//           <Row>
//             <h2>
//               <FormattedMessage id="writeOff" />
//             </h2>
//           </Row>
//           <hr />
//           <Row gutter={24}>
//             <Col span={16}>
//               <FormItem
//                 label={<FormattedMessage id="loan.effectiveDate" />}
//                 labelCol={{ span: 10 }}
//                 wrapperCol={{ span: 20 }}
//               >
//                 {getFieldDecorator('effectiveDate', {
//                   rules: [
//                     {
//                       required: true,
//                       message: 'please select writeoff date',
//                     },
//                   ],
//                 })(
//                   <DateOnlyPicker
//                     format="YYYY-MM-DD"
//                     onChange={value => this.calculateOsb(value)}
//                   />,
//                 )}
//               </FormItem>
//             </Col>
//             <Col span={16}>เงินต้นคงเหลือ: {principal}</Col>
//             <Col span={16}>ดอกเบี้ยคงเหลือ: {interest}</Col>
//             <Col span={16}>ค่าธรรมเนียมคงเหลือ: {fee}</Col>
//             <Col span={16}>ยอดหนี้คงเหลือ: {osb}</Col>
//             <Col span={16}>
//               <FormItem label="ยอดตัดหนี้ศูนย์" labelCol={{ span: 10 }} wrapperCol={{ span: 20 }}>
//                 {getFieldDecorator('approveAmount', {
//                   rules: [
//                     {
//                       required: true,
//                       message: 'please input number of writeoff amount',
//                     },
//                   ],
//                 })(<InputBaht />)}
//               </FormItem>
//             </Col>
//           </Row>
//         </Modal>
//       );
//     }
//   },
// );

// export default WriteoffModal;
