import React, { FC, Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import AdjModal from './AdjModal';

interface Props {
  projectId: string;
  loanId: string;
  reloadData: () => void;
}

const AdjButton: FC<Props> = ({ projectId, loanId, reloadData }) => {
  const intl = useIntl();
  const [adjModalVisible, setAdjModalVisible] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        type="primary"
        style={{
          marginLeft: '8px',
        }}
        onClick={() => setAdjModalVisible(true)}
      >
        <FormattedMessage id="menu.adj" />
      </Button>

      <AdjModal
        projectId={projectId}
        loanId={loanId}
        visible={adjModalVisible}
        onCancel={() => setAdjModalVisible(false)}
        okText={intl.formatMessage({ id: 'submit' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        reloadData={reloadData}
      />
    </Fragment>
  );
};

export default AdjButton;
