import React, { useState, FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Steps } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { UserState, onboarding } from '../../../redux/user/user-duck';
import CompanyInfo from './CompanyInfo';
import AdminInfo from './AdminInfo';
import VerifyOtp from './VerifyOtp';
import { postJson, API_URL, showError } from '../../../helpers/api/api';
import styles from './onboarding.module.scss';

const Onboarding: FC<PropsFromRedux> = ({ user, onboarding }) => {
  const intl = useIntl();
  const [step, setStep] = useState<number>(0);
  const [values, setValues] = useState<any>({});
  const [otpData, setOtpData] = useState<any>({});
  const { Step } = Steps;
  const updateValues = (update: Store) => {
    setValues({
      ...values,
      ...update,
    });
  };
  async function requestOtp(adminInfo: Store): Promise<void> {
    const data = {
      name: values.companyName,
      registerId: values.registerId,
      address: values.address,
      subDistrict: values.subDistrict,
      district: values.district,
      province: values.province,
      postCode: values.postCode,
      contactMail: user.email,
      contactFirstName: adminInfo.firstName,
      contactLastName: adminInfo.lastName,
      contactMobileNo: adminInfo.mobileNo,
    };
    try {
      updateValues({ ...adminInfo, email: user.email });
      const otpData = await postJson(`${API_URL}/users/onboarding/otps`, {
        ...data,
      });
      setOtpData(otpData);
    } catch (error) {
      showError(error);
    }
  }
  async function resendOtp(): Promise<void> {
    const data = {
      name: values.companyName,
      registerId: values.registerId,
      address: values.address,
      subDistrict: values.subDistrict,
      district: values.district,
      province: values.province,
      postCode: values.postCode,
      contactMail: values.email,
      contactFirstName: values.firstName,
      contactLastName: values.lastName,
      contactMobileNo: values.mobileNo,
    };
    try {
      const otpData = await postJson(`${API_URL}/users/onboarding/otps`, {
        ...data,
      });
      setOtpData(otpData);
    } catch (error) {
      showError(error);
    }
  }
  function verifyOtp(otp: string, ref: string): void {
    const successNotification = {
      message: intl.formatMessage({ id: 'onboarding.success-message' }),
      description: intl.formatMessage({ id: 'onboarding.success-description' }),
    };
    onboarding({ otp, ref, successNotification });
  }

  const steps = [
    {
      title: `${intl.formatMessage({ id: 'onboarding.step' })} 1`,
      description: <FormattedMessage id="onboarding.company-information" />,
      content: (
        <CompanyInfo next={() => setStep(step + 1)} values={values} updateValues={updateValues} />
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'onboarding.step' })} 2`,
      description: <FormattedMessage id="onboarding.admin-information" />,
      content: (
        <AdminInfo
          next={() => setStep(step + 1)}
          prev={() => setStep(step - 1)}
          values={values}
          updateValues={updateValues}
          requestOtp={requestOtp}
        />
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'onboarding.step' })} 3`,
      description: <FormattedMessage id="onboarding.verify-otp" />,
      content: (
        <VerifyOtp
          values={values}
          resendOtp={resendOtp}
          otpData={otpData}
          prev={() => setStep(step - 1)}
          verifyOtp={verifyOtp}
        />
      ),
    },
  ];

  return (
    <section className="card">
      <div className="card-body">
        <Steps current={step} className={styles.steps}>
          {steps.map(({ title, description }) => (
            <Step key={title} title={title} description={description} />
          ))}
        </Steps>
        <div className="steps-content">{steps[step].content}</div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ user });
const connector = connect(mapStateToProps, { onboarding });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Onboarding);

export type firstContentType = {
  company: string;
  registerId: string;
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postCode: string;
  [key: string]: any; // Add index signature
};
export type secondContentType = {
  email: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  [key: string]: any; // Add index signature
};
