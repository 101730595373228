import React, { useState, useEffect } from 'react';
import { getJson, API_URL } from '../../../helpers/api/api';
import RoleForm, { Role } from '../../../components/CbsComponents/RoleForm/RoleForm';
import { store } from '../../../redux/store';

const EditRole: React.FC = () => {
  const [data, setData] = useState<Role>();
  const [scopes, setscopes] = useState<string[]>();

  useEffect(() => {
    const fetchData = async () => {
      const projectId = store.getState().user.currentProjectId;
      const roleId = window.location.href.split('/').pop();
      const role = await getJson(`${API_URL}/projects/${projectId}/roles/${roleId}`);
      const scopeValue = await getJson(`${API_URL}/projects/${projectId}/roles/scopes`);
      setData(role.data);
      setscopes(scopeValue.data);
    };
    fetchData();
  }, []);

  if (!data || !scopes) return null;

  return (
    <div>
      <RoleForm roleData={data} scopesData={scopes} />
    </div>
  );
};

export default EditRole;
