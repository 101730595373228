import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Row, Col, DatePicker, notification } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { API_URL, postJson } from '../../helpers/api/api';

const { RangePicker } = DatePicker;
const monthEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const CashInInfo: React.FC<any> = ({ form, projectId }) => {
  const { getFieldDecorator } = form;
  const [stackedBarData, setLabels] = useState<any>({});

  const stackedBarOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        align: 'end',
        anchor: 'end',
        font: { size: '14' },
      },
    },
    legend: {
      position: 'bottom',
    },
    responsive: true,
  };

  const handleSubmit = async (rangeDate: any) => {
    try {
      const transactions = await postJson(`${API_URL}/projects/${projectId}/dashboards/cashIn`, {
        rangeDate,
      });
      const { data } = transactions;
      const { months, cashIns, cashOuts } = data;
      const mapMonthEn = months.map((month: any) => {
        return monthEn[month - 1];
      });
      const labels = mapMonthEn;
      const datasets = [
        {
          label: 'cashIn',
          backgroundColor: '#0190fe',
          data: cashIns,
        },
        {
          label: 'cashOut',
          backgroundColor: '#20c997',
          data: cashOuts,
        },
      ];
      setLabels({ labels, datasets });
    } catch (error) {
      notification.error({
        message: "Cannot get application's for date range",
      });
    }
  };

  useEffect(() => {
    const endDate = moment();
    const startDate = moment(endDate).subtract(3, 'month');
    const rangeDate: any = [startDate, endDate];
    handleSubmit(rangeDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <section className="card">
        <div className="card-body">
          <div className="card" style={{ backgroundColor: 'white' }}>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }}>
                <i style={{ marginLeft: '10%' }} className="fa fa-usd" />{' '}
                <FormattedMessage id="cashIn" />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                style={{ textAlign: 'right' }}
              >
                <Form layout="inline">
                  <Form.Item label={<FormattedMessage id="dashboards.rangetime" />}>
                    {getFieldDecorator('rangeDate')(
                      <RangePicker
                        onCalendarChange={(value: any) => {
                          if (value[1]) handleSubmit(value);
                        }}
                      />,
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <br />
            <div className="col-lg-10">
              <div className="mb-5">
                <Bar data={stackedBarData} options={stackedBarOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// export default CashInInfo;
const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
  };
};

const CashIn = Form.create<any>()(CashInInfo);
export default connect(mapStateToProps, { push })(CashIn);
