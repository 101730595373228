import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user/user-duck';
import menuReducer from './menu/menu-duck';
import settingsReducer from './settings/settings-duck';
import importReducer from './import/import-duck';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu: menuReducer,
    settings: settingsReducer,
    import: importReducer,
  });
