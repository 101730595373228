import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Radio, Input, Row, Col } from 'antd';
import componentValues from '../../../../../../../../../services/utilities/ComponentValue';
import InputBaht from '../../../../../../../common/InputBaht';
import styles from '../../../../../../application.module.scss';
import { getValidator } from '../../../../../../../../../helpers/validator';

interface Prop {
  index: number;
}
const SpouseInfo: React.FC<Prop> = ({ index }) => {
  const intl = useIntl();
  return (
    <div id="spouseInfo" className={styles.header}>
      <strong className={styles.title}>
        <FormattedMessage id="spouseInfo" />
      </strong>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item name={['guarantors', index, 'spouse', 'title']}>
            <Radio.Group>
              <Radio value="นาย">นาย</Radio>
              <Radio value="นาง">นาง</Radio>
              <Radio value="นางสาว">นางสาว</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="firstName" />}
            name={['guarantors', index, 'spouse', 'firstName']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<FormattedMessage id="lastName" />}
            name={['guarantors', index, 'spouse', 'lastName']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="citizenId" />}
            name={['guarantors', index, 'spouse', 'citizenId']}
            rules={[{ validator: getValidator(intl, 'citizenId') }]}
          >
            <Input placeholder="Citizen Id" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="company" />}
            name={['guarantors', index, 'spouse', 'company']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="occupation" />}
            name={['guarantors', index, 'spouse', 'occupation']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="position" />}
            name={['guarantors', index, 'spouse', 'position']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="mobileNo" />}
            name={['guarantors', index, 'spouse', 'mobileNo']}
            rules={[{ validator: getValidator(intl, 'telNo') }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col sm={{ span: componentValues.fullColSpan }} md={{ span: componentValues.halfColSpan }}>
          <Form.Item
            label={<FormattedMessage id="salary" />}
            name={['guarantors', index, 'spouse', 'salary']}
          >
            <InputBaht />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default SpouseInfo;
