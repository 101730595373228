import React, { useState, useEffect, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, Checkbox, notification } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { postJson, patchJson, API_URL } from '../../../helpers/api/api';
import componentValues from '../../../services/utilities/ComponentValue';
import { store } from '../../../redux/store';
import { getAccountType } from '../../../services/utilities/Choice';
import { Bank, getBankName } from '../../../services/utilities/Bank';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';

export interface Sale {
  email: string;
  code: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  bankName: string;
  branch: string;
  accountNo: string;
  accountName: string;
  accountType: string;
  teamLead: string;
}

interface Props extends FormComponentProps {
  id?: any;
  saleData?: any;
  teamLeads?: any;
  push: Push;
}

const { Option } = Select;

const SaleForm: React.FC<Props> = ({ id, saleData, teamLeads, form, push }) => {
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const urlPath = window.location.href;
  const [selectChoices, setSelectChoices] = useState<boolean>(false);
  const [checkBoxChoice, setCheckBoxChoice] = useState<boolean>(false);
  const [teamLeadValue, setTeamLeadValue] = useState<string>('');
  const [validate, setValidate] = useState<any>({ status: 'validating', message: '' });

  useEffect(() => {
    const data = saleData;
    if (data !== undefined) {
      setFieldsValue({
        email: data.email,
        code: data.code,
        firstName: data.firstName,
        lastName: data.lastName,
        mobileNo: data.mobileNo,
        bankName: data.bankName,
        branch: data.branch,
        accountNo: data.accountNo,
        accountName: data.accountName,
        accountType: data.accountType,
      });
      if (data.code === data.teamLead) {
        setCheckBoxChoice(true);
        setSelectChoices(true);
      } else {
        setFieldsValue({ select: data.teamLead });
      }
      setTeamLeadValue(data.teamLead);
    }
  }, [saleData, setFieldsValue]);

  function loadTeamLeadOptions(): JSX.Element[] {
    const teamLeadList = [];
    for (let i: number = 0; i < teamLeads.length; i += 1) {
      if (getFieldValue('code') !== teamLeads[i].code) {
        teamLeadList.push(
          <Option key={teamLeads[i].code} value={teamLeads[i].code}>
            {teamLeads[i].code} - {teamLeads[i].firstName} {teamLeads[i].lastName}
          </Option>,
        );
      }
    }
    return teamLeadList;
  }

  function loadStringOptions(optionKeys: string[]): JSX.Element[] {
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index]} value={optionKeys[index]}>
          <FormattedMessage id={`account.${optionKeys[index]}`} />
        </Option>,
      );
    }
    return options;
  }

  function loadBankOptions(optionKeys: Bank[]): JSX.Element[] {
    const options = [];
    for (let index = 0; index < optionKeys.length; index += 1) {
      options.push(
        <Option key={optionKeys[index].shortName} value={optionKeys[index].shortName}>
          <FormattedMessage id={`bank.${optionKeys[index].shortName}`} />
        </Option>,
      );
    }
    return options;
  }

  function handleSubmit(e: SyntheticEvent): void {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err || teamLeadValue === '') {
        if (teamLeadValue === '') {
          setValidate({
            status: 'error',
            message: <FormattedMessage id="sales.teamLead.inputMessage" />,
          });
        }
        return;
      }
      const sale: Sale = {
        email: values.email,
        code: values.code,
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNo: values.mobileNo,
        bankName: values.bankName,
        branch: values.branch,
        accountNo: values.accountNo,
        accountName: values.accountName,
        accountType: values.accountType,
        teamLead: teamLeadValue,
      };
      try {
        const projectId = store.getState().user.currentProjectId;
        await postJson(`${API_URL}/projects/${projectId}/sales`, sale);
        resetFields();
        notification.success({
          message: 'Added Sale',
          description: 'You have successfully added sale!',
        });
        push('/sales');
      } catch (error) {
        notification.error({
          message: 'Failed to add sale',
          description: error.message,
        });
      }
    });
  }

  function handleSave(): void {
    validateFields(async (err, values) => {
      if (err) {
        if (teamLeadValue === '') {
          setValidate({
            status: 'error',
            message: <FormattedMessage id="sales.teamLead.inputMessage" />,
          });
        }

        return;
      }
      const sale: Sale = {
        email: values.email,
        code: values.code,
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNo: values.mobileNo,
        bankName: values.bankName,
        branch: values.branch,
        accountNo: values.accountNo,
        accountName: values.accountName,
        accountType: values.accountType,
        teamLead: teamLeadValue,
      };
      try {
        const projectId = store.getState().user.currentProjectId;
        await patchJson(`${API_URL}/projects/${projectId}/sales/${id}`, sale);
        resetFields();
        notification.success({
          message: 'Edited Sale',
          description: 'You have successfully edited sale!',
        });
        push('/sales');
      } catch (error) {
        notification.error({
          message: 'Failed to edit sale',
          description: error.message,
        });
      }
    });
  }

  function handleCheckBox(e: any): void {
    const check = e.target.checked;
    setCheckBoxChoice(check);
    setSelectChoices(check);
    if (check) {
      setTeamLeadValue(getFieldValue('code'));
    } else {
      setTeamLeadValue(getFieldValue('select'));
    }
    setValidate({ status: 'success', message: '' });
  }

  function handleSelect(value: string): void {
    setTeamLeadValue(value);
    setValidate({ status: 'success', message: '' });
  }

  const AddButton = renderIfHasPermissisons(
    'sales.*.create',
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit" onClick={handleSubmit}>
      <FormattedMessage id="add" />
    </Button>,
  );
  const SaveButton = renderIfHasPermissisons(
    'sales.*.update',
    <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit" onClick={handleSave}>
      <FormattedMessage id="save" />
    </Button>,
  );

  return (
    <div className="card">
      <div className="card-header">
        <div className="utils__title">
          <strong>
            <FormattedMessage id="form.header.sales" />
          </strong>
        </div>
      </div>
      <div className="card-body">
        <Form layout="vertical">
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="common.email" />}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: <FormattedMessage id="common.email.invalidMessage" />,
                    },
                    {
                      required: true,
                      message: <FormattedMessage id="common.email.inputMessage" />,
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input maxLength={255} />)}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="sales.code" />}>
                {getFieldDecorator('code', {
                  rules: [
                    { required: true, message: <FormattedMessage id="sales.code.inputMessage" /> },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="common.name" />}>
                {getFieldDecorator('firstName', {
                  rules: [
                    { required: true, message: <FormattedMessage id="common.name.inputMessage" /> },
                  ],
                })(<Input maxLength={255} />)}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="common.lastName" />}>
                {getFieldDecorator('lastName', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="common.lastName.inputMessage" />,
                    },
                  ],
                })(<Input maxLength={255} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="common.mobileNo" />}>
                {getFieldDecorator('mobileNo', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="common.mobileNo.inputMessage" />,
                    },
                    {
                      len: 10,
                      message: <FormattedMessage id="common.mobileNo.invalidMessage" />,
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input type="number" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="bank.bankName" />}>
                {getFieldDecorator('bankName')(
                  <Select placeholder={<FormattedMessage id="bank.bankName.placeholder" />}>
                    {loadBankOptions(getBankName())}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="bank.branch" />}>
                {getFieldDecorator('branch')(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="account.accountNo" />}>
                {getFieldDecorator('accountNo', {
                  rules: [
                    {
                      len: 10,
                      message: <FormattedMessage id="common.accountNo.invalidMessage" />,
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input type="number" />)}
              </Form.Item>
            </Col>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="account.accountName" />}>
                {getFieldDecorator('accountName')(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={componentValues.gutterValue}>
            <Col
              sm={{ span: componentValues.fullColSpan }}
              md={{ span: componentValues.halfColSpan }}
            >
              <Form.Item label={<FormattedMessage id="account.accountType" />}>
                {getFieldDecorator('accountType')(
                  <Select placeholder={<FormattedMessage id="account.accountType.placeholder" />}>
                    {loadStringOptions(getAccountType())}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              label={<FormattedMessage id="sales.teamLead" />}
              validateStatus={validate.status}
              help={validate.message}
            >
              {getFieldDecorator(
                'teamLead',
                {},
              )(
                <Row>
                  <Row>
                    <Col>
                      {getFieldDecorator(
                        'owner',
                        {},
                      )(
                        <Checkbox checked={checkBoxChoice} onClick={(e: any) => handleCheckBox(e)}>
                          <FormattedMessage id="sales.teamLead.checkBox" />
                        </Checkbox>,
                      )}
                    </Col>
                  </Row>
                  <Row gutter={50}>
                    <Col
                      sm={{ span: componentValues.fullColSpan }}
                      md={{ span: componentValues.halfColSpan }}
                    >
                      {getFieldDecorator(
                        'select',
                        {},
                      )(
                        <Select
                          style={{ marginTop: '10px' }}
                          placeholder={<FormattedMessage id="sales.teamLead.select.placeholder" />}
                          disabled={selectChoices}
                          onChange={handleSelect}
                        >
                          {loadTeamLeadOptions()}
                        </Select>,
                      )}
                    </Col>
                  </Row>
                </Row>,
              )}
            </Form.Item>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" type="primary" ghost>
                <Link to="/products">
                  <FormattedMessage id="cancel" />
                </Link>
              </Button>
              {urlPath.endsWith('new') || urlPath.endsWith('new/') ? <AddButton /> : <SaveButton />}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const AntdForm = Form.create<Props>()(SaleForm);
export default connect(null, { push })(AntdForm);
