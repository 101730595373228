import React, { Fragment, useState, FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, notification } from 'antd';
import { API_URL, showError } from '../../../../helpers/api/api';
import { store } from '../../../../redux/store';

interface Props {
  projectId: string;
  loanId: string;
}

const ContractButton: FC<Props> = ({ projectId, loanId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();

  const getContractLoan = async () => {
    try {
      setLoading(true);
      const url = `${API_URL}/projects/${projectId}/loans/${loanId}/contract`;
      const { user } = store.getState();
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({ Authorization: `Bearer ${user.accessToken}` }),
      });
      const blob = await response.blob();
      const link = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = link;
      a.target = '_blank';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      setLoading(false);
      notification.success({
        message: intl.formatMessage({ id: 'download-contract.success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        loading={loading}
        disabled={loading}
        onClick={() => getContractLoan()}
      >
        <FormattedMessage id="contract" />
      </Button>
    </Fragment>
  );
};

export default ContractButton;
