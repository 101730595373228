import React, { useState, useEffect, Fragment, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { notification, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import ThaiAddress from 'react-thai-address';
import qs from 'query-string';
import { API_URL, getJson, showError } from '../api/api';
import { Sale } from '../../types/sale-types';
import { Product } from '../../types/product-types';
import { SelectOption, AddressField } from '../../types/form-types';

export function useProductOptions(projectId: string, option: any) {
  const [data, setData] = useState<SelectOption[]>([]);
  const { limit = 10 } = option;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getJson(`${API_URL}/projects/${projectId}/products?limit=${limit}`);
        const options = response.data.map((product: Product) => {
          return {
            label: `${product.name}`,
            value: product.id,
            info: {
              paymentType: product.paymentType,
            },
          };
        });
        setData(options);
      } catch (error) {
        notification.error({
          message: 'Cannot fetch product list',
          description: error.message,
        });
        setData([]);
      }
    };
    fetchProducts();
  }, [projectId]);
  return data;
}
export function useSaleOptions(projectId: string, option?: any) {
  const [data, setData] = useState<SelectOption[]>([]);
  const { limit = 10 } = option;

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await getJson(`${API_URL}/projects/${projectId}/sales?limit=${limit}`);
        const options = response.data.map((sale: Sale) => {
          return {
            label: `${sale.code} - ${sale.firstName} ${sale.lastName}`,
            value: sale.code,
          };
        });
        setData(options);
      } catch (error) {
        notification.error({
          message: 'Cannot fetch sale list',
          description: error.message,
        });
        setData([]);
      }
    };
    fetchSales();
  }, [projectId]);
  return data;
}
export function useAddressAutoComplete(
  spliter: string = ', ',
): [
  // setAddressesFromSearchResult
  (query: string, addressField: AddressField) => void,
  // renderFilteredAddress
  () => ReactNode,
  // setSelecedAddress
  (value: string, setFieldsValue: (value: Store) => void, addressPaths?: string[]) => void,
] {
  interface ThaiAddressAddressField {
    tumbon: string;
    city: string;
    province: string;
    zipcode: string;
  }
  const [addresses, setAddresses] = useState<string[]>([]);
  const search = (query: string, addressField: AddressField) => {
    // map Address field name to ThaiAddress field name
    const mapper: { [key: string]: string } = {
      subDistrict: 'tumbon',
      district: 'city',
      province: 'province',
      postCode: 'zipcode',
    };
    const results: ThaiAddressAddressField[] = ThaiAddress.search(
      { [mapper[addressField]]: query },
      200,
    );

    return results.map(
      ({ tumbon, city, province, zipcode }: ThaiAddressAddressField) =>
        `${tumbon}${spliter}${city}${spliter}${province}${spliter}${zipcode}`,
    );
  };
  const setAddressesFromSearchResult = (query: string, addressField: AddressField) => {
    const filtered = search(query, addressField);
    setAddresses(filtered);
  };
  const renderFilteredAddresses = () => {
    return (
      <Fragment>
        {addresses.map(address => (
          <Select.Option key={address} value={address}>
            {address}
          </Select.Option>
        ))}
      </Fragment>
    );
  };
  const setSetSelectedAddress = (
    value: string,
    setFieldsValue: (value: Store) => void,
    addressPaths?: string[],
  ) => {
    const array = value.split(', ');
    const subDistrict = array[0];
    const district = array[1];
    const province = array[2];
    const postCode = array[3];
    if (typeof addressPaths === 'undefined') {
      setFieldsValue({ subDistrict, district, province, postCode });
    } else {
      let current: any = {};
      const address = current;
      // Convert ['addressInfo', 'current'] => {
      //  addressInfo: {
      // .   current: { subDistrict, district, province, postCode}
      //  }
      // }
      addressPaths.forEach((path, i) => {
        if (i === addressPaths.length - 1) {
          current[path] = { subDistrict, district, province, postCode };
        } else {
          current[path] = {};
        }
        current = current[path];
      });
      setFieldsValue(address);
    }
  };
  return [setAddressesFromSearchResult, renderFilteredAddresses, setSetSelectedAddress];
}
export function useAddressGuarantorAutoComplete(
  spliter: string = ', ',
): [
  // setAddressesFromSearchResult
  (query: string, addressField: AddressField) => void,
  // renderFilteredAddress
  () => ReactNode,
  // setSelecedAddress
  (
    value: string,
    getFieldValue: any,
    setFieldsValue: (value: Store) => void,
    setFields: (fields: any) => void,
    index: number,
    addressPaths?: string[],
  ) => void,
] {
  interface ThaiAddressAddressField {
    tumbon: string;
    city: string;
    province: string;
    zipcode: string;
  }
  const [addresses, setAddresses] = useState<string[]>([]);
  const search = (query: string, addressField: AddressField) => {
    // map Address field name to ThaiAddress field name
    const mapper: { [key: string]: string } = {
      subDistrict: 'tumbon',
      district: 'city',
      province: 'province',
      postCode: 'zipcode',
    };
    const results: ThaiAddressAddressField[] = ThaiAddress.search(
      { [mapper[addressField]]: query },
      10,
    );

    return results.map(
      ({ tumbon, city, province, zipcode }: ThaiAddressAddressField) =>
        `${tumbon}${spliter}${city}${spliter}${province}${spliter}${zipcode}`,
    );
  };
  const setAddressesFromSearchResult = (query: string, addressField: AddressField) => {
    const filtered = search(query, addressField);
    setAddresses(filtered);
  };
  const renderFilteredAddresses = () => {
    return (
      <Fragment>
        {addresses.map(address => (
          <Select.Option key={address} value={address}>
            {address}
          </Select.Option>
        ))}
      </Fragment>
    );
  };
  const setSelectedAddress = (
    value: string,
    getFieldValue: any,
    setFieldsValue: (value: Store) => void,
    setFields: (fields: any) => void,
    index: number,
    addressPaths?: string[],
  ) => {
    const array = value.split(', ');
    const subDistrict = array[0];
    const district = array[1];
    const province = array[2];
    const postCode = array[3];

    if (typeof addressPaths === 'undefined') {
      setFieldsValue({ subDistrict, district, province, postCode });
    } else {
      const guarantors = getFieldValue('guarantors');
      const typeAddress = addressPaths[addressPaths.length - 1];
      const { address } = guarantors[index].addressInfo[typeAddress];
      const addressInfo: any = {};
      addressInfo[typeAddress] = { address, subDistrict, district, province, postCode };
      let addressValue;
      if (addressInfo.current !== undefined) {
        addressValue = addressInfo.current;
        const newAddress = { ...guarantors[index].addressInfo.current, ...addressValue };
        const { address, subDistrict, district, province, postCode } = newAddress;
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'current', 'address'],
            value: address,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'current', 'subDistrict'],
            value: subDistrict,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'current', 'district'],
            value: district,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'current', 'province'],
            value: province,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'current', 'postCode'],
            value: postCode,
          },
        ]);
      } else if (addressInfo.company !== undefined) {
        addressValue = addressInfo.company;
        const newAddress = { ...guarantors[index].addressInfo.company, ...addressValue };
        const { address, subDistrict, district, province, postCode } = newAddress;
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'company', 'address'],
            value: address,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'company', 'subDistrict'],
            value: subDistrict,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'company', 'district'],
            value: district,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'company', 'province'],
            value: province,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'company', 'postCode'],
            value: postCode,
          },
        ]);
      } else {
        addressValue = addressInfo.permanent;
        const newAddress = { ...guarantors[index].addressInfo.permanent, ...addressValue };
        const { address, subDistrict, district, province, postCode } = newAddress;
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'permanent', 'address'],
            value: address,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'permanent', 'subDistrict'],
            value: subDistrict,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'permanent', 'district'],
            value: district,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'permanent', 'province'],
            value: province,
          },
        ]);
        setFields([
          {
            name: ['guarantors', index, 'addressInfo', 'permanent', 'postCode'],
            value: postCode,
          },
        ]);
      }
    }
  };
  return [setAddressesFromSearchResult, renderFilteredAddresses, setSelectedAddress];
}
export function useUrlState(baseUrl: string): [any, (patch: any) => void] {
  const defaultValue = { page: 1, status: '*' };
  const location = useLocation();
  const parsed = location.search ? qs.parse(location.search) : {};
  const [urlState, setUrlState] = useState({ ...defaultValue, ...parsed });
  const history = useHistory();
  const patchUrlState = (patch: any): void => {
    setUrlState({ ...urlState, ...patch });
    history.push(`${baseUrl}?${qs.stringify({ ...urlState, ...patch })}`);
  };
  return [urlState, patchUrlState];
}
export function useDataById(
  fetchUrl: string,
): [{ loading: boolean; data: any; meta?: any }, () => void] {
  const initialState = {
    data: {},
    loading: false,
  };
  const [data, setData] = useState<any>(initialState);
  const [lastFetchTime, setLastFetchTime] = useState<number>(new Date().getTime());
  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({ ...data, loading: true });
        const response = await getJson(`${fetchUrl}`);
        setData({ data: response.data, meta: response.meta, loading: false });
      } catch (error) {
        showError(error);
        setData(initialState);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, lastFetchTime]);

  const reloadData = () => {
    setLastFetchTime(new Date().getTime());
  };
  return [data, reloadData];
}
export function useData(fetchUrl: string, pageSize: number): [any, () => void] {
  const initialState = {
    loading: false,
    data: [],
    paginations: { current: 1, pageSize, total: 0 },
  };
  const [data, setData] = useState(initialState);
  const [lastFetchTime, setLastFetchTime] = useState<number>(Date.now);

  const location = useLocation();
  const queryString = location.search;

  useEffect(() => {
    const fetchData = async (fetchUrl: string, pageSize: number, queryString?: string) => {
      try {
        const option = queryString ? qs.parse(queryString) : { status: '*' };
        setData((data: any) => ({ ...data, loading: true }));
        // eslint-disable-next-line no-underscore-dangle
        const _option = {
          searchterm: option.searchterm,
          status: option.status,
          offset: Number(option.page) ? (Number(option.page) - 1) * pageSize : undefined,
          limit: pageSize,
        };
        const response = await getJson(`${fetchUrl}?${qs.stringify(_option)}`);
        const { total, offset, limit } = response.meta;
        const paginations = {
          current: parseInt(offset, 10) / parseInt(limit, 10) + 1,
          pageSize: parseInt(limit, 10),
          total,
        };
        setData({
          loading: false,
          paginations,
          data: response.data,
        });
      } catch (error) {
        setData(initialState);
        showError(error);
      }
    };
    fetchData(fetchUrl, pageSize, queryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, pageSize, queryString, lastFetchTime]);

  const reloadData = () => setLastFetchTime(Date.now);

  return [data, reloadData];
}
