import React from 'react';
import { Descriptions, Row, Col } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { satangToBath } from '../../../helpers/format/satangToBath';
import ActionButtonPanel from './ActionButtonPanel/ActionButtonPanel';

interface Props {
  projectId: string;
  dataSource: any;
  getDataSource: () => void;
  permissions: any;
}
const LoanSummary: React.FC<Props> = ({ projectId, dataSource, getDataSource, permissions }) => {
  const { loans, transactions } = dataSource;
  const id = loans && loans.id ? loans.id : '-';
  const title = loans && loans.title ? loans.title : '';
  const firstName = loans && loans.firstName ? loans.firstName : '';
  const lastName = loans ? loans.lastName : '-';
  const appId = loans ? loans.appId : '';
  let lastAdjDate = '-';
  let lastDueDate = '-';
  if (transactions) {
    // eslint-disable-next-line no-restricted-syntax
    for (const transaction of transactions) {
      if (transaction.trc === 'ADJ') {
        const lastAdjMoment = moment(transaction.transDate);
        const { statementDate, dueDate } = loans;
        const lastDueMoment = moment(lastAdjMoment);
        // dueDate is next month
        if (dueDate < statementDate) {
          lastDueMoment.set('months', lastAdjMoment.get('months') + 1);
        }
        lastDueMoment.set('date', dueDate);

        lastAdjDate = lastAdjMoment.format('YYYY-MM-DD');
        lastDueDate = lastDueMoment.format('YYYY-MM-DD');
        break;
      }
    }
  }

  const linkApp = (
    <Link className="utils__link--blue utils__link--underlined" to={`/applications/${appId}`}>
      {appId}
    </Link>
  );

  return (
    <div>
      <div style={{ fontWeight: 'bold', fontSize: 'medium' }}>
        Loans ( <FormattedMessage id="application.id" />: {linkApp} )
      </div>
      <br />
      <Descriptions column={{ lg: 3, md: 3, sm: 2, xs: 1 }} size="small" bordered>
        <Descriptions.Item label={<FormattedMessage id="loan.id" />}>{id}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="customId" />}>
          {loans && loans.customId ? loans.customId : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="full-name" />}>
          {`${title} ${firstName} ${lastName}`}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="citizenId" />}>
          {loans && loans.citizenId ? loans.citizenId : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="openDate" />}>
          {loans && loans ? moment(loans.openDate).format('YYYY-MM-DD') : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="creditLimit" />}>
          {loans ? satangToBath(loans.creditLimit) : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="loanType" />}>
          {loans && loans.loanType ? <FormattedMessage id={`loanType.${loans.loanType}`} /> : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="minDue" />}>
          {loans && transactions[0].minDue ? satangToBath(transactions[0].minDue) : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="intRate" />}>
          {loans ? (loans.intRate * 100).toFixed(2) : '-'}
          {'%'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="minPaid" />}>
          {loans && transactions[0].minPaid ? satangToBath(transactions[0].minPaid) : '-'}
          {' บาท'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="installTerm" />}>
          {loans ? `${loans.installTerm} เดือน` : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="product" />}>
          {loans && loans.loanType ? loans.productName : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="lastDueDate" />}>
          {lastDueDate}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="installAmount" />}>
          {loans ? satangToBath(loans.installAmount) : '-'}
          {' บาท'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="minPayRate" />}>
          {loans ? (loans.minPayRate * 100).toFixed(2) : '-'}
          {' %'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="lastpayAmount" />}>
          {loans && transactions[0].lastpayAmount
            ? satangToBath(transactions[0].lastpayAmount)
            : '-'}
          {' บาท'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="statementDate" />}>
          {loans && loans.statementDate ? loans.statementDate : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="lastStatementDate" />}>
          {lastAdjDate}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="lastpayDate" />}>
          {loans && transactions[0].lastpayDate
            ? moment(transactions[0].lastpayDate).format('YYYY-MM-DD')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="status" />}>
          {loans ? loans.status : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="closeDate" />}>
          {loans && loans.closeDate ? moment(loans.closeDate).format('YYYY-MM-DD') : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="Remaining Withdraw Amount" />}>
          {loans && loans.creditLimit // && loans.repayType === 'revolve'
            ? satangToBath(loans.withdrawAmount)
            : '-'}
          {' บาท'}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="intType" />}>
          {loans && loans.intType ? <FormattedMessage id={`options.${loans.intType}`} /> : `-`}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="Last 8 period pastdue" />}>
          {loans && loans.countDebt ? `ค้างชำระ ${loans.countDebt} งวด` : `ค้างชำระ 0 งวด`}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Row>
        <Col span={24}>
          <ActionButtonPanel
            projectId={projectId}
            loan={loans}
            transactions={transactions}
            reloadData={getDataSource}
            permissions={permissions}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LoanSummary;
