import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import PolicieForm from '../../../components/CbsComponents/PolicieForm/PolicieForm';
import ContentContainer from '../../../components/LayoutComponents/ContentContainer';
import { UserState } from '../../../redux/user/user-duck';
import { useData } from '../../../helpers/form/fetch-hook';
import { API_URL } from '../../../helpers/api/api';

const InviteUser: FC<PropsFromRedux> = ({ projectId }) => {
  const [roles] = useData(`${API_URL}/projects/${projectId}/roles`, 1000);

  const breadcrumbs = [
    {
      title: 'menu.policies',
      href: '/policies',
    },
    {
      title: 'add',
      href: `/policies/new`,
    },
  ];

  return (
    <ContentContainer
      helmetTitle="user-new.helmet-title"
      pageTitle="user-new.page-title"
      breadcrumbs={breadcrumbs}
    >
      <PolicieForm roles={roles.data} isNew />
    </ContentContainer>
  );
};
const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InviteUser);
