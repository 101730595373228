import { connect } from 'react-redux';
import { hasPermission } from '../helpers/permission';
import permissionsSelector from '../selectors/permissionsSelector';

const mapStateToProps = (state: any) => {
  return {
    permissions: permissionsSelector(state),
  };
};

export default (requiredPermissions: string | string[], Component: any) => {
  return connect(mapStateToProps)(({ permissions }) => {
    if (hasPermission(requiredPermissions, permissions)) {
      return Component;
    }
    return null;
  });
};
