import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import { Form, Input, Row, Col, Button, notification, Descriptions } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import InputBaht from '../../common/InputBaht';
import styles from '../loan.module.scss';
import componentValues from '../../../../services/utilities/ComponentValue';
import { API_URL, patchJson, getJson, showError } from '../../../../helpers/api/api';
import { satangToBath } from '../../../../helpers/format/satangToBath';
import DebtSummary from '../../Application/ApplicationForm/DataTabs/Approval/Panel/DebtSummary';
import { Store } from 'antd/lib/form/interface';
import { ErrorInfo } from '../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../helpers/form';
import { applicationStatusToStage } from '../../../../helpers/application';
import renderIfHasPermissisons from '../../../../layouts/renderIfHasPermissisons';

interface Props {
  loan: any;
  projectId: string;
}

const ApproveInfo: React.FC<Props & PropsFromRedux> = ({ loan, projectId, push }) => {
  const intl = useIntl();
  const { TextArea } = Input;
  const { citizenId } = loan;
  console.log('loan: ', JSON.stringify(loan));
  const approveType = ['approving', 'confirming', 'loan_opened'];

  const [data, setData] = useState<any>({
    data: {
      loans: [],
      totalOsb: undefined,
      totalMindue: undefined,
      osbDivineSalary: 0,
      osbDivineIncome: 0,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getJson(
          `${API_URL}/projects/${projectId}/loans/${citizenId}/debt-summary-table`,
        );
        const { totalOsb, loans } = result.data;
        const application = await getJson(
          `${API_URL}/projects/${projectId}/applications/${loan.appId}`,
        );
        const { baseSalary, extraIncome } = application.data.employmentInfo;
        const newBaseSalary = Number(baseSalary || 0);
        const newExtraIncome = Number(extraIncome || 0);
        const sumIncome = newBaseSalary + newExtraIncome;

        result.data.baseSalary = newBaseSalary;
        result.data.extraIncome = newExtraIncome;
        result.data.osbDivineSalary = Number((totalOsb / newBaseSalary) * 100).toFixed(0);
        result.data.osbDivineIncome = Number((totalOsb / sumIncome) * 100).toFixed(0);
        result.data.totalOsb = totalOsb === 0 ? 0 : Number(totalOsb).toFixed(0);
        result.data.totalCreditLimit = loans
          .map(({ creditLimit, status }: { creditLimit: number; status: string }) =>
            status !== 'close' ? creditLimit : 0,
          )
          .reduce(
            (totalCreditLimit: number, creditLimit: number) => totalCreditLimit + creditLimit,
            0,
          );
        result.data.totalMindue = loans
          .map(({ minDue, status }: { minDue: number; status: string }) =>
            status !== 'close' ? minDue : 0,
          )
          .reduce((totalMindue: number, minDue: number) => totalMindue + minDue, 0);
        setData(result.data);
      } catch (error) {
        showError(error);
      }
    };
    fetchData();
  }, [projectId, citizenId, loan.employmentInfo]);

  return (
    <div id="ApproveInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="debtSummary" />}</strong>
      <div>
        <DebtSummary dataSource={data.loans} />
      </div>

      <Descriptions bordered>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.totalCreditLimit" />} span={2}>
          {satangToBath(data.totalCreditLimit)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.totalOsb" />} span={2}>
          {satangToBath(data.totalOsb)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="employmentInfo.baseSalary" />} span={2}>
          {satangToBath(data.baseSalary)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="employmentInfo.extraIncome" />} span={2}>
          {satangToBath(data.extraIncome)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.osbDivineSalary" />} span={2}>
          {satangToBath(data.osbDivineSalary)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.osbDivineIncome" />} span={2}>
          {satangToBath(data.osbDivineIncome)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="totalMindue" />} span={2}>
          {satangToBath(data.totalMindue)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

const connector = connect(null, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ApproveInfo);
