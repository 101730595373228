import React from 'react';
import { Table } from 'antd';
import { satangToBath } from '../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../helpers/date/date';
import { useIntl } from 'react-intl';

const SmsNotifyApprovalTable: React.FC<any> = (props: any) => {
  const intl = useIntl();
  const { dataSource, loading, rowSelection } = props;
  const column = [
    {
      title: intl.formatMessage({ id: 'application.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'common.date' }),
      dataIndex: 'date',
      render: (date: string) => {
        return iso8601ToLocalDate(date);
      },
    },
    {
      title: intl.formatMessage({ id: 'firstName' }),
      dataIndex: 'firstName',
    },
    {
      title: intl.formatMessage({ id: 'lastName' }),
      dataIndex: 'lastName',
    },
    {
      title: intl.formatMessage({ id: 'product' }),
      dataIndex: 'productName',
    },
    {
      title: intl.formatMessage({ id: 'requestAmount' }),
      dataIndex: 'requestAmount',
      render: (requestAmount: number) => {
        return satangToBath(requestAmount);
      },
    },
    {
      title: intl.formatMessage({ id: 'approveAmount' }),
      dataIndex: 'approveAmount',
      render: (approveAmount: number) => {
        return satangToBath(approveAmount);
      },
    },
    {
      title: intl.formatMessage({ id: 'common.mobileNo' }),
      dataIndex: 'mobileNo',
    },
    {
      title: intl.formatMessage({ id: 'sms.format' }),
      dataIndex: 'message',
    },
  ];
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        loading={loading}
        columns={column}
        pagination={{ pageSize: 5000000 }}
      />
    </div>
  );
};

export default SmsNotifyApprovalTable;
