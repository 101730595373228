import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import localStore from 'store';
import Router from './router';
import Localization from './components/LayoutComponents/Localization';
import { store, history } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { renewToken, scheduleRenewToken } from './redux/user/user-duck';

// app styles
import './global.scss';

// Cannot call this inside user-saga setup because renewToken need store to be setup first in order to work
const authorized = localStore.get('app.user.authorized');
if (authorized) {
  store.dispatch(renewToken());
  store.dispatch(scheduleRenewToken());
}

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
