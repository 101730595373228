import React from 'react';
import { Table, Popconfirm, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { API_URL, deleteJson, showError } from '../../../../helpers/api/api';
import { iso8601ToLocalDate, iso8601ToLocalDateWithTime } from '../../../../helpers/date/date';
import { satangToBath } from '../../../../helpers/format/satangToBath';

const TableNote: React.FC<any> = (props: any) => {
  const { dataSource, loading, projectId, getDataSource } = props;

  async function deleteNote(id: string) {
    const status = 'delete';
    try {
      await deleteJson(`${API_URL}/projects/${projectId}/notes`, {
        id,
        status,
      });
      getDataSource();
    } catch (err) {
      message.warning(`${err}`);
    }
  }

  function mapCollectionStatus(status: string) {
    switch (status) {
      case 'paid':
        return 'ชำระแล้ว';
      case 'promise to pay':
        return 'นัดชำระ';
      case 'broken promise':
        return 'ผิดนัดชำระ';
      case 'contact by phone':
        return 'เจอตัวได้คุย (ทางโทรศัพท์)';
      case 'contact by line':
        return 'เจอตัวได้คุย (ทางไลน์)';
      case 'no answer':
        return 'โทรติดแต่ไม่ได้คุย';
      case 'line not read':
        return 'อ่านไลน์แต่ไม่ตอบ';
      case 'no contact':
        return 'ติดต่อไม่ได้';
      case 'no direct contact':
        return 'ติดต่อไม่ได้(แต่ติดต่อบุคคลอ้างอิง / ที่ทำงานได้)';
      case 'pass away':
        return 'กลุ่มลูกค้าเสียชีวิต';
      case 'TL14':
        return 'คัด ทร14';
      case 'other':
        return 'อื่นๆ';
      default:
        return '';
    }
  }

  const columns: any = [
    {
      title: <FormattedMessage id="note.createDate" />,
      dataIndex: 'createDate',
      width: '100px',
      render: (createDate: any) => iso8601ToLocalDateWithTime(createDate),
    },
    {
      title: <FormattedMessage id="sms.createBy" />,
      dataIndex: 'createBy',
      width: '100px',
    },
    {
      title: <FormattedMessage id="note.category" />,
      dataIndex: 'category',
      width: '180px',
    },
    {
      title: <FormattedMessage id="note.channel" />,
      dataIndex: 'channel',
      width: '180px',
    },
    {
      title: <FormattedMessage id="note.collectionStatus" />,
      dataIndex: 'collectionStatus',
      width: '180px',
      render: (collectionStatus: any) =>
        collectionStatus ? mapCollectionStatus(collectionStatus) : '',
    },
    {
      title: <FormattedMessage id="note.followupDate" />,
      dataIndex: 'followupDate',
      width: '120px',
      render: (followupDate: any) => (followupDate ? iso8601ToLocalDate(followupDate) : ''),
    },
    {
      title: <FormattedMessage id="note.followupAmount" />,
      dataIndex: 'followupAmount',
      width: '120px',
      render: (followupAmount: any) =>
        !Number.isNaN(Number(followupAmount)) ? satangToBath(followupAmount) : '',
    },
    // {
    //   title: <FormattedMessage id="note.repayDate" />,
    //   dataIndex: 'repayDate',
    // },
    // {
    //   title: <FormattedMessage id="note.repayAmount" />,
    //   dataIndex: 'repayAmount',
    // },
    {
      title: <FormattedMessage id="note.message" />,
      dataIndex: 'message',
    },
    {
      fixed: 'right',
      render: (record: any, row: any, index: any) => {
        return (
          <span>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteNote(record.id)}>
              <i className="icmn-bin" />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={columns} />
    </div>
  );
};

export default TableNote;
