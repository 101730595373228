// Action types
const SET_STATE = 'menu/SET_STATE';

// Action creators
/**
 * Action creator for menu/SET_STATE
 * eg. if you want to set state.menuLeft to ['menu1', 'menu2']
 *  and state.menuRight to ['menu3', 'menu4']
 * call setState({ menuLeft: ['menu1', 'menu2'], menuRight: ['menu3', 'menu4']})
 * @param {object} payload - An object contains key/value that will be patched to current state
 */
export function setState(payload) {
  return {
    type: SET_STATE,
    payload,
  };
}
const initialState = {
  menuLeftData: [],
  menuTopData: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
