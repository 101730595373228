import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import AccComponent from '../../components/CbsComponents/Acc/AccComponent';

const AccPage: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.loans',
      href: '/loans',
    },
    {
      title: 'menu.acc',
      href: `/acc`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="acc.helmet-title"
      pageTitle="acc.page-title"
      breadcrumbs={breadcrumbs}
    >
      <AccComponent />
    </ContentContainer>
  );
};

export default AccPage;
