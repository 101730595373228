import React from 'react';
import ApplicationSummary from './ApplicationSummary';
import ActionButtonPanel from './ActionButtonPanel/ActionButtonPanel';
import DataTabs from './DataTabs/DataTabs';
import { API_URL } from '../../../../helpers/api/api';
import { useDataById } from '../../../../helpers/form/fetch-hook';

interface Props {
  projectId: string;
  applicationId: string;
}
const ApplicationForm: React.FC<Props> = ({ projectId, applicationId }) => {
  const [application, reloadApplication] = useDataById(
    `${API_URL}/projects/${projectId}/applications/${applicationId}`,
  );
  return (
    <div className="card">
      <div className="card-header">
        <div className="utils__title">
          <strong>{applicationId}</strong>
        </div>
      </div>
      <div className="card-body">
        <div style={{ marginBottom: '20px' }}>
          <ApplicationSummary application={application.data} />
        </div>
        <ActionButtonPanel projectId={projectId} application={application.data} />
        <DataTabs
          projectId={projectId}
          application={application.data}
          reloadApplication={reloadApplication}
        />
      </div>
    </div>
  );
};

export default ApplicationForm;
