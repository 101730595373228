import React, { FC, useEffect, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import QRCode from 'qrcode';
import { API_URL, getJson } from '../../../../helpers/api/api';
import { satangToBath } from '../../../../helpers/format/satangToBath';

interface Props {
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  transaction: any;
  loan: any;
  projectId: string;
}

const QrcodeModal: FC<Props> = ({
  visible,
  onCancel,
  cancelText,
  okText,
  transaction,
  loan,
  projectId,
}) => {
  const [qrcodeUrl, setQrcodeUrl] = useState<string>('');
  const qrcodeGenerator = async (qrcodeString: string) => {
    if (!qrcodeString) {
      return qrcodeString;
    }
    const qrcode = await QRCode.toDataURL(qrcodeString);
    setQrcodeUrl(qrcode);
    return qrcode;
  };

  useEffect(() => {
    async function fetchData() {
      if (loan && Object.keys(transaction).length !== 0) {
        const fetchUrl = `${API_URL}/projects/${projectId}/billers/transaction-qrcode/${transaction.id}`;
        const response = await getJson(fetchUrl);
        const qrcodeString = response.data;
        qrcodeGenerator(qrcodeString);
      }
    }
    fetchData();
  });

  return (
    <Modal
      visible={visible}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Row>
        <h2>
          <FormattedMessage id="qrcode" />
        </h2>
      </Row>
      <hr />
      <Row gutter={24}>
        <Col span={24}>
          {qrcodeUrl ? (
            <img
              style={{ width: '30%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
              src={qrcodeUrl}
              alt="noburo logo"
            />
          ) : (
            <h4 style={{ textAlign: 'center' }}>No QRcode</h4>
          )}
          <h4 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <FormattedMessage id="minDue" />: {satangToBath(transaction.minDue)} บาท
          </h4>
        </Col>
      </Row>
    </Modal>
  );
};

export default QrcodeModal;
