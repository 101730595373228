import React from 'react';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { changeSetting } from '../../../../redux/settings/settings-duck';
import styles from './language-selector.module.scss';

class LanguageSelector extends React.Component {
  changeLang = ({ key }) => {
    const { changeSetting } = this.props;
    changeSetting({
      setting: 'locale',
      value: key,
    });
  };

  render() {
    const {
      settings: { locale },
    } = this.props;
    const language = locale.substr(0, 2);

    const langMenu = (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
        <Menu.Item key="en-US">
          <span role="img" aria-label="English" className="mr-2">
            🇬🇧
          </span>
          English
        </Menu.Item>
        <Menu.Item key="th-TH">
          <span role="img" aria-label="Thai" className="mr-2">
            🇹🇭
          </span>
          Thai
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <strong className="text-uppercase">{language}</strong>
        </div>
      </Dropdown>
    );
  }
}

export default connect(
  ({ settings }) => ({ settings }),
  { changeSetting },
)(LanguageSelector);
