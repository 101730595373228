import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Select, Row, Col, Button, Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import componentValues from '../../../../../services/utilities/ComponentValue';
import { useAddressAutoComplete } from '../../../../../helpers/form/fetch-hook';
import styles from '../../../Application/application.module.scss';
import { getValidator } from '../../../../../helpers/validator';

interface Props {
  form: FormInstance;
}
const CurrentPermanentAddress: React.FC<Props> = ({ form }) => {
  const { getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();

  function copyFromCurrent() {
    const current = {
      address: getFieldValue(['addressInfo', 'current', 'address']),
      subDistrict: getFieldValue(['addressInfo', 'current', 'subDistrict']),
      district: getFieldValue(['addressInfo', 'current', 'district']),
      province: getFieldValue(['addressInfo', 'current', 'province']),
      postCode: getFieldValue(['addressInfo', 'current', 'postCode']),
    };
    setFieldsValue({
      addressInfo: {
        permanent: current,
      },
    });
  }

  return (
    <Fragment>
      <div id="currentAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="addressInfo.current" />
        </strong>
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'current', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'current', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'current', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'current', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'current', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'current'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item
              label={<FormattedMessage id="mobileNo" />}
              name="mobileNo"
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNo" />}
              name={['addressInfo', 'current', 'phoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNoExt" />}
              name={['addressInfo', 'current', 'phoneNoExt']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Divider dashed />
      <div id="permanentAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="addressInfo.permanent" />
          <Button style={{ marginLeft: '10px' }} type="primary" onClick={copyFromCurrent}>
            <FormattedMessage id="useCurrentAddress" />
          </Button>
        </strong>
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'permanent', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'permanent', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'permanent', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'permanent', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'permanent', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'permanent'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNo" />}
              name={['addressInfo', 'permanent', 'phoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="phoneNoExt" />}
              name={['addressInfo', 'permanent', 'phoneNoExt']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CurrentPermanentAddress;
