import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';
import ReportComponent from '../../components/CbsComponents/Reports/ReportComponent';

const ReportPage: React.FC<WrappedComponentProps> = ({ intl }) => {
  const breadcrumbs = [
    {
      title: 'menu.reports',
      href: '/reports',
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.reports' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.reports" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <ReportComponent />
      </div>
    </Layout.Content>
  );
};
export default injectIntl(ReportPage);
