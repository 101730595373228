import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd/lib/form';
import { Form, Input, AutoComplete, Row, Col, Radio, Select } from 'antd';
import DateOnlyPicker from '../../../../../common/DateOnlyPicker';
import InputBaht from '../../../../../common/InputBaht';
import { useAddressAutoComplete } from '../../../../../../../helpers/form/fetch-hook';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import { getValidator } from '../../../../../../../helpers/validator';

interface Props {
  form: FormInstance;
}
const incomeOptions = [
  { value: 'OT' },
  { value: 'Commission' },
  { value: 'โบนัส' },
  { value: 'ค่าเบี้ยเลี้ยง' },
  { value: 'ค่าตำแหน่ง' },
  { value: 'Service' },
  { value: 'ค่าเดินทาง' },
];
const occupationTypeOptions = [
  { value: 'อาจารย์' },
  { value: 'ตำรวจ/ทหาร' },
  { value: 'แพทย์' },
  { value: 'พยาบาล' },
  { value: 'ธุรการ' },
  { value: 'พนง.การผลิต' },
  { value: 'พนง.บุคคล' },
  { value: 'พนง.บัญชี/การเงิน' },
  { value: 'พนง.ขาย' },
  { value: 'รักษาความปลอดภัย' },
  { value: 'แม่บ้าน' },
];
const businessTypeOption = [
  { value: 'ราชการ' },
  { value: 'รัฐวิสาหกิจ' },
  { value: 'ก่อสร้าง' },
  { value: 'การเงิน' },
  { value: 'การเกษตร' },
  { value: 'อุตสาหกรรม' },
  { value: 'การพาณิชย์' },
  { value: 'การบริการ' },
  { value: 'โรงแรม' },
  { value: 'การศึกษา' },
  { value: 'ห้างสรรพสินค้า/ซูเปอร์มาร์เก็ต/ค้าปลีก' },
  { value: 'อาหาร/เครื่องดื่ม/ภัตตาคาร' },
  { value: 'ความงาม/สุขภาพ' },
];
const EmploymentInfo: React.FC<Props> = ({ form }) => {
  const intl = useIntl();
  const { setFieldsValue } = form;
  const [
    setAddressesFromSearchResult,
    renderFilteredAddresses,
    setSelectedAddress,
  ] = useAddressAutoComplete();

  function caseInsensitiveMatch(inputValue: any, option: any) {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }
  return (
    <Fragment>
      <div id="companyAddress" className={styles.header}>
        <strong className={styles.title}>
          <FormattedMessage id="education.title" />
        </strong>
        {/* EDUCATION */}
        <Form.Item
          label={<FormattedMessage id="personalInfo.education" />}
          name={['personalInfo', 'education']}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="underHighSchool">
              <FormattedMessage id="education.underHighSchool" />
            </Radio.Button>
            <Radio.Button value="highSchool">
              <FormattedMessage id="education.highSchool" />
            </Radio.Button>
            <Radio.Button value="diploma">
              <FormattedMessage id="education.diploma" />
            </Radio.Button>
            <Radio.Button value="bachelor">
              <FormattedMessage id="education.bachelor" />
            </Radio.Button>
            <Radio.Button value="higherBachelor">
              <FormattedMessage id="education.higherBachelor" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* EMPLOYMENT STATUS */}
        <Form.Item
          label={<FormattedMessage id="employmentInfo.employmentStatus" />}
          name={['employmentInfo', 'employmentStatus']}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="permanent">
              <FormattedMessage id="employmentStatus.permanent" />
            </Radio.Button>
            <Radio.Button value="partTime">
              <FormattedMessage id="employmentStatus.partTime" />
            </Radio.Button>
            <Radio.Button value="daily">
              <FormattedMessage id="employmentStatus.daily" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* WORK ADDRESS */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.name" />}
              name={['addressInfo', 'company', 'name']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.isSubcontract" />}
              name={['addressInfo', 'company', 'isSubcontract']}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="yes">
                  <FormattedMessage id="yes" />
                </Radio.Button>
                <Radio.Button value="no">
                  <FormattedMessage id="no" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.subcontractPhoneNo" />}
              name={['addressInfo', 'company', 'subcontractPhoneNo']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* ADDRESS */}
        <Form.Item
          label={<FormattedMessage id="address" />}
          name={['addressInfo', 'company', 'address']}
        >
          <Input />
        </Form.Item>
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="subDistrict" />}
              name={['addressInfo', 'company', 'subDistrict']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'subDistrict');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="district" />}
              name={['addressInfo', 'company', 'district']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'district');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="province" />}
              name={['addressInfo', 'company', 'province']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'province');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="postCode" />}
              name={['addressInfo', 'company', 'postCode']}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                onChange={(value: string) =>
                  setSelectedAddress(value, setFieldsValue, ['addressInfo', 'company'])
                }
                onSearch={(value: string) => {
                  setAddressesFromSearchResult(value, 'postCode');
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
              >
                {renderFilteredAddresses()}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* PHONE */}
        <Row gutter={componentValues.gutterValue}>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.hrPhoneNo" />}
              name={['addressInfo', 'company', 'hrPhoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.hrPhoneNoExt" />}
              name={['addressInfo', 'company', 'hrPhoneNoExt']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.deptPhoneNo" />}
              name={['addressInfo', 'company', 'deptPhoneNo']}
              rules={[{ validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="addressInfo.company.deptPhoneNoExt" />}
              name={['addressInfo', 'company', 'deptPhoneNoExt']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>

        {/* START WORK DATE */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="personalInfo.startWorkDate" />}
              name={['personalInfo', 'startWorkDate']}
            >
              <DateOnlyPicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.serviceYear" />}
              name={['employmentInfo', 'serviceYear']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.serviceMonth" />}
              name={['employmentInfo', 'serviceMonth']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* POSITION INFO */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.position" />}
              name={['employmentInfo', 'position']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.department" />}
              name={['employmentInfo', 'department']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.baseSalary" />}
              name={['employmentInfo', 'baseSalary']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.extraIncome" />}
              name={['employmentInfo', 'extraIncome']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>

        {/* SALARY INFO */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.extraIncomeSource" />}
              name={['employmentInfo', 'extraIncomeSource']}
            >
              <AutoComplete filterOption={caseInsensitiveMatch} options={incomeOptions} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.salaryChannel" />}
              name={['employmentInfo', 'salaryChannel']}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="bankTransfer">
                  <FormattedMessage id="salaryChannel.bankTransfer" />
                </Radio.Button>
                <Radio.Button value="cheque">
                  <FormattedMessage id="salaryChannel.cheque" />
                </Radio.Button>
                <Radio.Button value="cash">
                  <FormattedMessage id="salaryChannel.cash" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.employeeId" />}
              name={['employmentInfo', 'employeeId']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.salaryFrequency" />}
              name={['employmentInfo', 'salaryFrequency']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.salaryDate" />}
              name={['employmentInfo', 'salaryDate']}
            >
              <Input placeholder="1,5,15" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.occupationType" />}
              name={['employmentInfo', 'occupationType']}
            >
              <AutoComplete filterOption={caseInsensitiveMatch} options={occupationTypeOptions} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="employmentInfo.businessType" />}
              name={['employmentInfo', 'businessType']}
            >
              <AutoComplete filterOption={caseInsensitiveMatch} options={businessTypeOption} />
            </Form.Item>
          </Col>
        </Row>

        {/* LOAN INFO */}
        <Row gutter={componentValues.gutterValue}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="personalInfo.totalLegalLoan" />}
              name={['personalInfo', 'totalLegalLoan']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="personalInfo.totalLegalLoanAmount" />}
              name={['personalInfo', 'totalLegalLoanAmount']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="personalInfo.totalIllegalLoan" />}
              name={['personalInfo', 'totalIllegalLoan']}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <Form.Item
              label={<FormattedMessage id="personalInfo.totalIllegalLoanAmount" />}
              name={['personalInfo', 'totalIllegalLoanAmount']}
            >
              <InputBaht />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default EmploymentInfo;
