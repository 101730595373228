import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';

const CollectorLoansTable: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;
  const column = [
    {
      title: <FormattedMessage id="import.collectorLoans.period" />,
      dataIndex: 'period',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.collectorLoans.loanId" />,
      dataIndex: 'loanId',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.collectorLoans.collector" />,
      dataIndex: 'collector',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.collectorLoans.fromDate" />,
      dataIndex: 'fromDate',
      width: '15%',
    },
    {
      title: <FormattedMessage id="import.column.status" />,
      dataIndex: 'status',
      width: '10%',
    },
  ];
  return (
    <div>
      <Table dataSource={dataSource} loading={loading} columns={column} />
    </div>
  );
};

export default CollectorLoansTable;
