import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Button, Row, Col } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { getValidator } from '../../../helpers/validator';
import { API_URL, postJson, showError } from '../../../helpers/api/api';

interface Props {
  next: () => void;
  userContentValues: any;
  setUserContentValues: (values: any) => void;
  setOtpData: (otpData: any) => void;
}

const UserContent: FC<Props> = ({ next, userContentValues, setUserContentValues, setOtpData }) => {
  const intl = useIntl();
  const onFinish = async (value: Store) => {
    try {
      const otpData = await postJson(`${API_URL}/users/onboarding/otps`, {
        firstName: value.firstName,
        lastName: value.lastName,
        password: value.password,
        contactMobileNo: value.mobileNo,
      });
      setOtpData(otpData);
      setUserContentValues(value);
      next();
    } catch (error) {
      showError(error);
    }
  };
  return (
    <div>
      <Form layout="vertical" onFinish={onFinish} initialValues={userContentValues}>
        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              label={<FormattedMessage id="firstName" />}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<FormattedMessage id="lastName" />}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              label={<FormattedMessage id="password" />}
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<FormattedMessage id="mobileNo" />}
              name="mobileNo"
              rules={[{ required: true }, { validator: getValidator(intl, 'telNo') }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {<FormattedMessage id="next" />}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserContent;

// type formEvent = React.FormEvent<EventTarget>;

// interface Contentprops {
//   form: any;
//   next: () => void;
//   values: UserContentType;
//   setValues: (values: UserContentType) => void;
//   requestOtp: () => Promise<boolean>;
// }

// const ContentForm: React.FC<Contentprops> = props => {
//   const { form, next, values, setValues, requestOtp } = props;

//   const handleChange = async (e: any, field: string) => {
//     const temp = values;
//     temp[field] = e.target.value;
//     await setValues(temp);
//   };

//   const handleSubmit = (e: formEvent) => {
//     e.preventDefault();
//     form.validateFields(async (err: any, values: UserContentType) => {
//       if (!err) {
//         const otp = await requestOtp();
//         if (otp) {
//           next();
//         }
//       }
//     });
//   };

//   function checkMobileNo(rule: any, value: string, callback: any) {
//     if (value.length !== 10) {
//       callback('Invalid mobile number length');
//     } else {
//       callback();
//     }
//   }

//   return (
//     <div>
//       <Form onSubmit={handleSubmit} className="secondContent-form">
//         <Row gutter={50}>
//           <Col span={10}>
//             <Form.Item label={<FormattedMessage id="common.name" />}>
//               {form.getFieldDecorator('firstName', {
//                 rules: [{ required: true, message: 'Please input your first name' }],
//                 initialValue: values.firstName,
//               })(<Input onChange={value => handleChange(value, 'firstName')} />)}
//             </Form.Item>
//           </Col>
//           <Col span={10}>
//             <Form.Item label={<FormattedMessage id="common.lastName" />}>
//               {form.getFieldDecorator('lastName', {
//                 rules: [{ required: true, message: 'Please input your last name' }],
//                 initialValue: values.lastName,
//               })(<Input onChange={value => handleChange(value, 'lastName')} />)}
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={50}>
//           <Col span={10}>
//             <Form.Item label={<FormattedMessage id="form.password" />}>
//               {form.getFieldDecorator('password', {
//                 rules: [{ required: true, message: 'Please input your password' }],
//                 initialValue: values.password,
//               })(<Input.Password onChange={value => handleChange(value, 'password')} />)}
//             </Form.Item>
//           </Col>
//           <Col span={10}>
//             <Form.Item label={<FormattedMessage id="common.mobileNo" />}>
//               {form.getFieldDecorator('mobileNo', {
//                 rules: [
//                   {
//                     required: true,
//                     message: <FormattedMessage id="common.mobileNo.inputMessage" />,
//                   },
//                   {
//                     validator: checkMobileNo,
//                   },
//                 ],
//                 initialValue: values.mobileNo,
//               })(
//                 <Input
//                   onChange={value => handleChange(value, 'mobileNo')}
//                   type="number"
//                   placeholder="08XXXXXXXX"
//                 />,
//               )}
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row>
//           <Col
//             xs={{ span: 24 }}
//             sm={{ span: 24 }}
//             md={{ span: 3 }}
//             lg={{ span: 3 }}
//             style={{ marginRight: 10 }}
//           >
//             <Form.Item>
//               <Button type="primary" htmlType="submit" className={styles.button}>
//                 {<FormattedMessage id="common.next" />}
//               </Button>
//             </Form.Item>
//           </Col>
//         </Row>
//       </Form>
//     </div>
//   );
// };

// const UserContentForm = Form.create<Contentprops>({ name: 'userContentForm' })(ContentForm);

// export default UserContentForm;
