import React from 'react';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import AdjComponent from '../../components/CbsComponents/Adj/AdjComponent';

const AdjPage: React.FC = () => {
  const breadcrumbs = [
    {
      title: 'menu.loans',
      href: '/loans',
    },
    {
      title: 'menu.adj',
      href: `/adj`,
    },
  ];
  return (
    <ContentContainer
      helmetTitle="adj.helmet-title"
      pageTitle="adj.page-title"
      breadcrumbs={breadcrumbs}
    >
      <AdjComponent />
    </ContentContainer>
  );
};

export default AdjPage;
