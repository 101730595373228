import React from 'react';
import { Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs';
import ImportCreditreportsConfirm from '../../components/CbsComponents/Imports/ImportCreditreportsConfirm';

const ImportPaymentdTable: React.FC<any> = (props: any) => {
  const breadcrumbs = [
    {
      title: 'menu.imports',
      href: '#',
    },
    {
      title: 'menu.importApplications',
      href: `/import/applications`,
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.imports" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <ImportCreditreportsConfirm />
      </div>
    </Layout.Content>
  );
};

export default ImportPaymentdTable;
