import React, { FC, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { notification } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import qs from 'query-string';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import LoanSummary from '../../components/CbsComponents/Loans/LoanSummary';
import ContentContainer from '../../components/LayoutComponents/ContentContainer';
import { API_URL, getJson } from '../../helpers/api/api';
import TransactionsTab from '../../components/CbsComponents/Loans/TransactionsTab';
import permissionsSelector from '../../selectors/permissionsSelector';
// import { patchJson, getJson, API_URL } from '../../helpers/api/api';

// interface UrlParams {
//   id: string;
// }
// interface Props {
//   projectId: string;
// }
// const EditLoan: React.FC<Props &
//   RouteComponentProps<UrlParams> &
//   FormComponentProps &
//   WrappedComponentProps> = ({ projectId, match, form, intl }) => {
//   const { id } = match.params;
//   const [loanWithTran, setLoanWithTran] = useState<any>([]);
//   const [pagination, setPagination] = useState<any>([]);

//   async function getDataSource() {
//     const url = `${API_URL}/projects/${projectId}/loans/withTransactions/${id}`;
//     const { data, meta } = await getJson(url);
//     setLoanWithTran(data);
//     setPagination(meta);
//   }

//   const updateLoan = () => {
//     form.validateFields(async (err: any, values: any) => {
//       if (err) {
//         notification.error({
//           message: 'Please check required fields',
//         });
//         return;
//       }
//       try {
//         const { id } = loanWithTran.loans;
//         await patchJson(`${API_URL}/projects/${projectId}/loans/${id}`, { values });
//         notification.success({
//           message: 'Update loan complete',
//           description: 'You have successfully update loan',
//         });
//         push(`/loans/${id}`);
//       } catch (error) {
//         notification.error({
//           message: 'Cannot update loan',
//         });
//       }
//     });
//   };

//   useEffect(() => {
//     getDataSource();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const breadcrumbs = [
//     {
//       title: 'menu.loans',
//       href: '/loans',
//     },
//     {
//       title: id,
//       href: `/loans/${id}`,
//     },
//   ];

//   return (
//     <Layout.Content style={{ height: '100%', position: 'relative' }}>
//       <Helmet title={`${intl.formatMessage({ id: 'menu.loans' })} | ${id}`} />
//       <div className="utils__content">
//         <div style={{ marginBottom: '20px' }}>
//           <div className="page-title">
//             <FormattedMessage id="menu.loans" />
//           </div>
//           <Breadcrumbs breadcrumbs={breadcrumbs} />
//         </div>
//         <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
//           <LoanSummary dataSource={loanWithTran} getDataSource={getDataSource} />
//         </div>
//         <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
//           <TransactionsTab
//             dataSource={loanWithTran}
//             pagination={pagination}
//             getDataSource={getDataSource}
//             form={form}
//             updateLoan={updateLoan}
//           />
//         </div>
//       </div>
//     </Layout.Content>
//   );
// };

function useData(fetchUrl: string, pageSize: number, page: any): [any, () => void] {
  const [data, setData] = useState({
    loading: true,
    pagination: { current: page, pageSize, total: 0 },
    data: [],
  });
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());
  const [status, setStatus] = useState<string>('active');
  const reloadData = (status?: any) => {
    if (status) setStatus(status);
    setTimestamp(new Date().getTime());
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const condition = {
          offset: page ? (page - 1) * pageSize : undefined,
          limit: pageSize,
          status,
        };
        const response = await getJson(`${fetchUrl}?${qs.stringify(condition)}`);
        const { total, offset, limit } = response.meta;
        const pagination = {
          current: parseInt(offset, 10) / parseInt(limit, 10) + 1,
          pageSize: parseInt(limit, 10),
          total,
        };
        setData({
          loading: false,
          pagination,
          data: response.data,
        });
      } catch (error) {
        const { code, message } = error;
        notification.error({ message: code, description: message });
        setData({
          loading: false,
          pagination: { current: page, pageSize, total: 0 },
          data: [],
        });
      }
    }
    fetchData();
  }, [fetchUrl, page, pageSize, timestamp]);
  return [data, reloadData];
}

const EditLoan: FC<PropsFromRedux> = ({ projectId, permissions, pageSize }) => {
  const { id } = useParams();
  const fetchUrl = `${API_URL}/projects/${projectId}/loans/withTransactions/${id}`;
  const breadcrumbs = [
    {
      title: 'menu.loans',
      href: '/loans',
    },
    {
      title: id,
      href: `/loans/${id}`,
    },
  ];

  const [page, setPage] = useState<number>(1);
  const [loanWithTran, reloadLoanWithTran] = useData(fetchUrl, pageSize, page);
  const changePage = (pagination: TablePaginationConfig) => {
    const { current } = pagination;
    setPage(Number(current));
  };
  return (
    <ContentContainer
      helmetTitle="loan-edit.helmet-title"
      helmetTitleParams={{ id }}
      pageTitle="loan-edit.page-title"
      breadcrumbs={breadcrumbs}
    >
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <LoanSummary
          projectId={projectId}
          dataSource={loanWithTran.data}
          getDataSource={reloadLoanWithTran}
          permissions={permissions}
        />
      </div>
      <div className="card" style={{ backgroundColor: 'white', padding: '10px' }}>
        <TransactionsTab
          projectId={projectId}
          dataSource={loanWithTran.data}
          loading={loanWithTran.loading}
          pagination={loanWithTran.pagination}
          getDataSource={reloadLoanWithTran}
          onChange={changePage}
        />
      </div>
    </ContentContainer>
  );
};
const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
    permissions: permissionsSelector(state),
    pageSize: parseInt(state.settings.pageSize, 10),
  };
};
const connector = connect(mapStateToProps, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditLoan);
