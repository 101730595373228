import React, { Fragment, useState } from 'react';
import { Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import EditLoanModal from './EditLoanModal';

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
}
const EditLoanButton: React.FC<Props> = ({ projectId, loan, transactions }) => {
  const intl = useIntl();
  const lastTransaction = transactions ? transactions[0] : {};
  const [editLoanModalVisible, setEditLoanModalVisible] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        onClick={() => setEditLoanModalVisible(true)}
      >
        <FormattedMessage id="editloan" />
      </Button>

      <EditLoanModal
        projectId={projectId}
        visible={editLoanModalVisible}
        onCancel={() => setEditLoanModalVisible(false)}
        okText={intl.formatMessage({ id: 'submit' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        lastTransaction={lastTransaction}
        loan={loan || {}}
      />
    </Fragment>
  );
};

export default EditLoanButton;
