import React from 'react';
import { Table } from 'antd';
import { satangToBath } from '../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../helpers/date/date';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const SmsNotifyCashTable: React.FC<any> = (props: any) => {
  const intl = useIntl();
  const { dataSource, loading, rowSelection } = props;
  const column = [
    {
      title: intl.formatMessage({ id: 'transaction.id' }),
      dataIndex: 'transId',
    },
    {
      title: intl.formatMessage({ id: 'loan.id' }),
      dataIndex: 'loanId',
      render: (loanId: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`/loans/${loanId}`}>
            {loanId}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.date' }),
      dataIndex: 'transDate',
      render: (transDate: string) => {
        return iso8601ToLocalDate(transDate);
      },
    },
    {
      title: intl.formatMessage({ id: 'firstName' }),
      dataIndex: 'firstName',
    },
    {
      title: intl.formatMessage({ id: 'lastName' }),
      dataIndex: 'lastName',
    },
    {
      title: intl.formatMessage({ id: 'product' }),
      dataIndex: 'productName',
    },
    {
      title: intl.formatMessage({ id: 'cashIn' }),
      dataIndex: 'cashIn',
      render: (cashIn: number) => {
        return satangToBath(cashIn);
      },
    },
    {
      title: intl.formatMessage({ id: 'cashOut' }),
      dataIndex: 'cashOut',
      render: (cashOut: number) => {
        return satangToBath(cashOut);
      },
    },
    {
      title: intl.formatMessage({ id: 'common.mobileNo' }),
      dataIndex: 'mobileNo',
    },
    {
      title: intl.formatMessage({ id: 'sms.format' }),
      dataIndex: 'message',
    },
  ];
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        loading={loading}
        columns={column}
        pagination={{ pageSize: 5000000 }}
      />
    </div>
  );
};

export default SmsNotifyCashTable;
