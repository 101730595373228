import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Layout } from 'antd';
import Breadcrumbs from '../../../components/LayoutComponents/Breadcrumbs';
import {
  convertSatangToBaht,
  convertDecimalToPercent,
} from '../../../services/utilities/Converter';
import SearchableTable from '../../../components/CbsComponents/SearchableTable/SearchableTable';
import { API_URL } from '../../../helpers/api/api';
import { Product } from '../../../types/product-types';
import { getSortOrderFromQueryString } from '../../../helpers/querystring';

interface Props {
  queryString: string;
  pathname: string;
  currentProjectId: string;
}
const ListProduct: React.FC<Props & WrappedComponentProps> = ({
  queryString,
  pathname,
  currentProjectId,
  intl,
}) => {
  const columns = [
    {
      title: <FormattedMessage id="product.id" />,
      dataIndex: 'id',
      width: '128px',
      fixed: 'left',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Product, b: Product) => (a.id < b.id ? -1 : 1),
      sortOrder: getSortOrderFromQueryString({ dataIndex: 'id', queryString }),
      render: (productId: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`products/${productId}`}>
            {productId}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="product.name" />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id="loanType" />,
      dataIndex: 'loanType',
      width: '128px',
      render: (value: number) => {
        return <FormattedMessage id={`loanType.${value}`} />;
      },
    },
    {
      title: <FormattedMessage id="paymentType" />,
      dataIndex: 'paymentType',
      width: '128px',
    },
    {
      title: <FormattedMessage id="intType" />,
      dataIndex: 'intType',
      width: '112px',
      render: (value: number) => {
        return <FormattedMessage id={`intType.${value}`} />;
      },
    },
    {
      title: <FormattedMessage id="intRate" />,
      dataIndex: 'intRate',
      width: '112px',
      align: 'right',
      render: (value: number) => {
        const val = convertDecimalToPercent(value).toFixed(2);
        return val;
      },
    },
    {
      title: <FormattedMessage id="minPayRate" />,
      dataIndex: 'minPayRate',
      width: '112px',
      align: 'right',
      render: (value: number) => {
        const val = convertDecimalToPercent(value).toFixed(2);
        return val;
      },
    },
    {
      title: <FormattedMessage id="minPayAmount" />,
      dataIndex: 'minPayAmount',
      width: '112px',
      align: 'right',
      render: (value: number) => {
        const val = convertSatangToBaht(value).toFixed(2);
        return val;
      },
    },
  ];
  const breadcrumbs = [
    {
      title: 'menu.products',
      href: '/products',
    },
  ];
  return (
    <Layout.Content style={{ height: '100%', position: 'relative' }}>
      <Helmet title={intl.formatMessage({ id: 'menu.products' })} />
      <div className="utils__content">
        <div style={{ marginBottom: '20px' }}>
          <div className="page-title">
            <FormattedMessage id="menu.products" />
          </div>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <SearchableTable
          columns={columns}
          fetchUrl={`${API_URL}/projects/${currentProjectId}/products`}
          addUrl="products/new"
          addPermissions="products.*.create"
          pathname={pathname}
          search={queryString}
          rowKey="id"
          enableDownload
          urlDelete={`${API_URL}/projects/${currentProjectId}/products`}
          onDelete
          deletePermissions="products.*.delete"
        />
      </div>
    </Layout.Content>
  );
};

const mapStateToProps = (state: any) => ({
  currentProjectId: state.user.currentProjectId,
  pathname: state.router.location.pathname,
  queryString: state.router.location.search,
});

export default connect(mapStateToProps)(injectIntl(ListProduct));
