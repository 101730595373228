import React, { FC, Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Form,
  Table,
  Row,
  Col,
  Modal,
  Input,
  notification,
  Button,
  Tabs,
  Switch,
  Space,
} from 'antd';
import moment from 'moment';
import { iso8601ToLocalDate } from '../../../helpers/date/date';
import { satangToBath, satangToBathCommaEscape } from '../../../helpers/format/satangToBath';
import { API_URL, deleteJson, patchJson, showError, getJson } from '../../../helpers/api/api';
import { Store } from 'antd/lib/form/interface';
import renderIfHasPermissisons from '../../../layouts/renderIfHasPermissisons';
import { store } from '../../../redux/store';
import QrcodeModal from './Modal/QrcodeModal';

const { TabPane } = Tabs;

const downloadCsv = require('download-csv');

interface DeleteModalProps {
  visible: boolean;
  okText: string;
  cancelText: string;
  onCancel: () => void;
  projectId: string;
  transId: string;
  reloadData: () => void;
  rollback: boolean;
}
const DeleteModal: FC<DeleteModalProps> = ({
  visible,
  okText,
  cancelText,
  onCancel,
  projectId,
  transId,
  reloadData,
  rollback,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { TextArea } = Input;
  const onFinish = async (value: Store) => {
    try {
      const { note } = value;
      let textMessage = intl.formatMessage({ id: 'delete-transaction.success-message' });
      if (rollback) {
        await deleteJson(`${API_URL}/projects/${projectId}/transactions/rollback/${transId}`, {
          note,
        });
        textMessage = intl.formatMessage({ id: 'rollback-transaction.success-message' });
      } else {
        await deleteJson(`${API_URL}/projects/${projectId}/transactions/${transId}`, {
          note,
        });
      }
      notification.success({
        message: textMessage,
      });
      form.resetFields();
      onCancel();
      reloadData();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => {
        form.submit();
      }}
    >
      <Row>
        <h2>
          <FormattedMessage id="loan-edit.delete-transaction" />
        </h2>
      </Row>
      <hr />
      <Row>
        <Col span={12}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item label={<FormattedMessage id="note" />} name="note">
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

interface NoteModalProps {
  visible: boolean;
  okText: string;
  cancelText: string;
  onCancel: () => void;
  projectId: string;
  transId: string;
  note: string;
  reloadData: () => void;
}
const NoteModal: FC<NoteModalProps> = ({
  visible,
  okText,
  cancelText,
  onCancel,
  projectId,
  transId,
  note,
  reloadData,
}) => {
  const [form] = Form.useForm();
  form.setFieldsValue({ note });
  const intl = useIntl();
  const { TextArea } = Input;
  const onFinish = async (value: Store) => {
    try {
      const { note } = value;
      await patchJson(`${API_URL}/projects/${projectId}/transactions/${transId}`, {
        note,
      });
      notification.success({
        message: intl.formatMessage({ id: 'update-loan.success-message' }),
      });
      form.resetFields();
      onCancel();
      reloadData();
    } catch (error) {
      form.resetFields();
      showError(error);
    }
  };
  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      width="700px"
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', ghost: true }}
      onOk={() => {
        form.submit();
      }}
    >
      <Row>
        <h2>
          <FormattedMessage id="note" />
        </h2>
      </Row>
      <hr />
      <Row>
        <Col span={12}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item label={<FormattedMessage id="note" />} name="note">
              <TextArea rows={4} defaultValue="" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
  pagination: any;
  loading: boolean;
  reloadData: (status?: any) => void;
  onChange: any;
}
const TransactionTable: FC<Props> = ({
  projectId,
  loan,
  transactions,
  pagination,
  loading,
  reloadData,
  onChange,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [qrcodeModalVisible, setqrcodeModalVisible] = useState<boolean>(false);
  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [transId, setTransId] = useState<string>('');
  const [tranQrcode, setTranQrcode] = useState<any>({});
  const [note, setNote] = useState<any>('');
  const intl = useIntl();
  const [rollback, setRollback] = useState<boolean>(false);

  async function printTransaction(transaction: any) {
    try {
      const { loanId, transDate } = transaction;
      const url = `${API_URL}/projects/${projectId}/loans/${loanId}/statement?statementDate=${transDate}`;
      const { user } = store.getState();
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({ Authorization: `Bearer ${user.accessToken}` }),
      });
      const blob = await response.blob();
      const link = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = link;
      a.target = '_blank';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      notification.success({
        message: intl.formatMessage({ id: 'download-statement.success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  }
  function convertToPercent(value: any) {
    return Number(value * 100).toFixed(0);
  }
  function renderContext(context: any) {
    const { updated } = context;
    const updateData = updated ? Object.entries(updated) : Object.entries(context);
    return updateData.map(listData => {
      let nameOfData: any;
      if (listData[0] === 'beforTrc') return true;
      return listData.map((data: any, index) => {
        if (index === 0) {
          nameOfData = data;
        }
        if (index === 1) {
          let value: any = 0;
          if (
            nameOfData === 'intRate' ||
            nameOfData === 'minPayRate' ||
            nameOfData === 'stampFee'
          ) {
            // percent
            value = `${convertToPercent(data)} %`;
          } else if (
            nameOfData === 'preterminatePeriod' ||
            nameOfData === 'status' ||
            nameOfData === 'writeoffDate' ||
            nameOfData === 'closeDate' ||
            nameOfData === 'restructureDate' ||
            nameOfData === 'paymentType' ||
            nameOfData === 'judgementDate' ||
            nameOfData === 'blackCaseNo' ||
            nameOfData === 'redCaseNo' ||
            nameOfData === 'installTerm' ||
            nameOfData === 'filingDate' ||
            nameOfData === 'litigationStatus' ||
            nameOfData === 'Copy' ||
            nameOfData === 'Guarantor' ||
            nameOfData === 'beforTrc' ||
            nameOfData === 'gracePeriod' ||
            nameOfData === 'useGracePeriod'
          ) {
            // date
            value = data;
          } else {
            // bath
            value = satangToBath(data);
          }
          return (
            <Col span={5} style={{ textAlign: 'right' }}>
              {value}
            </Col>
          );
        }
        if (nameOfData === 'copy') nameOfData = 'Copy fee';
        else if (nameOfData === 'landing') nameOfData = 'Borrower contract';
        else if (nameOfData === 'numCopy') nameOfData = 'Copy';
        else if (nameOfData === 'guarantor') nameOfData = 'Guarantor contract';
        else if (nameOfData === 'creditLimit') nameOfData = 'Credit limit';
        else if (nameOfData === 'numGuarantors') nameOfData = 'Guarantor';
        return (
          <Col span={7}>
            <strong>{nameOfData}:</strong>
          </Col>
        );
      });
    });
  }
  async function onDownloadCsv() {
    const url = `${API_URL}/projects/${projectId}/loans/withTransactions/${loan.id}`;
    const { data } = await getJson(url);
    const getTrans = data.transactions;
    const values = getTrans.map((transaction: any) => {
      const mapData = {
        วันทำรายการ: iso8601ToLocalDate(transaction.transDate),
        เลขที่รายการ: transaction.id,
        เลขที่สัญญา: transaction.loanId,
        เลขที่สัญญากำหนดเอง: loan.customId,
        รหัสรายการ: transaction.trc,
        รหัสธนาคาร: transaction.bankCode,
        เบิก: satangToBathCommaEscape(transaction.cashOut),
        ชำระ: satangToBathCommaEscape(transaction.cashIn),
        ค่าธรรมเนียม: satangToBathCommaEscape(transaction.dueFee),
        ลดขั้นต่ำ: satangToBathCommaEscape(transaction.waiveMinpay),
        ลดหนี้: satangToBathCommaEscape(transaction.waiveBalance),
        เงินต้นยกมา: satangToBathCommaEscape(transaction.bfPrincipal),
        ดอกเบี้ยยกมา: satangToBathCommaEscape(transaction.bfInterest),
        ค่าธรรมเนียมยกมา: satangToBathCommaEscape(transaction.bfFee),
        เงินต้นรับ: satangToBathCommaEscape(transaction.paidPrincipal),
        ดอกเบี้ยรับ: satangToBathCommaEscape(transaction.paidInterest),
        ค่าธรรมเนียมรับ: satangToBathCommaEscape(transaction.paidFee),
        เงินต้นยกไป: satangToBathCommaEscape(transaction.cfPrincipal),
        ดอกเบี้ยยกไป: satangToBathCommaEscape(transaction.cfInterest),
        ค่าธรรมเนียมยกไป: satangToBathCommaEscape(transaction.cfFee),
        ขั้นต่ำเรียกเก็บ: satangToBathCommaEscape(transaction.minDue),
        ขั้นต่ำชำระแล้ว: satangToBathCommaEscape(transaction.minPaid),
        วันชำระล่าสุด: satangToBathCommaEscape(transaction.lastpayDate),
        จำนวนชำระล่าสุด: satangToBathCommaEscape(transaction.lastpayAmount),
        b0: satangToBathCommaEscape(transaction.b[0]),
        b1: satangToBathCommaEscape(transaction.b[1]),
        b2: satangToBathCommaEscape(transaction.b[2]),
        b3: satangToBathCommaEscape(transaction.b[3]),
        b4: satangToBathCommaEscape(transaction.b[4]),
        b5: satangToBathCommaEscape(transaction.b[5]),
        b6: satangToBathCommaEscape(transaction.b[6]),
        b7: satangToBathCommaEscape(transaction.b[7]),
        b8: satangToBathCommaEscape(transaction.b[8]),
        b9: satangToBathCommaEscape(transaction.b[9]),
        b10: satangToBathCommaEscape(transaction.b[10]),
        b11: satangToBathCommaEscape(transaction.b[11]),
        b12: satangToBathCommaEscape(transaction.b[12]),
        b13: satangToBathCommaEscape(transaction.b[13]),
        refNo: transaction.refNo,
        extRefId: transaction.extRefId,
        statementCount: transaction.statementCount,
        ผู้ทำรายการ: transaction.createdBy,
      };
      return mapData;
    });
    const columns: any = [];
    const fileName = `transactions_${loan.id}_${moment().format('YYYYMMDD')}.csv`;
    downloadCsv(values, columns, fileName);
  }
  function showDeletedTransaction(checked: boolean) {
    // eslint-disable-next-line no-unused-expressions
    checked ? reloadData('delete') : reloadData('active');
  }

  const columns: any = [
    {
      title: <FormattedMessage id="transaction.id" />,
      key: 'id',
      width: '128px',
      render: (record: any) => {
        if (record.status === 'delete') return record.id;
        if (record.trc === 'PO') return <div style={{ color: 'red' }}>{record.id}</div>;

        return record.id;
      },
    },
    {
      title: <FormattedMessage id="transDate" />,
      key: 'transDate',
      width: '128px',
      render: (record: any) => {
        if (record.status === 'delete') return iso8601ToLocalDate(record.transDate);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{iso8601ToLocalDate(record.transDate)}</div>;

        return iso8601ToLocalDate(record.transDate);
      },
    },
    {
      title: <FormattedMessage id="trc" />,
      key: 'trc',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return record.trc;
        if (record.trc === 'PO') return <div style={{ color: 'red' }}>{record.trc}</div>;

        return record.trc;
      },
    },
    {
      title: <FormattedMessage id="bankCode" />,
      key: 'bankCode',
      width: '64px',
      render: (record: any) => {
        if (record.status === 'delete') return record.bankCode;
        if (record.trc === 'PO') return <div style={{ color: 'red' }}>{record.bankCode}</div>;

        return record.bankCode;
      },
    },
    {
      title: <FormattedMessage id="duration" />,
      key: 'duration',
      width: '128px',
      render: (record: any) => {
        if (record.status === 'delete') return record.duration;
        if (record.trc === 'PO') return <div style={{ color: 'red' }}>{record.duration}</div>;

        return record.duration;
      },
      align: 'right',
    },
    {
      title: <FormattedMessage id="cashIn" />,
      key: 'cashIn',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.cashIn);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.cashIn)}</div>;

        return satangToBath(record.cashIn);
      },
    },
    {
      title: <FormattedMessage id="cashOut" />,
      key: 'cashOut',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.cashOut);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.cashOut)}</div>;

        return satangToBath(record.cashOut);
      },
    },
    {
      title: <FormattedMessage id="cfPrincipal" />,
      key: 'cfPrincipal',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.cfPrincipal);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.cfPrincipal)}</div>;

        return satangToBath(record.cfPrincipal);
      },
    },
    {
      title: <FormattedMessage id="cfInterest" />,
      key: 'cfInterest',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.cfInterest);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.cfInterest)}</div>;

        return satangToBath(record.cfInterest);
      },
    },
    {
      title: <FormattedMessage id="cfFee" />,
      key: 'cfFee',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.cfFee);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.cfFee)}</div>;

        return satangToBath(record.cfFee);
      },
    },
    {
      title: <FormattedMessage id="minDue" />,
      key: 'minDue',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.minDue);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.minDue)}</div>;

        return satangToBath(record.minDue);
      },
    },
    {
      title: <FormattedMessage id="minPaid" />,
      key: 'minPaid',
      align: 'right',
      width: '96px',
      render: (record: any) => {
        if (record.status === 'delete') return satangToBath(record.minPaid);
        if (record.trc === 'PO')
          return <div style={{ color: 'red' }}>{satangToBath(record.minPaid)}</div>;

        return satangToBath(record.minPaid);
      },
    },
    {
      title: '',
      key: 'actionbutton',
      width: '96px',
      render: (record: any, row: any, index: any) => {
        const renderPrintButton = (record: any) => {
          const printableStatuses = [
            'ADJ',
            'PO',
            // 'CD',
            // 'BC',
            // 'CT',
            // 'PP',
            // 'CS',
            // 'PR',
            // 'WAB',
            // 'WAM',
            // 'WAC',
            // 'LC',
          ];
          if (printableStatuses.includes(record.trc) && record.status === 'active') {
            return (
              <a
                style={{ marginRight: '10px' }}
                tabIndex={row}
                role="button"
                onClick={() => {
                  printTransaction(record);
                }}
                onKeyPress={() => {
                  printTransaction(record);
                }}
              >
                <i style={{ cursor: 'pointer' }} className="icmn-printer" />
              </a>
            );
          }
          return null;
        };
        const renderQrCodeButton = (record: any) => {
          const printableStatuses = ['ADJ', 'PO'];
          let qrcodeTransaction = record;
          if (index !== 0 && transactions[index - 1].trc === 'LFE') {
            qrcodeTransaction = transactions[index - 1];
          }
          if (printableStatuses.includes(record.trc) && record.status === 'active') {
            return (
              <a
                style={{ marginRight: '10px' }}
                tabIndex={row}
                role="button"
                onClick={() => {
                  setqrcodeModalVisible(true);
                  setTranQrcode(qrcodeTransaction);
                }}
                onKeyPress={() => {
                  setqrcodeModalVisible(true);
                  setTranQrcode(qrcodeTransaction);
                }}
              >
                <i style={{ cursor: 'pointer' }} className="icmn-qrcode" />
              </a>
            );
          }
          return null;
        };
        const renderDeleteButton = (record: any) => {
          const DeleteButton = renderIfHasPermissisons(
            'transactions.*.delete',
            <a
              style={{ marginRight: '10px' }}
              tabIndex={row}
              role="button"
              onClick={() => {
                setTransId(record.id);
                setDeleteModalVisible(true);
              }}
              onKeyPress={() => {
                setTransId(record.id);
                setDeleteModalVisible(true);
              }}
            >
              <i style={{ cursor: 'pointer' }} className="icmn-bin" />
            </a>,
          );
          if (
            (index === 0 &&
              pagination.current === 1 &&
              record.trc !== 'LO' &&
              record.status === 'active') ||
            rollback
          ) {
            return <DeleteButton />;
          }
          return null;
        };
        const renderNoteButton = (record: any) => {
          return (
            <a
              style={{ marginRight: '10px' }}
              tabIndex={row}
              role="button"
              onClick={() => {
                setNoteModalVisible(true);
                setTransId(record.id);
                setNote(record.note);
              }}
              onKeyPress={() => {
                setNoteModalVisible(true);
                setTransId(record.id);
                setNote(record.note);
              }}
            >
              <i style={{ cursor: 'pointer' }} className="icmn-clipboard" />
            </a>
          );
        };
        return (
          <Fragment>
            {renderPrintButton(record)}
            {renderQrCodeButton(record)}
            {renderDeleteButton(record)}
            {renderNoteButton(record)}
          </Fragment>
        );
      },
    },
  ];
  const columnsBucket = [
    {
      title: 'b0',
      render: (record: any) => {
        return satangToBath(record.b[0]);
      },
    },
    {
      title: 'b1',
      render: (record: any) => {
        return satangToBath(record.b[1]);
      },
    },
    {
      title: 'b2',
      render: (record: any) => {
        return satangToBath(record.b[2]);
      },
    },
    {
      title: 'b3',
      render: (record: any) => {
        return satangToBath(record.b[3]);
      },
    },
    {
      title: 'b4',
      render: (record: any) => {
        return satangToBath(record.b[4]);
      },
    },
    {
      title: 'b5',
      render: (record: any) => {
        return satangToBath(record.b[5]);
      },
    },
    {
      title: 'b6',
      render: (record: any) => {
        return satangToBath(record.b[6]);
      },
    },
    {
      title: 'b7',
      render: (record: any) => {
        return satangToBath(record.b[7]);
      },
    },
    {
      title: 'b8',
      render: (record: any) => {
        return satangToBath(record.b[8]);
      },
    },
    {
      title: 'b9',
      render: (record: any) => {
        return satangToBath(record.b[9]);
      },
    },
    {
      title: 'b10',
      render: (record: any) => {
        return satangToBath(record.b[10]);
      },
    },
    {
      title: 'b11',
      render: (record: any) => {
        return satangToBath(record.b[11]);
      },
    },
    {
      title: 'b12',
      render: (record: any) => {
        return satangToBath(record.b[12]);
      },
    },
    {
      title: 'b13',
      render: (record: any) => {
        return satangToBath(record.b[13]);
      },
    },
  ];

  return (
    <Fragment>
      <Button
        type="primary"
        onClick={onDownloadCsv}
        loading={loading}
        style={{ marginBottom: '10px' }}
      >
        <FormattedMessage id="downloadAll" />
      </Button>
      <div>
        <Space direction="horizontal">
          <Switch
            checkedChildren={intl.formatMessage({ id: 'loan-edit.show-delete-transaction' })}
            unCheckedChildren={intl.formatMessage({ id: 'loan-edit.show-delete-transaction' })}
            defaultChecked={false}
            onClick={showDeletedTransaction}
          />
          <Switch
            checkedChildren={intl.formatMessage({ id: 'loan-edit.show-rollback-transaction' })}
            unCheckedChildren={intl.formatMessage({ id: 'loan-edit.show-rollback-transaction' })}
            defaultChecked={false}
            onClick={() => setRollback(!rollback)}
          />
        </Space>
      </div>
      <Table
        expandedRowRender={(record: any) => (
          <div>
            <Row gutter={16}>
              <Col span={10}>
                {record?.context === null || Object.keys(record.context).length === 0 ? (
                  <Row gutter={16}>
                    <Col span={5}>
                      <strong>Date:</strong>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <strong>{iso8601ToLocalDate(record.transDate)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>Duration: </strong>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <strong>{record.duration}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>MinDue: </strong>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.minDue)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>MinPaid: </strong>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.minPaid)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>IntDue: </strong>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.dueInterest)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>Amount: </strong>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.cashIn)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>WaiveBalance: </strong>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.waiveBalance)}</strong>
                    </Col>
                    <Col span={5}>
                      <strong>WaiveMinpay: </strong>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <strong>{satangToBath(record.waiveMinpay)}</strong>
                    </Col>
                  </Row>
                ) : (
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="info" key="1">
                      <Row gutter={16}>
                        <Col span={5}>
                          <strong>Date:</strong>
                        </Col>
                        <Col span={7} style={{ textAlign: 'right' }}>
                          <strong>{iso8601ToLocalDate(record.transDate)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>Duration: </strong>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <strong>{record.duration}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>MinDue: </strong>
                        </Col>
                        <Col span={7} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.minDue)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>MinPaid: </strong>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.minPaid)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>IntDue: </strong>
                        </Col>
                        <Col span={7} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.dueInterest)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>Amount: </strong>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.cashIn)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>WaiveBalance: </strong>
                        </Col>
                        <Col span={7} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.waiveBalance)}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>WaiveMinpay: </strong>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <strong>{satangToBath(record.waiveMinpay)}</strong>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="update" key="2">
                      <Row gutter={16}>{renderContext(record.context)}</Row>
                    </TabPane>
                  </Tabs>
                )}
              </Col>
              <Col span={14}>
                <table style={{ textAlign: 'right' }}>
                  <tr style={{ backgroundColor: '#f2f4f8', textAlign: 'center' }}>
                    <td />
                    <td>ยกมา</td>
                    <td>งวดนี้</td>
                    <td>ชำระ</td>
                    <td>เบิก</td>
                    <td>ยกไป</td>
                  </tr>
                  <tr style={{ backgroundColor: '#fff' }}>
                    <td style={{ backgroundColor: '#f2f4f8', textAlign: 'center' }}>ต้น</td>
                    <td>{satangToBath(record.bfPrincipal)}</td>
                    <td>{satangToBath(record.cashOut)}</td>
                    <td>{satangToBath(record.paidPrincipal)}</td>
                    <td>{satangToBath(record.cashOut)}</td>
                    <td>{satangToBath(record.cfPrincipal)}</td>
                  </tr>
                  <tr style={{ backgroundColor: '#f5f5f5' }}>
                    <td style={{ backgroundColor: '#f2f4f8', textAlign: 'center' }}>ดอก</td>
                    <td>{satangToBath(record.bfInterest)}</td>
                    <td>{satangToBath(record.dueInterest)}</td>
                    <td>{satangToBath(record.paidInterest)}</td>
                    <td>0.00</td>
                    <td>{satangToBath(record.cfInterest)}</td>
                  </tr>
                  <tr style={{ backgroundColor: '#fff' }}>
                    <td style={{ backgroundColor: '#f2f4f8', textAlign: 'center' }}>ปรับ</td>
                    <td>{satangToBath(record.bfFee)}</td>
                    <td>{satangToBath(record.dueFee)}</td>
                    <td>{satangToBath(record.paidFee)}</td>
                    <td>0.00</td>
                    <td>{satangToBath(record.cfFee)}</td>
                  </tr>
                </table>
              </Col>
            </Row>
            <br />
            <Table dataSource={[record]} columns={columnsBucket} pagination={false} />
          </div>
        )}
        dataSource={transactions}
        onRow={transactions => ({
          style: {
            background: transactions.status === 'delete' ? '#DFDFDF' : 'white',
          },
        })}
        rowKey="id"
        loading={loading}
        columns={columns}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
        onChange={onChange}
      />
      <QrcodeModal
        visible={qrcodeModalVisible}
        okText={intl.formatMessage({ id: 'confirm' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={() => setqrcodeModalVisible(false)}
        transaction={tranQrcode}
        loan={loan}
        projectId={projectId}
      />
      <DeleteModal
        visible={deleteModalVisible}
        okText={intl.formatMessage({ id: 'confirm' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={() => setDeleteModalVisible(false)}
        projectId={projectId}
        transId={transId}
        reloadData={reloadData}
        rollback={rollback}
      />
      <NoteModal
        visible={noteModalVisible}
        okText={intl.formatMessage({ id: 'confirm' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onCancel={() => setNoteModalVisible(false)}
        projectId={projectId}
        transId={transId}
        note={note}
        reloadData={reloadData}
      />
    </Fragment>
  );
};

export default TransactionTable;
