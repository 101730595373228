import React, { FC, Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import FilingModal from './FilingModal';

interface Props {
  projectId: string;
  loan: any;
  transactions: any[];
}
const FilingButton: FC<Props> = ({ projectId, loan, transactions }) => {
  const intl = useIntl();
  const [filingModalVisible, setFilingModalVisible] = useState<any>(false);
  const lastTransaction = transactions ? transactions[0] : {};
  return (
    <Fragment>
      <Button
        style={{
          marginLeft: '8px',
        }}
        type="primary"
        ghost
        onClick={() => setFilingModalVisible(true)}
      >
        <FormattedMessage id="filing" />
      </Button>

      <FilingModal
        projectId={projectId}
        visible={filingModalVisible}
        onCancel={() => setFilingModalVisible(false)}
        okText={intl.formatMessage({ id: 'filing' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        lastTransaction={lastTransaction}
        loan={loan}
      />
    </Fragment>
  );
};

export default FilingButton;
